import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import infoIcn from '../../images/modal/info-icn.svg'
import { useHistory } from 'react-router-dom';
import './styles.scss'

function ModalSeleksiWawancara(props) {
    const {
        id,
        wawancaraPribadi,
        wawancaraBahasaInggris,
        deleted,
        deleteScore
    } = props
    const history = useHistory()
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <div style={{ width: 500, padding: !deleted ? '30px 30px' : '20px 20px', textAlign: 'center' }}>
                <img src={infoIcn} alt='info' style={{ width: 80, height: 80 }} />
                <div className='txt-modal-wawancara'>
                    Silahkan Pilih Jenis Wawancara<br/>yang Akan Di{deleted ? 'hapus' : 'nilai'}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                        className='btn-modal-wawancara btn-green mr16'
                        onClick={() => !deleted ? history.push(`/seleksi-wawancara/${wawancaraPribadi ? 'ubah' : 'beri'}-nilai/pribadi`,{ id }) : deleteScore('hapus-wawancara-kepribadian')}
                    >
                        {
                            !deleted ?
                                wawancaraPribadi ?
                                'Ubah Nilai Kepribadian'
                                :
                                'Beri Nilai Kepribadian'
                            :
                            'Hapus Nilai Kepribadian'
                        }
                    </div>
                    <div
                        className='btn-modal-wawancara btn-blue'
                        onClick={() => !deleted ? history.push(`/seleksi-wawancara/${wawancaraBahasaInggris ? 'ubah' : 'beri'}-nilai/bahasa-inggris`,{ id }) : deleteScore('hapus-wawancara-bahasa-inggris')}
                    >
                        {
                            !deleted ?
                                wawancaraBahasaInggris ?
                                'Ubah Nilai Bahasa Inggris'
                                :
                                'Beri Nilai Bahasa Inggris'
                            :
                            'Hapus Nilai Bahasa Inggris'
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalSeleksiWawancara