import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DelIcn from '../../images/modal/delete.svg'
import './styles.scss'
import Button from '@material-ui/core/Button';

export default function ModalDeleteNilaiBerkas(props) {
    function hapus () {
        if (props.for === 'hapus nilai') {
            props.hapusNilai()
        } else if (props.for === 'hapus rank') {
            props.changeRank(null)
        }  else if (props.for === 'hapus status') {
            props.changeStatus(null)
        }
    }
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
            >
                <div className='con-mo-nilai'>
                    <img src={DelIcn} alt='info' style={{ width: 80, height: 80 }} />
                    <div className='txt-modal-nilai'>
                        {
                            props.for === 'hapus nilai' ?
                                'Yakin Akan Menghapus Penilaian yang Sudah Diberikan?' : null
                        }
                        {
                            props.for === 'hapus rank' ?
                                'Yakin Akan Menghapus Ranking?' : null
                        }
                        {
                            props.for === 'hapus status' ?
                                'Yakin Akan Menghapus Status Kelulusan?' : null
                        }

                    </div>
                    <div className='cek-nilai'>
                        {
                            props.for === 'hapus nilai' ?
                                'Nilai yang sudah dihapus tidak bisa dipulihkan lagi' : null
                        }
                        {
                            props.for === 'hapus rank' ?
                                'Cek kembali keputusan Anda sebelum menghapus ranking' : null
                        }
                        {
                            props.for === 'hapus status' ?
                                'Cek kembali keputusan Anda sebelum menghapus ranking' : null
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='btn-modal-wawancara btn-blue' style={{ width: 125, height: 44, marginRight: 20 }} onClick={hapus}>Ya, Hapus</div>
                        <Button className='btn-hapus-obs' variant='contained' style={{ width: 125, height: 44, background: '#E7E7E7' }} onClick={props.handleClose}>Batal</Button>
                    </div>
                </div>

            </Dialog>
        </>
    )
}
