import React, { useEffect, useState, Fragment } from "react";
import "./styles.scss";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfilPeserta } from "../../store/actions/dataPeserta";
import NoImage from "../../images/no-user-image-icon-18.jpg";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";

const KepribadianButton = withStyles((theme) => ({
  root: {
    marginTop: 5,
    marginRight: 16,
    width: "137px",
    height: "38px",
    color: "white;",
    backgroundColor: "#3598DC",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "#2f88c6",
      boxShadow: "none",
      color: "white",
    },
  },
}))(Button);

const EssayButton = withStyles((theme) => ({
  root: {
    marginTop: 5,
    marginRight: 16,
    width: "137px",
    height: "38px",
    color: "#3598DC",
    border: "1px solid #3598DC",
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    "&:hover": {
      boxShadow: "none",
    },
  },
}))(Button);

const MSButton = withStyles((theme) => ({
  root: {
    marginTop: 5,
    width: "207px",
    height: "38px",
    color: "#3598DC",
    border: "1px solid #3598DC",
    backgroundColor: "rgba(53, 152, 220, 0.15)",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    "&:hover": {
      boxShadow: "none",
    },
  },
}))(Button);

const InfoButton = withStyles((theme) => ({
  root: {
    marginTop: 9,
    paddingLeft: 20,
    paddingRight: 20,
    height: "29px",
    color: "#3598DC",
    backgroundColor: "rgba(53, 152, 220, 0.15)",
    borderRadius: "100px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    "&:hover": {
      boxShadow: "none",
    },
  },
}))(Button);

const TopButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#3c98dc",
    minWidth: 44,
    minHeight: 44,
    maxWidth: 44,
    maxHeight: 44,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    boxShadow:
      "0px 10px 20px rgba(74, 159, 193, 0.18), 0px 2px 6px rgba(74, 159, 193, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04)",
    borderRadius: 80,
    position: "fixed",
    bottom: 41,
    right: 53,
    zIndex: 99,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#3c98dc",
      boxShadow: "0px 10px 16px rgba(74, 159, 193, 0.25)",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    background: "#FAFAFA",
    borderRadius: "2px 2px 0px 0px",
  },
  tableHeaderFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#888888",
  },
  tableMain: {
    boxShadow: "none",
    border: "0.5px solid #D1D1D1",
    borderRadius: "2px",
    width: "100%",
  },
  pagination: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#999999",
    paddingLeft: 0,
    marginTop: 10,
  },
  paginationRight: {
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },
  formControl: {
    "& label.Mui-focused": {
      color: "#999999",
    },
    "& label": {
      color: "#999999",
    },
    "& .MuiOutlinedInput-root": {
      width: "200px",
      height: "44px",
      boxSizing: "border-box",
      borderRadius: "4px",
      transition: "0.8s",
      "&.Mui-focused fieldset": {
        border: "0.5px solid #D1D1D1",
      },
      "&:hover fieldset": {
        border: "0.5px solid #D1D1D1",
      },
    },
  },
}));

const DownloadButton = withStyles((theme) => ({
  root: {
    height: "40px",
    padding: "10px 20px",
    color: "#FFFFFF",
    backgroundColor: "#3598DC",
    fontFamily: ["Roboto", "sans-serif"],
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "19px",
    boxSizing: "border-box",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#2f88c6",
    },
  },
}))(Button);

function ProfilPeserta() {
  const { id } = useParams();
  const classes = useStyles();
  const Dispatch = useDispatch();
  const peserta = useSelector((state) => state.dataPesertaState.profilePeserta);
  const chapterList = useSelector((state) => state.chapterState.chapter);
  const propinsiDB = useSelector((state) => state.daerahState.propinsi);
  const kabupatenDB = useSelector((state) => state.daerahState.kabupaten);
  const essay = useSelector((state) => state.filterState.essay);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, [document.body.scrollTop]);

  useEffect(() => {
    if (id) {
      Dispatch(getProfilPeserta(id));
    }
  }, [id]);

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  };

  const goOnTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const getMyName = (dataPribadi) => {
    let namaDepan;
    let namaTengah;
    let namaBelakang;
    if (dataPribadi) {
      namaDepan = dataPribadi.namaDepan;
      namaTengah = dataPribadi.namaTengah;
      namaBelakang = dataPribadi.namaBelakang;
    }
    return `${namaDepan ? namaDepan + " " : ""}${
      namaTengah ? namaTengah + " " : ""
    }${namaBelakang ? namaBelakang + " " : ""}`;
  };

  const getChapterCode = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter((a) => a._id === chapter)[0];
      return temp ? temp.code : "-";
    }
  };

  const getValue = (obj, key) => {
    let result;
    if (obj) {
      result = obj[key];
    }
    return `${result ? result : "-"}`;
  };

  const getReligion = (dataPribadi) => {
    let agama;
    if (dataPribadi) {
      if (dataPribadi.agama) {
        agama =
          dataPribadi.agama === "protestan" ? "kristen" : dataPribadi.agama;
      }
    }
    return `${agama ? agama : "-"}`;
  };

  const getTwoValue = (obj, key, key2) => {
    let result;
    let result2;
    if (obj) {
      result = obj[key];
      result2 = obj[key2];
    }
    return `${result ? result : "-"}, ${result2 ? result2 : "-"}`;
  };

  const getAddressBorn = (dataPribadi) => {
    let tempat;
    let tanggal;
    if (dataPribadi) {
      tempat = dataPribadi.tempatLahir;
      tanggal = dataPribadi.tanggalLahir;
    }
    return `${tempat ? tempat : "-"}, ${tanggal ? getDate(tanggal) : "-"}`;
  };

  const getAddressPlace = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsi) {
        if (propinsiDB.filter((a) => a.id === dataPribadi.propinsi)[0]) {
          propinsi = propinsiDB.filter((a) => a.id === dataPribadi.propinsi)[0]
            .propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsi]) {
        if (
          kabupatenDB[dataPribadi.propinsi].filter(
            (a) => a.id === dataPribadi.kabupaten
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsi].filter(
            (a) => a.id === dataPribadi.kabupaten
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamat;
      kecamatan = dataPribadi.kecamatan;
      kodepos = dataPribadi.kodepos;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getDate = (value) => {
    if (value) {
      let start = new Date(value).getDate();
      let startMonth = new Date(value).getMonth();
      let monthStart = "";
      let yearResult = new Date(value).getFullYear();
      switch (startMonth) {
        case 0:
          monthStart = "Januari";
          break;
        case 1:
          monthStart = "Februari";
          break;
        case 2:
          monthStart = "Maret";
          break;
        case 3:
          monthStart = "April";
          break;
        case 4:
          monthStart = "Mei";
          break;
        case 5:
          monthStart = "Juni";
          break;
        case 6:
          monthStart = "Juli";
          break;
        case 7:
          monthStart = "Agustus";
          break;
        case 8:
          monthStart = "September";
          break;
        case 9:
          monthStart = "Oktober";
          break;
        case 10:
          monthStart = "November";
          break;
        case 11:
          monthStart = "Desember";
          break;
        default:
          monthStart = "";
          break;
      }

      return start + " " + monthStart + " " + yearResult;
    }
  };

  const getFamilyTable = (obj, key) => {
    let result = [
      {
        nama: "-",
        tanggalLahir: null,
        sekolahPekerjaan: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getYear = (value) => {
    if (value) {
      let yearResult = new Date(value).getFullYear();
      return yearResult;
    } else {
      return "-";
    }
  };

  const getAddressSchool = (riwayatPendidikan) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (riwayatPendidikan) {
      if (riwayatPendidikan.propinsiSekolah) {
        if (
          propinsiDB.filter(
            (a) => a.id === riwayatPendidikan.propinsiSekolah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === riwayatPendidikan.propinsiSekolah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[riwayatPendidikan.propinsiSekolah]) {
        if (
          kabupatenDB[riwayatPendidikan.propinsiSekolah].filter(
            (a) => a.id === riwayatPendidikan.kabupatenSekolah
          )[0]
        ) {
          kota = kabupatenDB[riwayatPendidikan.propinsiSekolah].filter(
            (a) => a.id === riwayatPendidikan.kabupatenSekolah
          )[0].kabupaten;
        }
      }
      alamat = riwayatPendidikan.alamatSekolah;
      kecamatan = riwayatPendidikan.kecamatanSekolah;
      kodepos = riwayatPendidikan.kodeposSekolah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getMapelTable = (obj, key) => {
    let result = [
      {
        kelas: "-",
        semester: "-",
        agama: "-",
        pancasila: "-",
        indonesia: "-",
        matematika: "-",
        sejarah: "-",
        inggris: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getMapelTableSMP = (obj, key) => {
    let result = [
      {
        kelas: "-",
        semester: "-",
        agama: "-",
        pancasila: "-",
        indonesia: "-",
        matematika: "-",
        ipa: "-",
        ips: "-",
        inggris: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const convertToRoman = (num) => {
    if (num) {
      let roman = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      };
      var str = "";
      for (var i of Object.keys(roman)) {
        var q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
      }
      return str ? str : "-";
    } else {
      return "-";
    }
  };

  const getPengetahuanBahasa = (obj, key) => {
    let result = [
      {
        bahasa: "-",
        lama: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getKegiatan = (obj, key) => {
    let result = [
      {
        nama: "-",
        jenis: "-",
        jabatan: "-",
        tahun: null,
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getRiwayatKunjungan = (obj, key) => {
    let result = [
      {
        negara: "-",
        tahun: null,
        rangka: "-",
      },
    ];
    if (obj) {
      result = obj[key];
    }
    return result;
  };

  const getEditorValue = (obj, key) => {
    let result;
    if (obj) {
      result = obj[key]?.text;
    }
    return result ? result : null;
  };

  const getEditorFile = (obj, key) => {
    let result;
    if (obj) {
      result = obj[key]?.file;
    }
    return result ? result : null;
  };

  const getTopic = (topic, choice, program) => {
    if (topic && program) {
      let temp = program.toLowerCase();
      if (temp) {
        if (topic[temp]) {
          return topic[temp][choice - 1];
        }
      }
    }
  };

  const getNegara = (obj) => {
    let result = [];
    if (obj) {
      result = obj;
    }
    return result;
  };

  const getAddressLS = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiLingkunganSekolah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganSekolah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganSekolah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiLingkunganSekolah]) {
        if (
          kabupatenDB[dataPribadi.propinsiLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganSekolah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganSekolah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatLingkunganSekolah;
      kecamatan = dataPribadi.kecamatanLingkunganSekolah;
      kodepos = dataPribadi.kodeposLingkunganSekolah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressLS2 = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiKerjaLingkunganSekolah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganSekolah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganSekolah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiKerjaLingkunganSekolah]) {
        if (
          kabupatenDB[dataPribadi.propinsiKerjaLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganSekolah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiKerjaLingkunganSekolah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganSekolah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatKerjaLingkunganSekolah;
      kecamatan = dataPribadi.kecamatanKerjaLingkunganSekolah;
      kodepos = dataPribadi.kodeposKerjaLingkunganSekolah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressLR = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiLingkunganRumah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganRumah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiLingkunganRumah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiLingkunganRumah]) {
        if (
          kabupatenDB[dataPribadi.propinsiLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganRumah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenLingkunganRumah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatLingkunganRumah;
      kecamatan = dataPribadi.kecamatanLingkunganRumah;
      kodepos = dataPribadi.kodeposLingkunganRumah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressLR2 = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiKerjaLingkunganRumah) {
        if (
          propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganRumah
          )[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiKerjaLingkunganRumah
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiKerjaLingkunganRumah]) {
        if (
          kabupatenDB[dataPribadi.propinsiKerjaLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganRumah
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiKerjaLingkunganRumah].filter(
            (a) => a.id === dataPribadi.kabupatenKerjaLingkunganRumah
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatKerjaLingkunganRumah;
      kecamatan = dataPribadi.kecamatanKerjaLingkunganRumah;
      kodepos = dataPribadi.kodeposKerjaLingkunganRumah;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  const getAddressTD = (dataPribadi) => {
    let alamat;
    let kecamatan;
    let kota;
    let propinsi;
    let kodepos;
    if (dataPribadi) {
      if (dataPribadi.propinsiTemanDekat) {
        if (
          propinsiDB.filter((a) => a.id === dataPribadi.propinsiTemanDekat)[0]
        ) {
          propinsi = propinsiDB.filter(
            (a) => a.id === dataPribadi.propinsiTemanDekat
          )[0].propinsi;
        }
      }
      if (kabupatenDB[dataPribadi.propinsiTemanDekat]) {
        if (
          kabupatenDB[dataPribadi.propinsiTemanDekat].filter(
            (a) => a.id === dataPribadi.kabupatenTemanDekat
          )[0]
        ) {
          kota = kabupatenDB[dataPribadi.propinsiTemanDekat].filter(
            (a) => a.id === dataPribadi.kabupatenTemanDekat
          )[0].kabupaten;
        }
      }
      alamat = dataPribadi.alamatTemanDekat;
      kecamatan = dataPribadi.kecamatanTemanDekat;
      kodepos = dataPribadi.kodeposTemanDekat;
    }
    return `${alamat ? alamat : "-"}, Kecamatan ${
      kecamatan ? kecamatan : "-"
    }, Kota/Kabupaten ${kota ? kota : "-"}, Propinsi ${
      propinsi ? propinsi : "-"
    } ${kodepos ? kodepos : ""}`;
  };

  return (
    <div className="info-peserta-wrapper-container">
      {hidden && (
        <TopButton onClick={goOnTop}>
          <KeyboardArrowUpIcon style={{ fontSize: 30 }} />
        </TopButton>
      )}
      <div className="info-peserta-wrapper">
        <div className="wrapper-back-info-peserta">
          <span className="info-peserta-title-header">Profil Peserta</span>
        </div>
        <div className="bread-cab-container">
          <span className="bread-grey">Profil Peserta /</span>
        </div>
        <div className="card-info-peserta-picture">
          {peserta?.pasFoto ? (
            <img
              src={peserta.pasFoto}
              className="info-peserta-picture"
              alt="pictures"
            />
          ) : (
            <img
              src={NoImage}
              className="info-peserta-picture"
              alt="pictures"
            />
          )}
          <div className="card-info-pictures-wrap-one">
            <span className="card-info-font-name">
              {getMyName(peserta?.dataPribadi)}
            </span>
            <span className="card-info-font-nourut" style={{ marginTop: 10 }}>
              YBA/YP25-26/
              {getChapterCode(chapterList, peserta?.chapterId)}/
              {peserta?.program === "KAKEHASHI" ? "MXT" : peserta?.program}/
              {peserta &&
              peserta.nomorUrut !== "0000" &&
              peserta.statusVerifikasiBerkas === true
                ? peserta.nomorUrut
                : "-"}
            </span>
            {peserta?.program === "AFS" && (
              <InfoButton>{peserta?.pinId && peserta.pinId.pin}</InfoButton>
            )}
            <div className="card-info-button-row" style={{ marginTop: "auto" }}>
              <KepribadianButton
                onClick={(e) => document.getElementById("kepribadian").focus()}
              >
                {" "}
                Rekomendasi{" "}
              </KepribadianButton>
              <EssayButton
                onClick={(e) => document.getElementById("essay").focus()}
              >
                {" "}
                Essay{" "}
              </EssayButton>
              <MSButton
                onClick={(e) => document.getElementById("motivasi").focus()}
              >
                {" "}
                Motivational Statement{" "}
              </MSButton>
            </div>
          </div>
          <div className="card-info-pictures-wrap-two wrap-two-131231">
            <span className="card-info-litle-title-grey"> Asal Sekolah </span>
            <span className="card-info-litle-title">
              {getValue(peserta?.riwayatPendidikan, "namaSMA")}
            </span>
            <InfoButton>
              {getValue(peserta?.riwayatPendidikan, "jenisSekolah")}
            </InfoButton>
            <div className="card-info-pictures-bottom">
              <div className="card-info-pictures-content-bottom">
                <span className="card-info-litle-title-grey">
                  {" "}
                  Pilihan Program{" "}
                </span>
                <span className="card-info-litle-title">
                  {peserta?.program === "KAKEHASHI"
                    ? "ASIA KAKEHASHI PROJECT"
                    : peserta?.program}
                </span>
              </div>
              <div
                className="card-info-pictures-content-bottom"
                style={{ marginLeft: 59 }}
              >
                <span className="card-info-litle-title-grey"> Chapter </span>
                <span className="card-info-litle-title">
                  {getChapterCode(chapterList, peserta?.chapterId)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-info-pictures-wrap-two wrap-two-standin-131231">
          <div
            style={{
              display: "flex",
            }}
          >
            <InfoButton
              style={{
                marginRight: 15,
              }}
            >
              {getValue(peserta?.riwayatPendidikan, "jenisSekolah")}
            </InfoButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="card-info-litle-title-grey"> Asal Sekolah </span>
              <span className="card-info-litle-title">
                {getValue(peserta?.riwayatPendidikan, "namaSMA")}
              </span>
            </div>
          </div>
          <div className="card-info-pictures-bottom">
            <div className="card-info-pictures-content-bottom">
              <span className="card-info-litle-title-grey">
                {" "}
                Pilihan Program{" "}
              </span>
              <span className="card-info-litle-title">
                {peserta?.program === "KAKEHASHI"
                  ? "ASIA KAKEHASHI PROJECT"
                  : peserta?.program}
              </span>
            </div>
            <div
              className="card-info-pictures-content-bottom"
              style={{ marginLeft: 59 }}
            >
              <span className="card-info-litle-title-grey"> Chapter </span>
              <span className="card-info-litle-title">
                {getChapterCode(chapterList, peserta?.chapterId)}
              </span>
            </div>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header"> Data Pribadi </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-one"> Nama Depan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "namaDepan")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Nama Tengah </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "namaTengah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Belakang{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "namaBelakang")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Tempat dan Tanggal Lahir{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressBorn(peserta?.dataPribadi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Email{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "email")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nomor Ponsel{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "telepon")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Lengkap{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressPlace(peserta?.dataPribadi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Jenis Kelamin{" "}
            </span>
            <span
              className="card-info-view-input-wrap-two"
              style={{ textTransform: "capitalize" }}
            >
              {getValue(peserta?.dataPribadi, "kelamin")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Tinggi Badan{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "tinggi")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Berat Badan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "berat")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Golongan Darah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.dataPribadi, "goldar")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Kewarganegaraan{" "}
            </span>
            <span
              className="card-info-view-input-wrap-two"
              style={{ textTransform: "capitalize" }}
            >
              {getValue(peserta?.dataPribadi, "wargaNegara")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Agama </span>
            <span
              className="card-info-view-input-wrap-two"
              style={{ textTransform: "capitalize" }}
            >
              {getReligion(peserta?.dataPribadi)}
            </span>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header"> Pilihan Program </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-one"> Program </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.pilihanProgram, "program")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              Dimana Adik Mengetahui tentang Program{" "}
              <b>
                {peserta?.program === "KAKEHASHI"
                  ? "Asia Kakehashi Project"
                  : peserta?.program === "YES"
                  ? `KL-YES`
                  : peserta?.program
                  ? peserta?.program.toUpperCase()
                  : "-"}
              </b>
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.pilihanProgram, "optionProgram")}
              {getValue(peserta?.pilihanProgram, "optionProgram") === "Lainnya"
                ? `  -  ${getValue(
                    peserta?.pilihanProgram,
                    "optionProgramLainnya"
                  )}`
                : ""}
            </span>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header"> Keluarga </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* AYAH */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue"> Ayah </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Lengkap Ayah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "namaAyah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Pendidikan Terakhir{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "pendidikanAyah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Pekerjaan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "pekerjaanAyah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Email{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "emailAyah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nomor Ponsel{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "teleponAyah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama dan Alamat Kantor{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getTwoValue(
                peserta?.infoKeluarga,
                "namaKantorAyah",
                "alamatKantorAyah"
              )}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* IBU */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue"> Ibu </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Lengkap Ibu{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "namaIbu")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Pendidikan Terakhir{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "pendidikanIbu")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Pekerjaan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "pekerjaanIbu")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Email{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "emailIbu")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nomor Ponsel{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "teleponIbu")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama dan Alamat Kantor{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getTwoValue(
                peserta?.infoKeluarga,
                "namaKantorIbu",
                "alamatKantorIbu"
              )}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* WALI */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue"> Wali </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Lengkap Wali{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "namaWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Hubungan Dengan Adik{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "hubunganWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Pendidikan Terakhir{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "pendidikanWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Email{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "emailWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nomor Ponsel{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "teleponWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Rumah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alamatRumahWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Pekerjaan/Jabatan{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "pekerjaanWali")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama dan Alamat Kantor{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getTwoValue(
                peserta?.infoKeluarga,
                "namaKantorWali",
                "alamatKantorWali"
              )}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* Kakak dan Adik */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Kakak dan Adik{" "}
            </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span
              className="card-info-view-input-wrap-two"
              style={{ minWidth: 270 }}
            >
              {`Anak ke ${getValue(
                peserta?.infoKeluarga,
                "anakKe"
              )} dari ${getValue(
                peserta?.infoKeluarga,
                "dariAnak"
              )} Bersaudara`}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <TableContainer className={classes.tableMain} component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont}>
                      NO
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      NAMA SAUDARA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      TANGGAL LAHIR
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      SEKOLAH/PEKERJAAN
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFamilyTable(peserta?.infoKeluarga, "saudara").map(
                    (anak, i) => (
                      <TableRow key={i} className="table-hover-style-custom">
                        <TableCell component="th" scope="row">
                          {(i += 1)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {anak ? (anak.nama ? anak.nama : "-") : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {anak
                            ? anak.tanggalLahir
                              ? getDate(anak.tanggalLahir)
                              : "-"
                            : "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {anak
                            ? anak.sekolahPekerjaan
                              ? anak.sekolahPekerjaan
                              : "-"
                            : "-"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Adakah di antara keluarga besar Adik yang pernah mengikuti program
              pertukaran yang diselenggarakan oleh Bina Antarbudaya?{" "}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one"> Nama </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alumniNama")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Hubungan Dengan Peserta{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alumniHubungan")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Program </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alumniProgram")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Tujuan (Sending)/Asal (Hosting){" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alumniTujuanAsal")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Sekarang{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alumniAlamat")}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Pernahkah Adik atau keluarga Adik berpartisipasi dalam kegiatan
              Bina Antarbudaya sebelumnya?{" "}
            </span>
            {/* <span className="card-info-view-input-wrap-two"></span> */}
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one"> Kegiatan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.infoKeluarga, "alumniKegiatan")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Tahun </span>
            <span className="card-info-view-input-wrap-two">
              {getYear(
                peserta &&
                  peserta.infoKeluarga &&
                  peserta.infoKeluarga.alumniTahun
              )}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header"> Riwayat Pendidikan </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* SMA/SMK/MA */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              SMA/SMK/MA/Pesantren{" "}
            </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Jenis Sekolah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "jenisSekolah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Sekolah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "namaSMA")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Sekolah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressSchool(peserta?.riwayatPendidikan)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Telepon </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "teleponSekolah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Faks </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "faksSekolah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Tahun Masuk </span>
            <span className="card-info-view-input-wrap-two">
              {getYear(
                peserta &&
                  peserta.riwayatPendidikan &&
                  peserta.riwayatPendidikan.tahunMasukSekolah
              )}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Tahun Keluar{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getYear(
                peserta &&
                  peserta.riwayatPendidikan &&
                  peserta.riwayatPendidikan.tahunKeluarSekolah
              )}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-two">
              {" "}
              Data Prestasi SMA/SMK/MA(Non Pesantren)/Pesantren (Skala 0-100){" "}
            </span>
            <span className="card-info-view-input-wrap-one"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <TableContainer
              className={classes.tableMain}
              style={{ width: "100%" }}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont}>
                      NO
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      KELAS
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      SEMESTER
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      PENDIDIKAN AGAMA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      PANCASILA DAN KEWARGANEGARAAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      BAHASA INDONESIA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      MATEMATIKA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      SEJARAH INDONESIA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      BAHASA INGGRIS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getMapelTable(
                    peserta?.riwayatPendidikan,
                    "prestasiSekolah"
                  ).map(
                    (mapel, i) =>
                      mapel && (
                        <TableRow key={i} className="table-hover-style-custom">
                          <TableCell component="th" scope="row">
                            {(i += 1)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.kelas
                                ? convertToRoman(mapel.kelas)
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.semester
                                ? convertToRoman(mapel.semester)
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel ? (mapel.agama ? mapel.agama : "-") : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.pancasila
                                ? mapel.pancasila
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.indonesia
                                ? mapel.indonesia
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.matematika
                                ? mapel.matematika
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.sejarah
                                ? mapel.sejarah
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.inggris
                                ? mapel.inggris
                                : "-"
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* SMP/MTs */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue"> SMP/MTs </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Sekolah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "namaSMP")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Tahun Ijazah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getYear(
                peserta &&
                  peserta.riwayatPendidikan &&
                  peserta.riwayatPendidikan.tahunIjazahSMP
              )}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-two">
              {" "}
              Data prestasi SMP/MTs (skala 0-100, Indeks Prestasi, atau bentuk
              abjad){" "}
            </span>
            <span className="card-info-view-input-wrap-one"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <TableContainer
              className={classes.tableMain}
              style={{ width: "100%" }}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont}>
                      NO
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      KELAS
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      SEMESTER
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      PENDIDIKAN AGAMA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      PANCASILA DAN KEWARGANEGARAAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      BAHASA INDONESIA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      MATEMATIKA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      ILMU PENGETAHUAN ALAM
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      ILMU PENGETAHUAN SOSIAL
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      BAHASA INGGRIS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getMapelTableSMP(
                    peserta?.riwayatPendidikan,
                    "prestasiSMP"
                  ).map(
                    (mapel, i) =>
                      mapel && (
                        <TableRow key={i} className="table-hover-style-custom">
                          <TableCell component="th" scope="row">
                            {(i += 1)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.kelas
                                ? convertToRoman(mapel.kelas)
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.semester
                                ? convertToRoman(mapel.semester)
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel ? (mapel.agama ? mapel.agama : "-") : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.pancasila
                                ? mapel.pancasila
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.indonesia
                                ? mapel.indonesia
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.matematika
                                ? mapel.matematika
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel ? (mapel.ipa ? mapel.ipa : "-") : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel ? (mapel.ips ? mapel.ips : "-") : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {mapel
                              ? mapel.inggris
                                ? mapel.inggris
                                : "-"
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* SD/MI */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue"> SD/MI </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Nama Sekolah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "namaSD")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Tahun Ijazah{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getYear(
                peserta &&
                  peserta.riwayatPendidikan &&
                  peserta.riwayatPendidikan.tahunIjazahSD
              )}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* Pengetahuan Bahasa */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Pengetahuan Bahasa{" "}
            </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          {getPengetahuanBahasa(
            peserta?.riwayatPendidikan,
            "pengetahuanBahasa"
          ).map(
            (data, i) =>
              data && (
                <div
                  className="card-info-view-input-wrap"
                  style={{ marginTop: i === 0 ? 15 : 10 }}
                  key={i}
                >
                  <span className="card-info-view-input-wrap-one">
                    {data ? (data.bahasa ? data.bahasa : "-") : "-"}
                  </span>
                  <span className="card-info-view-input-wrap-two">
                    {data ? (data.lama ? data.lama : "-") : "-"}
                  </span>
                </div>
              )
          )}
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* Pelajaran Favorit dan Cita-Cita */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Pelajaran Favorit dan Cita-Cita{" "}
            </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Mata pelajaran favorit{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "pelajaranFavorit")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Cita-cita </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.riwayatPendidikan, "citaCita")}
            </span>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header"> Riwayat Kegiatan </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          {/* Kegiatan */}
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue"> Kegiatan </span>
            <span className="card-info-view-input-wrap-two"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-two">
              {" "}
              Kegiatan Pernah dan Sedang Diikuti{" "}
            </span>
            <span className="card-info-view-input-wrap-one"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <TableContainer
              className={classes.tableMain}
              style={{ width: "100%" }}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont}>
                      NO
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      KEGIATAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      JENIS KEGIATAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      JABATAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      TAHUN{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <td className="table-td-pdf">{}</td>
                  <td className="table-td-pdf">{}</td>
                  <td className="table-td-pdf">{}</td>
                  <td className="table-td-pdf">{}</td>
                  {getKegiatan(peserta?.riwayatKegiatan, "kegiatan").map(
                    (person, i) =>
                      person && (
                        <TableRow key={i} className="table-hover-style-custom">
                          <TableCell component="th" scope="row">
                            {(i += 1)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person ? (person.nama ? person.nama : "-") : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person ? (person.jenis ? person.jenis : "-") : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.jabatan
                                ? person.jabatan
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.tahun
                                ? getYear(person.tahun)
                                : "-"
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-two">
              {" "}
              Prestasi yang pernah diraih{" "}
            </span>
            <span className="card-info-view-input-wrap-one"></span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <TableContainer
              className={classes.tableMain}
              style={{ width: "100%" }}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont}>
                      NO
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      KEJUARAAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      TINGKAT
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      PRESTASI
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      TAHUN{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getKegiatan(peserta?.riwayatKegiatan, "prestasi").map(
                    (person, i) =>
                      person && (
                        <TableRow key={i} className="table-hover-style-custom">
                          <TableCell component="th" scope="row">
                            {(i += 1)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.kejuaraan
                                ? person.kejuaraan
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.tingkat
                                ? person.tingkat
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.prestasi
                                ? person.prestasi
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.tahun
                                ? getYear(person.tahun)
                                : "-"
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header">
            {" "}
            RIWAYAT PERJALANAN KE LUAR NEGERI{" "}
          </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Pernahkah Adik Melawat/Berpergian Dalam Jangka Pendek ke Luar
              Negeri?{" "}
            </span>
            {/* <span className="card-info-view-input-wrap-two"></span> */}
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <TableContainer
              className={classes.tableMain}
              style={{ width: "100%" }}
              component={Paper}
            >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      Ke Mana?
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      Kapan?
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      Dalam Rangka Apa?
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRiwayatKunjungan(
                    peserta?.riwayatPerjalanan,
                    "kunjungan"
                  ).map(
                    (person, i) =>
                      person && (
                        <TableRow key={i} className="table-hover-style-custom">
                          <TableCell component="th" scope="row">
                            {person
                              ? person.negara
                                ? person.negara
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.tahun
                                ? getYear(person.tahun)
                                : "-"
                              : "-"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {person
                              ? person.rangka
                                ? person.rangka
                                : "-"
                              : "-"}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              {" "}
              Pernahkah Adik tinggal di luar negeri?{" "}
            </span>
            {/* <span className="card-info-view-input-wrap-two"></span> */}
          </div>
          {peserta &&
          peserta.riwayatPerjalanan &&
          peserta.riwayatPerjalanan.tinggalDiluarNegeri === "false" ? (
            <div
              className="card-info-view-input-wrap"
              style={{ marginTop: 15 }}
            >
              <span className="card-info-view-input-wrap-two">
                {" "}
                Belum Pernah{" "}
              </span>
              <span className="card-info-view-input-wrap-one"></span>
            </div>
          ) : (
            <div
              className="card-info-view-input-wrap"
              style={{ marginTop: 15 }}
            >
              <TableContainer
                className={classes.tableMain}
                style={{ width: "100%" }}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell
                        className={classes.tableHeaderFont}
                        align="left"
                      >
                        Negara
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderFont}
                        align="left"
                      >
                        Dari Tahun
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderFont}
                        align="left"
                      >
                        Sampai Tahun
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRiwayatKunjungan(
                      peserta?.riwayatPerjalanan,
                      "tinggal"
                    ).map(
                      (person, i) =>
                        person && (
                          <TableRow
                            key={i}
                            className="table-hover-style-custom"
                          >
                            <TableCell component="th" scope="row">
                              {person
                                ? person.negara
                                  ? person.negara
                                  : "-"
                                : "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {person
                                ? person.dariTahun
                                  ? getYear(person.dariTahun)
                                  : "-"
                                : "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {person
                                ? person.sampaiTahun
                                  ? getYear(person.sampaiTahun)
                                  : "-"
                                : "-"}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <a href="top" id="essay" />
          <span className="card-info-peserta-header"> Motivational Statements </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div
            className="card-info-view-input-wrap-box-grey"
            style={{ marginTop: 15}}
          >
            <Link
              to={{
                pathname: peserta?.motivasi?.file || ""
              }}
              target="_BLANK"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              <span className="title-grey-top-litle" style={{textTransform: "lowercase", textDecoration: "italic", color: "#3598DC"}}>
                {peserta?.motivasi?.file || "-"}
              </span>
            </Link>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <a href="top" id="essay" />
          <span className="card-info-peserta-header"> essay </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div
            className="card-info-view-input-wrap-box-grey"
            style={{ marginTop: 15}}
          >
            <Link
              to={{
                pathname: peserta?.essay?.file || ""
              }}
              target="_BLANK"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              <span className="title-grey-top-litle" style={{textTransform: "lowercase", textDecoration: "italic", color: "#3598DC"}}>
                {peserta?.essay?.file || "-"}
              </span>
            </Link>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header">
            {" "}
            pilihan negara tujuan{" "}
          </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              Tujuan Negara{" "}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <ol
              className="list-item-font-style-card-info"
              style={{
                margin: 0,
                textAlign: "left",
                textTransform: "capitalize",
                color: "#000000",
              }}
            >
              {getNegara(peserta?.pilihanNegara).map(
                (data, i) =>
                  data && (
                    <li
                      key={i}
                      style={{
                        marginTop: i !== 0 && 10,
                        fontSize: 17,
                      }}
                    >
                      {data ? data : "-"}
                    </li>
                  )
              )}
            </ol>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <a href="top" id="kepribadian" />
          <span className="card-info-peserta-header"> rekomendasi </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              Lingkungan Sekolah{" "}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one"> Nama </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "namaLingkunganSekolah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Alamat </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressLS(peserta?.rekomendasi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Telepon </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "teleponLingkunganSekolah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Pekerjaan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "pekerjaanLingkunganSekolah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Pekerjaan{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressLS2(peserta?.rekomendasi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Hubungan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "hubunganLingkunganSekolah")}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">
              Lingkungan Rumah/Organisasi di Luar Sekolah{" "}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one"> Nama </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "namaLingkunganRumah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Alamat </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressLR(peserta?.rekomendasi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Telepon </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "teleponLingkunganRumah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Pekerjaan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "pekerjaanLingkunganRumah")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one">
              {" "}
              Alamat Pekerjaan{" "}
            </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressLR2(peserta?.rekomendasi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Hubungan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "hubunganLingkunganRumah")}
            </span>
          </div>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div className="card-info-view-input-wrap" style={{ marginTop: 20 }}>
            <span className="card-info-view-input-wrap-blue">Teman Dekat</span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 15 }}>
            <span className="card-info-view-input-wrap-one"> Nama </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "namaTemanDekat")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Alamat </span>
            <span className="card-info-view-input-wrap-two">
              {getAddressTD(peserta?.rekomendasi)}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Telepon </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "teleponTemanDekat")}
            </span>
          </div>
          <div className="card-info-view-input-wrap" style={{ marginTop: 10 }}>
            <span className="card-info-view-input-wrap-one"> Hubungan </span>
            <span className="card-info-view-input-wrap-two">
              {getValue(peserta?.rekomendasi, "hubunganTemanDekat")}
            </span>
          </div>
        </div>
        <div className="card-info-data-pribadi-wrapper">
          <span className="card-info-peserta-header"> kepribadian </span>
          <Divider
            style={{
              height: 0.5,
              backgroundColor: "#D1D1D1",
              width: "100%",
              marginTop: 10,
            }}
          />
          <div
            className="card-info-view-input-wrap-box-grey"
            style={{ marginTop: 15}}
          >
            <Link
              to={{
                pathname: peserta?.kepribadian?.file || ""
              }}
              target="_BLANK"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              <span className="title-grey-top-litle" style={{textTransform: "lowercase", textDecoration: "italic", color: "#3598DC"}}>
                {peserta?.kepribadian?.file || "-"}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilPeserta;
