import React, { useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import './styles.scss'
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// COMPONENT
import InfoChapter from './infoChapter'
import AdminChapter from './adminChapter'
import Unduhan from './unduhan'
import { useSelector } from 'react-redux'

function TabPanel(props) {  
    const { children, value, index, ...other } = props;
    const History = useHistory()
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function PengaturanUmum(){
    const History = useHistory()
    const profile = useSelector(state=>state.userState.profile)
    const [page, setPage] = useState(0);

    const handleChangeTabs = (page) =>{
        setPage(page)
        if (page === 0) {
            History.push('/pengaturan-umum/info-chapter')
        } else if (page === 1) {
            History.push('/pengaturan-umum/admin-chapter')
        } else if (page === 2) {
            History.push('/pengaturan-umum/interviewer')
        } else if (page === 3) {
            History.push('/pengaturan-umum/observer')
        } else if (page === 4) {
            History.push('/pengaturan-umum/juri-nasional')
        }
    }

    useEffect(()=>{
        if (profile) {
            if ((History.location.pathname).includes('/pengaturan-umum/info-chapter')) {
                setPage(0)
            } else if ((History.location.pathname).includes('/pengaturan-umum/admin-chapter')) {
                setPage(1)
            } else {
                History.push("/pengaturan-umum/info-chapter")
                setPage(0)
            }
        }
    },[profile])

    return(
        <div className="data-container-wrapper" >
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Pengaturan Umum
                </span>
            </div>
            <div className="bread-cab-container">
                <span className="bread-blue">
                    Pengaturan Umum /
                </span>
                <span className="bread-grey">
                    {
                        page === 0 ? 'Info Chapter /' :
                        page === 1 ? 'Admin Chapter /' :
                        page === 2 ? 'Unduhan /' :
                        page === 3 ? 'Observer /' :
                        page === 4 ? 'Juri Nasional /' :
                        ''
                    }
                </span>
            </div>
            <div className="content-parent-wrapper-2021">
                <div style={{ width: '100%' }} >
                    <Tabs
                        value={page}
                        variant="scrollable"
                        scrollButtons="auto"
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label="scrollable force tabs example"
                        style={{ backgroundColor: 'white', height: '80px', borderRadius: 4, width: '100%', paddingRight: 20 }}
                        TabIndicatorProps={{
                            style:{
                                backgroundColor: '#3598DC',
                                height: 4
                            }
                        }}
                        ScrollButtonComponent={(props) => {
                            if (
                                props.direction === "left" &&
                                !props.disabled
                            ) {
                                return (
                                    <div className="btn-input-appbar">
                                        <ChevronLeftIcon {...props}
                                            style={{
                                                color: 'white',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                );
                            } else if (
                                props.direction === "right" &&
                                !props.disabled
                            ) {
                                return (
                                    <div className="btn-input-appbar">
                                        <ChevronRightIcon {...props}
                                            style={{
                                                height: '100%',
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }}
                    >
                        <Tab
                            label="Info Chapter"
                            className={'select-tabs'}
                            onClick={()=>handleChangeTabs(0)} {...a11yProps(0)}
                        />
                        <Tab
                            label="Admin Chapter"
                            className={'select-tabs'}
                            onClick={()=>handleChangeTabs(1)} {...a11yProps(1)}
                        />
                        <Tab
                            label="Unduhan"
                            className={'select-tabs'}
                            onClick={()=>handleChangeTabs(2)} {...a11yProps(2)}
                        />
                        {/* <Tab
                            label="Interviewer"
                            disabled={true}
                            className={'select-tabs-disabled'}
                        />
                        <Tab
                            label="Observer"
                            disabled={true}
                            className={'select-tabs-disabled'}
                        />
                        <Tab
                            label="Juri Nasional"
                            disabled={true}
                            className={'select-tabs-disabled'}
                        /> */}
                    </Tabs>
                </div>
                {
                    page === 0 ?
                    <InfoChapter/>
                    :
                    page === 1 ?
                    <AdminChapter/> :
                    page === 2 &&
                    <Unduhan/>
                }
            </div>
        </div>
    )
}

export default PengaturanUmum