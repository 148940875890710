import {
    GET_PROFILPESERTA,
    GET_VERIFIKASI,
    SET_VERIFIKASI,
    GET_SELEKSI_BERKAS,
    SET_SELEKSI_BERKAS,
    GET_PROFIL_PESERTA_WAWANCARA_KEPRIBADIAN,
    GET_PROFIL_PESERTA_WAWANCARA_BAHASA_INGGRIS,
    GET_SELEKSI_WAWANCARA,
    SET_SELEKSI_WAWANCARA,
    GET_DETAIL_NILAI_PESERTA,
    GET_SELEKSI_INTERAKSI_KELOMPOK,
    SET_SELEKSI_INTERAKSI_KELOMPOK,
    GET_PROFIL_PESERTA_INTERAKSI_KELOMPOK
} from "../constanta";

const intialState = {
    verifikasiBerkas: null,
    profilePeserta: null,
    seleksiBerkas: null,
    seleksiWawancara: null,
    seleksiInteraksiKelompok: null,
    pesertaWawancaraKepribadian: null,
    pesertaWawancaraBahasaInggris: null,
    detailNilaiPeserta: null,
    pesertaInteraksiKelompok: null,
}

function dataPesertaState(state = intialState, action) {
    switch (action.type) {
        case GET_VERIFIKASI:
            return {
                ...state,
                verifikasiBerkas: action.data
            }
        case SET_VERIFIKASI:
            let data = action.data.dataAll
            data[action.data.index] = action.data.data
            return {
                ...state,
                verifikasiBerkas: data
            }
        case GET_PROFILPESERTA:
            return {
                ...state,
                profilePeserta: action.data
            }
        case GET_SELEKSI_BERKAS:
            return {
                ...state,
                seleksiBerkas: action.data
            }
        case SET_SELEKSI_BERKAS:
            let dataBerkas = action.data.dataAll
            dataBerkas[action.data.index] = action.data.data
            console.log(action.data, 'dattttttttttttttttttt')
            return {
                ...state,
                seleksiBerkas: dataBerkas
            }
        case GET_SELEKSI_WAWANCARA:
            return {
                ...state,
                seleksiWawancara: action.data,
                pesertaWawancaraKepribadian: null,
                pesertaWawancaraBahasaInggris: null,
                detailNilaiPeserta: null
            }
        case SET_SELEKSI_WAWANCARA:
            let data1 = action.data.dataAll
            data1[action.data.index] = action.data.data
            return {
                ...state,
                seleksiWawancara: data1
            }
        case GET_PROFIL_PESERTA_WAWANCARA_KEPRIBADIAN:
            return {
                ...state,
                pesertaWawancaraKepribadian: action.data
            }
        case GET_PROFIL_PESERTA_WAWANCARA_BAHASA_INGGRIS:
            return {
                ...state,
                pesertaWawancaraBahasaInggris: action.data
            }
        case GET_DETAIL_NILAI_PESERTA:
            return {
                ...state,
                detailNilaiPeserta: action.data
            }
        case GET_SELEKSI_INTERAKSI_KELOMPOK:
            return {
                ...state,
                seleksiInteraksiKelompok: action.data,
                pesertaInteraksiKelompok: null
            }
        case SET_SELEKSI_INTERAKSI_KELOMPOK:
            let data2 = action.data.dataAll
            data2[action.data.index] = action.data.data
            return {
                ...state,
                seleksiInteraksiKelompok: data2
            }
        case GET_PROFIL_PESERTA_INTERAKSI_KELOMPOK:
            return {
                ...state,
                pesertaInteraksiKelompok: action.data
            }
        default: {
            return state
        }
    }
}

export default dataPesertaState