
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const BatalButton = withStyles((theme) => ({
    root: {
        width: '140px',
        height: '44px',
        color: '#3598DC',
        border: "1px solid #3598DC",
        boxSizing: "border-box",
        backgroundColor: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: 'none',
            boxShadow: 'none',
            color: '#3598DC',
        },
    },
}))(Button);

export const KembaliButton = withStyles((theme) => ({
    root: {
        width: '100px',
        height: '36px',
        color: '#3598DC',
        border: "1px solid #3598DC",
        boxSizing: "border-box",
        backgroundColor: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        '&:hover': {
            backgroundColor: 'none',
            boxShadow: 'none',
            color: '#3598DC',
        },
    },
}))(Button);



export const InputButton = withStyles((theme) => ({
    root: {
        marginLeft: 20,
        width: '140px',
        height: '44px',
        color: 'white',
        boxSizing: "border-box",
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#3598DC',
            boxShadow: 'none',
            color: 'white',
        },
    },
}))(Button);

export const InputEditButton = withStyles((theme) => ({
    root: {
        marginLeft: 20,
        width: '210px',
        height: '44px',
        color: 'white',
        boxSizing: "border-box",
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#3598DC',
            boxShadow: 'none',
            color: 'white',
        },
    },
}))(Button);