import axios from 'axios'
import server from '../../endpoint'
import { generateToken } from '../../helpers/bearer'
import {
    GET_OPTIONS
} from "../constanta";

export const getOption = () => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/option`,
                headers: {
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_OPTIONS,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const changeOption = ({key, route, program, Swal}) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if (token && key) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'patch',
                url: `${server}/option/${(route).toLowerCase()}`,
                data:{ program },
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Mengganti Status ${key}`,
            })
            dispatch({
                type: GET_OPTIONS,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

