import React, { useState, useEffect } from "react";
import "./styles.scss";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PaginationCustom from "../../../../components/PaginationCustom";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPinGroup } from "../../../../store/actions/pin";
import { DatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../../../components/Breadcrumb";
import Swal from "sweetalert2";

const CetakButton = withStyles((theme) => ({
  root: {
    height: "32px",
    padding: "0px 15px",
    color: "#3598DC",
    backgroundColor: "rgba(53, 152, 220, 0.1)",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    "&:hover": {
      boxShadow: "none",
    },
  },
}))(Button);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    background: "#FAFAFA",
    borderRadius: "2px 2px 0px 0px",
  },
  tableHeaderFont: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#888888",
  },
  tableMain: {
    boxShadow: "none",
    border: "0.5px solid #D1D1D1",
    borderRadius: "2px",
  },
  pagination: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#999999",
    paddingLeft: 0,
    marginTop: 10,
  },
  paginationRight: {
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },
  date: {
    background: "#FAFAFA",
    border: "0.5px solid #D1D1D1",
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "100%",
    height: "44px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "0px 16px",
    display: "flex",
    outline: "none",
    "& .MuiOutlinedInput-input": {
      padding: "12.4px 14px",
    },
    "& .MuiInputBase-input": {
      color: "#999999",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0.5px solid #D1D1D1",
      },
      "&:hover fieldset": {
        border: "0.5px solid #D1D1D1",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #D1D1D1",
      },
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-6px",
      color: "#cfd0ca",
    },
  },
});

function DaftarPIN() {
  // const pin = useSelector(state=>state.pinState.pin)
  const chapter = useSelector((state) => state.chapterState.chapter);
  const userState = useSelector((state) => state.userState.profile);
  const chapterRole = useSelector((state) => state.chapterState.chapterRole);
  const classes = useStyles();
  const History = useHistory();
  const Dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [PIN, setPIN] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    chapter: "",
    penerbit: "",
    date: null,
  });

  useEffect(() => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
    });
    Swal.showLoading();
    Dispatch(
      getPinGroup(
        {
          limit,
          offset: page,
          penerbit: dataFilter?.penerbit,
          chapter_id: dataFilter?.chapter,
          expired_date: showDate(dataFilter?.date),
        },
        ({ status, data, error }) => {
          Swal.close();
          if (status === 200) {
            setPIN(data);
          } else if (status === 400) {
            Toast.fire({
              icon: "error",
              title: error,
            });
          }
        }
      )
    );
  }, [page, limit, dataFilter.penerbit, dataFilter.chapter, dataFilter?.date]);

  const handleChangeValue = (key, value) => {
    switch (key) {
      case "penerbit":
        setDataFilter({
          ...dataFilter,
          penerbit: value,
        });
        break;
      case "chapter":
        setDataFilter({
          ...dataFilter,
          chapter: value,
        });
        break;
      case "date":
        setDataFilter({
          ...dataFilter,
          date: value,
        });
        break;
      default:
        break;
    }
  };

  let showDateTime = (dateParams) => {
    if (dateParams) {
      let date = new Date(dateParams).getDate();
      let monthNumber = new Date(dateParams).getMonth();
      let month = "";
      let year = new Date(dateParams).getFullYear();
      switch (monthNumber) {
        case 0:
          month = "Januari";
          break;
        case 1:
          month = "Februari";
          break;
        case 2:
          month = "Maret";
          break;
        case 3:
          month = "April";
          break;
        case 4:
          month = "Mei";
          break;
        case 5:
          month = "Juni";
          break;
        case 6:
          month = "Juli";
          break;
        case 7:
          month = "Agustus";
          break;
        case 8:
          month = "September";
          break;
        case 9:
          month = "Oktober";
          break;
        case 10:
          month = "November";
          break;
        case 11:
          month = "Desember";
          break;
        default:
          month = "hehe";
          break;
      }
      var time =
        (new Date(dateParams).getHours() < 10
          ? "0" + new Date(dateParams).getHours()
          : new Date(dateParams).getHours()) +
        ":" +
        (new Date(dateParams).getMinutes() < 10
          ? "0" + new Date(dateParams).getMinutes()
          : new Date(dateParams).getMinutes()) +
        ":" +
        (new Date(dateParams).getSeconds() < 10
          ? "0" + new Date(dateParams).getSeconds()
          : new Date(dateParams).getSeconds());
      if (date < 10) {
        return "0" + date + " " + month + " " + year + " " + time;
      } else {
        return date + " " + month + " " + year + " " + time;
      }
    } else {
      return "-";
    }
  };

  let showDate = (dateParams) => {
    if (dateParams) {
      let date = new Date(dateParams).getDate();
      let monthNumber = new Date(dateParams).getMonth();
      let month = "";
      let year = new Date(dateParams).getFullYear();
      switch (monthNumber) {
        case 0:
          month = "01";
          break;
        case 1:
          month = "02";
          break;
        case 2:
          month = "03";
          break;
        case 3:
          month = "04";
          break;
        case 4:
          month = "05";
          break;
        case 5:
          month = "06";
          break;
        case 6:
          month = "07";
          break;
        case 7:
          month = "08";
          break;
        case 8:
          month = "09";
          break;
        case 9:
          month = "10";
          break;
        case 10:
          month = "11";
          break;
        case 11:
          month = "12";
          break;
        default:
          month = "hehe";
          break;
      }
      if (date < 10) {
        return year + "/" + month + "/" + "0" + date;
      } else {
        return year + "/" + month + "/" + date;
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChange = (event, value) => {
    setPage(value - 1);
  };

  const handleDetail = ({ group, chapter }, route) => {
    History.push(
      `/management-pin/daftar/${route}/q?chapter=${chapter}&group=${group}`
    );
  };

  const getChapterName = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter((a) => a._id === chapter)[0];
      return temp ? temp.chapter : "-";
    }
  };

  const getLengthPIN = (PIN) => {
    let result = 0;
    if (PIN) {
      result = PIN.length;
    }
    return result;
  };

  const getUsedPIN = (PIN) => {
    let result = 0;
    if (PIN) {
      PIN.forEach((pin) => {
        if (pin.pesertaId) {
          result += 1;
        }
      });
    }
    return result;
  };

  const getUnUsedPIN = (PIN) => {
    let result = 0;
    if (PIN) {
      PIN.forEach((pin) => {
        if (!pin.pesertaId) {
          result += 1;
        }
      });
    }
    return result;
  };

  const getPenerbit = (penerbit) => {
    let result = "-";
    if (penerbit) {
      if (penerbit.role) {
        result = penerbit.role;
      }
    }
    return result;
  };

  return (
    <div className="data-container-wrapper">
      <div className="bread-cab-container">
        <span className="input-data-header">Management PIN</span>
      </div>
      <Breadcrumb
        links={[
          {
            label: "Management PIN",
            link: true,
          },
          {
            label: "Daftar PIN",
            link: false,
          },
        ]}
      />
      <div className="daftar-component-wrapper">
        <div className="daftar-component-header">
          <span className="daftar-header-font">
            Daftar PIN yang Telah Diterbitkan
          </span>
        </div>
        <div className="daftar-table-wrapper">
          <div className="daftar-filter-table">
            <select
              disabled={
                userState && userState.role === "admin chapter" ? true : false
              }
              className="filter-table"
              onChange={(e) => handleChangeValue("penerbit", e.target.value)}
              style={{
                color: dataFilter.penerbit ? "#666666" : "#C9C9C9",
                textTransform: "capitalize",
                marginRight: 10,
              }}
            >
              <option
                value=""
                style={{
                  color: dataFilter.penerbit ? "#d3d5d7" : "#d3d5d7",
                }}
                selected
              >
                Akun Penerbit
              </option>
              {chapterRole?.map((chapter, i) => (
                <option
                  key={i}
                  value={chapter.role}
                  style={{
                    color:
                      dataFilter.penerbit === chapter.role
                        ? "#3c98dc"
                        : "#666666",
                  }}
                  selected={dataFilter.penerbit === chapter.role ? true : false}
                >
                  {chapter.role}
                </option>
              ))}
            </select>
            <select
              disabled={
                userState && userState.role === "admin chapter" ? true : false
              }
              className="filter-table"
              onChange={(e) => handleChangeValue("chapter", e.target.value)}
              style={{
                color: dataFilter.chapter ? "#666666" : "#C9C9C9",
                textTransform: "capitalize",
                marginRight: 10,
              }}
            >
              <option
                value=""
                style={{
                  color: dataFilter.chapter ? "#d3d5d7" : "#d3d5d7",
                }}
                selected
              >
                Pilih Chapter
              </option>
              {chapter
                ?.sort((a, b) =>
                  a?.chapter < b?.chapter ? -1 : a?.chapter > b?.chapter ? 1 : 0
                )
                .map((chapter, i) => (
                  <option
                    key={i}
                    value={chapter._id}
                    style={{
                      color:
                        dataFilter.chapter === chapter.chapter
                          ? "#3c98dc"
                          : "#666666",
                    }}
                    selected={
                      dataFilter.chapter === chapter.chapter ? true : false
                    }
                  >
                    {chapter.chapter}
                  </option>
                ))}
            </select>
            <div className="filter-table-date-style-2021">
              <DatePicker
                clearable
                clearLabel="Hapus"
                autoOk
                views={["year", "month", "date"]}
                placeholder="Tanggal Kadaluarsa"
                value={dataFilter.date}
                onChange={(newValue) => {
                  handleChangeValue("date", newValue);
                }}
                animateYearScrolling
                okLabel=""
                cancelLabel=""
                helperText=""
                format="dd/MM/yyyy"
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  border: "0px",
                  zIndex: "9",
                  height: "44px",
                  borderRadius: "4px",
                }}
                minDate={new Date("2024-01-1")}
                maxDate={new Date("2025-12-31")}
              />
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{
                  color: "#3c98dc",
                  fontSize: 18,
                }}
              />
            </div>
          </div>
          <div className="daftar-table">
            <TableContainer className={classes.tableMain} component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeaderFont}>
                      NO
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      CHAPTER
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      TANGGAL PENERBITAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      TANGGAL KADALUARSA
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      AKUN PENERBIT
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      JUMLAH
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      SUDAH DIAKTIFKAN
                    </TableCell>
                    <TableCell className={classes.tableHeaderFont} align="left">
                      BELUM DIAKTIFKAN
                    </TableCell>
                    <TableCell
                      className={classes.tableHeaderFont}
                      style={{ minWidth: 220 }}
                      align="left"
                    >
                      AKSI
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // ?.filter(pin=>
                    //     dataFilter.penerbit ? dataFilter.penerbit === getPenerbit(pin && pin.penerbitId) : pin
                    // )
                    // .filter(pin=>
                    //     dataFilter.chapter ? dataFilter.chapter === getChapterName(chapter, pin && pin.chapterId) : pin
                    // )
                    // .filter(pin=>
                    //     dataFilter.date ? showDate(dataFilter.date) === showDate(pin && pin.expired) : pin
                    // )
                    // .slice(page * limit, page * limit + limit)
                    // .sort((a,b) =>  getChapterName(chapter, a && a.chapterId) < getChapterName(chapter, a && b.chapterId) ? -1 : getChapterName(chapter, a && a.chapterId) > getChapterName(chapter, a && b.chapterId) ? 1 : 0)
                    PIN?.data?.map((PIN, i) => (
                      <TableRow key={i} className="table-hover-style-custom">
                        <TableCell component="th" scope="row">
                          {(page - 1) * limit + i + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textTransform: "capitalize" }}
                        >
                          {getChapterName(chapter, PIN?.chapterId)}
                        </TableCell>
                        <TableCell align="left">
                          {showDateTime(PIN?.terbit)}
                        </TableCell>
                        <TableCell align="left">
                          {showDateTime(PIN?.expired)}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textTransform: "capitalize" }}
                        >
                          {PIN?.role || "-"}
                        </TableCell>
                        <TableCell align="left">
                          {getLengthPIN(PIN?.pinId)}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className="btn-detail-pin"
                            onClick={() =>
                              handleDetail(
                                { group: PIN?._id, chapter: PIN?.chapterId },
                                "aktif"
                              )
                            }
                          >
                            <span>{getUsedPIN(PIN?.pinId)}</span>
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className="btn-detail-pin"
                            onClick={() =>
                              handleDetail(
                                { group: PIN?._id, chapter: PIN?.chapterId },
                                "tidak-aktif"
                              )
                            }
                          >
                            <span>{getUnUsedPIN(PIN?.pinId)}</span>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: `/management-pin/download/${PIN?._id}`,
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CetakButton> Cetak / Download </CetakButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationCustom
              handleChangePage={handleChangePage}
              page={page}
              setPage={setPage}
              dataFilter={PIN?.length || 0}
              limit={limit}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DaftarPIN;
