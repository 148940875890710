import React, { useState, useEffect } from 'react'
import Back from '../../../components/Button/Back'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Divider from '@material-ui/core/Divider';
import { BatalButton, InputButton, InputEditButton } from '../../../components/Button/Cus';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SERVER from '../../../endpoint';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ModalSuccesNilai from '../../../components/modal/ModalSuccesNilai';
import { toFixedIfNecessary } from '../../../helpers/decimal';
import { setRefreshSeleksiWawancara } from '../../../store/actions/user'

export default function BeriNilaiBerkas() {
    const [openModal, setOpenModal] = useState(false)
    const chapter = useSelector(state => state.chapterState.chapter)
    const history = useHistory()
    const Dispatch = useDispatch()
    const dataSMP = [1, 1, 1, 1]
    const dataSMA = [1, 1]
    const { id } = useParams()
    const [peserta, setPeserta] = useState(null)
    const [nilaiBank, setNilaiBank] = useState([])
    const [nilaiEssay, setNilaiEssay] = useState(null)
    const [nilaiSejarah, setNilaiSejarah] = useState(null)
    const [nilaiEssayTerbobot, setNilaiEssayTerbobot] = useState(null)
    const [nilaiMotivasi, setNilaiMotivasi] = useState(null)
    const [nilaiMotivasiTerbobot, setNilaiMotivasiTerbobot] = useState(null)
    const [click, setClick] = useState(null)
    const [keterangan, setKeterangan] = useState(null)
    const [def, setDef] = useState ({status: null, rankSeleksi: null})
    const [title, setTitle] = useState('INPUT')
    const [err, setErr] = useState(false)

    useEffect(() => {
        let a = history.location.pathname.split('/')
        if (a[a.length - 2] === 'edit') {
            setTitle('UBAH')
        }
        window.scrollTo(0, 0)
        let count = []
        for (let i = 1; i <= 100; i++) {
            count.push(i)
        }
        setNilaiBank(count)

        //getDetail
        let token = localStorage.getItem('token')
        axios({
            method: 'get',
            url: `${SERVER}/peserta/seleksi-berkas/detail/${id}`,
            headers: { token }
        })
            .then(data => {
                setPeserta(data.data)
                if (a[a.length - 2] === 'edit') {
                    setNilaiEssay(data.data.seleksiBerkas.esai)
                    setNilaiEssayTerbobot(data.data.seleksiBerkas.esaiNilaiTerbobot)
                    setNilaiMotivasi(data.data.seleksiBerkas.mStatement)
                    setNilaiMotivasiTerbobot(data.data.seleksiBerkas.mStatementNilaiTerbobot)
                    setNilaiSejarah(data.data.seleksiBerkas.sejarahKepemimpinan)
                    setKeterangan(data.data.seleksiBerkas.keterangan)
                    setDef({status: data.data.seleksiBerkas.status, rankSeleksi: data.data.seleksiBerkas.rankSeleksi})
                }
            })
            .catch(function (error) {
                console.log(error);
            })

    }, [])

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan + ' ' : ''}${namaTengah ? namaTengah + ' ' : ''}${namaBelakang ? namaBelakang + ' ' : ''}`
    }

    function closeModal() {
        setOpenModal(false)
    }

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a => a._id === chapter)[0]
            return temp ? temp.code : '-'
        }
    }

    const noPesertaGenerate = (peserta) => {
        let options = JSON.parse(localStorage.getItem('options'))
        let program = '-'
        if (options) {
            if (options.program === 'KAKEHASHI') {
                program = 'MXT'
            } else {
                program = options.program
            }
        }
        if (peserta) {
            return `YBA/YP25-26/${getChapterCode(chapter, peserta && peserta.chapterId)}/${program}/${peserta.nomorUrut === '0000' ? '-' : peserta.nomorUrut}`
        }
        return ''
    }

    const handleNilaiEssay = (nilai) => {
        setNilaiEssay(nilai)
        if (nilai) {
            var percentToGet = 20;
            var percentAsDecimal = (percentToGet / 100);
            var percent = toFixedIfNecessary((percentAsDecimal * nilai));
            setNilaiEssayTerbobot(percent)
        } else {
            setNilaiEssayTerbobot(null)
        }
    }

    const getChapter = () => {
        if (chapter && peserta) {
            let temp = chapter.filter(a => a._id === peserta.chapterId)[0]
            return temp ? temp.chapter : '-'
        }
    }

    const handleNilaiMotivasi = (nilai) => {
        setNilaiMotivasi(nilai)
        if (nilai) {
            var percentToGet = 15;
            var percentAsDecimal = (percentToGet / 100);
            var percent = toFixedIfNecessary((percentAsDecimal * nilai));
            setNilaiMotivasiTerbobot(percent)
        } else {
            setNilaiMotivasiTerbobot(null)
        }
    }

    function submit() {
        if (nilaiEssay && nilaiMotivasi && nilaiSejarah) {
            let token = localStorage.getItem('token')
            axios({
                method: 'patch',
                url: `${SERVER}/peserta/nilai-seleksi-berkas/${id}`,
                headers: { token },
                data: { esai: nilaiEssay, esaiNilaiTerbobot: nilaiEssayTerbobot, mStatement: nilaiMotivasi, mStatementNilaiTerbobot: nilaiMotivasiTerbobot, sejarahKepemimpinan: nilaiSejarah, keterangan: keterangan, status: def.status, rankSeleksi: def.rankSeleksi}
            })
                .then(data => {
                    Dispatch(setRefreshSeleksiWawancara(true))
                    setOpenModal(true)
                })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            setErr(true)
        }
    }

    return (
        <div>
            <ModalSuccesNilai open={openModal} handleClose={closeModal} namaSeleksi='Berkas' nama={getMyName(peserta && peserta.dataPribadi)} />
            <Container className='con-beri-nilai'>
                <Back />
                <Paper className='paper-beri-nilai pt10'>
                    <div className='title-beri'>
                        {title} NILAI SELEKSI BERKAS
                    </div>
                    <div className='div-blue-beri ta-left'>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Nama Peserta</div><span className='txt-left'>:</span><div className='div-right'>{getMyName(peserta && peserta.dataPribadi)}</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Nomor Ujian</div><span className='txt-left'>:</span><div className='div-right'>{noPesertaGenerate(peserta)}</div>
                        </div>
                        <div className='flex ai-center'>
                            <div className='txt-left div-left'>Chapter</div><span className='txt-left'>:</span><div className='div-right capitalize'>{getChapter()}</div>
                        </div>
                    </div>
                    <div className='mx50'>
                        <div className='txt-beri-sub-title mt56 mb20'>Nilai Peserta</div>
                        <div className='txt-beri-sch'>SMP/MTs</div>
                        <div>
                            <Table className='tabel-beri'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='t-head-beri'>KELAS</TableCell>
                                        <TableCell className='t-head-beri'>SEMESTER</TableCell>
                                        <TableCell className='t-head-beri'>PENDIDIKAN<br />AGAMA</TableCell>
                                        <TableCell className='t-head-beri'>PANCASILA DAN<br />KEWARGANEGARAAN</TableCell>
                                        <TableCell className='t-head-beri'>BAHASA<br />INDONESIA</TableCell>
                                        <TableCell className='t-head-beri'>MATEMATIKA</TableCell>
                                        <TableCell className='t-head-beri'>IPA</TableCell>
                                        <TableCell className='t-head-beri'>IPS</TableCell>
                                        <TableCell className='t-head-beri'>BAHASA<br />INGGRIS</TableCell>
                                        <TableCell className='t-head-beri'>JUMLAH</TableCell>
                                        <TableCell className='t-head-beri'>RATA-RATA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        peserta && peserta.riwayatPendidikan.prestasiSMP.map((item, i) => (
                                            <TableRow>
                                                <TableCell>{item.kelas}</TableCell>
                                                <TableCell>{item.semester}</TableCell>
                                                <TableCell>{item.agama}</TableCell>
                                                <TableCell>{item.pancasila}</TableCell>
                                                <TableCell>{item.indonesia}</TableCell>
                                                <TableCell>{item.matematika}</TableCell>
                                                <TableCell>{item.ipa}</TableCell>
                                                <TableCell>{item.ips}</TableCell>
                                                <TableCell>{item.inggris}</TableCell>
                                                <TableCell>{Number(item.agama) + Number(item.pancasila) + Number(item.indonesia) + Number(item.matematika) + Number(item.ipa) + Number(item.ips) + Number(item.inggris)}</TableCell>
                                                <TableCell>{toFixedIfNecessary(((Number(item.agama) + Number(item.pancasila) + Number(item.indonesia) + Number(item.matematika) + Number(item.ipa) + Number(item.ips) + Number(item.inggris)) / 7))}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>

                        </div>
                        <div className='txt-beri-sch mt30'>SMA/SMK/MA/Pesantren</div>
                        <div>
                            <Table className='tabel-beri'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='t-head-beri'>KELAS</TableCell>
                                        <TableCell className='t-head-beri'>SEMESTER</TableCell>
                                        <TableCell className='t-head-beri'>PENDIDIKAN<br />AGAMA</TableCell>
                                        <TableCell className='t-head-beri'>PANCASILA DAN<br />KEWARGANEGARAAN</TableCell>
                                        <TableCell className='t-head-beri'>BAHASA<br />INDONESIA</TableCell>
                                        <TableCell className='t-head-beri'>MATEMATIKA</TableCell>
                                        <TableCell className='t-head-beri'>SEJARAH<br />INDONESIA</TableCell>
                                        <TableCell className='t-head-beri'>BAHASA<br />INGGRIS</TableCell>
                                        <TableCell className='t-head-beri'>JUMLAH</TableCell>
                                        <TableCell className='t-head-beri'>RATA-RATA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        peserta && peserta.riwayatPendidikan.prestasiSekolah.map((item, i) => (
                                            <TableRow>
                                                <TableCell>{item.kelas}</TableCell>
                                                <TableCell>{item.semester}</TableCell>
                                                <TableCell>{item.agama}</TableCell>
                                                <TableCell>{item.pancasila}</TableCell>
                                                <TableCell>{item.indonesia}</TableCell>
                                                <TableCell>{item.matematika}</TableCell>
                                                <TableCell>{item.sejarah}</TableCell>
                                                <TableCell>{item.inggris}</TableCell>
                                                <TableCell>{Number(item.agama) + Number(item.pancasila) + Number(item.indonesia) + Number(item.matematika) + Number(item.sejarah) + Number(item.inggris)}</TableCell>
                                                <TableCell>{toFixedIfNecessary(((Number(item.agama) + Number(item.pancasila) + Number(item.indonesia) + Number(item.matematika) + Number(item.sejarah) + Number(item.inggris)) / 6))}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>
                        <div className='txt-beri-sub-title mt47 mb20'>Beri Nilai Dibawah ini</div>
                        <div className='box-beri-nilai-berkas'>
                            <div className='div-out-nilai'>
                                <div className="flex" >
                                    {/* Esai */}
                                    <div className="div-input-nilai-berkas mr15">
                                        <span className="data-pribadi-font">
                                            Esai
                                        </span>
                                        <select className={err && !nilaiEssay ? "data-pribadi-input mt10 input-err" : "data-pribadi-input mt10"} value={nilaiEssay} onChange={e => handleNilaiEssay(e.target.value)} style={{ color: nilaiEssay ? "#444444" : "#C9C9C9", border: (click && !nilaiEssay) && '1px solid red' }} >
                                            <option value="" selected style={{ color: "#C9C9C9" }} >Pilih Nilai yang Diberikan</option>
                                            {
                                                nilaiBank.sort((a, b) => b - a).map(nilai => (
                                                    <option key={nilai} value={nilai} style={{ color: "#444444" }}>{nilai}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="div-input-nilai-berkas">
                                        <span className="data-pribadi-font" style={{ marginRight: 10 }} >
                                            Nilai Terbobot (20%)
                                        </span>
                                        <input style={{ cursor: 'default', marginTop: 10, background: "#F0F0F0", color: "#A0A0A0" }} type="text" value={nilaiEssayTerbobot} readOnly placeholder="Nilai akan dihitung otomatis" className="data-pribadi-input" />
                                    </div>
                                </div>
                                <div className="flex mt25">
                                    {/* Motivational Statement */}
                                    <div className="div-input-nilai-berkas mr15">
                                        <span className="data-pribadi-font">
                                            Motivational Statement
                                        </span>
                                        <select className={err && !nilaiMotivasi ? "data-pribadi-input mt10 input-err" : "data-pribadi-input mt10"} value={nilaiMotivasi} onChange={e => handleNilaiMotivasi(e.target.value)} style={{ color: nilaiMotivasi ? "#444444" : "#C9C9C9", border: (click && !nilaiMotivasi) && '1px solid red' }} >
                                            <option value="" selected style={{ color: "#C9C9C9" }} >Pilih Nilai yang Diberikan</option>
                                            {
                                                nilaiBank.sort((a, b) => b - a).map(nilai => (
                                                    <option key={nilai} value={nilai} style={{ color: "#444444" }}>{nilai}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="div-input-nilai-berkas">
                                        <span className="data-pribadi-font">
                                            Nilai Terbobot (15%)
                                        </span>
                                        <input style={{ cursor: 'default', marginTop: 10, background: "#F0F0F0", color: "#A0A0A0" }} value={nilaiMotivasiTerbobot} type="text" readOnly placeholder="Nilai akan dihitung otomatis" className="data-pribadi-input" />
                                    </div>
                                </div>
                                <div className='mt25 ta-left'>
                                    <span className="data-pribadi-font">
                                    Prestasi dan Pengalaman Organisasi
                                    </span>
                                    <select className={err && !nilaiSejarah ? "data-pribadi-input mt10 input-err" : "data-pribadi-input mt10"} value={nilaiSejarah} onChange={(e) => setNilaiSejarah(e.target.value)} style={{ color: nilaiEssay ? "#444444" : "#C9C9C9", border: (click && !nilaiEssay) && '1px solid red' }} >
                                        <option value="" selected style={{ color: "#C9C9C9" }} >Pilih A/B/C</option>
                                        <option value="A" style={{ color: "#444444" }}>A</option>
                                        <option value="B" style={{ color: "#444444" }}>B</option>
                                        <option value="C" style={{ color: "#444444" }}>C</option>
                                    </select>
                                </div>
                                <div className="mt25 ta-left">
                                    <span className="data-pribadi-font" >
                                        Keterangan
                                    </span>
                                    <TextareaAutosize value={keterangan} onChange={e => setKeterangan(e.target.value)} className="data-pribadi-input-area mt10" placeholder="Tulis Keterangan" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="div-btn-sub-can">
                        <div className="tblsi-btn-wrap" >
                            <BatalButton onClick={() => [history.goBack()]}>
                                Batal
                            </BatalButton>
                            {
                                title === 'UBAH' ?
                                    <InputEditButton onClick={submit}>Simpan Perubahan Nilai</InputEditButton> :
                                    <InputButton onClick={submit}>Submit Nilai</InputButton>
                            }
                        </div>
                    </div>
                </Paper>
            </Container>

        </div>
    )
}
