import React from 'react'
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box'

function PaginationCustom(props) {
    return (
        <Box display="flex" paddingY="10px" width={'100%'}>
            <Box display="flex" alignItems="center" marginRight='20vw'>
                <div className='txt-pagenation'>Menampilkan  </div>
                <div className='mx10'>
                    <select className='select-page' style={{ width: 55 }} value={props.limit} onChange={props.handleChangeRowsPerPage}>
                        <option className='option-page' value={10}>10</option>
                        <option className='option-page' value={20}>20</option>
                        <option className='option-page' value={30}>30</option>
                        <option className='option-page' value={40}>40</option>
                        <option className='option-page' value={props?.dataFilter}>All</option>
                    </select>
                </div>
                <div className='txt-pagenation'>  dari {props?.dataFilter} Data</div>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Pagination page={props.page} onChange={props.handleChangePage} count={Math.ceil(props?.dataFilter / props.limit)} variant="outlined" shape="rounded" />
            </Box>
        </Box>
    )
}
export default PaginationCustom