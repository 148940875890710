import React, { useState, useEffect, Fragment, useRef } from "react";
import "./styles.scss";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import AFSBanner from "../../images/AFSHeader.png";
import YESBanner from "../../images/YESHeader.png";
import KAKEHASHIBanner from "../../images/KAKEHASHIHeader.png";
import BinabudCircle from "../../images/dashboard/binabud-circle.png";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import LockIcon from "@material-ui/icons/Lock";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useHistory, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/People";
import Dashboard from "./Dashboard";
import Tabulasi from "./Tabulasi";
import ManagementPINPenerbit from "./PIN/penerbitan";
import ManagementPINDaftar from "./PIN/daftar";
import ManagementPINDetail from "./PIN/daftar/details";
import ManagementPINDetailNonActive from "./PIN/daftar/detailsNonActive";
import PengaturanUmum from "./PengaturanUmum";
import TambahInfoChapter from "./PengaturanUmum/infoChapter/tambah";
import EditInfoChapter from "./PengaturanUmum/infoChapter/edit";
import TambahAdminChapter from "./PengaturanUmum/adminChapter/tambah";
import EditAdminChapter from "./PengaturanUmum/adminChapter/edit";
import VerifikasiBerkas from "./VerifikasiBerkas";
import SeleksiBerkas from "./SeleksiBerkas/index";
import SeleksiWawancara from "./SeleksiWawancara/index";
import SeleksiKelompok from "./SeleksiInteraksi/index";
import { changeOption } from "../../store/actions/option";
import {
  // getDataVerifikasiBerkas,
  // getDataSeleksiBerkas,
  clearListPeserta,
  // getDataSeleksiWawancara,
  // getDataSeleksiInteraksiKelompok
} from "../../store/actions/dataPeserta";
import {
  getTotalPesertaProgramRow1,
  getTotalPesertaPinRow1,
  getTotalPesertaProgramRow2,
  getTotalPesertaProgramRow3Line,
  getTotalPesertaProgramRow3Pie,
  getTotalPesertaProgramRow3Bar,
  getTotalPesertaProgramRowSchoolBar,
} from "../../store/actions/dashboard";
import { changePassword } from "../../store/actions/user";
import { getTotalPeserta } from '../../store/actions/dashboard'
import SwitchToggle from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import ModalChangePassword from "../../components/changePasswordMe";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import AssessmentIcon from "@material-ui/icons/Assessment";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 37,
    height: 22,
    padding: 0,
    marginLeft: "auto",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "white",
      "& + $track": {
        backgroundColor: "#3598DC",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
    color: "white",
  },
  track: {
    borderRadius: 30,
    border: `1px solid #CECECE`,
    backgroundColor: "#CECECE",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <SwitchToggle
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#3598DC",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: "border-box",
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflow: "overlay",
    "@global": {
      "*::-webkit-scrollbar": {
        width: "3px",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        outline: "1px solid slategrey",
      },
    },
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  popupTop: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#666666",
    height: 34,
  },
  popuplist: {
    height: 40,
    borderTop: "0.3px solid #D1D1D1",
  },
  paperCustom: {
    marginTop: 5,
    marginRight: 10,
  },
  formControlNormaly: {
    marginLeft: 10,
    width: 200,
    "& label.Mui-focused": {
      color: "#999999",
    },
    "& label": {
      color: "#999999",
    },
    "& .MuiSelect-root": {
      // paddingTop: 0,
      // paddingBottom: 15,
      borderRadius: "4px",
      backgroundColor: "white",
      color: "#3598DC",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px 10px 0px",
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: "white",
      },
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: 0,
      paddingBottom: 0,
      // width: '200px',
      height: "40px",
      boxSizing: "border-box",
      borderRadius: "4px",
      transition: "0.8s",
      marginLeft: 5,
      "&.Mui-focused fieldset": {
        border: "0.5px solid #D1D1D1",
        // background: 'white',
      },
      "&:hover fieldset": {
        border: "0.5px solid #D1D1D1",
      },
    },
    "& .MuiSelect-iconOutlined": {
      zIndex: 99,
    },
    "&:hover .MuiSelect-iconOutlined": {
      zIndex: 99,
    },
  },
}));

const MenuButtonActive = withStyles((theme) => ({
  root: {
    width: "90%",
    height: "50px",
    color: "#3598DC",
    backgroundColor: "#F1F9FF",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "flex-start",
    paddingLeft: "19px",
  },
}))(Button);

const MenuButton = withStyles((theme) => ({
  root: {
    width: "90%",
    height: "50px",
    color: "#9C9C9C",
    backgroundColor: "none",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "flex-start",
    paddingLeft: "19px",
    "&:hover": {
      backgroundColor: "#d8e0e5",
      color: "#3598DC",
      boxShadow: "none",
    },
  },
}))(Button);

const MenuButtonAccordion = withStyles((theme) => ({
  root: {
    width: "90%",
    height: "50px",
    color: "#3598DC",
    backgroundColor: "none",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "flex-start",
    paddingLeft: "19px",
    "&:hover": {
      backgroundColor: "#d8e0e5",
      color: "#3598DC",
      boxShadow: "none",
    },
  },
}))(Button);

const MenuButtonAccordionDeactive = withStyles((theme) => ({
  root: {
    width: "90%",
    height: "50px",
    color: "#C1C1C1",
    backgroundColor: "none",
    borderRadius: "4px",
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "flex-start",
    paddingLeft: "19px",
    "&:hover": {
      backgroundColor: "#d8e0e5",
      color: "#C1C1C1",
      boxShadow: "none",
    },
  },
}))(Button);

const AppBarButton = withStyles((theme) => ({
  root: {
    marginRight: 18,
    padding: "0 20px",
    height: "40px",
    color: "#C1C1C1",
    backgroundColor: "#FFFFFF",
    borderRadius: 100,
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3598DC",
    "&:hover": {
      backgroundColor: "#d8e0e5",
      color: "#3598DC",
      boxShadow: "none",
    },
  },
}))(Button);

const AppBarButtonDisable = withStyles((theme) => ({
  root: {
    marginRight: 18,
    padding: "0 20px",
    height: "40px",
    color: "#FFFFFF",
    backgroundColor: "#42A4E8",
    borderRadius: 100,
    boxShadow: "none",
    textTransform: "none",
    transition: "0.5s",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    "&:hover": {
      backgroundColor: "#d8e0e5",
      color: "#3598DC",
      boxShadow: "none",
    },
  },
}))(Button);

function Menu() {
  const profile = useSelector((state) => state.userState.profile);
  const chapter = useSelector((state) => state.chapterState.chapter);
  const option = useSelector((state) => state.optionState.option);
  const tabulasi = useSelector((state) => state.optionState.tabulasi);
  const classes = useStyles();
  const History = useHistory();
  const Dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [variant, setVariant] = useState('permanent');
  const anchorRef = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const [program, setProgram] = useState(null);
  const [managementPIN, setManagementPIN] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordOld, setPasswordOld] = useState("");
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [click, setClick] = useState(false);
  const [error, setError] = useState("");
  const [dataFilter, setDataFilter] = useState({
    chapter: "",
    trigger: false,
  });

  useEffect(()=>{
    if (tabulasi === true) {
      setVariant("")
    }
    if (tabulasi === false) {
      setVariant("permanent")
    }
  },[tabulasi])

  useEffect(() => {
    let options = JSON.parse(localStorage.getItem("options"));
    if (
      !program &&
      options.program &&
      (options?.chapter === "all" || !options?.chapter)
    ) {
      setProgram(options.program);
      // Dispatch(getDataVerifikasiBerkas(options.program, ''))
      // Dispatch(getDataSeleksiBerkas(options.program, ''))
      // Dispatch(getDataSeleksiWawancara(options.program, ''))
      // Dispatch(getDataSeleksiInteraksiKelompok(options.program, ''))
      Dispatch(getTotalPesertaProgramRow1(options.program, ""));
      Dispatch(getTotalPesertaProgramRow2(options.program, ""));
      Dispatch(getTotalPesertaProgramRow3Line(options.program, ""));
      Dispatch(getTotalPesertaProgramRow3Pie(options.program, ""));
      Dispatch(getTotalPesertaProgramRow3Bar(options.program, ""));
      // Dispatch(getTotalPesertaProgramRowSchoolBar(options.program, ''))
      if (options.program === "AFS" || program === "AFS") {
        Dispatch(getTotalPesertaPinRow1(""));
      }
    } else if (
      !program &&
      !options.program &&
      (options?.chapter === "all" || !options?.chapter)
    ) {
      handleChangeProgram("AFS", "");
      // Dispatch(getDataVerifikasiBerkas('AFS', ''))
      // Dispatch(getDataSeleksiBerkas('AFS', ''))
      // Dispatch(getDataSeleksiWawancara('AFS', ''))
      // Dispatch(getDataSeleksiInteraksiKelompok('AFS', ''))
      Dispatch(getTotalPesertaProgramRow1("AFS", ""));
      Dispatch(getTotalPesertaProgramRow2("AFS", ""));
      Dispatch(getTotalPesertaProgramRow3Line("AFS", ""));
      Dispatch(getTotalPesertaProgramRow3Pie("AFS", ""));
      Dispatch(getTotalPesertaProgramRow3Bar("AFS", ""));
      // Dispatch(getTotalPesertaProgramRowSchoolBar('AFS', ''))
      Dispatch(getTotalPesertaPinRow1(""));
    }
  }, [localStorage.getItem("options") && !program]);

  useEffect(() => {
    let options = JSON.parse(localStorage.getItem("options"));
    if (!dataFilter.chapter) {
      localStorage.setItem(
        "options",
        JSON.stringify({ ...options, chapter: options?.chapter || "all" })
      );
      setDataFilter({
        ...dataFilter,
        chapter: options?.chapter || "all",
      });
    }
    if (
      (dataFilter.chapter !== "all" || dataFilter.trigger) &&
      dataFilter.chapter
    ) {
      const query =
        dataFilter.chapter !== "all" ? `chapter_id=${dataFilter.chapter}` : "";
      setProgram(options.program);
      // Dispatch(getDataVerifikasiBerkas(options.program, query))
      // Dispatch(getDataSeleksiBerkas(options.program, query))
      // Dispatch(getDataSeleksiWawancara(options.program, query))
      // Dispatch(getDataSeleksiInteraksiKelompok(options.program, query))
      Dispatch(getTotalPesertaProgramRow1(options.program, query));
      Dispatch(getTotalPesertaProgramRow2(options.program, query));
      Dispatch(getTotalPesertaProgramRow3Line(options.program, query));
      Dispatch(getTotalPesertaProgramRow3Pie(options.program, query));
      Dispatch(getTotalPesertaProgramRow3Bar(options.program, query));
      // Dispatch(getTotalPesertaProgramRowSchoolBar(options.program, query))
      if (options.program === "AFS" || program === "AFS") {
        Dispatch(getTotalPesertaPinRow1(query));
      }
    }
  }, [dataFilter.chapter]);

  useEffect(() => {
    if (History.location.pathname.includes("/management-pin")) {
      setManagementPIN(true);
    }
  }, [History.location.pathname]);

  useEffect(() => {
    Dispatch(getTotalPeserta())
  },[])

  const handleChangeProgram = (program) => {
    const SwalBootstrap = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    let options = JSON.parse(localStorage.getItem("options"));
    if (options.program) {
      SwalBootstrap.fire({
        title: `Program Seleksi`,
        text: `Ingin Mengganti Program Menjadi ${program}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
        .then((result) => {
          if (result.value) {
            Dispatch(clearListPeserta());
            localStorage.setItem(
              "options",
              JSON.stringify({ ...options, program: program })
            );
            setProgram(program);
            Swal.fire({
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: () => {
                Swal.showLoading();
              },
            });
            const query =
              options.chapter !== "all" ? `chapter_id=${options.chapter}` : "";
            // Dispatch(getDataVerifikasiBerkas(program, query))
            // Dispatch(getDataSeleksiBerkas(program, query))
            // Dispatch(getDataSeleksiWawancara(program, query))
            // Dispatch(getDataSeleksiInteraksiKelompok(program, query))
            Dispatch(getTotalPesertaProgramRow1(program, query));
            Dispatch(getTotalPesertaProgramRow2(program, query));
            Dispatch(getTotalPesertaProgramRow3Line(program, query));
            Dispatch(getTotalPesertaProgramRow3Pie(program, query));
            Dispatch(getTotalPesertaProgramRow3Bar(program, query));
            // Dispatch(getTotalPesertaProgramRowSchoolBar(program, query))
            if (program === "AFS") {
              Dispatch(getTotalPesertaPinRow1(query));
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        })
        .finally((_) => {
          setTimeout(() => {
            Swal.close();
          }, 1000);
        });
    }
  };

  const handleChangeMenu = (to) => {
    if (!to.includes("/management-pin")) {
      setManagementPIN(false);
    }
    History.push(to);
  };

  const handleChangeManagementPIN = () => {
    setManagementPIN(!managementPIN);
  };

  const handleToggle = (e) => {
    if (e) e.preventDefault();
    setOpenPopup((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopup(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  const logOut = (event) => {
    handleClose(event);
    localStorage.removeItem("token");
    History.replace("/");
  };

  const handleChangeOption = (key, route, status) => {
    const SwalBootstrap = Swal.mixin({
      customClass: {
        confirmButton: "btn-finalisasi-yakin",
        cancelButton: "btn-finalisasi-tidak",
      },
      buttonsStyling: false,
    });
    if (key && profile) {
      if (profile.role === "admin nasional") {
        SwalBootstrap.fire({
          title: `Ingin ${status ? "Menonaktifkan" : "Mengaktifkan"} ${key}?`,
          text: ``,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
            Dispatch(changeOption({ key, route, program, Swal }));
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
      } else {
        SwalBootstrap.fire({
          title: `Akses Ditolak`,
          text: `Menu Akses Peserta hanya dapat diubah oleh Super Admin Kantor Nasional`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          cancelButtonText: "",
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.value) {
          } else if (result.dismiss === Swal.DismissReason.cancel) {
          }
        });
      }
    }
  };

  const handleOpenClose = () => {
    setOpenAccess(!openAccess);
    if (document.getElementById("drawerParent")) {
      document.getElementById("drawerParent").focus();
    }
  };

  const modalHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    resetPassword();
    setModalToggle(!modalToggle);
  };

  const passwordOldHandler = () => {
    setShowPasswordOld(!showPasswordOld);
  };

  const passwordHandler = () => {
    setShowPassword(!showPassword);
  };

  const passwordHandlerRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };

  const handleChangePass = () => {
    setClick(true);
    if (passwordOld && password && passwordRepeat) {
      if (password !== passwordRepeat) {
        setError("Password tidak sama");
      } else {
        Dispatch(
          changePassword(
            History,
            { password, passwordOld },
            Swal,
            setError,
            resetPassword
          )
        );
      }
    } else {
      setError("Lengkapi Persyaratan");
    }
  };

  const resetPassword = () => {
    setModalToggle(false);
    setShowPassword(false);
    setPassword("");
    setShowPasswordRepeat(false);
    setPasswordRepeat("");
    setClick(false);
    setError("");
    setPasswordOld("");
    setShowPasswordOld(false);
  };

  const getChapterName = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter((a) => a._id === chapter)[0];
      return temp ? temp.chapter : "-";
    }
  };

  const handleChangeFilter = (key, value) => {
    let options = JSON.parse(localStorage.getItem("options"));
    switch (key) {
      case "chapter":
        localStorage.setItem(
          "options",
          JSON.stringify({ ...options, chapter: value })
        );
        setDataFilter({
          ...dataFilter,
          chapter: value,
          trigger: true,
        });
        break;
      default:
        break;
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{
          zIndex: 99,
        }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className="appbar-title-wrapper">
            {(History.location.pathname === "/dashboard" ||
              History.location.pathname === "/tabulasi" ||
              History.location.pathname.includes("/verifikasi-berkas") ||
              History.location.pathname.includes("/seleksi-berkas") ||
              History.location.pathname.includes("/seleksi-wawancara") ||
              History.location.pathname.includes("/seleksi-kelompok")) && (
              <Fragment>
                {program === "AFS" ? (
                  <AppBarButton> AFS </AppBarButton>
                ) : (
                  <AppBarButtonDisable
                    onClick={(e) => handleChangeProgram("AFS")}
                  >
                    {" "}
                    AFS{" "}
                  </AppBarButtonDisable>
                )}
              </Fragment>
            )}
            {(History.location.pathname === "/dashboard" ||
              History.location.pathname === "/tabulasi" ||
              History.location.pathname.includes("/verifikasi-berkas") ||
              History.location.pathname.includes("/seleksi-berkas") ||
              History.location.pathname.includes("/seleksi-wawancara") ||
              History.location.pathname.includes("/seleksi-kelompok")) && (
              <Fragment>
                {program === "YES" ? (
                  <AppBarButton> YES </AppBarButton>
                ) : (
                  <AppBarButtonDisable
                    onClick={(e) => handleChangeProgram("YES")}
                  >
                    {" "}
                    YES{" "}
                  </AppBarButtonDisable>
                )}
              </Fragment>
            )}
            {profile?.role === "admin nasional" &&
              (History.location.pathname === "/dashboard" ||
                History.location.pathname === "/tabulasi") && (
                <FormControl
                  variant="outlined"
                  className={`${classes.formControlNormaly} wrapping-filter-004 filter-004-display`}
                  style={{ textTransform: "capitalize" }}
                >
                  <InputLabel
                    className="filter-table-peserta-placeholder"
                    shrink={false}
                  >
                    {getChapterName(chapter, profile?.chapterId)}
                  </InputLabel>
                  <Select
                    native={false}
                    value={dataFilter?.chapter || "all"}
                    onChange={(e) => [
                      handleChangeFilter("chapter", e.target.value),
                    ]}
                    className={classes.formControlNormaly}
                    style={{ marginLeft: 0, outline: "none" }}
                    IconComponent={FilterListIcon}
                    inputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                      getContentAnchorEl: null,
                    }}
                    disabled={
                      profile && profile.role === "admin chapter" ? true : false
                    }
                  >
                    <MenuItem
                      className={
                        !dataFilter.chapter
                          ? "value-selectable-active"
                          : "value-selectable"
                      }
                      value="all"
                    >
                      {" "}
                      Seluruh Chapter
                    </MenuItem>
                    {chapter
                      ?.sort((a, b) =>
                        a.chapter < b.chapter
                          ? -1
                          : a.chapter > b.chapter
                          ? 1
                          : 0
                      )
                      .map((chapter, i) => (
                        <MenuItem
                          key={i}
                          className={
                            dataFilter.chapter === chapter._id
                              ? "value-selectable-active"
                              : "value-selectable"
                          }
                          value={chapter._id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {chapter.chapter}{" "}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            {/* {
                            (
                                (History.location.pathname) === '/dashboard' ||
                                (History.location.pathname).includes('/verifikasi-berkas') ||
                                (History.location.pathname).includes('/seleksi-berkas') ||
                                (History.location.pathname).includes('/seleksi-wawancara') ||
                                (History.location.pathname).includes('/seleksi-kelompok')
                            ) &&
                            <Fragment>
                                {
                                    program === 'KAKEHASHI' ?
                                        <AppBarButton> KAKEHASHI </AppBarButton>
                                        :
                                        <AppBarButtonDisable onClick={e => handleChangeProgram('KAKEHASHI')} > KAKEHASHI </AppBarButtonDisable>
                                }
                            </Fragment>
                        } */}
            {/* <span className="appbar-font-title" > Total Pendaftar Seluruh Program: 12.700 Orang </span> */}
            <span className="appbar-font-title"> </span>
            <div className="custom-popup">
              <span className="popup-welcome"> Selamat Datang </span>
              <span
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  display: "flex",
                  marginTop: 1,
                }}
              >
                {profile?.nama}
                <ExpandMoreIcon />
              </span>
              <Popper
                open={openPopup}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center top",
                    }}
                  >
                    <Paper className={classes.paperCustom}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openPopup}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={(e) => modalHandler(e)}
                            className={classes.popuplist}
                          >
                            <LockIcon
                              style={{
                                color: "#999999",
                                width: 20,
                                marginRight: 8,
                              }}
                            />
                            <span className="text-menu-item-popup">
                              {" "}
                              Ganti Password{" "}
                            </span>
                          </MenuItem>
                          <MenuItem
                            onClick={logOut}
                            className={classes.popuplist}
                          >
                            <i
                              className="fas fa-sign-out-alt"
                              style={{
                                color: "#FF5579",
                                fontSize: 17,
                                marginLeft: 4,
                                marginRight: 7,
                              }}
                            ></i>
                            <span
                              className="text-menu-item-popup"
                              style={{ color: "#FF5579" }}
                            >
                              {" "}
                              Keluar{" "}
                            </span>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={variant}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div
          style={{
            minHeight: 135,
            maxHeight: 135,
          }}
        >
          {open ? (
            program === "AFS" ? (
              <img
                src={AFSBanner}
                style={{ width: "100%" }}
                className="logo-binabudaya-sidebar"
                alt="Binabudaya-logo"
              />
            ) : program === "YES" ? (
              <img
                src={YESBanner}
                style={{ width: "100%" }}
                className="logo-binabudaya-sidebar"
                alt="Binabudaya-logo"
              />
            ) : (
              program === "KAKEHASHI" && (
                <img
                  src={KAKEHASHIBanner}
                  style={{ width: "100%" }}
                  className="logo-binabudaya-sidebar"
                  alt="Binabudaya-logo"
                />
              )
            )
          ) : (
            <img
              src={BinabudCircle}
              className="logo-binabudaya-circle-sidebar"
              alt="Binabudaya-logo"
            />
          )}
        </div>
        {!open && <Divider />}
        <div className="wrapper-btn-list-menu">
          {History.location.pathname.includes("/dashboard") ? (
            <MenuButtonActive>
              <DashboardIcon />
              {open && <span className="menu-list-font-active">Dashboard</span>}
            </MenuButtonActive>
          ) : (
            <MenuButton onClick={() => handleChangeMenu("/dashboard")}>
              <DashboardIcon />
              {open && <span className="menu-list-font-active">Dashboard</span>}
            </MenuButton>
          )}
          {History.location.pathname.includes("/tabulasi") ? (
            <MenuButtonActive>
              <AssessmentIcon />
              {open && <span className="menu-list-font-active">Tabulasi</span>}
            </MenuButtonActive>
          ) : (
            <MenuButton onClick={() => handleChangeMenu("/tabulasi")}>
              <AssessmentIcon />
              {open && <span className="menu-list-font-active">Tabulasi</span>}
            </MenuButton>
          )}
          {program === "AFS" &&
          History.location.pathname.includes("/management-pin") ? (
            <MenuButtonActive onClick={() => handleChangeManagementPIN()}>
              <OfflinePinIcon />
              {open && (
                <span className="menu-list-font-active">Manajemen PIN</span>
              )}
              {managementPIN ? (
                <ArrowDropUpIcon style={{ marginLeft: "auto" }} />
              ) : (
                <ArrowDropDownIcon style={{ marginLeft: "auto" }} />
              )}
            </MenuButtonActive>
          ) : (
            program === "AFS" && (
              <MenuButton
                onClick={() => handleChangeMenu("/management-pin/penerbitan")}
              >
                <OfflinePinIcon />
                {open && (
                  <span className="menu-list-font-active">Manajemen PIN</span>
                )}
                {managementPIN ? (
                  <ArrowDropUpIcon
                    style={{ marginLeft: "auto", color: "#6D6D6D" }}
                  />
                ) : (
                  <ArrowDropDownIcon
                    style={{ marginLeft: "auto", color: "#6D6D6D" }}
                  />
                )}
              </MenuButton>
            )
          )}
          {managementPIN && (
            <Fragment>
              {History.location.pathname === "/management-pin/penerbitan" ? (
                <MenuButtonAccordion>
                  {open && (
                    <span
                      className="menu-list-font-active-accordion"
                      style={{ paddingLeft: 25 }}
                    >
                      Penerbitan PIN
                    </span>
                  )}
                </MenuButtonAccordion>
              ) : (
                <MenuButtonAccordionDeactive
                  onClick={() => handleChangeMenu("/management-pin/penerbitan")}
                >
                  {open && (
                    <span
                      className="menu-list-font-active-accordion"
                      style={{ paddingLeft: 25 }}
                    >
                      Penerbitan PIN
                    </span>
                  )}
                </MenuButtonAccordionDeactive>
              )}

              {History.location.pathname.includes("/management-pin/daftar") ? (
                <MenuButtonAccordion>
                  {open && (
                    <span
                      className="menu-list-font-active-accordion"
                      style={{ paddingLeft: 25 }}
                    >
                      Daftar PIN
                    </span>
                  )}
                </MenuButtonAccordion>
              ) : (
                <MenuButtonAccordionDeactive
                  onClick={() => handleChangeMenu("/management-pin/daftar")}
                >
                  {open && (
                    <span
                      className="menu-list-font-active-accordion"
                      style={{ paddingLeft: 25 }}
                    >
                      Daftar PIN
                    </span>
                  )}
                </MenuButtonAccordionDeactive>
              )}
            </Fragment>
          )}
          {History.location.pathname.includes("/verifikasi-berkas") ? (
            <MenuButtonActive>
              <DescriptionIcon />
              {open && (
                <span className="menu-list-font-active">Verifikasi Berkas</span>
              )}
            </MenuButtonActive>
          ) : (
            <MenuButton onClick={() => handleChangeMenu("/verifikasi-berkas")}>
              <DescriptionIcon />
              {open && (
                <span className="menu-list-font-active">Verifikasi Berkas</span>
              )}
            </MenuButton>
          )}
          {History.location.pathname.includes("/seleksi-berkas") ? (
            <MenuButtonActive>
              <PeopleIcon />
              {open && (
                <span className="menu-list-font-active">
                  Tabulasi Seleksi
                  <br />
                  Berkas
                </span>
              )}
            </MenuButtonActive>
          ) : (
            <MenuButton onClick={() => handleChangeMenu("/seleksi-berkas")}>
              <PeopleIcon />
              {open && (
                <span className="menu-list-font-active">
                  Tabulasi Seleksi
                  <br />
                  Berkas
                </span>
              )}
            </MenuButton>
          )}
          {History.location.pathname.includes("/seleksi-wawancara") ? (
            <MenuButtonActive>
              <PeopleIcon />
              {open && (
                <span className="menu-list-font-active">
                  Tabulasi Seleksi
                  <br />
                  Wawancara
                </span>
              )}
            </MenuButtonActive>
          ) : (
            <MenuButton onClick={() => handleChangeMenu("/seleksi-wawancara")}>
              <PeopleIcon />
              {open && (
                <span className="menu-list-font-active">
                  Tabulasi Seleksi
                  <br />
                  Wawancara
                </span>
              )}
            </MenuButton>
          )}
          {History.location.pathname.includes("/seleksi-kelompok") ? (
            <MenuButtonActive>
              <PeopleIcon />
              {open && (
                <span className="menu-list-font-active">
                  Tabulasi Interaksi
                  <br />
                  Kelompok
                </span>
              )}
            </MenuButtonActive>
          ) : (
            <MenuButton onClick={() => handleChangeMenu("/seleksi-kelompok")}>
              <PeopleIcon />
              {open && (
                <span className="menu-list-font-active">
                  Tabulasi Interaksi
                  <br />
                  Kelompok
                </span>
              )}
            </MenuButton>
          )}
          {profile &&
          profile.role === "admin nasional" &&
          History.location.pathname.includes("/pengaturan-umum") ? (
            <MenuButtonActive
              style={{
                marginBottom: 30,
              }}
            >
              <SettingsIcon />
              {open && (
                <span className="menu-list-font-active">Pengaturan Umum</span>
              )}
            </MenuButtonActive>
          ) : (
            profile &&
            profile.role === "admin nasional" && (
              <MenuButton
                onClick={() => handleChangeMenu("/pengaturan-umum")}
                style={{
                  marginBottom: 30,
                }}
              >
                <SettingsIcon />
                {open && (
                  <span className="menu-list-font-active">Pengaturan Umum</span>
                )}
              </MenuButton>
            )
          )}
        </div>
        {open ? (
          <div className="drawer-wrapper">
            <div className="drawer-access" onClick={handleOpenClose}>
              <div> AKSES PESERTA {program}</div>
              {openAccess ? (
                <ArrowDropUpIcon
                  style={{
                    marginLeft: "auto",
                    color: "#6D6D6D",
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  style={{
                    marginLeft: "auto",
                    color: "#6D6D6D",
                  }}
                />
              )}
            </div>
            {openAccess && (
              <Fragment>
                <div className="drawer-access-menu">
                  <div> Akses Registrasi Peserta </div>
                  <IOSSwitch
                    checked={option?.[program]?.pendaftaran}
                    onChange={(_) =>
                      handleChangeOption(
                        "Pendaftaran",
                        "pendaftaran",
                        option?.[program]?.pendaftaran
                      )
                    }
                    name="checkedB"
                  />
                </div>
                <div className="drawer-access-menu">
                  <div> Akses Finalisasi Peserta </div>
                  <IOSSwitch
                    checked={option?.[program]?.finalisasi}
                    onChange={(_) =>
                      handleChangeOption(
                        "Finalisasi",
                        "finalisasi",
                        option?.[program]?.finalisasi
                      )
                    }
                    name="checkedB"
                  />
                </div>
                <div className="drawer-access-menu">
                  <div> Akses Upload Berkas </div>
                  <IOSSwitch
                    checked={option?.[program]?.uploadBerkas}
                    onChange={(_) =>
                      handleChangeOption(
                        "Upload Berkas",
                        "upload-berkas",
                        option?.[program]?.uploadBerkas
                      )
                    }
                    name="checkedB"
                  />
                </div>
                <div className="drawer-access-menu">
                  <div> Akses Hasil Seleksi </div>
                  <IOSSwitch
                    checked={option?.[program]?.hasilSeleksi}
                    onChange={(_) =>
                      handleChangeOption(
                        "Hasil Seleksi",
                        "hasil-seleksi",
                        option?.[program]?.hasilSeleksi
                      )
                    }
                    name="checkedB"
                  />
                  <a href="" id="drawerParent" />
                </div>
              </Fragment>
            )}
            <div className="drawer-footer">2020 © Yayasan Bina Antarbudaya</div>
          </div>
        ) : (
          <div className="drawer-footer-small">
            <span>2020 ©</span>
            <span>Yayasan</span>
            <span>Bina</span>
            <span>Antarbudaya</span>
          </div>
        )}
      </Drawer>
      <div className="parents-component-2021" id="parent-component"
        style={{
          width: tabulasi && '999999px'
        }}
      >
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/tabulasi" component={Tabulasi} />
          <Route
            path="/management-pin/penerbitan"
            component={ManagementPINPenerbit}
          />
          <Route
            path="/management-pin/daftar/aktif"
            component={ManagementPINDetail}
          />
          <Route
            path="/management-pin/daftar/tidak-aktif"
            component={ManagementPINDetailNonActive}
          />
          <Route
            path="/management-pin/daftar"
            component={ManagementPINDaftar}
          />
          <Route
            path="/pengaturan-umum/info-chapter/tambah"
            component={TambahInfoChapter}
          />
          <Route
            path="/pengaturan-umum/info-chapter/edit"
            component={EditInfoChapter}
          />
          <Route
            path="/pengaturan-umum/admin-chapter/tambah"
            component={TambahAdminChapter}
          />
          <Route
            path="/pengaturan-umum/admin-chapter/edit"
            component={EditAdminChapter}
          />
          <Route path="/pengaturan-umum" component={PengaturanUmum} />

          <Route path="/verifikasi-berkas" component={VerifikasiBerkas} />
          <Route path="/seleksi-berkas" component={SeleksiBerkas} />
          <Route path="/seleksi-wawancara" component={SeleksiWawancara} />
          <Route path="/seleksi-kelompok" component={SeleksiKelompok} />
          {/* <Route path="/interviewer" component={Interviewer} />
                    <Route path="/observer" component={Observer} />
                    <Route path="/juri-nasional" component={JuriNasional} />
                    <Route path="/pengaturan-umum" component={PengaturanUmum} />
                    <Route path="/penilaian-seleksi" component={PenilaianSeleksi} />
                    <Route path="/management-pin" component={PIN} />
                    <Route path="/management-peserta" component={Peserta} />
                    <Route path="/tabulasi" component={Tabulasi} /> */}
        </Switch>
      </div>
      <ModalChangePassword
        username={profile && profile.nama}
        show={modalToggle}
        modalClosed={modalHandler}
        children={"modal"}
        passwordHandler={passwordHandler}
        passwordHandlerRepeat={passwordHandlerRepeat}
        password={password}
        setPassword={setPassword}
        click={click}
        showPassword={showPassword}
        passwordRepeat={passwordRepeat}
        showPasswordRepeat={showPasswordRepeat}
        setPasswordRepeat={setPasswordRepeat}
        error={error}
        setError={setError}
        handleChangePass={handleChangePass}
        passwordOld={passwordOld}
        setPasswordOld={setPasswordOld}
        passwordOldHandler={passwordOldHandler}
        showPasswordOld={showPasswordOld}
      />
    </div>
  );
}

export default Menu;
