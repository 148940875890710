import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import CheckIcn from '../../images/modal/check-icn.svg'
import './styles.scss'

export default function ModalSucces(props) {
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
            >
                <div style={{ width: 500, padding: '40px 92px', textAlign: 'center' }}>
                    <img src={CheckIcn} alt='info' style={{ width: 80, height: 80 }} />
                    <div className='txt-modal-wawancara'>
                    Berhasil Memberikan Otoritas untuk {props.nama}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='btn-modal-wawancara btn-blue' style={{width: 197}} onClick={props.handleClose}>Ya, Kembali ke Tabel</div>
                    </div>
                </div>

            </Dialog>
        </>
    )
}
