import { combineReducers } from 'redux'
import User from './user'
import Chapter from './chapter'
import Filter from './filter'
import DaerahReducer from './daerah'
import dataPeserta from './dataPeserta'
import PinReducer from './pin'
import optionReducer from './option'
import dashboardReducer from './dashboard'

const reducers = combineReducers({
    userState : User,
    chapterState : Chapter,
    filterState : Filter,
    daerahState : DaerahReducer,
    dataPesertaState : dataPeserta,
    pinState : PinReducer,
    optionState : optionReducer,
    dashboardState : dashboardReducer
})

export default reducers