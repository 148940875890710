import React, { useEffect, useState, useRef } from 'react'
import style from './styles.module.scss'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { Bar } from 'react-chartjs-2';
import Breadcrumb from '../../../components/Breadcrumb';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  getTotalPesertaProgramRowSchoolBar
} from '../../../store/actions/dashboard'
import { SET_TABULASI } from '../../../store/constanta';
import { toPng } from 'html-to-image';
import Swal from 'sweetalert2'
import moment from 'moment'
import BinabudayaLogo from '../.../../../../images/binabudaya.svg'

const CountButton = withStyles((theme) => ({
  root: {
    marginTop: -10,
    height: '40px',
    color: '#3598DC',
    backgroundColor: 'rgba(53, 152, 220, 0.1)',
    borderRadius: '1000px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    justifyContent: 'space-between',
    padding: '0 20px'
  },
}))(Button);

const PrintButton = withStyles((theme) => ({
  root: {
      height: '40px',
      color: 'white',
      backgroundColor: 'rgba(0, 157, 34, 1)',
      borderRadius: '1000px',
      boxShadow: 'none',
      textTransform: 'none',
      transition: '0.5s',
      justifyContent: 'space-between',
      padding: '0 20px',
      gap: '10px',
      '&:hover': {
        backgroundColor: 'rgba(0, 157, 34, 0.7)',
    },
  },
}))(Button);

function Dashboard() {
  const Dispatch = useDispatch();
  const domRef = useRef(null);
  const profile = useSelector(state => state.userState.profile)
  const chapter = useSelector(state => state.chapterState.chapter)
  const totalPeserta = useSelector(state => state.dashboardState.totalPeserta)
  const row1Data = useSelector(state => state.dashboardState.row1)
  const row2Data = useSelector(state => state.dashboardState.row2)
  const row3Pie = useSelector(state => state.dashboardState.row3Pie)
  const row3Bar = useSelector(state => state.dashboardState.row3Bar)
  const tabulasi = useSelector((state) => state.optionState.tabulasi);
  const [program, setProgram] = useState(null)
  const [unduh, setUnduh] = useState(false)
  const [dataFilter] = useState({
    chapter: '',
    school: ''
  })

  const [dataBarChart, setDataBarChart] = useState({
    labels: ['-', '-', '-', '-', '-'],
    datasets: [
      {
        label: 'Pendaftar',
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          '#FC8800',
          '#00D67D',
          '#FF1F48',
          '#6F00FC',
          '#B007DA',
        ],
        borderWidth: 1,
      },
    ],
  })
  const [dataSeleksi, setDataSeleksi] = useState([])

  useEffect(() => {
    let options = JSON.parse(localStorage.getItem('options'))
    setProgram(options?.program)
    if (dataFilter?.chapter) {
      const query = `chapter_id=${dataFilter.chapter}&school_type=${dataFilter.school}`
      Dispatch(getTotalPesertaProgramRowSchoolBar(options?.program, query))
    }
  }, [localStorage.getItem('options')])

  useEffect(() => {
    if (chapter && row3Bar) {
      const dataChapter = chapter?.map(chapter => ({
        "title": chapter?.code,
        "pendaftaran": row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.total || 0,
        "verifikasi": (
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaLulusVerifikasiBerkas || 0) +
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaTidakLulusVerifikasiBerkas || 0)
        ),
        "selberkas": (
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaLulusSeleksiBerkas || 0) +
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaTidakLulusSeleksiBerkas || 0)
        ),
        "selWawancara": (
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaLulusSeleksiWawancara || 0) +
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaTidakLulusSeleksiWawancara || 0)
        ),
        "interaksiKelompok": (
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaLulusSeleksiInteraksiKelompok || 0) +
          (row3Bar?.filter(data=> (data?.chapter)?.toLowerCase() === (chapter?.chapter)?.toLowerCase())?.[0]?.totalPesertaTidakLulusSeleksiInteraksiKelompok || 0)
        ),
      }))

      let totalPendaftar = 0
      let totalVerifikasi = 0
      let totalSelberkas = 0
      let totalSelWawancara = 0
      let totalInteraksiKelompok = 0

      dataChapter?.map(x => {
        totalPendaftar += x.pendaftaran
        totalVerifikasi += x.verifikasi
        totalSelberkas += x.selberkas
        totalSelWawancara += x.selWawancara
        totalInteraksiKelompok += x.interaksiKelompok
      })

      dataChapter.push({
        "title": "Jumlah",
        "pendaftaran": totalPendaftar,
        "verifikasi": totalVerifikasi,
        "selberkas": totalSelberkas,
        "selWawancara": totalSelWawancara,
        "interaksiKelompok": totalInteraksiKelompok
      })

      setDataSeleksi([
        {
          "title": program === "AFS" ? "AFS" : "KL-YES",
          "pendaftaran": "Pendaftaran",
          "verifikasi": "Verifikasi Berkas",
          "selberkas": "Seleksi Berkas",
          "selWawancara": "Seleksi Wawancara",
          "interaksiKelompok": "Interaksi Kelompok"
        },
        ...dataChapter
      ])

      setDataBarChart({
        labels: (row3Bar || [])?.map(chapter => getChapterNameBar(chapter?.chapter)),
        datasets: [
          {
            label: 'Pendaftar',
            data: (row3Bar || [])?.map(chapter => getChapterCountBar(chapter?.total)),
            backgroundColor: [
              '#FC8800',
              '#00D67D',
              '#FF1F48',
              '#6F00FC',
              '#B007DA',
              '#FC8800',
              '#00D67D',
              '#FF1F48',
              '#6F00FC',
              '#B007DA',
              '#FC8800',
              '#00D67D',
              '#FF1F48',
              '#6F00FC',
              '#B007DA',
              '#FC8800',
              '#00D67D',
              '#FF1F48',
              '#6F00FC',
              '#B007DA',
            ],
            borderWidth: 1,
          },
        ],
      })
    }
  }, [row3Bar, chapter, program])

  const getChapterCountBar = (value) => {
    let count = 0
    if (value) {
      count = value
    }
    return count
  }

  const getChapterNameBar = (value) => {
    let name = '-'
    if (value) {
      name = value
    }
    return name
  }

  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const getChapterName = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter(a => a._id === chapter)[0]
      return temp ? temp.chapter : '-'
    }
  }

  return (
    <div className="data-container-wrapper"
      style={{
        width: '100% !important'
      }}
    >
      <div className="bread-cab-container">
        <span className="input-data-header">
          Tabulasi
        </span>
        <CountButton style={{ marginLeft: 'auto', textTransform: 'capitalize' }}>
          {'Total ' + numberWithCommas(totalPeserta) + ' Peserta Seluruh Program' + (profile && profile.role === 'admin chapter' ? ` Chapter ${getChapterName(chapter, profile && profile.chapterId)}` : '')}
        </CountButton>
      </div>

      <div className={`${style["download-container"]}`}>
        <Breadcrumb
          links={[
            {
              label: 'Tabulasi',
              link: false
            }
          ]}
        />

        <PrintButton
          onClick={() => {
            setUnduh(true)
            Swal.showLoading()
            Dispatch({
              type: SET_TABULASI,
              data: true
            })

            setTimeout(async () => {
              const file = await toPng(domRef.current, { cacheBust: false })

              const link = document.createElement("a");
              link.download = `${program}-${moment(new Date()).format('DD-MM-YYYY')}.png`;
              link.href = file;
              link.click();

              Swal.close()
              setUnduh(false)
              Dispatch({
                type: SET_TABULASI,
                data: false
              })
            }, 1000);
          }}
        >
          <span style={{ marginRight: 5 }}>Download</span>
          <i class="fas fa-print"></i>
        </PrintButton>
      </div>

      <div ref={domRef} className={`${style["dom-picture"]}`}>
        {/* HEADER */}
        <div className={`${style["dashboard-2021-wpapper-header-dom"]}`}
          style={{
            display: unduh ? 'flex' : 'none',
          }}
        >
          <img src={BinabudayaLogo} className="image-logo-login" alt="logo"/>
          <div className={`${style["dashboard-2021-wpapper-header-dom-text"]}`}
            style={{
              color: program === 'AFS' ? '#4a86e8' : '#ff9900'
            }}
          >
            <h1>Online Registration 2025-2026 | Program {program === "AFS" ? "AFS" : "KL-YES"}</h1>
            <h1 style={{ fontWeight: "normal"}}>{moment(new Date()).format('DD-MM-YYYY')}</h1>
          </div>
        </div>

        {/* ROW1 */}
        <div className="dashboard-2021-wpapper-row-one" >
          {/* COL1 */}
          <div className={`${style["dashboard-2021-whitebox-rowone-non"]}`} >
            <TableContainer className='table-con' component={Paper} style={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 2 }}>
              <Table aria-label="simple table" id='exporttable-verifikasi' stickyHeader>
                <TableHead className='head-table-seleksi'>
                  <TableRow>
                    {
                      dataSeleksi?.map((data, i) => (
                        <TableCell key={data?.title} align="center" style={{ zIndex: i === 0 && 9, background: program === 'AFS' ? '#4a86e8' : '#ff9900', color: 'black'}}>
                          {data?.title}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      dataSeleksi?.map((data, i) => (
                        <TableCell align={i !== 0 ? "center" : "left"} key={`${data.pendaftaran}-${i}`} className={`col-fix`} style={{ left: 0, zIndex: i === 0 && 98, fontWeight: 'bold'}}>
                          {data.pendaftaran}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {
                      dataSeleksi?.map((data, i) => (
                        <TableCell align={i !== 0 ? "center" : "left"} key={`${data.pendaftaran}-verifikasi-${i}`} className={`col-fix`} style={{ left: 0, zIndex: i === 0 && 98, fontWeight: 'bold'}}>
                          {data.verifikasi}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {
                      dataSeleksi?.map((data, i) => (
                        <TableCell align={i !== 0 ? "center" : "left"} key={`${data.pendaftaran}-selberkas-${i}`} className={`col-fix`} style={{ left: 0, zIndex: i === 0 && 98, fontWeight: 'bold'}}>
                          {data.selberkas}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {
                      dataSeleksi?.map((data, i) => (
                        <TableCell align={i !== 0 ? "center" : "left"} className={`col-fix`} key={`${data.pendaftaran}-selWawancara-${i}`} style={{ left: 0, zIndex: i === 0 && 98, fontWeight: 'bold'}}>
                          {data.selWawancara}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                  <TableRow>
                    {
                      dataSeleksi?.map((data, i) => (
                        <TableCell align={i !== 0 ? "center" : "left"} key={`${data.pendaftaran}-interaksiKelompok-${i}`} className={`col-fix`} style={{ left: 0, zIndex: i === 0 && 98, fontWeight: 'bold'}}>
                          {data.interaksiKelompok}
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        {/* ROW2 */}
        <div className={`${style["dashboard-2021-wpapper-row-two-parent"]}`} >
          <div className={`${style["dashboard-2021-wpapper-row-two-parent-child"]}`} >
            <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
              <div className={`${style["dashboard-2021-whitebox-header-parent"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                Verifikasi Berkas
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Lulus
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Tidak
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Jumlah
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`} >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusVerifikasiBerkas}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaTidakLulusVerifikasiBerkas}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusVerifikasiBerkas + row2Data.totalPesertaTidakLulusVerifikasiBerkas}
                </div>
              </div>
            </div>

            <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
              <div className={`${style["dashboard-2021-whitebox-header-parent"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                Seleksi Tahap I
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Lulus
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Tidak
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Jumlah
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`} >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusSeleksiBerkas}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaTidakLulusSeleksiBerkas}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusSeleksiBerkas + row2Data.totalPesertaTidakLulusSeleksiBerkas}
                </div>
              </div>
            </div>

            <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
              <div className={`${style["dashboard-2021-whitebox-header-parent"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                Seleksi Tahap II
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Lulus
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Tidak
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Jumlah
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`} >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusSeleksiWawancara}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaTidakLulusSeleksiWawancara}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusSeleksiWawancara + row2Data.totalPesertaTidakLulusSeleksiWawancara}
                </div>
              </div>
            </div>
          </div>

          <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
            <div className="dashboard-2021-whitebox-header"
              style={{
                background: program === 'AFS' ? '#4a86e8' : '#ff9900'
              }}
            >
              <span> JUMLAH PENDAFTAR {program} BERDASARKAN CHAPTER </span>
            </div>
            <div className="dashboard-2021-whitebox-header-row3-chart" >
              <Bar data={dataBarChart} options={{
                plugins: {
                  legend: {
                    display: false
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                  }
                }
              }} />
            </div>
          </div>
        </div>

        {/* ROW3 */}
        <div className="dashboard-2021-wpapper-row-three" >
          <div className={`${style["dashboard-2021-wpapper-row-two-parent-child"]}`}
            style={{
              flexDirection: 'row'
            }}
          >
            <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
              <div className={`${style["dashboard-2021-whitebox-header-parent"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                Seleksi Tahap III
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Lulus
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Tidak
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Jumlah
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`} >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusSeleksiInteraksiKelompok}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaTidakLulusSeleksiInteraksiKelompok}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  {row2Data.totalPesertaLulusSeleksiInteraksiKelompok + row2Data.totalPesertaTidakLulusSeleksiInteraksiKelompok}
                </div>
              </div>
            </div>

            <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900',
                  borderRadius: '8px 8px 0px 0px'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child-2"]}`}
                  style={{
                    borderRadius: '4px 0px 0px 0px'
                  }}
                >
                  Jumlah
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child-2"]}`}
                  style={{
                    borderRadius: '0px 4px 0px 0px',
                    background: 'white',
                  }}
                >
                  {row3Pie.pesertaLakiLaki + row3Pie.pesertaPerempuan}
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child-2"]}`} >
                  Laki-Laki
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child-2"]}`} >
                  Perempuan
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`} >
                <div className={`${style["dashboard-2021-whitebox-header-child-2"]}`} >
                  {row3Pie.pesertaLakiLaki}
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child-2"]}`} >
                  {row3Pie.pesertaPerempuan}
                </div>
              </div>
            </div>

            <div className={`${style["dashboard-2021-wpapper-row-two"]}`}>
              <div className={`${style["dashboard-2021-whitebox-header-parent"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                Finalisasi
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`}
                style={{
                  background: program === 'AFS' ? '#4a86e8' : '#ff9900'
                }}
              >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Sudah
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Belum
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  Jumlah
                </div>
              </div>
              <div className={`${style["dashboard-2021-whitebox-header-sub-child"]}`} >
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  { row1Data.totalPesertaFinalisasi }
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  { row1Data.totalPesertaBelumFinalisasi }
                </div>
                <div className={`${style["dashboard-2021-whitebox-header-child"]}`} >
                  { row1Data.totalPesertaProgram }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard