import axios from 'axios'
import server from '../../endpoint'
import { generateToken } from '../../helpers/bearer'
import { Query } from '../../helpers/query'
import {
    GET_SELEKSI_BERKAS,
    GET_PROFILPESERTA,
    GET_VERIFIKASI,
    SET_VERIFIKASI,
    SET_SELEKSI_BERKAS,
    GET_SELEKSI_WAWANCARA,
    GET_PROFIL_PESERTA_WAWANCARA_KEPRIBADIAN,
    GET_PROFIL_PESERTA_WAWANCARA_BAHASA_INGGRIS,
    SET_SELEKSI_WAWANCARA,
    GET_DETAIL_NILAI_PESERTA,
    GET_SELEKSI_INTERAKSI_KELOMPOK,
    SET_SELEKSI_INTERAKSI_KELOMPOK,
    GET_PROFIL_PESERTA_INTERAKSI_KELOMPOK,
} from "../constanta";
import {
    setSaveAction,
    setRefreshSeleksiBerkas,
    setRefreshSeleksiWawancara,
    setRefreshSeleksiInteraksiKelompok,
} from './user'

export const clearListPeserta = (program) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && program) {
            dispatch({
                type: GET_VERIFIKASI,
                data: null
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getDataVerifikasiBerkas = (program, query, cb) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && program) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/verifikasi-berkas/${program}/?${Query(query)}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
        } else {
            throw 'Access Blocked'
        }
    } catch (err) {
        console.error(err)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}

export const changeStatusPesertaVerifikasiBerkas = (status, newStatus, nomorUrut, program, chapterId, id, dataAll ,index, Swal, reset, setModalProps, handleModalPropsClose, name) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'patch',
                url: `${server}/peserta/status-verifikasi/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: { status, nomorUrut, program, chapterId },
            })
            dispatch({
                type: SET_VERIFIKASI,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            dispatch(setRefreshSeleksiBerkas(true))
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil ${newStatus ? 'Memberikan' : status !== null ? 'Mengganti' : 'Menghapus'} Status Kelulusan untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const changeStatusPesertaOtorisasiBerkas = (status, id, dataAll ,index, Swal, setDataCr, closeModal, handleCloseMenu, name, setModalProps, handleModalPropsClose, reset) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'patch',
                url: `${server}/peserta/status-otorisasi/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: { status },
            })
            dispatch({
                type: SET_VERIFIKASI,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            setDataCr(data)
            closeModal()
            handleCloseMenu()
            Swal.close()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil ${status ? 'Memberikan' : 'Membatalkan'} Otoritas untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> [handleModalPropsClose(), reset()],
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getDataSeleksiBerkas = (program, query, cb) => async dispatch => {
    let token = localStorage.getItem('token')
    try {
        if (token && program) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/seleksi-berkas/${program}/?${Query(query)}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
        } else {
            throw 'Access Blocked'
        }
    } catch (err) {
        console.error(err)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}

export const berkasAction = ( file, Swal, key, method, id, dataAll, index, setDataCr, setModalProps, handleModalPropsClose) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method,
                url: `${server}/peserta/berkas/${key}/${id}`,
                data: file,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: SET_VERIFIKASI,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            setDataCr(data)
            dispatch(setRefreshSeleksiBerkas(true))
            Swal.close()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `${method === 'PATCH'? 'Menyimpan' : 'Hapus'} Berkas Berhasil`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        Swal.close()
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        if (err.response.data.message === "File too large") {
                            setModalProps({
                                open: true,
                                close: handleModalPropsClose,
                                icon: 'info',
                                title: `Ukuran Berkas Maksimal 10MB`,
                                text: '',
                                buttonCancel: false,
                                buttonCancelText: '',
                                buttonCancelAction: null,
                                buttonConfirm: true,
                                buttonConfirmText: 'OK',
                                buttonConfirmAction: _=> handleModalPropsClose(),
                            })
                        }
                    }
                }
            }
        }
    }
}

export const getProfilPeserta = (id) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_PROFILPESERTA,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }

}

export const getDataSeleksiWawancara = (program, query, cb) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && program) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/seleksi-wawancara/${program}/?${Query(query)}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
        } else {
            throw 'Access Blocked'
        }
    } catch (err) {
        console.error(err)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}

export const getInfoPesertaSeleksiWawancaraKepribadian = (id) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/wawancara-kepribadian/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_PROFIL_PESERTA_WAWANCARA_KEPRIBADIAN,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createWawancaraKepribadian = (History, sendData, id, Swal, name, handleModalPropsClose, setModalProps) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            await axios({
                method: 'PATCH',
                url: `${server}/peserta/wawancara-kepribadian/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            dispatch(setSaveAction(false))
            dispatch(setRefreshSeleksiInteraksiKelompok(true))
            Swal.close()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil Input Nilai Wawancara Kepribadian untuk ${name}`,
                text: 'Silahkan lihat dan cek nilai di Tabulasi',
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Kembali ke Tabulasi',
                buttonConfirmAction: _=> [handleModalPropsClose(), History.replace('/seleksi-wawancara')],
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getInfoPesertaSeleksiWawancaraBahasaInggris = (id) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/wawancara-bahasa-inggris/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_PROFIL_PESERTA_WAWANCARA_BAHASA_INGGRIS,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createWawancaraBahasaInggris = (History, sendData, id, Swal, name, handleModalPropsClose, setModalProps) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            await axios({
                method: 'PATCH',
                url: `${server}/peserta/wawancara-bahasa-inggris/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            dispatch(setSaveAction(false))
            dispatch(setRefreshSeleksiInteraksiKelompok(true))
            Swal.close()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil Input Nilai Wawancara Bahasa Inggris untuk ${name}`,
                text: 'Silahkan lihat dan cek nilai di Tabulasi',
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Kembali ke Tabulasi',
                buttonConfirmAction: _=> [handleModalPropsClose(), History.replace('/seleksi-wawancara')],
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createRankingSeleksi2 = (rank, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/ranking-seleksi2/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    rank
                }
            })
            dispatch({
                type: SET_SELEKSI_WAWANCARA,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: rank ? `Berhasil Memberi Rangking ${rank} untuk ${name}` : `Berhasil Menghapus Rangking untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const updateStatusSeleksi2 = (status, newStatus, id, Swal, index, dataAll, reset, setModalProps, handleModalPropsClose, name) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/status-seleksi2/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    status
                }
            })
            dispatch(setRefreshSeleksiInteraksiKelompok(true))
            dispatch({
                type: SET_SELEKSI_WAWANCARA,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil ${newStatus ? 'Memberikan' : status !== null ? 'Mengganti' : 'Menghapus'} Status Kelulusan untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const deleteNilaiSeleksi2 = (root, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/${root}/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch(setRefreshSeleksiInteraksiKelompok(true))
            dispatch({
                type: SET_SELEKSI_WAWANCARA,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil Menghapus Input Nilai Wawancara ${root === 'hapus-wawancara-kepribadian' ? 'Kepribadian' : 'Bahasa Inggris'} untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getDetailPesertaSeleksi2 = (id) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        dispatch({
            type: GET_DETAIL_NILAI_PESERTA,
            data: null
        })
        if (token && id) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/detail-nilai-seleksi2/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_DETAIL_NILAI_PESERTA,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getDetailPesertaSeleksi3 = (id) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        dispatch({
            type: GET_DETAIL_NILAI_PESERTA,
            data: null
        })
        if (token && id) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/detail-nilai-seleksi3/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_DETAIL_NILAI_PESERTA,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const changeStatusPesertaSeleksiBerkas = (status, id, dataAll, index, Swal, reset, modalClose) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if (token) {
            modalClose(false)
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'patch',
                url: `${server}/peserta/status-seleksi-berkas/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: { status },
            })
            dispatch(setRefreshSeleksiWawancara(true))
            dispatch({
                type: SET_SELEKSI_BERKAS,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            reset()
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Mengganti Status`,
            })
        }
    } catch (err) {
        console.error(err)
    }

}

export const changeRankPesertaSeleksiBerkas = (rankSeleksi, id, dataAll, index, Swal, reset, modalClose) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if (token) {
            modalClose(false)
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'patch',
                url: `${server}/peserta/rank-seleksi-berkas/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: { rankSeleksi },
            })
            dispatch({
                type: SET_SELEKSI_BERKAS,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            reset()
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Mengganti Rank Seleksi`,
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const hapusNilaiSeleksiBerkas = (id, dataAll, index, Swal, reset) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if (token) {
            const { data } = await axios({
                method: 'patch',
                url: `${server}/peserta/nilai-seleksi-berkas/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: { esai: null, esaiNilaiTerbobot: null, mStatement: null, mStatementNilaiTerbobot: null, sejarahKepemimpinan: null, keterangan: null, rankSeleksi: null, status: null }
            })
            dispatch(setRefreshSeleksiWawancara(true))
            dispatch({
                type: SET_SELEKSI_BERKAS,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            reset()
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Menghapus Nilai Seleksi Berkas`,
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getDataSeleksiInteraksiKelompok = (program, query, cb) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && program) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/seleksi-interaksi-kelompok/${program}/?${Query(query)}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
            // dispatch(setRefreshSeleksiInteraksiKelompok(false))
            // dispatch({
            //     type: GET_SELEKSI_INTERAKSI_KELOMPOK,
            //     data: data
            // })
        } else {
            throw 'Access Blocked'
        }
    } catch (err) {
        console.error(err)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}

export const updateStatusSeleksiInteraksiKelompok = (status, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name, newStatus) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/status-seleksi3/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    status
                }
            })
            dispatch({
                type: SET_SELEKSI_INTERAKSI_KELOMPOK,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil ${newStatus ? 'Memberikan' : status !== null ? 'Mengganti' : 'Menghapus'} Status Kelulusan untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const updateStatusSeleksiInteraksiKelompokChapter = (status, statusBefore, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name, newStatus) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/status-seleksi-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    status,
                    statusBefore
                }
            })
            dispatch({
                type: SET_SELEKSI_INTERAKSI_KELOMPOK,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil ${newStatus ? 'Memberikan' : status !== null ? 'Mengganti' : 'Menghapus'} Status Kelulusan untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createRankingSeleksi3 = (rank, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/ranking-seleksi3/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    rank
                }
            })
            dispatch({
                type: SET_SELEKSI_INTERAKSI_KELOMPOK,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: rank ? `Berhasil Memberi Rangking ${rank} untuk ${name}` : `Berhasil Menghapus Rangking untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getInfoPesertaSeleksiInteraksiKelompok = (id) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/interaksi-kelompok/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_PROFIL_PESERTA_INTERAKSI_KELOMPOK,
                data: data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createInteraksiKelompok = (History, sendData, id, Swal, name, handleModalPropsClose, setModalProps) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            await axios({
                method: 'PATCH',
                url: `${server}/peserta/interaksi-kelompok/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            dispatch(setSaveAction(false))
            Swal.close()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil Input Nilai Interaksi Kelompok untuk ${name}`,
                text: 'Silahkan lihat dan cek nilai di Tabulasi',
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Kembali ke Tabulasi',
                buttonConfirmAction: _=> [handleModalPropsClose(), History.replace('/seleksi-kelompok')],
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const deleteInteraksiKelompok = (id, Swal, name, reset, index, dataAll, handleModalPropsClose, setModalProps) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/interaksi-kelompok/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    deleted : true
                }
            })
            dispatch({
                type: SET_SELEKSI_INTERAKSI_KELOMPOK,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil Menghapus Nilai Interaksi Kelompok untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createRankingSeleksiChapter = (rank, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name, chapter, program) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/ranking-seleksi-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    rank
                }
            })
            dispatch({
                type: SET_SELEKSI_INTERAKSI_KELOMPOK,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: rank ? `Berhasil Memberi Rangking ${rank} untuk ${name}` : `Berhasil Menghapus Rangking untuk ${name}`,
                text: `Chapter ${chapter} Program ${program}`,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createCommentSeleksiChapter = (comment, id, Swal, index, dataAll, reset, handleModalPropsClose, setModalProps, name, status) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token && id) {
            handleModalPropsClose()
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'PATCH',
                url: `${server}/peserta/komentar-seleksi-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    comment
                }
            })
            dispatch({
                type: SET_SELEKSI_INTERAKSI_KELOMPOK,
                data: {
                    data,
                    index,
                    dataAll
                }
            })
            Swal.close()
            reset()
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'success',
                title: `Berhasil ${status === 'delete' ? 'Menghapus' : status === true ? 'Mengubah' : 'Memberikan'} Keterangan untuk ${name}`,
                text: null,
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _=> handleModalPropsClose(),
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getProfilPesertaDownload = (id, setProfile, Swal) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'get',
                url: `${server}/peserta/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            Swal.close()
            setProfile(data)
        }
    } catch (error) {
        console.error(error);
    }
}

export const downloadFile = (uri, fileName, Swal) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        })
        const { data } = await axios({
            method: 'GET',
            url: uri,
            responseType: 'blob',
            headers: {
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            },
        })
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            Swal.close()
            ToastMid.fire({
                icon: 'success',
                title: 'Berhasil Download Berkas'
            })
        }
    } catch (err) {
        Swal.close()
    }
}

export const exportExcel = (uri, filename ,Swal, query) => async dispatch => {
    let token = localStorage.getItem('token')
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        })
        const { data, headers } = await axios({
            method: 'GET',
            url: `${server}${uri}/?${Query(query)}`,
            headers: {
                token,
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            },
            responseType:"blob"
        })
        var blob = new Blob([data], {
            type: headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;
        link.click();
        ToastMid.fire({
            icon: 'success',
            title: 'Berhasil Download Berkas'
        })
    } catch (err) {
        Swal.close()
    }
}

export const importExcel = (uri, sendData, Swal, setRefetch, refetch) => async dispatch => {
    let token = localStorage.getItem('token')
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        })
        await axios({
            method: 'POST',
            url: `${server}${uri}/import`,
            data: sendData,
            headers: {
                token,
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            }
        })
        ToastMid.fire({
            icon: 'success',
            title: 'Data Berhasil Diubah'
        })
        setTimeout(() => {
            setRefetch(!refetch)
        }, 3000);
    } catch (err) {
        Swal.close()
    }
}

export const sendEmail = (uri, sendData, Swal) => async dispatch => {
    let token = localStorage.getItem('token')
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        })
        await axios({
            method: 'POST',
            url: `${server}${uri}/mail`,
            headers: {
                token,
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            },
            data: sendData
        })
        ToastMid.fire({
            icon: 'success',
            title: 'Berhasil Kirim Email'
        })
    } catch (err) {
        Swal.close()
    }
}