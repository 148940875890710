import React, { useState } from 'react'
import './styles.scss'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { createFilterOptions } from '@material-ui/lab';
import { useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { DatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { useQuery } from '../../../../../helpers/useQuery'
import { getPin } from '../../../../../store/actions/pin'
import Swal from 'sweetalert2';
import PaginationCustom from '../../../../../components/PaginationCustom'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Breadcrumb from '../../../../../components/Breadcrumb';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHeader:{
        background: '#FAFAFA',
        borderRadius: '2px 2px 0px 0px',
    },
    tableHeaderFont : {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#888888',
    },
    tableMain: {
        boxShadow: "none",
        border: '0.5px solid #D1D1D1',
        borderRadius: '2px'
    },
    pagination:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#999999',
        paddingLeft: 0,
        marginTop: 10
    },
    paginationRight:{
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width : '60%'
    },
    date : {
        background: "#FAFAFA",
        border: "0.5px solid #D1D1D1",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "100%",
        height: "44px",
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        padding: '0px 16px',
        display: 'flex',
        outline: 'none',
        '& .MuiOutlinedInput-input' : {
            padding: '12.4px 14px'
        },
        '& .MuiInputBase-input' : {
            color: '#999999',
        },
        '& .MuiOutlinedInput-adornedEnd' : {
            paddingRight: '0px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "0.5px solid #D1D1D1",
            },
            '&:hover fieldset': {
                border: "0.5px solid #D1D1D1",
            },
            '&.Mui-focused fieldset': {
                border: "0.5px solid #D1D1D1",
            },
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-6px',
            color : '#cfd0ca'
        }
    }
});

function PINaktif({ location }){
    const query = useQuery(location?.search)
    const classes = useStyles();
    const Dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const defaultFilterOptions = createFilterOptions();
    const [pinList, setPinList] = useState([]);
    const [filterData, setFilterData] = useState({
        search: '',
        date: null
    })

    useEffect(()=>{
        if (query.get('chapter') && query.get('group')) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            })
            Swal.showLoading()
            Dispatch(getPin(
                { 
                    limit,
                    offset: page,
                    chapter_id: query.get('chapter'),
                    group_id: query.get('group'),
                    active: 'aktif',
                    search_pin: filterData?.search,
                    activasion_date: showDateBE(filterData?.date)
                }, ({ status, data, error })=>{
                    Swal.close()
                    if (status === 200) {
                        setPinList(data)
                    } else if (status === 400) {
                        Toast.fire({
                            icon: 'error',
                            title: error
                        })
                    }
                }
            ))
        }
    },[
        query.get('chapter'),
        query.get('group'),
        page,
        limit,
        filterData.search,
        filterData.date
    ])

    const handleChangeValue = (key, value) => {
        switch (key) {
            case 'search':
                setFilterData({
                    ...filterData,
                    search: value
                })
                break;
            case 'date':
                setFilterData({
                    ...filterData,
                    date: value
                })
                break;
            default:
                break;
        }
    }

    let showDate = (dateParams) => {
        if (dateParams) {
            let date = new Date(dateParams).getDate() 
            let monthNumber = new Date(dateParams).getMonth()
            let month = ''
            let year = new Date(dateParams).getFullYear()
            switch (monthNumber) {
                case 0 :
                    month = 'Januari'
                    break;
                case 1 :
                    month = 'Februari'
                    break;
                case 2 :
                    month = 'Maret'
                    break;
                case 3 :
                    month = 'April'
                    break;
                case 4 :
                    month = 'Mei'
                    break;
                case 5 :
                    month = 'Juni'
                    break;
                case 6 :
                    month = 'Juli'
                    break;
                case 7 :
                    month = 'Agustus'
                    break;
                case 8 :
                    month = 'September'
                    break;
                case 9 :
                    month = 'Oktober'
                    break;
                case 10 :
                    month = 'November'
                    break;
                case 11 :
                    month = 'Desember'
                    break;
                default:
                    month = 'hehe'
                    break;
            }
            if (date < 10) {
                return '0'+date + ' ' + month  + ' ' + year
            } else {
                return date + ' ' + month  + ' ' + year
            }
        } else {
            return ''
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(event.target.value);
        setPage(1);
    };
    
    const HandleFromIndex = (from, to, count) => {
        return `dari ${count} Data`
    } 

    const handleChange = (event, value) => {
        setPage(value-1)
    };

    let showDateTime = (dateParams) => {
        if (dateParams) {
            let date = new Date(dateParams).getDate() 
            let monthNumber = new Date(dateParams).getMonth()
            let month = ''
            let year = new Date(dateParams).getFullYear()
            switch (monthNumber) {
                case 0 :
                    month = 'Januari'
                    break;
                case 1 :
                    month = 'Februari'
                    break;
                case 2 :
                    month = 'Maret'
                    break;
                case 3 :
                    month = 'April'
                    break;
                case 4 :
                    month = 'Mei'
                    break;
                case 5 :
                    month = 'Juni'
                    break;
                case 6 :
                    month = 'Juli'
                    break;
                case 7 :
                    month = 'Agustus'
                    break;
                case 8 :
                    month = 'September'
                    break;
                case 9 :
                    month = 'Oktober'
                    break;
                case 10 :
                    month = 'November'
                    break;
                case 11 :
                    month = 'Desember'
                    break;
                default:
                    month = 'hehe'
                    break;
            }
            var time = new Date(dateParams).getHours() + ":" + new Date(dateParams).getMinutes() + ":" + new Date(dateParams).getSeconds();
            if (date < 10) {
                return '0'+date + ' ' + month  + ' ' + year + ' ' + time
            } else {
                return date + ' ' + month  + ' ' + year+ ' ' + time
            }
        } else {
            return '-'
        }
    }

    let showDateBE = (dateParams) => {
        if (dateParams) {
            let date = new Date(dateParams).getDate() 
            let monthNumber = new Date(dateParams).getMonth()
            let month = ''
            let year = new Date(dateParams).getFullYear()
            switch (monthNumber) {
                case 0 :
                    month = '01'
                    break;
                case 1 :
                    month = '02'
                    break;
                case 2 :
                    month = '03'
                    break;
                case 3 :
                    month = '04'
                    break;
                case 4 :
                    month = '05'
                    break;
                case 5 :
                    month = '06'
                    break;
                case 6 :
                    month = '07'
                    break;
                case 7 :
                    month = '08'
                    break;
                case 8 :
                    month = '09'
                    break;
                case 9 :
                    month = '10'
                    break;
                case 10 :
                    month = '11'
                    break;
                case 11 :
                    month = '12'
                    break;
                default:
                    month = 'hehe'
                    break;
            }
            if (date < 10) {
                return year + '/' + month  + '/' + '0'+date
            } else {
                return year + '/' + month  + '/' + date
            }
        }
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const getUsername = (peserta) => {
        let temp = '-'
        if (peserta) {
            if (peserta.username) {
                temp = peserta.username
            }
        }
        return temp
    }

    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, 5);
    };

    return(
        <div className="data-container-wrapper" >
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Management PIN
                </span>
            </div>
            <Breadcrumb
                links={[
                    {
                        label: 'Management PIN',
                        link: true
                    },
                    {
                        label: 'Daftar PIN',
                        link: '/management-pin/daftar'
                    },
                    {
                        label: 'Daftar Pengguna PIN',
                        link: false
                    }
                ]}
            />
            <div className="daftar-component-wrapper" >
                <div className="daftar-component-header" >
                    <span className="daftar-header-font" >
                        Daftar PENGGUNA PIN
                    </span>
                </div>
                <div className="daftar-table-wrapper" >
                    <div className="daftar-filter-table" >
                        <div className="search-filter filter-table" style={{ marginRight: 10 }}>
                            <Autocomplete
                                filterOptions={filterOptions}
                                options={(pinList?.search || [])}
                                noOptionsText={'Tidak Ditemukan'}
                                getOptionLabel={(option) => `${ option?.pin || '-' }`}
                                freeSolo
                                fullWidth
                                id="free-solo-2-demo"
                                onChange={(_, val) => [handleChangeValue('search', val?._id), setPage(1)]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className='search-nilai-cus'
                                        placeholder='Cari PIN'
                                        variant="outlined"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i class="fas fa-search search-custom-filter" style={{ minWidth: 47, paddingRight: 0 }}></i>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                                renderOption={(option) => (
                                    <div>
                                        <div>{`${ option?.pin || '-' }`}</div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="filter-table-date-style-2021">
                            <DatePicker
                                clearable
                                clearLabel="Hapus"
                                autoOk
                                views={['year', 'month', 'date']}
                                placeholder="Tanggal Aktivasi"
                                value={filterData.date}
                                onChange={(newValue) => {
                                    handleChangeValue( 'date', newValue);
                                }}
                                animateYearScrolling
                                okLabel=""
                                cancelLabel=""
                                helperText=""
                                format="dd/MM/yyyy"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: '0px',
                                    zIndex: '9',
                                    height: '44px',
                                    borderRadius: '4px',
                                }}
                                minDate={
                                    new Date("2022-1-1") 
                                }
                                maxDate={
                                    new Date("2022-12-31") 
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                                style={{
                                    color : "#3c98dc",
                                    fontSize: 18,
                                }}
                            />
                        </div>
                    </div>
                    <div className="daftar-table" >
                        <TableContainer className={classes.tableMain} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                <TableRow className={classes.tableHeader} >
                                    <TableCell className={classes.tableHeaderFont} >NO</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">PIN</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">TANGGAL AKTIVASI</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">TANGGAL KADALUARSA</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">USERNAME</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">CHAPTER</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    pinList?.data?.map((listPin,i) => (
                                        <TableRow key={i} className="table-hover-style-custom">
                                            <TableCell component="th" scope="row">
                                                {((page - 1) * limit) + i + 1}
                                            </TableCell>
                                            <TableCell align="left">{listPin ? listPin.pin : '-'}</TableCell>
                                            <TableCell align="left">{ showDate(listPin?.activasiDate) || '-' }</TableCell>
                                            <TableCell align="left">{showDateTime(listPin.expired)}</TableCell>
                                            <TableCell align="left">{ listPin?.pesertaId?.username || '-' }</TableCell>
                                            <TableCell align="left"
                                                style={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                { listPin?.chapterId?.chapter || '-' }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationCustom
                            handleChangePage={handleChangePage}
                            page={page}
                            setPage={setPage}
                            dataFilter={pinList?.length || 0}
                            limit={limit}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PINaktif