import {
    GET_PROFILE,
    IS_LOGOUT,
    GET_ADMINCHAPTER,
    SET_ADMINCHAPTER,
    UPDATE_ADMINCHAPTER,
    DELETE_ADMINCHAPTER
} from '../constanta'

const intialState = {
    profile: null,
    adminChapter: []
}

function userState(state = intialState, action) {
    switch (action.type) {
        case GET_PROFILE:
            return {
                ...state, 
                profile : action.profile
            }
        case GET_ADMINCHAPTER:
            return {
                ...state, 
                adminChapter : action.data
            }
        case SET_ADMINCHAPTER:
            return {
                ...state, 
                adminChapter : [ ...state.adminChapter, action.data ]
            }
        case UPDATE_ADMINCHAPTER:
            let data = []
            if (state.adminChapter) {
                state.adminChapter.map(admin=>{
                    if (admin._id !== action.data._id) {
                        data.push(admin)
                    }
                })
            }
            return {
                ...state, 
                adminChapter : [ ...data, action.data ]
            }
        case DELETE_ADMINCHAPTER:
            let data1 = []
            if (state.adminChapter) {
                state.adminChapter.map(admin=>{
                    if (admin._id !== action.data) {
                        data1.push(admin)
                    }
                })
            }
            return {
                ...state, 
                adminChapter : data1
            }
        case IS_LOGOUT:
            return {
                profile : null
            }
        default: {
            return state
        }
    }
}

export default userState