import axios from 'axios'
import server from '../../endpoint'
import { generateToken } from '../../helpers/bearer'
import { Query } from '../../helpers/query'
import {
    GET_PINLIST
} from "../constanta";

export const createPin = ( History, sendData, Swal, goTop, reset ) => async dispatch => {
    const SwalBootstrap = Swal.mixin({
        customClass: {
            confirmButton: 'btn-finalisasi-yakin',
            cancelButton: 'btn-finalisasi-tidak'
        },
        buttonsStyling: false
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.showLoading()
            await axios({
                method: 'post',
                url: `${server}/pin`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            // dispatch(getPinGroup())
            SwalBootstrap.fire({
                title: `PIN Berhasil dibuat`,
                text: `Ingin Membuat PIN lagi?`,
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Lihat Daftar',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    goTop()
                    reset()
                } else if ( result.dismiss === Swal.DismissReason.cancel) {
                    History.push('/management-pin/daftar')
                }
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getPinGroup = (query, cb) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/pin/?${Query(query)}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
            // dispatch({
            //     type: GET_PINLIST,
            //     data
            // })
        }
    } catch (error) {
        console.error(error)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}

export const getPin = (query, cb) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/pin/list/?${Query(query)}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
            // dispatch({
            //     type: GET_PINLIST,
            //     data
            // })
        }
    } catch (error) {
        console.error(error)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}

export const getPinPDF = (id, cb) => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/pin/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            cb({
                status : 200,
                data: data,
                error: null
            })
            // dispatch({
            //     type: GET_PINLIST,
            //     data
            // })
        }
    } catch (error) {
        console.error(error)
        cb({
            status : 400,
            data: [],
            error: `Something Error`
        })
    }
}