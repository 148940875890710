import React, { useEffect, useState, Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import fileblue from '../../images/modal/file-c-blue.svg'
import filered from '../../images/modal/file-c-red.svg'
import ModalSucces from './ModalSucces';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './styles.scss'
import { showDate, showHour } from '../../helpers/convertDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import { downloadFile } from '../../store/actions/dataPeserta'

function ModalBerkas(props) {
    const Dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [berkas, setBerkas] = useState([])
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        if (props) {
            if (props.peserta) {
                setBerkas(
                    [
                        { nama: 'Berkas Pendaftaran', file: { file: 'ada', date: props.peserta.tanggalFinalisasi } },
                        { nama: 'Surat Pernyataan Sekolah', file: props.peserta.suratPernyataanSekolah },
                        { nama: 'Surat Pernyataan Orang Tua', file: props.peserta.suratIzinOrangTua },
                        { nama: 'Surat Rekomendasi Lingkungan Rumah', file: props.peserta.suratRekomendasiKeluarga },
                        { nama: 'Rekomendasi Lingkungan Sekolah', file: props.peserta.suratRekomendasiSekolah },
                        { nama: 'Rekomendasi Teman Dekat', file: props.peserta.suratRekomendasiTemanDekat },
                        { nama: 'Raport', file: props.peserta.raport },
                        { nama: 'Akta Kelahiran', file: props.peserta.aktaKelahiran },
                        { nama: 'SKHUN & Ijazah SMP', file: props.peserta.skhunIjazah },
                        { nama: 'Sertifikat dan lain-lain', file: props.peserta.sertifikatDll }
                    ]
                )
                if (props.peserta._id) setFlag(true)
            }
        }
    }, [props])

    const beriOtoritas = (stat) => {
        props.changeOtoritasiChapter(stat, handleCloseMenu)
    }

    const handleClose = () => {
        setOpenModal(false)
        props.handleClose()
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const cabutOtoritas = (stat) => {
        props.changeOtoritasiChapter(stat, handleCloseMenu)
    }

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan+' ' : ''}${namaTengah ? namaTengah+' ' : ''}${namaBelakang ? namaBelakang+' ' : ''}`
    }

    const handleDownload = (e, url, fileName) => {
        if (e) e.preventDefault()
        if (props) {
            if (props.peserta) {
                if (props.peserta.dataPribadi) {
                    if (fileName && url) {
                        Dispatch(downloadFile(url, `${getMyName(props.peserta.dataPribadi)}-${fileName}.pdf` ,Swal))
                    }
                }
            }
        }
    }

    return (
        <Fragment>
            {
                props &&
                props.peserta &&
                props.peserta.dataPribadi &&
                <ModalSucces open={openModal} handleClose={handleClose} nama={`${getMyName(props.peserta.dataPribadi)}`} />
            }
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
            >
                <div className='title-m-berkas'>
                    <div className='title-berkas-modal'>Berkas Peserta - { props && props.peserta && props.peserta.dataPribadi && getMyName(props.peserta.dataPribadi)}</div>
                    <IconButton onClick={props.handleClose} size='small'>
                        <CloseIcon style={{ color: '#A2A2A2' }} />
                    </IconButton>
                </div>
                {
                    props &&
                    props.peserta &&
                    props.peserta._id &&
                    <div className='p20' style={{ width: 700, paddingTop: 10 }}>
                        <div className='txt-ttl-berkas' style={{ marginTop: 5 }} >Nama-nama berkas</div>
                        <div className='mt12'>
                            <Grid container spacing={2}>
                                {
                                    berkas.map((item, i) => (
                                        flag &&
                                        <Grid item xs={6}>
                                            {
                                                !item.file || item.file.file === null ?
                                                    <div className='div-berkas-red'>
                                                        <img src={filered} alt='icn' style={{ width: 38 }} />
                                                        <div className='ml10'>
                                                            <div className='txt-name'>{item.nama}</div>
                                                            <div className='no-berkas'>Belum ada berkas</div>
                                                        </div>
                                                    </div> :
                                                    <div className='div-berkas-blue'>
                                                        <img src={fileblue} alt='icn' style={{ width: 38 }} />
                                                        <div className='ml10'>
                                                            <div className='txt-name'>{item.nama}</div>
                                                            <div className='txt-upload'>Upload: {showDate(item.file.date)} | {showHour(item.file.date)}</div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                marginLeft: "auto"
                                                            }}
                                                        >
                                                            <Link
                                                                to={{ pathname:
                                                                    i !== 0 ?
                                                                        item && item.file && item.file.file
                                                                        :
                                                                        `profil/${props && props.peserta && props.peserta._id}`
                                                                }}
                                                                target="_BLANK"
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    style={{
                                                                        color: '#3c98dc',
                                                                        fontSize: 20,
                                                                    }}
                                                                />
                                                            </Link>
                                                            {
                                                                i === 0 ?
                                                                <Link
                                                                    to={{ pathname: `/download-profile/${props && props.peserta && props.peserta._id}` }}
                                                                    target="_BLANK"
                                                                    style={{
                                                                        textDecoration: 'none',
                                                                        cursor: 'pointer',
                                                                        marginTop: 2
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowCircleDown}
                                                                        style={{
                                                                            color: '#3c98dc',
                                                                            fontSize: 20,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                </Link>
                                                                :
                                                                <FontAwesomeIcon
                                                                    icon={faArrowCircleDown}
                                                                    style={{
                                                                        color: '#3c98dc',
                                                                        fontSize: 20,
                                                                        cursor: 'pointer',
                                                                        marginTop: 2
                                                                    }}
                                                                    onClick={e=> handleDownload(e, item && item.file && item.file.file, item && item.nama) }
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </div>
                    </div>
                }
                <div className='title-m-berkas' style={{ padding: '13px 20px' }}>
                    <div className='txt-act-berkas'>Setelah otoritas diberikan, chapter bisa upload berkas peserta</div>
                    {
                        props &&
                        props.peserta &&
                        props.peserta.otoritasiChapter === true ?
                        <div className='div-oto-diberi' onClick={handleClick}>Otoritas Diberikan<div className='div-oto-arr'><ArrowDropDownIcon /> </div></div>:
                        <Button color='primary' variant='contained' className='btn-otoritas' style={{backgroundColor: '#3598DC'}} onClick={_ => beriOtoritas(true)}>Beri Otoritas</Button>
                    }
                </div>
                <Menu
                    anchorEl={anchorEl}
                    style={{marginTop: "-34px"}}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <MenuItem className='menu-item-table' onClick={() => cabutOtoritas(false)}>Batalkan Otoritas</MenuItem>
                </Menu>
            </Dialog>
        </Fragment>
    )
}

export default ModalBerkas