import React, { Fragment, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import Divider from '@material-ui/core/Divider';
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import {
    updateStatusSeleksiInteraksiKelompok,
    updateStatusSeleksiInteraksiKelompokChapter,
    createRankingSeleksi3,
    createRankingSeleksiChapter,
    createCommentSeleksiChapter,
    deleteInteraksiKelompok,
    getDataSeleksiInteraksiKelompok,
    exportExcel,
    importExcel,
    sendEmail
} from '../../../store/actions/dataPeserta'
import { useHistory, Link } from 'react-router-dom';
import './styles.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PaginationCustom from '../../../components/PaginationCustom'
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import ModalKeterangan from '../../../components/modal/keteranganChapterInteraksiKelompok'
import ModalBerkasPreview from '../../../components/modal/ModalBerkasPreview';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ModalCustom from '../../../components/modal/ModalCustom'
import XLSX from 'xlsx'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from '../../../components/JZZIPUtils';
import Breadcrumb from '../../../components/Breadcrumb';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    tableHeader: {
        background: '#FAFAFA',
        borderRadius: '2px 2px 0px 0px',
    },
    tableHeaderFont: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#888888',
    },
    tableMain: {
        boxShadow: "none",
        border: '0.5px solid #D1D1D1',
        borderRadius: '2px',
        width: '100%'
    },
    pagination: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#999999',
        paddingLeft: 0,
        marginTop: 10
    },
    paginationRight: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60%'
    },
    formControl: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '200px',
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            zIndex: 99
        },
    },
    formControlNormaly: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root': {
            // paddingTop: 0,
            // paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-input':{
            padding: '10px 25px 10px 14px'
        },
        '& .MuiSelect-select':{
            '&:focus': {
                backgroundColor: 'white',
            },
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '200px',
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                // background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            zIndex: 99
        },
    },
    formControlNormal: {
        '& label.Mui-focused': {
            color: '#444444',
        },
        '& label': {
            color: '#444444',
        },
        '&:hover label': {
            color: 'white',
        },
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '92px',
            height: '32px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                color: '#444444',
                background: 'white',
            },
            '&:hover fieldset': {
                border: 'none',
                background: '#3598DC',
            },
        },
        '& .MuiSelect-iconOutlined': {
            color: '#888888',
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            color: 'white',
            zIndex: 99
        },
    },
    formControlRed: {
        '& label.Mui-focused': {
            color: '#FF4040',
        },
        '& label': {
            color: '#FF4040',
        },
        '&:hover label': {
            color: 'white',
        },
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '92px',
            height: '32px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                color: '#FF4040',
                background: 'white',
            },
            '&:hover fieldset': {
                border: 'none',
                background: '#3598DC',
            },
        },
        '& .MuiSelect-iconOutlined': {
            color: '#FF4040',
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            color: 'white',
            zIndex: 99
        },
    },
    formControlScored: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root': {
            // paddingTop: 0,
            // paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '250px',
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                // background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            zIndex: 99
        },
    },
}));

function SeleksiInteraksi() {
    const options = JSON.parse(localStorage.getItem('options'))
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const History = useHistory()
    const hiddenFileInput = useRef(null);
    const Dispatch = useDispatch()
    const optionState = useSelector(state => state.optionState.option)
    const propinsiDB = useSelector(state=> state.daerahState.propinsi )
    const kabupatenDB = useSelector(state=> state.daerahState.kabupaten )
    // const refreshSeleksiInteraksiKelompok = useSelector(state => state.filterState.refreshSeleksiInteraksiKelompok);
    // const seleksiInteraksiKelompok = useSelector(state => state.dataPesertaState.seleksiInteraksiKelompok);
    const chapter = useSelector(state => state.chapterState.chapter)
    const profile = useSelector(state => state.userState.profile)
    const defaultFilterOptions = createFilterOptions();
    const [datacr, setDataCr] = useState(null)
    const [datacrIndex, setDataCrIndex] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [statusAction, setStatusAction] = useState(null)
    const [openModalBerkas, setOpenModalBerkas] = useState(false)
    const [dataFilter, setDataFilter] = useState({
        search: '',
        chapter: '',
        status: null,
        nilai: '',
    })
    const [modalProps, setModalProps] = useState({
        open: false,
        icon: '',
        title: '',
        text: '',
        buttonCancel: false,
        buttonCancelText: '',
        buttonCancelAction: null,
        buttonConfirm: false,
        buttonConfirmText: '',
        buttonConfirmAction: null,
    })
    const [isExport, setIsExport] = useState(false)
    const [seleksiInteraksiKelompok, setSeleksiInteraksiKelompok] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [sort, setSort] = useState({ key: null, by: null })

    // useEffect(()=>{
    //     let options = JSON.parse(localStorage.getItem('options'))
    //     if(refreshSeleksiInteraksiKelompok && options.program){
    //         Dispatch(getDataSeleksiInteraksiKelompok(options.program))
    //     }
    // },[refreshSeleksiInteraksiKelompok])

    useEffect(()=>{
        if (options?.program) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            })
            Swal.showLoading()
            Dispatch(getDataSeleksiInteraksiKelompok(options?.program,
                {
                    limit,
                    offset: page,
                    chapter_id: dataFilter?.chapter,
                    status_seleksi_interaksi_kelompok: dataFilter?.status === 'true-seleksi3' ? true : dataFilter?.status === 'false-seleksi3' ? false : null,
                    status_seleksi_chapter: dataFilter?.status === 'true-chapter' ? true : dataFilter?.status === 'false-chapter' ? false : null,
                    nilai: dataFilter?.nilai,
                    search_id: dataFilter?.search,
                    ranking_chapter: sort.key === 'ranking_chapter' ? sort.by : null,
                    ranking_seleksi: sort.key === 'ranking_seleksi' ? sort.by : null,
                    nilai_akumulatif: sort.key === 'nilai_akumulatif' ? sort.by : null,
                },
                ({ status, data, error })=>{
                    Swal.close()
                    if (status === 200) {
                        setSeleksiInteraksiKelompok(data)
                    } else if (status === 400) {
                        Toast.fire({
                            icon: 'error',
                            title: error
                        })
                    }
                }
            ))
        }
    },[
        options?.program,
        page,
        limit,
        sort,
        dataFilter.chapter,
        dataFilter.status,
        dataFilter.nilai,
        dataFilter.search,
        refetch
    ])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleModalPropsClose = () => {
        setModalProps({
            ...modalProps,
            open: false,
        })
    }

    const handleModalPropsRankSeleksi3 = (value) => {
        setModalProps({
            open: true,
            close: handleModalPropsClose,
            icon: 'info',
            title: `Beri Rangking ${value} Untuk ${getMyName(datacr && datacr.dataPribadi)} Ditahap Interaksi Kelompok?`,
            text: 'Cek kembali keputusan Anda sebelum memberi rangking',
            buttonCancel: true,
            buttonCancelText: 'Tidak',
            buttonCancelAction: handleModalPropsClose,
            buttonConfirm: true,
            buttonConfirmText: 'Ya, Yakin',
            buttonConfirmAction: _ => setRanking(value),
        })
    }

    const handleModalPropsDeleteRankSeleksi3 = (value) => {
        setModalProps({
            open: true,
            close: handleModalPropsClose,
            icon: 'delete',
            title: `Yakin Akan Menghapus Ranking?`,
            text: 'Cek kembali keputusan Anda sebelum menghapus ranking',
            buttonCancel: true,
            buttonCancelText: 'Batal',
            buttonCancelAction: handleModalPropsClose,
            buttonConfirm: true,
            buttonConfirmText: 'Ya, Hapus',
            buttonConfirmAction: _ => setRanking(value),
        })
    }

    const handleModalPropsRankSeleksiChapter = (value) => {
        let options = JSON.parse(localStorage.getItem('options'))
        setModalProps({
            open: true,
            close: handleModalPropsClose,
            icon: 'info',
            title: `Beri Rangking ${value} Untuk ${getMyName(datacr && datacr.dataPribadi)}?`,
            text: `Cek kembali keputusan Anda sebelum memberi rangking program ${options && options.program} chapter ${capitalizeFirstLetter(getChapterName(chapter, datacr && datacr.chapterId))}`,
            buttonCancel: true,
            buttonCancelText: 'Tidak',
            buttonCancelAction: handleModalPropsClose,
            buttonConfirm: true,
            buttonConfirmText: 'Ya, Yakin',
            buttonConfirmAction: _ => setRankingChapter(value),
        })
    }

    const handleModalPropsDeleteRankSeleksiChapter = (value) => {
        setModalProps({
            open: true,
            close: handleModalPropsClose,
            icon: 'delete',
            title: `Yakin Akan Menghapus Ranking?`,
            text: 'Cek kembali keputusan Anda sebelum menghapus ranking',
            buttonCancel: true,
            buttonCancelText: 'Batal',
            buttonCancelAction: handleModalPropsClose,
            buttonConfirm: true,
            buttonConfirmText: 'Ya, Hapus',
            buttonConfirmAction: _ => setRankingChapter(value),
        })
    }

    const handleModalPropsStatus = (value, newStatus) => {
        let obj = {
            title: '',
            text: '',
            icon: 'info',
            buttonConfirmText : 'Ya, Yakin',
            buttonCancelText: 'Tidak'
        }
        if (value !== null && newStatus) {
            obj.title = `${value === true ? 'Meluluskan' : value === false ? 'Tidak Meluluskan' : 'Menghapus'} ${getMyName(datacr && datacr.dataPribadi)} Ditahap Interaksi Kelompok?`
            obj.text = `Cek kembali keputusan Anda sebelum memberi keputusan kelulusan`
        } else if(value !== null) {
            obj.title = `Mengubah Status Kelulusan ${getMyName(datacr && datacr.dataPribadi)}?`
            obj.text = `Anda akan ${value === true ? 'meluluskan' : value === false ? 'tidak meluluskan' : 'Menghapus'} ${getMyName(datacr && datacr.dataPribadi)} ditahap interaksi kelompok`
        } else if(value === null){
            obj.icon = 'delete'
            obj.buttonCancelText = `Batal`
            obj.buttonConfirmText = `Ya, Hapus`
            obj.title = `Yakin Akan Menghapus Status Kelulusan?`
            obj.text = `Cek kembali keputusan Anda sebelum menghapus kelulusan`
        }
        setModalProps({
            open: true,
            close: handleModalPropsClose,
            icon: obj.icon,
            title: obj.title,
            text: obj.text,
            buttonCancel: true,
            buttonCancelText: obj.buttonCancelText,
            buttonCancelAction: handleModalPropsClose,
            buttonConfirm: true,
            buttonConfirmText: obj.buttonConfirmText,
            buttonConfirmAction: _ => setStatus(value, newStatus, statusAction),
        })
    }

    const closeModalBerkas = () => {
        setOpenModalBerkas(false)
    }

    const handleCloseModal = (dat) => {
        setOpenModal(false)
    }

    const handleOpenModal = (dat) => {
        setOpenModal(true)
        handleCloseKomentarOption()
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    }

    const setKeterangan = (keterangan, status) => {
        if (!keterangan) {
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'info',
                title: `Silahkan Isi Persyaratan`,
                text: '',
                buttonCancel: false,
                buttonCancelText: '',
                buttonCancelAction: null,
                buttonConfirm: true,
                buttonConfirmText: 'OK',
                buttonConfirmAction: _ => [handleModalPropsClose()],
            })
        } else if (datacr) {
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'info',
                title: `${status ? 'Ubah' : 'Beri' } Keterangan Untuk ${getMyName(datacr && datacr.dataPribadi)}?`,
                text: 'Cek kembali keputusan Anda sebelum memberi keterangan',
                buttonCancel: true,
                buttonCancelText: 'Tidak',
                buttonCancelAction: handleModalPropsClose,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Yakin',
                buttonConfirmAction: _ => saveKeterangan(keterangan, status),
            })
        }
    }

    const saveKeterangan = (keterangan, status) => {
        if (datacr) {
            Dispatch(createCommentSeleksiChapter(keterangan, datacr && datacr._id, Swal, datacrIndex, seleksiInteraksiKelompok, reset, handleModalPropsClose, setModalProps, getMyName(datacr && datacr.dataPribadi), status))
        }
    }

    const setKeteranganDelete = (keterangan) => {
        if (datacr) {
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'delete',
                title: `Yakin Akan Menghapus Keterangan?`,
                text: 'Cek kembali keputusan Anda sebelum menghapus keterangan',
                buttonCancel: true,
                buttonCancelText: 'Batal',
                buttonCancelAction: handleModalPropsClose,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Hapus',
                buttonConfirmAction: _ => saveKeterangan(keterangan, 'delete'),
            })
        }
    }

    const setNilaiDelete = () => {
        if (datacr) {
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'delete',
                title: `Yakin Akan Menghapus Nilai?`,
                text: 'Cek kembali keputusan Anda sebelum menghapus nilai',
                buttonCancel: true,
                buttonCancelText: 'Batal',
                buttonCancelAction: handleModalPropsClose,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Hapus',
                buttonConfirmAction: _ => Dispatch(deleteInteraksiKelompok((datacr && datacr._id), Swal, getMyName(datacr && datacr.dataPribadi), reset, datacrIndex, seleksiInteraksiKelompok, handleModalPropsClose, setModalProps)),
            })
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(1);
    };

    const [anchorEl1, setAnchorEl1] = useState(null);
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClick1 = (event, dat) => {
        setAnchorEl1(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event, dat, action) => {
        setAnchorEl(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
            if (action) {
                setStatusAction(action)
            }
        }
    };

    const [anchorEl2, setAnchorEl2] = useState(null);
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClick2 = (event, dat) => {
        setAnchorEl2(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    };

    const [anchorEl3, setAnchorEl3] = useState(null);
    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    const handleClick3 = (event, dat) => {
        setAnchorEl3(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    };

    const [anchorEl4, setAnchorEl4] = useState(null);
    const handleClose4 = () => {
        setAnchorEl4(null);
    };

    const handleClick4 = (event, dat) => {
        setAnchorEl4(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    };

    const [anchorElUbahStatus, setAnchorElUbahStatus] = useState(null);
    const handleCloseUbahStatus = () => {
        setAnchorElUbahStatus(null);
    };

    const handleClickUbahStatus = (event) => {
        setAnchorElUbahStatus(event.currentTarget)
    };

    const [anchorElInputStatus, setAnchorElInputStatus] = useState(null);
    const handleCloseInputStatus = () => {
        setAnchorElInputStatus(null);
    };

    const handleClickInputStatus = (event, dat, action) => {
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
            if (action) {
                setStatusAction(action)
            }
        }
        setAnchorElInputStatus(event.currentTarget)
    };

    const [anchorElInputRank, setAnchorElInputRank] = useState(null);
    const handleCloseInputRank = () => {
        setAnchorElInputRank(null);
    };

    const handleClickInputRank = (event, dat) => {
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
        setAnchorElInputRank(event.currentTarget)
    };

    const [anchorElInputRankChapter, setAnchorElInputRankChapter] = useState(null);
    const handleCloseInputRankChapter = () => {
        setAnchorElInputRankChapter(null);
    };

    const handleClickInputRankChapter = (event, dat) => {
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
        setAnchorElInputRankChapter(event.currentTarget)
    };

    const [anchorEl1Chapter, setAnchorEl1Chapter] = useState(null);
    const handleClose1Chapter = () => {
        setAnchorEl1Chapter(null);
    };

    const handleClick1Chapter = (event, dat) => {
        setAnchorEl1Chapter(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    };

    const [KomentarOption, setAnchorElKomentarOption] = useState(null);
    const handleCloseKomentarOption = () => {
        setAnchorElKomentarOption(null);
    };

    const handleClickKomentarOption = (event, dat) => {
        setAnchorElKomentarOption(event.currentTarget)
        if (dat) {
            let index = seleksiInteraksiKelompok?.data?.indexOf(dat)
            setDataCr(dat)
            setDataCrIndex(index)
        }
    };

    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, 5);
    };

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan+' ' : ''}${namaTengah ? namaTengah+' ' : ''}${namaBelakang ? namaBelakang+' ' : ''}`
    }

    const handleChangeFilter = (key, value, active) => {
        switch (key) {
            case 'search':
                setDataFilter({
                    ...dataFilter,
                    search: value
                })
                break;
            case 'chapter':
                setDataFilter({
                    ...dataFilter,
                    chapter: value
                })
                break;
            case 'status':
                setDataFilter({
                    ...dataFilter,
                    status: value
                })
                break;
            case 'statusChapter':
                setDataFilter({
                    ...dataFilter,
                    statusChapter: value
                })
                break;
            case 'nilai':
                setDataFilter({
                    ...dataFilter,
                    nilai: value
                })
                break;
            case 'sortRankInteraksiKelompok':
                if (active === 'sortRankInteraksiKelompok') {
                    setDataFilter({
                        ...dataFilter,
                        sortRankInteraksiKelompok: value,
                        activeSort: 'sortRankInteraksiKelompok'
                    })
                } else {
                    setDataFilter({
                        ...dataFilter,
                        activeSort: 'sortRankInteraksiKelompok'
                    })
                }
                break;
            case 'sortNilaiAkumulatif':
                if (active === 'sortNilaiAkumulatif') {
                    setDataFilter({
                        ...dataFilter,
                        sortNilaiAkumulatif: value,
                        activeSort: 'sortNilaiAkumulatif'
                    })
                } else {
                    setDataFilter({
                        ...dataFilter,
                        activeSort: 'sortNilaiAkumulatif'
                    })
                }
                break;
            case 'sortRankChapter':
                if (active === 'sortRankChapter') {
                    setDataFilter({
                        ...dataFilter,
                        sortRankChapter: value,
                        activeSort: 'sortRankChapter'
                    })
                } else {
                    setDataFilter({
                        ...dataFilter,
                        activeSort: 'sortRankChapter'
                    })
                }
                break;
            default:
                break;
        }
    }

    const getValue = (obj, key) => {
        let result
        if (obj) {
            result = obj[key]
        }
        return `${result ? result : '-'}`
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const getTotal = (list, key, value) => {
        if (list) {
            if (key === 'kelamin') {
                let count = list.filter(a=> dataFilter.chapter ? a.chapterId === dataFilter.chapter : a)
                                .filter(a=> dataFilter.status !== null ? a.statusSeleksi3 === dataFilter.status : a)
                                .filter(a=> dataFilter.statusChapter !== null ? a.statusSeleksiChapter === dataFilter.statusChapter : a)
                                .filter(a=> dataFilter.nilai !== null ? checkPenilaiFilter(dataFilter.nilai, a) : a)
                if (value !== 'all') {
                    count = count.filter(a => (a.dataPribadi && a.dataPribadi.kelamin) === value)
                }
                if (count) {
                    return count.length
                } else {
                    return 0
                }
            }
        }
    }

    const noPesertaGenerate = (peserta) => {
        let options = JSON.parse(localStorage.getItem('options'))
        let program = '-'
        if (options) {
            if (options.program === 'KAKEHASHI') {
                program = 'MXT'
            } else {
                program = options.program
            }
        }
        if (peserta) {
            return `YBA/YP25-26/${getChapterCode(chapter, peserta && peserta.chapterId)}/${program}/${peserta.nomorUrut === '0000' ? '-' : peserta.nomorUrut}`
        }
        return ''
    }

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.code : '-'
        }
    }

    const getBorn = (dataPribadi) => {
        let tanggal
        if (dataPribadi) {
            tanggal = dataPribadi.tanggalLahir
        }
        return `${tanggal ? getDate(tanggal) : '-'}`
    }

    const getDate = (value) => {
        if (value) {
            let start = new Date(value).getDate()
            let startMonth = new Date(value).getMonth()
            let monthStart = ''
            let yearResult = new Date(value).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }

            return start + ' '+ monthStart +' '+ yearResult
        }
    }

    const getAddressPlace = (dataPribadi) => {
        let alamat
        let kecamatan
        let kota
        let propinsi
        let kodepos
        if (dataPribadi) {
            if (dataPribadi.propinsi) {
                if (propinsiDB.filter(a=> a.id === dataPribadi.propinsi)[0]) {
                    propinsi = propinsiDB.filter(a=> a.id === dataPribadi.propinsi)[0].propinsi
                }
            }
            if(kabupatenDB[dataPribadi.propinsi]){
                if (kabupatenDB[dataPribadi.propinsi].filter(a=> a.id === dataPribadi.kabupaten )[0]) {
                    kota = kabupatenDB[dataPribadi.propinsi].filter(a=> a.id === dataPribadi.kabupaten )[0].kabupaten
                }
            }
            alamat = dataPribadi.alamat
            kecamatan = dataPribadi.kecamatan
            kodepos = dataPribadi.kodepos
        }
        return `${alamat ? alamat : '-'}, Kecamatan ${kecamatan ? kecamatan : '-'}, Kota/Kabupaten ${kota ? kota : '-'}, Propinsi ${propinsi ? propinsi : '-'} ${kodepos ? kodepos : ''}`
    }

    const reset = () => {
        handleClose()
        handleClose1()
        handleClose2()
        handleClose3()
        handleClose4()
        handleClose1Chapter()
        handleCloseInputStatus()
        handleCloseUbahStatus()
        handleCloseInputRank()
        handleCloseInputRankChapter()
        handleCloseKomentarOption()
        setDataCr(null)
        setDataCrIndex(null)
        setStatusAction(null)
        handleCloseModal()
        closeModalBerkas()
    }

    const setStatus = (status, newStatus, statusAction) => {
        if (datacr && statusAction) {
            if (statusAction === 'statusSeleksi3') {
                Dispatch(updateStatusSeleksiInteraksiKelompok(status, datacr && datacr._id, Swal, datacrIndex, seleksiInteraksiKelompok, reset, handleModalPropsClose, setModalProps, getMyName(datacr && datacr.dataPribadi), newStatus))
            } else if (statusAction === 'statusSeleksiChapter') {
                Dispatch(updateStatusSeleksiInteraksiKelompokChapter(status, datacr && datacr.statusSeleksiChapter ,datacr && datacr._id, Swal, datacrIndex, seleksiInteraksiKelompok, reset, handleModalPropsClose, setModalProps, getMyName(datacr && datacr.dataPribadi), newStatus))
            }
        }
    }

    const checkRanking = (pesertas, rank) => {
        let result = true
        if (pesertas && rank) {
            if (pesertas.filter(a=> a.rankingSeleksi3 == rank)[0]) {
                return false
            }
        }
        return result
    }

    const checkRankingChapter = (pesertas, rank) => {
        let result = true
        if (pesertas && rank) {
            if (pesertas.filter(a=> a.rankingSeleksiChapter == rank)[0]) {
                return false
            }
        }
        return result
    }

    const setRankingChapter = (ranking) => {
        let options = JSON.parse(localStorage.getItem('options'))
        if (datacr) {
            Dispatch(createRankingSeleksiChapter(ranking, datacr && datacr._id, Swal, datacrIndex, seleksiInteraksiKelompok, reset, handleModalPropsClose, setModalProps, getMyName(datacr && datacr.dataPribadi), capitalizeFirstLetter(getChapterName(chapter, datacr && datacr.chapterId)),options && options.program))
        }
    }

    const getRanking = (pesertas) => {
        let result = []
        if (pesertas) {
            result = pesertas
        }
        return result
    }

    const setRanking = (ranking) => {
        if (datacr) {
            Dispatch(createRankingSeleksi3(ranking, datacr && datacr._id, Swal, datacrIndex, seleksiInteraksiKelompok, reset, handleModalPropsClose, setModalProps, getMyName(datacr && datacr.dataPribadi)))
        }
    }

    const getTotalNilaiInteraksiKelompok = (data) => {
        let result = 0
        let totalObserver = 0
        if (data) {
            if (data.observer) {
                totalObserver = data.observer.length
                data.observer.map(nilai=>{
                    if (
                        nilai.nilaiKemampuanBerkomunikasi ||
                        nilai.nilaiKemampuanMemutuskan ||
                        nilai.nilaiKemampuanBekerjasama ||
                        nilai.nilaiOrientasiPadaHasil
                    ) {
                        if (result) {
                            result = result + ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        } else {
                            result = ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        }
                    }
                })
            }
        }
        return result ? (result/totalObserver) : '-'
    }

    const getTerbobotNilaiInteraksiKelompok = (data, percent) => {
        let totalNilai = 0
        let result = 0
        let totalObserver = 0
        if (data) {
            if (data.observer) {
                let percentAsDecimal = (Number(percent) / 100);
                totalObserver = data.observer.length
                data.observer.map(nilai=>{
                    if (
                        nilai.nilaiKemampuanBerkomunikasi ||
                        nilai.nilaiKemampuanMemutuskan ||
                        nilai.nilaiKemampuanBekerjasama ||
                        nilai.nilaiOrientasiPadaHasil
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        } else {
                            totalNilai = ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalObserver)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const getTotalTerbobotSeleksi3 = (data) => {
        let result = 0
        if (data) {
            result = Number(Number(result) + Number(getTerbobotNilaiInteraksiKelompok(data, 25)))
        }
        return result
    }

    const getTerbobotNilaiPribadi = (data, percent) => {
        let totalNilai = 0
        let totalPewawancara = 0
        let result = 0
        if (data) {
            if (data.pewawancara) {
                let percentAsDecimal = (Number(percent) / 100);
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiKualitasPribadi ||
                        nilai.nilaiVisi ||
                        nilai.nilaiEmpatiSosial ||
                        nilai.nilaiNasional
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        } else {
                            totalNilai = ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalPewawancara)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const getTerbobotNilaiBahasaInggris = (data, percent) => {
        let totalNilai = 0
        let result = 0
        let totalPewawancara = 0
        if (data) {
            if (data.pewawancara) {
                let percentAsDecimal = (Number(percent) / 100);
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiOralProficiency ||
                        nilai.nilaiAttitude
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiOralProficiency) * 3) + (Number(nilai.nilaiAttitude) * 2.5))
                        } else {
                            totalNilai = ((Number(nilai.nilaiOralProficiency) * 3) + (Number(nilai.nilaiAttitude) * 2.5))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalPewawancara)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const getTotalTerbobotSeleksi2 = (data1, data2) => {
        let result = 0
        if (data1) {
            result = Number(Number(result) + Number(getTerbobotNilaiPribadi(data1, 25)))
        }
        if (data2) {
            result = Number(Number(result) + Number(getTerbobotNilaiBahasaInggris(data2, 15)))
        }
        return result
    }

    const TotalTerbobotSeleksi1 = (nilai, percent) => {
        let totalNilai = 0
        let result = 0
        if (nilai) {
            let percentAsDecimal = (Number(percent) / 100);
            totalNilai = Number(nilai)
            result = percentAsDecimal * totalNilai
        }
        return result
    }

    const getTotalAkumulatif = (seleksi1, { seleksiWawancaraPribadi ,seleksiWawancaraBahasaInggris }, seleksi3) => {
        let result = 0
        if (seleksi3) {
            if (seleksi1) {
                result = Number(Number(result) + Number(TotalTerbobotSeleksi1(seleksi1.esai, 20)) + Number(TotalTerbobotSeleksi1(seleksi1.mStatement, 15)))
            }
            if (seleksiWawancaraPribadi && seleksiWawancaraBahasaInggris) {
                result = Number(Number(result) + Number(getTotalTerbobotSeleksi2(seleksiWawancaraPribadi, seleksiWawancaraBahasaInggris)))
            }
            if (seleksi3) {
                result = Number(Number(result) + Number(getTotalTerbobotSeleksi3(seleksi3)))
            }
        }
        return result ? (result).toFixed(1) : '-'
    }

    const getTotalAkumulatifSort = (seleksi1, { seleksiWawancaraPribadi ,seleksiWawancaraBahasaInggris }, seleksi3, action) => {
        let result = 0
        if (seleksi3) {
            if (seleksi1) {
                result = Number(Number(result) + Number(TotalTerbobotSeleksi1(seleksi1.esai, 20)) + Number(TotalTerbobotSeleksi1(seleksi1.mStatement, 15)))
            }
            if (seleksiWawancaraPribadi && seleksiWawancaraBahasaInggris) {
                result = Number(Number(result) + Number(getTotalTerbobotSeleksi2(seleksiWawancaraPribadi, seleksiWawancaraBahasaInggris)))
            }
            if (seleksi3) {
                result = Number(Number(result) + Number(getTotalTerbobotSeleksi3(seleksi3)))
            }
        }
        return result ? result : action === 'desc' ? 0 : 9999999
    }

    const checkPenilaiFilter = (status, peserta) => {
        if (status === 'belum dinilai') {
            if (
                (getTotalNilaiInteraksiKelompok(peserta && peserta.seleksiInteraksiKelompok) === '-') &&
                ((peserta && peserta.rankingSeleksi3) === null) &&
                ((peserta && peserta.statusSeleksi3) === null) &&
                ((peserta && peserta.rankingSeleksiChapter) === null) &&
                ((peserta && peserta.statusSeleksiChapter) === null)
            ) {
                return peserta
            }
        } else if (status === 'nilai belum lengkap') {
            if (
                !(
                    (getTotalNilaiInteraksiKelompok(peserta && peserta.seleksiInteraksiKelompok) === '-') &&
                    ((peserta && peserta.rankingSeleksi3) === null) &&
                    ((peserta && peserta.statusSeleksi3) === null) &&
                    ((peserta && peserta.rankingSeleksiChapter) === null) &&
                    ((peserta && peserta.statusSeleksiChapter) === null)
                ) &&
                ((getTotalNilaiInteraksiKelompok(peserta && peserta.seleksiInteraksiKelompok) === '-') ||
                ((peserta && peserta.rankingSeleksi3) === null) ||
                ((peserta && peserta.statusSeleksi3) === null) ||
                ((peserta && peserta.rankingSeleksiChapter) === null) ||
                ((peserta && peserta.statusSeleksiChapter) === null))
            ) {
                return peserta
            }
        } else if (status === 'nilai sudah lengkap') {
            if (
                (getTotalNilaiInteraksiKelompok(peserta && peserta.seleksiInteraksiKelompok) !== '-') &&
                (peserta && peserta.rankingSeleksi3) &&
                (peserta && peserta.statusSeleksi3) &&
                (peserta && peserta.rankingSeleksiChapter) &&
                (peserta && peserta.statusSeleksiChapter)
            ) {
                return peserta
            }
        }
    }

    const classStatus = (peserta, optionState) => {
        if (peserta && optionState) {
            if (
                !( peserta.suratPernyataanSekolah && peserta.suratPernyataanSekolah.file) ||
                !( peserta.suratIzinOrangTua && peserta.suratIzinOrangTua.file) ||
                !( peserta.suratIzinOrangTua && peserta.suratIzinOrangTua.file) ||
                !( peserta.suratRekomendasiKeluarga && peserta.suratRekomendasiKeluarga.file) ||
                !( peserta.suratRekomendasiSekolah && peserta.suratRekomendasiSekolah.file) ||
                !( peserta.suratRekomendasiTemanDekat && peserta.suratRekomendasiTemanDekat.file) ||
                !( peserta.raport && peserta.raport.file) ||
                !( peserta.aktaKelahiran && peserta.aktaKelahiran.file) ||
                !( peserta.skhunIjazah && peserta.skhunIjazah.file) ||
                !( peserta.sertifikatDll && peserta.sertifikatDll.file)
            ) {
                return 'belum-lengkap'
            }
            if (peserta.otoritasiChapter === true || peserta.otoritasiChapter === false) {
                return 'belum-lengkap-adm'
            }
        }
        return ''
    }

    const exportFile = () => {
        // setIsExport(true)
        Dispatch(exportExcel(
            `/peserta/interaksi-kelompok/export`,
            `Interaksi Kelompok ${options?.program}${(dataFilter?.chapter || profile?.chapterId) ? `_${getChapterName(chapter, (dataFilter?.chapter || profile?.chapterId))}` : ''}`,
            Swal,
            {
                program: options?.program,
                chapter_id: dataFilter?.chapter,
                status_seleksi_interaksi_kelompok: dataFilter?.status === 'true-seleksi3' ? true : dataFilter?.status === 'false-seleksi3' ? false : null,
                status_seleksi_chapter: dataFilter?.status === 'true-chapter' ? true : dataFilter?.status === 'false-chapter' ? false : null,
                nilai: dataFilter?.nilai,
                search_id: dataFilter?.search,
                ranking_chapter: sort.key === 'ranking_chapter' ? sort.by : null,
                ranking_seleksi: sort.key === 'ranking_seleksi' ? sort.by : null,
                nilai_akumulatif: sort.key === 'nilai_akumulatif' ? sort.by : null,
            }
        ))
    }

    const handleEmail = event => {
        Dispatch(sendEmail(
            `/peserta`,
            {
                program: options?.program,
                chapter_id: dataFilter?.chapter,
                status_seleksi_interaksi_kelompok: dataFilter?.status === 'true-seleksi3' ? true : dataFilter?.status === 'false-seleksi3' ? false : null,
                status_seleksi_chapter: dataFilter?.status === 'true-chapter' ? true : dataFilter?.status === 'false-chapter' ? false : null,
                nilai: dataFilter?.nilai,
                search_id: dataFilter?.search,
            },
            Swal
        ))
    };

    const handleImport = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const file = event.target.files[0];
        const sendData = new FormData()
        sendData.append('file', file)
        hiddenFileInput.current.value = '';
        Dispatch(importExcel(
            `/peserta/interaksi-kelompok`,
            sendData,
            Swal,
            setRefetch,
            refetch
        ))
    };

    useEffect(() => {
        if (isExport) {
            Swal.showLoading()
            exportTo()
        }
    }, [isExport])

    const exportTo = () => {
        var wb = XLSX.utils.table_to_book(document.getElementById('exporttable-interaksi-kelompok'));
        XLSX.writeFile(wb, 'export-tabulasi-interaksi-kelompok.xlsx');
        setIsExport(false)
        Swal.close()
    }

    const downloadAndZip = urls => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            text: "Proses download berkas sedang berlangsung, silahkan tunggu.",
            onOpen: () => {
                Swal.showLoading()
            }
        })
        var zip = new JSZip();
        var urls = [
            datacr && datacr.suratPernyataanSekolah && datacr.suratPernyataanSekolah.file,
            datacr && datacr.suratIzinOrangTua && datacr.suratIzinOrangTua.file,
            datacr && datacr.suratRekomendasiKeluarga && datacr.suratRekomendasiKeluarga.file,
            datacr && datacr.suratRekomendasiSekolah && datacr.suratRekomendasiSekolah.file,
            datacr && datacr.suratRekomendasiTemanDekat && datacr.suratRekomendasiTemanDekat.file,
            datacr && datacr.raport && datacr.raport.file,
            datacr && datacr.aktaKelahiran && datacr.aktaKelahiran.file,
            datacr && datacr.skhunIjazah && datacr.skhunIjazah.file,
            datacr && datacr.sertifikatDll && datacr.sertifikatDll.file,
        ];
        var urlsName = [
            'Surat Pernyataan Sekolah.pdf',
            'Surat Pernyataan Orang Tua.pdf',
            'Surat Rekomendasi Lingkungan Rumah.pdf',
            'Rekomendasi Lingkungan Sekolah.pdf',
            'Rekomendasi Teman Dekat.pdf',
            'Raport.pdf',
            'Akta Kelahiran.pdf',
            'SKHUN & Ijazah SMP.pdf',
            'Sertifikat dan lain-lain.pdf',
        ];
        var count = 0;
        var zipFilename = `${getMyName(datacr && datacr.dataPribadi)}.zip`;
        urls.forEach(function (url, i) {
            if (url) {
                var filename = urlsName[i];
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if (err) throw err;
                    zip.file(filename, data, {
                        binary: true,
                        createFolders: true
                    });
                    count++;
                    if (count == urls.length) {
                        zip.generateAsync({
                                type: "blob"
                            })
                            .then(function (blob) {
                                Swal.close()
                                saveAs(blob, zipFilename);
                            });
                    }
                });
            } else {
                count++;
                if (count == urls.length) {
                    zip.generateAsync({
                            type: "blob"
                        })
                        .then(function (blob) {
                            Swal.close()
                            saveAs(blob, zipFilename);
                        });
                }
            }
        });
    }

    const setAlamatDisplayText = (count, text) => {
        if (text && count) {
            let txt = text.slice(0,count)
            return `${txt}...`
        } else {
            return text
        }
    }

    return (
        <div className="data-container-wrapper" style={{ paddingRight: 20 }}>
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Tabulasi Interaksi Kelompok
                </span>
            </div>
            <Breadcrumb
                links={[
                    {
                        label: 'Tabulasi Interaksi Kelompok',
                        link: false
                    }
                ]}
            />
            <div className="daftar-component-wrapper" >
                <div className="daftar-component-header" >
                    <span className="daftar-header-font" >
                        Daftar Kandidat
                    </span>
                </div>
                <div className="daftar-table-wrapper" >
                    <div className="daftar-filter-table" style={{ justifyContent: 'space-between' }} >
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <div className='flex'>
                                <div className="search-filter wrapping-filter-002">
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        options={(seleksiInteraksiKelompok?.search || [])}
                                        noOptionsText={'Tidak Ditemukan'}
                                        getOptionLabel={(option) => `${getMyName(option && option.dataPribadi)}`}
                                        freeSolo
                                        fullWidth
                                        id="free-solo-2-demo"
                                        onChange={(_, val) => [handleChangeFilter('search', val && val._id), setPage(1)]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className='search-nilai-cus'
                                                placeholder='Cari'
                                                variant="outlined"
                                                size='small'
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <i class="fas fa-search search-custom-filter" style={{ minWidth: 47, paddingRight: 0 }}></i>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                        renderOption={(option) => (
                                            <div>
                                                <div>{getMyName(option && option.dataPribadi)}</div>
                                                <div className='sch-auto'>{getValue(option && option.riwayatPendidikan, 'namaSMA')}</div>
                                            </div>
                                        )}
                                    />
                                </div>
                                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-004 filter-004-display`} style={{ textTransform: 'capitalize' }} >
                                    <InputLabel className="filter-table-peserta-placeholder" shrink={false}
                                    >
                                        {
                                            profile && profile.role === 'admin chapter' ?
                                                getChapterName(chapter, profile && profile.chapterId)
                                                :
                                                !dataFilter.chapter && 'Filter Chapter'
                                        }
                                    </InputLabel>
                                    <Select
                                        native={false}
                                        value={dataFilter.chapter}
                                        onChange={(e) => [handleChangeFilter('chapter', e.target.value), setPage(1)]}
                                        className={classes.formControlNormaly}
                                        style={{ marginLeft: 0, outline: 'none' }}
                                        IconComponent={FilterListIcon}
                                        inputProps={{
                                            style: {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        disabled={profile && profile.role === 'admin chapter' ? true : false}
                                    >
                                        <MenuItem className={!dataFilter.chapter ? "value-selectable-active" : "value-selectable"} value=""> Seluruh Chapter</MenuItem>
                                        {
                                            chapter?.sort((a,b) =>  a.chapter < b.chapter ? -1 : a.chapter > b.chapter ? 1 : 0)
                                            .map((chapter, i) => (
                                                <MenuItem key={i} className={dataFilter.chapter === chapter._id ? "value-selectable-active" : "value-selectable"} value={chapter._id} style={{ textTransform: 'capitalize' }}> {chapter.chapter} </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-005 filter-005-display`} >
                                    <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                                        {
                                            dataFilter.status === null && 'Filter Status'
                                        }
                                    </InputLabel>
                                    <Select
                                        native={false}
                                        value={dataFilter.status}
                                        onChange={(e) => [handleChangeFilter('status', e.target.value), setPage(1)]}
                                        className={classes.formControlNormaly}
                                        style={{ marginLeft: 0, outline: 'none' }}
                                        IconComponent={FilterListIcon}
                                        inputProps={{
                                            style: {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem className={!dataFilter.status ? "value-selectable-active" : "value-selectable"} value={null}> Seluruh Status</MenuItem>
                                        <MenuItem className={dataFilter.status === true ? "value-selectable-active" : "value-selectable"} value={'true-seleksi3'}>Lulus (Interaksi Kelompok)</MenuItem>
                                        <MenuItem className={dataFilter.status === false ? "value-selectable-active" : "value-selectable"} value={'false-seleksi3'}>Tidak Lulus (Interaksi Kelompok)</MenuItem>
                                        <MenuItem className={dataFilter.status === false ? "value-selectable-active" : "value-selectable"} value={'true-chapter'}>Lulus (Chapter)</MenuItem>
                                        <MenuItem className={dataFilter.status === false ? "value-selectable-active" : "value-selectable"} value={'false-chapter'}>Tidak Lulus (Chapter)</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-006 filter-006-display`} >
                                    <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                                        {
                                            dataFilter.nilai === '' && 'Filter Nilai'
                                        }
                                    </InputLabel>
                                    <Select
                                        native={false}
                                        value={dataFilter.nilai}
                                        onChange={(e) => [handleChangeFilter('nilai', e.target.value), setPage(1)]}
                                        className={classes.formControlNormaly}
                                        style={{ marginLeft: 0, outline: 'none' }}
                                        IconComponent={FilterListIcon}
                                        inputProps={{
                                            style: {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem className={dataFilter.nilai === '' ? "value-selectable-active" : "value-selectable"} value=''> Tampilkan Seluruh Data</MenuItem>
                                        <MenuItem className={dataFilter.nilai === 'belum dinilai' ? "value-selectable-active" : "value-selectable"} value='belum dinilai'>Belum Dinilai</MenuItem>
                                        <MenuItem className={dataFilter.nilai === 'belum lengkap' ? "value-selectable-active" : "value-selectable"} value='belum lengkap'>Nilai Belum Lengkap</MenuItem>
                                        <MenuItem className={dataFilter.nilai === 'sudah lengkap' ? "value-selectable-active" : "value-selectable"} value='sudah lengkap'>Nilai Sudah Lengkap</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="wrapper-filter-column" >
                                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-005 filter-005-display-column`} >
                                    <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                                        {
                                            dataFilter.status === null && 'Filter Status'
                                        }
                                    </InputLabel>
                                    <Select
                                        native={false}
                                        value={dataFilter.status}
                                        onChange={(e) => [handleChangeFilter('status', e.target.value), setPage(1)]}
                                        className={classes.formControlNormaly}
                                        style={{ marginLeft: 0, outline: 'none' }}
                                        IconComponent={FilterListIcon}
                                        inputProps={{
                                            style: {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem className={!dataFilter.status ? "value-selectable-active" : "value-selectable"} value={null}> Seluruh Status</MenuItem>
                                        <MenuItem className={dataFilter.status === true ? "value-selectable-active" : "value-selectable"} value={true}>Lulus</MenuItem>
                                        <MenuItem className={dataFilter.status === false ? "value-selectable-active" : "value-selectable"} value={false}>Tidak Lulus</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-006 filter-006-display-column`} >
                                    <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                                        {
                                            dataFilter.nilai === '' && 'Filter Nilai'
                                        }
                                    </InputLabel>
                                    <Select
                                        native={false}
                                        value={dataFilter.nilai}
                                        onChange={(e) => [handleChangeFilter('nilai', e.target.value), setPage(1)]}
                                        className={classes.formControlNormaly}
                                        style={{ marginLeft: 0, outline: 'none' }}
                                        IconComponent={FilterListIcon}
                                        inputProps={{
                                            style: {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                },
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem className={dataFilter.nilai === '' ? "value-selectable-active" : "value-selectable"} value=''> Tampilkan Seluruh Data</MenuItem>
                                        <MenuItem className={dataFilter.nilai === 'belum dinilai' ? "value-selectable-active" : "value-selectable"} value='belum dinilai'>Belum Dinilai</MenuItem>
                                        <MenuItem className={dataFilter.nilai === 'belum lengkap' ? "value-selectable-active" : "value-selectable"} value='belum lengkap'>Nilai Belum Lengkap</MenuItem>
                                        <MenuItem className={dataFilter.nilai === 'sudah lengkap' ? "value-selectable-active" : "value-selectable"} value='sudah lengkap'>Nilai Sudah Lengkap</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ rowGap: 5, columnGap: 5, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }} >
                            <Fragment>
                                <Button variant='contained' className='btn-ekspor' onClick={handleImport}>Import</Button>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                    accept=".xlsx, .xls"
                                />
                            </Fragment>
                            <Button variant='contained' className='btn-ekspor' onClick={exportFile}>Export</Button>
                            <Button variant='contained' className='btn-send-email' onClick={handleEmail}>Send Email</Button>
                        </div>
                    </div>
                    <div className="daftar-filter-table" style={{ marginTop: '1vw', flexWrap: 'wrap', rowGap: 10, columnGap: 10 }} >
                        <div style={{ display: 'flex' }} >
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', backgroundColor: '#3c98dc', padding: '5px 15px 5px 15px', borderRadius: '5px 0px 0px 5px', color: "white" }} >
                                <span>Perempuan : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { seleksiInteraksiKelompok?.count?.female || 0 }
                                </span>
                            </div>
                            <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', borderRadius: '0px 0px 0px 0px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', backgroundColor: '#3c98dc', color: "white" }} >
                                <span>Laki-laki : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { seleksiInteraksiKelompok?.count?.male || 0 }
                                </span>
                            </div>
                            <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 5px 5px 0px', backgroundColor: '#3c98dc', color: 'white' }} >
                                <span>Total Peserta : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { (seleksiInteraksiKelompok?.count?.male || 0) + (seleksiInteraksiKelompok?.count?.female || 0) }
                                </span>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', padding: '5px 15px 5px 15px', borderRadius: '5px 0px 0px 5px', color: "white", backgroundColor: '#3c98dc' }} >
                                <span>SMK : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { seleksiInteraksiKelompok?.count?.smk || 0 }
                                </span>
                            </div>
                            <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px',  alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 0px 0px 0px', color: "white", backgroundColor: '#3c98dc' }} >
                                <span>SMA : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { seleksiInteraksiKelompok?.count?.sma || 0 }
                                </span>
                            </div>
                            <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 0px 0px 0px', color: "white", backgroundColor: '#3c98dc' }} >
                                <span>MA : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { seleksiInteraksiKelompok?.count?.ma || 0 }
                                </span>
                            </div>
                            <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 5px 5px 0px', color: "white", backgroundColor: '#3c98dc' }} >
                                <span>Pesantren : </span>
                                <span style={{ marginLeft: 3, fontSize: 15 }} >
                                    { seleksiInteraksiKelompok?.count?.pesantren || 0 }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="daftar-filter-table" style={{ marginTop: '1vw', flexWrap: 'wrap', rowGap: 10, columnGap: 10 }} >
                        <div style={{ display: 'flex' }}>
                            {
                                seleksiInteraksiKelompok?.daftarSekolah?.map((x, index)=>(
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', padding: '5px 15px 5px 15px', borderRadius: index === 0 ? '5px 0px 0px 5px' : seleksiInteraksiKelompok?.daftarSekolah?.length -1 === index ? '0px 5px 5px 0px' : '0px 0px 0px 0px', color: "white", backgroundColor: '#3c98dc', borderLeft: index !== 0 && '2px solid #DDDDDD' }} >
                                        <span>{`${x?._id?.jenis} - ${x?._id?.sekolah}`} : </span>
                                        <span style={{ marginLeft: 3, fontSize: 15 }} >
                                            { x?.total || 0 }
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='txt-scroll'>
                        Geser ke samping untuk melihat seluruh isi tabulasi
                    </div>
                    <div className="daftar-table cus-table" style={{ maxWidth: '100vw', position: 'relative' }}>
                        <TableContainer className='table-con' component={Paper} style={{ maxHeight: 80*5+105, border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 2 }}>
                            <Table aria-label="simple table" className='table-fix' id='exporttable-interaksi-kelompok' >
                                <TableHead className='head-table-seleksi' style={{ position: 'sticky', zIndex: '200', top: '0', background: '#FAFAFA' }}>
                                    <TableRow>
                                        <TableCell align="center" className='col-fix head-table-seleksi' style={{ left: 0, minWidth: 67, height: 95 }} rowSpan={2}>
                                            NO
                                        </TableCell>
                                        <TableCell align="center" className='col-fix head-table-seleksi' style={{ minWidth: 103, left: 67 }} rowSpan={2}>NO. PESERTA</TableCell>
                                        <TableCell align="center" className='col-fix head-table-seleksi' style={{ minWidth: 190, left: 170 }} rowSpan={2}>NAMA</TableCell>
                                        <TableCell align="center" className='' rowSpan={2} style={{ minWidth: 212 }}>SEKOLAH</TableCell>
                                        <TableCell align="center" className='' rowSpan={2} style={{ minWidth: 167 }}>EMAIL</TableCell>
                                        <TableCell align="center" className='' rowSpan={2} style={{ minWidth: 139 }}>NOMOR HP</TableCell>
                                        <TableCell align="center" className='' rowSpan={2} style={{ minWidth: 109 }}>TANGGAL LAHIR</TableCell>
                                        <TableCell align="center" className='' rowSpan={2} style={{ minWidth: 222 }}>ALAMAT</TableCell>
                                        <TableCell align="center" className='p4' colSpan={5}>INTERAKSI KELOMPOK</TableCell>
                                        <TableCell align="center" className='p4' rowSpan={2} style={{ minWidth: 148, cursor: 'pointer' }}
                                            onClick={() => {
                                                if (seleksiInteraksiKelompok && sort.key === 'ranking_chapter') {
                                                    if (sort.by === 1) {
                                                        return setSort({ key: null, by: null })
                                                    } else {
                                                        return setSort({ key: 'ranking_chapter', by: 1 })
                                                    }
                                                }
                                                return setSort({ key: 'ranking_chapter', by: -1 })
                                            }}
                                        >
                                            <div className='ai-center flex'>
                                                {
                                                    seleksiInteraksiKelompok && sort.key === 'ranking_chapter' ?
                                                        <div>
                                                            {
                                                                sort.by === 1 ?
                                                                <ArrowUpwardIcon className='act-sort' /> :
                                                                <ArrowDownwardIcon className='act-sort' />
                                                            }
                                                        </div> :
                                                        <ArrowUpwardIcon className='non-sort' />
                                                }
                                                <div>
                                                    RANK SELEKSI<br/>(CHAPTER)
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className='  p4' rowSpan={2} style={{ minWidth: 163 }}>LULUS/TIDAK LULUS<br/>(CHAPTER)</TableCell>
                                        <TableCell align="center" className=' ' rowSpan={2} style={{ minWidth: 250, maxWidth: 500 }}>KETERANGAN</TableCell>
                                        {
                                            !isExport &&
                                            <TableCell align="center" className='table-border-left col-fix head-table-seleksi' style={{ minWidth: 173, right: 0 }} rowSpan={2}>AKSI</TableCell>
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className='  p4' style={{ minWidth: 66 }}>NILAI</TableCell>
                                        <TableCell align="center" className='  p4' style={{ minWidth: 168 }}>NILAI TERBOBOT (25%)</TableCell>
                                        <TableCell align="center" className='  p4' style={{ minWidth: 213, cursor: 'pointer' }}
                                            onClick={() => {
                                                if (seleksiInteraksiKelompok && sort.key === 'ranking_seleksi') {
                                                    if (sort.by === 1) {
                                                        return setSort({ key: null, by: null })
                                                    } else {
                                                        return setSort({ key: 'ranking_seleksi', by: 1 })
                                                    }
                                                }
                                                return setSort({ key: 'ranking_seleksi', by: -1 })
                                            }}
                                        >
                                            <div className='ai-center flex'>
                                                {
                                                    seleksiInteraksiKelompok && sort.key === 'ranking_seleksi' ?
                                                        <div>
                                                            {
                                                                sort.by === 1 ?
                                                                <ArrowUpwardIcon className='act-sort' /> :
                                                                <ArrowDownwardIcon className='act-sort' />
                                                            }
                                                        </div> :
                                                        <ArrowUpwardIcon className='non-sort' />
                                                }
                                                <div>
                                                    RANK SELEKSI<br/>(INTERAKSI KELOMPOK)
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className='  p4' style={{ minWidth: 304, cursor: 'pointer' }}
                                            onClick={() => {
                                                if (seleksiInteraksiKelompok && sort.key === 'nilai_akumulatif') {
                                                    if (sort.by === 1) {
                                                        return setSort({ key: null, by: null })
                                                    } else {
                                                        return setSort({ key: 'nilai_akumulatif', by: 1 })
                                                    }
                                                }
                                                return setSort({ key: 'nilai_akumulatif', by: -1 })
                                            }}
                                        >
                                            <div className='ai-center flex'>
                                                {
                                                    seleksiInteraksiKelompok && sort.key === 'nilai_akumulatif' ?
                                                        <div>
                                                            {
                                                                sort.by === 1 ?
                                                                <ArrowUpwardIcon className='act-sort' /> :
                                                                <ArrowDownwardIcon className='act-sort' />
                                                            }
                                                        </div> :
                                                        <ArrowUpwardIcon className='non-sort' />
                                                }
                                                <div>
                                                    NILAI AKUMULATIF TERBOBOT<br />(Seleksi 1 + Seleksi 2 +  Seleksi 3)
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className='  p4' style={{ minWidth: 182 }}>LULUS/TIDAK LULUS (INTERAKSI KELOMPOK)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        seleksiInteraksiKelompok?.data?.map((peserta, i) => (
                                        <TableRow className='table-row' key={i} >
                                            <TableCell className={`${classStatus(peserta, optionState)} col-fix`} style={{ left: 0, minWidth: 67, height: 80 }}>
                                                {((page - 1) * limit) + i + 1}
                                            </TableCell>
                                            <TableCell align="left" className={`${classStatus(peserta,optionState)} col-fix`} style={{ minWidth: 103, left: 67 }}>
                                                {noPesertaGenerate(peserta)}
                                            </TableCell>
                                            <TableCell align="left" className={`${classStatus(peserta, optionState)} col-fix`} style={{ minWidth: 190, left: 170 }}>
                                                {getMyName(peserta && peserta.dataPribadi)}
                                            </TableCell>
                                            <TableCell align="center" className='' >
                                                {getValue(peserta?.riwayatPendidikan, 'namaSMA')}
                                            </TableCell>
                                            <TableCell align="center" className=''>{getValue(peserta?.dataPribadi, 'email')}</TableCell>
                                            <TableCell align="center" className=''>{getValue(peserta?.dataPribadi, 'telepon')}</TableCell>
                                            <TableCell align="center" className='' style={{ minWidth: 150 }} >{getBorn(peserta?.dataPribadi)}</TableCell>
                                            <TableCell align="center" className='' style={{ minWidth: 350 }}>{
                                                setAlamatDisplayText(90,getAddressPlace(peserta?.dataPribadi))
                                            }</TableCell>
                                            <TableCell align="center" className=''>{
                                                // getTotalNilaiInteraksiKelompok(peserta?.seleksiInteraksiKelompok)
                                                getValue(peserta?.seleksiInteraksiKelompok, 'nilai')
                                            }</TableCell>
                                            <TableCell align="center" className=''>{
                                                // getTerbobotNilaiInteraksiKelompok(peserta?.seleksiInteraksiKelompok, 25)
                                                getValue(peserta?.seleksiInteraksiKelompok, 'nilaiTerbobot')
                                            }</TableCell>
                                            <TableCell align="center" className=''>
                                                { getValue(peserta, 'rankingSeleksi3') }
                                                {/* {
                                                    peserta &&
                                                    peserta.rankingSeleksi3 ?
                                                        <div className='div-rank' >
                                                            <span>{ peserta ? peserta.rankingSeleksi3 ? peserta.rankingSeleksi3 : '-' : '-' }</span>
                                                            <Button className='btn-pilihan' onClick={(event) => handleClick1(event, peserta)}>
                                                                <i class="fas fa-caret-down arr-cus"></i>
                                                            </Button>
                                                        </div> :
                                                        <Button variant='outlined' color='primary' className='btn-outlined btn-out-blue' onClick={(event) => handleClickInputRank(event, peserta)} >Input Rank</Button>
                                                } */}
                                            </TableCell>
                                            <TableCell align="center" className=''>
                                                { getValue(peserta?.seleksiInteraksiKelompok, 'jumlahNilaiAkumulatifTerbobot') }
                                                {/* {getTotalAkumulatif(peserta && peserta.seleksiBerkas, {seleksiWawancaraPribadi : peserta && peserta.seleksiWawancaraPribadi, seleksiWawancaraBahasaInggris : peserta && peserta.seleksiWawancaraBahasaInggris}, peserta && peserta.seleksiInteraksiKelompok)} */}
                                            </TableCell>
                                            <TableCell align="center"
                                                style={{
                                                    maxWidth: 200,
                                                    minWidth: 200,
                                                    backgroundColor: peserta?.statusSeleksi3 === true ? "#c6efce" : peserta?.statusSeleksi3 === false && "#f2c5ce",
                                                    color: peserta?.statusSeleksi3 === true ? "#53824f" : peserta?.statusSeleksi3 === false && "#843840"
                                                }}
                                            >
                                                {peserta?.statusSeleksi3 === true ? 'Lulus' : peserta?.statusSeleksi3 === false && 'Tidak Lulus'}
                                                {/* {
                                                    peserta &&
                                                    (peserta.statusSeleksi3 === true || peserta.statusSeleksi3 === false) ?
                                                        <div className='div-rank'>
                                                            <span>{peserta.statusSeleksi3 === true ? 'L' : peserta.statusSeleksi3 === false && 'TL'}</span>
                                                            <Button className='btn-pilihan' onClick={(event) => handleClick(event, peserta, 'statusSeleksi3')}>
                                                                <i class="fas fa-caret-down arr-cus"></i>
                                                            </Button>
                                                        </div> :
                                                        <Button variant='outlined' color='primary' onClick={(event) => handleClickInputStatus(event, peserta, 'statusSeleksi3')} className='btn-outlined btn-out-blue'>Input Status</Button>
                                                } */}
                                            </TableCell>
                                            <TableCell align="center" className=''>
                                                { getValue(peserta, 'rankingSeleksiChapter') }
                                                {/* {
                                                    peserta &&
                                                    peserta.rankingSeleksiChapter ?
                                                        <div className='div-rank' >
                                                            <span>{ peserta ? peserta.rankingSeleksiChapter ? peserta.rankingSeleksiChapter : '-' : '-' }</span>
                                                            <Button className='btn-pilihan' onClick={(event) => handleClick1Chapter(event, peserta)}>
                                                                <i class="fas fa-caret-down arr-cus"></i>
                                                            </Button>
                                                        </div> :
                                                        <Button variant='outlined' color='primary' className='btn-outlined btn-out-blue' onClick={(event) => handleClickInputRankChapter(event, peserta)} >Input Rank</Button>
                                                } */}
                                            </TableCell>
                                            <TableCell align="center"
                                                style={{
                                                    maxWidth: 200,
                                                    minWidth: 200,
                                                    backgroundColor: peserta?.statusSeleksiChapter === true ? "#c6efce" : peserta?.statusSeleksiChapter === false && "#f2c5ce",
                                                    color: peserta?.statusSeleksiChapter === true ? "#53824f" : peserta?.statusSeleksiChapter === false && "#843840"
                                                }}
                                            >
                                                {peserta?.statusSeleksiChapter === true ? 'Lulus' : peserta?.statusSeleksiChapter === false && 'Tidak Lulus'}
                                                {/* {
                                                    peserta &&
                                                    (peserta.statusSeleksiChapter === true || peserta.statusSeleksiChapter === false) ?
                                                        <div className='div-rank'>
                                                            <span>{peserta.statusSeleksiChapter === true ? 'L' : peserta.statusSeleksiChapter === false && 'TL'}</span>
                                                            <Button className='btn-pilihan' onClick={(event) => handleClick(event, peserta, 'statusSeleksiChapter')}>
                                                                <i class="fas fa-caret-down arr-cus"></i>
                                                            </Button>
                                                        </div> :
                                                        <Button variant='outlined' color='primary' onClick={(event) => handleClickInputStatus(event, peserta, 'statusSeleksiChapter')} className='btn-outlined btn-out-blue'>Input Status</Button>
                                                } */}
                                            </TableCell>
                                            <TableCell align="right" className=''
                                                style={{
                                                    maxWidth: 400,
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                { getValue(peserta, 'komentarSeleksiChapter') }
                                                {/* {
                                                    peserta &&
                                                    peserta.komentarSeleksiChapter ?
                                                        <div className='div-rank'
                                                            style={{
                                                                justifyContent: 'flex-end',
                                                                wordWrap: 'break-word',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: 'center',
                                                                    width: '88%',
                                                                    wordWrap: 'break-word',
                                                                    paddingRight: 10
                                                                }}
                                                            >{peserta.komentarSeleksiChapter}</div>
                                                            <Button className='btn-pilihan'
                                                                style={{
                                                                    minWidth: 30,
                                                                    minHeight: 30,
                                                                    marginLeft: 0
                                                                }}
                                                                onClick={(event) => handleClickKomentarOption(event, peserta)}
                                                            >
                                                                <i class="fas fa-caret-down arr-cus"></i>
                                                            </Button>
                                                        </div> :
                                                        <Button variant='outlined' color='primary' className='btn-outlined btn-out-blue'
                                                            onClick={_=>handleOpenModal(peserta)}
                                                        >Input Keterangan</Button>
                                                } */}
                                            </TableCell>
                                            {
                                                !isExport &&
                                                <TableCell align="center" className='table-border-left col-fix' style={{ minWidth: 173, right: 0 }}>
                                                    <div className='div-rank'>
                                                        <Button color='primary' variant='outlined' className='btn-outlined w-aksi p4' style={{ width: 100 }} onClick={(event) => handleClick3(event, peserta)}>Pilihan <i class="fas fa-caret-down ml-5"></i></Button>
                                                    </div>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                    <Menu
                                        className='menu-table'
                                        anchorEl={KomentarOption}
                                        keepMounted
                                        open={Boolean(KomentarOption)}
                                        onClose={handleCloseKomentarOption}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                    >
                                        <MenuItem className='menu-item-table'
                                            onClick={e=>handleOpenModal()}
                                        >Ubah Komentar</MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table'
                                            onClick={e=> setKeteranganDelete(null) }
                                        >Hapus</MenuItem>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorEl4}
                                        keepMounted
                                        open={Boolean(anchorEl4)}
                                        onClose={handleClose4}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table'
                                            onClick={e=> setOpenModalBerkas(true)}
                                        >Lihat Berkas</MenuItem>
                                        <Divider />
                                        <Link
                                            to={{ pathname:`/profil/${datacr && datacr._id}`}}
                                            target="_BLANK"
                                            style={{
                                                textDecoration: 'none',
                                                marginLeft: 'auto',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <MenuItem className='menu-item-table'>Lihat Profil</MenuItem>
                                        </Link>
                                        <Divider />
                                        <MenuItem className='menu-item-table' onClick={() => downloadAndZip()}>Download Seluruh Berkas</MenuItem>
                                        <Divider />
                                        <Link
                                            to={{ pathname: `/download-profile/${datacr && datacr._id}` }}
                                            target="_BLANK"
                                            style={{
                                                textDecoration: 'none',
                                                marginLeft: 'auto',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <MenuItem className='menu-item-table'>Download Profil</MenuItem>
                                        </Link>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorEl2}
                                        keepMounted
                                        open={Boolean(anchorEl2)}
                                        onClose={handleClose2}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table' onClick={() => History.push(`/seleksi-kelompok/detail-nilai/${datacr && datacr._id}`)}>Lihat Detail Nilai</MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table' onClick={() => History.push(`/seleksi-kelompok/ubah-nilai`,{ id: datacr && datacr._id })}>Ubah Nilai</MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table'
                                            onClick={_=> setNilaiDelete()}
                                        >Hapus</MenuItem>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table' onClick={(event) => handleClickUbahStatus(event)}>Ubas Status</MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table'
                                            onClick={_=> handleModalPropsStatus(null)}
                                        >Hapus</MenuItem>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorEl1}
                                        keepMounted
                                        open={Boolean(anchorEl1)}
                                        onClose={handleClose1}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table'
                                            onClick={event=>handleClickInputRank(event)}
                                        >
                                            Ubah Rank
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table'
                                            onClick={_=> handleModalPropsDeleteRankSeleksi3(null)}
                                        >Hapus Rank</MenuItem>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorEl1Chapter}
                                        keepMounted
                                        open={Boolean(anchorEl1Chapter)}
                                        onClose={handleClose1Chapter}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table'
                                            onClick={event=>handleClickInputRankChapter(event)}
                                        >
                                            Ubah Rank
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table'
                                            onClick={_=> handleModalPropsDeleteRankSeleksiChapter(null)}
                                        >Hapus Rank</MenuItem>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorEl3}
                                        keepMounted
                                        open={Boolean(anchorEl3)}
                                        onClose={handleClose3}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table'
                                            onClick={e=> setOpenModalBerkas(true)}
                                        >Lihat Berkas</MenuItem>
                                        <Divider />
                                        <Link
                                            to={{ pathname:`/profil/${datacr && datacr._id}`}}
                                            target="_BLANK"
                                            style={{
                                                textDecoration: 'none',
                                                marginLeft: 'auto',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <MenuItem className='menu-item-table'>Lihat Profil</MenuItem>
                                        </Link>
                                        <Divider />
                                        <MenuItem className='menu-item-table' onClick={() => downloadAndZip()}>Download Seluruh Berkas</MenuItem>
                                        <Divider />
                                        <Link
                                            to={{ pathname: `/download-profile/${datacr && datacr._id}` }}
                                            target="_BLANK"
                                            style={{
                                                textDecoration: 'none',
                                                marginLeft: 'auto',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <MenuItem className='menu-item-table'>Download Profil</MenuItem>
                                        </Link>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorElUbahStatus}
                                        keepMounted
                                        open={Boolean(anchorElUbahStatus)}
                                        onClose={handleCloseUbahStatus}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                                    >
                                        {
                                            datacr &&
                                            datacr[(statusAction === 'statusSeleksi3') ? 'statusSeleksi3' : (statusAction === 'statusSeleksiChapter') && 'statusSeleksiChapter'] === false ?
                                            <MenuItem className='menu-item-table' onClick={_=> handleModalPropsStatus(true)} >Lulus</MenuItem>
                                            :
                                            <MenuItem className='menu-item-table' disabled >Lulus</MenuItem>
                                        }
                                        <Divider />
                                        {
                                            datacr &&
                                            datacr[(statusAction === 'statusSeleksi3') ? 'statusSeleksi3' : (statusAction === 'statusSeleksiChapter') && 'statusSeleksiChapter'] === true ?
                                            <MenuItem className='menu-item-table' onClick={_=> handleModalPropsStatus(false)}>Tidak Lulus</MenuItem>
                                            :
                                            <MenuItem className='menu-item-table' disabled>Tidak Lulus</MenuItem>
                                        }
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorElInputStatus}
                                        keepMounted
                                        open={Boolean(anchorElInputStatus)}
                                        onClose={handleCloseInputStatus}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    >
                                        <MenuItem className='menu-item-table'
                                            onClick={_=> handleModalPropsStatus(true, true)}
                                        >Lulus</MenuItem>
                                        <Divider />
                                        <MenuItem className='menu-item-table'
                                            onClick={_=> handleModalPropsStatus(false, true)}
                                        >Tidak Lulus</MenuItem>
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorElInputRank}
                                        keepMounted
                                        open={Boolean(anchorElInputRank)}
                                        onClose={handleCloseInputRank}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 240,
                                                width: 90
                                            },
                                        }}
                                    >
                                        {
                                            getRanking(seleksiInteraksiKelompok?.data)
                                            .map((a, i) => {
                                                return (
                                                    checkRanking(seleksiInteraksiKelompok?.data, i+1) &&
                                                    <div key={i}>
                                                        <MenuItem
                                                            onClick={e=>handleModalPropsRankSeleksi3(i+1)}
                                                            style={{ paddingRight: 0 }}
                                                            className='menu-item-table menu-center'
                                                        >
                                                            { i+1 }
                                                        </MenuItem>
                                                        {i !== 11 ? <Divider /> : null}
                                                    </div>
                                                )
                                            })
                                        }
                                    </Menu>
                                    <Menu
                                        className='menu-table'
                                        anchorEl={anchorElInputRankChapter}
                                        keepMounted
                                        open={Boolean(anchorElInputRankChapter)}
                                        onClose={handleCloseInputRankChapter}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 240,
                                                width: 90
                                            },
                                        }}
                                    >
                                        {
                                            getRanking(seleksiInteraksiKelompok?.data)
                                            .map((a, i) => {
                                                return (
                                                    checkRankingChapter(seleksiInteraksiKelompok?.data, i+1) &&
                                                    <div key={i}>
                                                        <MenuItem
                                                            onClick={e=>handleModalPropsRankSeleksiChapter(i+1)}
                                                            style={{ paddingRight: 0 }}
                                                            className='menu-item-table menu-center'
                                                        >
                                                            { i+1 }
                                                        </MenuItem>
                                                        {i !== 11 ? <Divider /> : null}
                                                    </div>
                                                )
                                            })
                                        }
                                    </Menu>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <PaginationCustom
                        handleChangePage={handleChangePage}
                        page={page}
                        setPage={setPage}
                        dataFilter={seleksiInteraksiKelompok?.length || 0}
                        limit={limit}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            </div>
            <ModalKeterangan
                open={openModal}
                handleClose={handleCloseModal}
                setKeteranganSave={setKeterangan}
                datacr={datacr}
            />
            <ModalBerkasPreview open={openModalBerkas} handleClose={closeModalBerkas} peserta={datacr} />
            <ModalCustom action={modalProps} />
        </div>
    )
}

export default SeleksiInteraksi