import React, { useState, useEffect } from 'react'
import Back from '../../../components/Button/Back'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { KembaliButton } from '../../../components/Button/Cus'
import { useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import SERVER from '../../../endpoint'
import { useSelector } from 'react-redux'


export default function DetailNilai() {
    const history = useHistory()
    const [peserta, setPeserta] = useState(null)
    const { id } = useParams()
    const chapter = useSelector(state => state.chapterState.chapter)

    useEffect(() => {
        Swal.showLoading()
        let token = localStorage.getItem('token')
        axios({
            method: 'get',
            url: `${SERVER}/peserta/seleksi-berkas/detail/${id}`,
            headers: { token }
        })
            .then(data => {
                setPeserta(data.data)
                Swal.close()
            })
            .catch(function (error) {
                console.log(error);
            })

    }, [])

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan + ' ' : ''}${namaTengah ? namaTengah + ' ' : ''}${namaBelakang ? namaBelakang + ' ' : ''}`
    }

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a => a._id === chapter)[0]
            return temp ? temp.code : '-'
        }
    }

    const noPesertaGenerate = (peserta) => {
        let options = JSON.parse(localStorage.getItem('options'))
        let program = '-'
        if (options) {
            if (options.program === 'KAKEHASHI') {
                program = 'MXT'
            } else {
                program = options.program
            }
        }
        if (peserta) {
            return `YBA/YP25-26/${getChapterCode(chapter, peserta && peserta.chapterId)}/${program}/${peserta.nomorUrut === '0000' ? '-' : peserta.nomorUrut}`
        }
        return ''
    }

    const getChapter = () => {
        if (chapter && peserta) {
            let temp = chapter.filter(a => a._id === peserta.chapterId)[0]
            return temp ? temp.chapter : '-'
        }
    }

    return (
        <>
            <Container className='con-beri-nilai'>
                <Back />
                <Paper className='paper-beri-nilai pt10'>
                    <Container style={{ width: 660, padding: 0 }}>
                        <div className='title-beri '>
                            DETAIL NILAI SELEKSI BERKAS
                        </div>
                        <div className='div-blue-beri ta-left'>
                            <div className='div-txt-beri'>
                                <div className='txt-left div-left'>Nama Peserta</div><span className='txt-left'>:</span><div className='div-right capitalize'>{getMyName(peserta && peserta.dataPribadi)}</div>
                            </div>
                            <div className='div-txt-beri'>
                                <div className='txt-left div-left'>Nomor Ujian</div><span className='txt-left'>:</span><div className='div-right'>{noPesertaGenerate(peserta)}</div>
                            </div>
                            <div className='flex ai-center'>
                                <div className='txt-left div-left'>Chapter</div><span className='txt-left'>:</span><div className='div-right capitalize'>{getChapter()}</div>
                            </div>
                        </div>
                        {peserta &&
                            <div className='mt40'>
                                <div className='title-beri ta-left capitalize'>Nilai Seleksi Berkas</div>
                                <div className='mt15'>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nilai Essai</div>
                                        <div className='ta-right w50'>{peserta?.seleksiBerkas?.esai}</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nilai Tebobot 20%</div>
                                        <div className='ta-right w50'>{peserta?.seleksiBerkas?.esaiNilaiTerbobot}</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Motivational Statement</div>
                                        <div className='ta-right w50'>{peserta?.seleksiBerkas?.mStatement}</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nilai Terbobot 15%</div>
                                        <div className='ta-right w50'>{peserta?.seleksiBerkas?.mStatementNilaiTerbobot}</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Prestasi dan Pengalaman Organisasi</div>
                                        <div className='ta-right w50'>{peserta?.seleksiBerkas?.sejarahKepemimpinan}</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Keterangan</div>
                                        <div className='ta-right w50'>{peserta?.seleksiBerkas?.keterangan}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='mt40' style={{ paddingBottom: '40px' }}>
                            <KembaliButton onClick={() => [history.goBack()]}>
                                Kembali
                            </KembaliButton>
                        </div>
                    </Container>
                </Paper>
            </Container>
        </>
    )
}
