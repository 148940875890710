import {
    GET_PINLIST,
    IS_LOGOUT
} from '../constanta'

const intialState = {
    pin: [],
}

function pinState(state = intialState, action) {
    switch (action.type) {
        case GET_PINLIST:
            return {
                ...state, 
                pin : action.data
            }
        case IS_LOGOUT:
            return {
                pin : null
            }
        default: {
            return state
        }
    }
}

export default pinState