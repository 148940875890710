import {
    GET_CHAPTER,
    IS_LOGOUT,
    SET_CHAPTER,
    UPDATE_CHAPTER
} from '../constanta'

const intialState = {
    chapterList: [
        { name : 'ambon', code : 'AMQ', mail : "chapter.ambon@afs.org" },
        { name : 'balikpapan', code : 'BPP', mail : "chapter.balikpapan@afs.org" },
        { name : 'banda aceh', code : 'BNA', mail : "chapter.bandaaceh@afs.org" },
        { name : 'bandung', code : 'BDG', mail : "chapter.bandung@afs.org" },
        { name : 'banjarmasin', code : 'BMS', mail : "chapter.banjarmasin@afs.org" },
        { name : 'bogor', code : 'BGR', mail : "chapter.bogor@afs.org" },
        { name : 'denpasar', code : 'DPS', mail : "chapter.denpasar@afs.org" },
        { name : 'jakarta', code : 'JKT', mail : "chapter.jakarta@afs.org" },
        { name : 'karawang', code : 'KRW', mail : "chapter.karawang@afs.org" },
        { name : 'makassar', code : 'MKS', mail : "chapter.makassar@afs.org" },
        { name : 'malang', code : 'MLG', mail : "chapter.malang@afs.org" },
        { name : 'mataram', code : 'MTR', mail : "chapter.mataram@afs.org" },
        { name : 'medan', code : 'MDN', mail : "chapter.medan@afs.org" },
        { name : 'padang', code : 'PDG', mail : "chapter.padang@afs.org" },
        { name : 'palembang', code : 'PLM', mail : "chapter.palembang@afs.org" },
        { name : 'pontianak', code : 'PTK', mail : "chapter.pontianak@afs.org" },
        { name : 'samarinda', code : 'SMD', mail : "chapter.samarinda@afs.org" },
        { name : 'semarang', code : 'SMG', mail : "chapter.semarang@afs.org" },
        { name : 'surabaya', code : 'SUB', mail : "chapter.surabaya@afs.org" },
        { name : 'yogyakarta', code : 'JOG', mail : "chapter.yogyakarta@afs.org" }
    ],
    chapter:null,
    chapterRole: [
        { role : 'admin nasional' },
        { role : 'admin chapter' }
    ]
}

function chapterState(state = intialState, action) {
    switch (action.type) {
        case GET_CHAPTER:
            return {
                ...state, 
                chapter : action.data
            }
        case SET_CHAPTER:
            return {
                ...state, 
                chapter : [ ...state.chapter, action.data ]
            }
        case UPDATE_CHAPTER:
            let data = []
            if (state.chapter) {
                state.chapter.map(chap=>{
                    if (chap._id !== action.data._id) {
                        data.push(chap)
                    }
                })
            }
            return {
                ...state, 
                chapter : [ ...data, action.data ]
            }
        case IS_LOGOUT:
            return {
                chapterFilter : null
            }
        default: {
            return state
        }
    }
}

export default chapterState