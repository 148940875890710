import { GET_OPTIONS, SET_TABULASI } from "../constanta";

const intialState = {
  option: {
    YES: {
      pendaftaran: false,
      finalisasi: false,
      hasilSeleksi: false,
      uploadBerkas: false,
    },
    AFS: {
      pendaftaran: false,
      finalisasi: false,
      hasilSeleksi: false,
      uploadBerkas: false,
    },
  },
  tabulasi: false
};

function optionState(state = intialState, action) {
  switch (action.type) {
    case GET_OPTIONS:
      return {
        ...state,
        option: action.data,
      };
    case SET_TABULASI:
      return {
        ...state,
        tabulasi: action.data,
      };
    default: {
      return state;
    }
  }
}

export default optionState;
