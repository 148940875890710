import React, { useState, useEffect } from 'react'
import Back from '../../../components/Button/Back'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { KembaliButton } from '../../../components/Button/Cus'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailPesertaSeleksi3 } from '../../../store/actions/dataPeserta'

function DetailNilai() {
    const { id } = useParams()
    const detailNilaiPeserta = useSelector(state => state.dataPesertaState.detailNilaiPeserta);
    const chapter = useSelector(state => state.chapterState.chapter)
    const History = useHistory()
    const Dispatch = useDispatch()

    useEffect(() => {
        if (id) {
            Dispatch(getDetailPesertaSeleksi3(id))
        }
    }, [id])

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan + ' ' : ''}${namaTengah ? namaTengah + ' ' : ''}${namaBelakang ? namaBelakang + ' ' : ''}`
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const getTotalNilaiInteraksiKelompok = (data) => {
        let result = 0
        let totalObserver = 0
        if (data) {
            if (data.observer) {
                totalObserver = data.observer.length
                data.observer.map(nilai=>{
                    if (
                        nilai.nilaiKemampuanBerkomunikasi ||
                        nilai.nilaiKemampuanMemutuskan ||
                        nilai.nilaiKemampuanBekerjasama ||
                        nilai.nilaiOrientasiPadaHasil
                    ) {
                        if (result) {
                            result = result + ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        } else {
                            result = ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        }
                    }
                })
            }
        }
        return result ? (result/totalObserver) : '-'
    }

    const getTerbobotNilaiInteraksiKelompok = (data, percent) => {
        let totalNilai = 0
        let result = 0
        let totalObserver = 0
        if (data) {
            if (data.observer) {
                let percentAsDecimal = (Number(percent) / 100);
                totalObserver = data.observer.length
                data.observer.map(nilai=>{
                    if (
                        nilai.nilaiKemampuanBerkomunikasi ||
                        nilai.nilaiKemampuanMemutuskan ||
                        nilai.nilaiKemampuanBekerjasama ||
                        nilai.nilaiOrientasiPadaHasil
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        } else {
                            totalNilai = ((Number(nilai.nilaiKemampuanBerkomunikasi) * 5) + (Number(nilai.nilaiKemampuanMemutuskan) * 5) + (Number(nilai.nilaiKemampuanBekerjasama) * 5) + (Number(nilai.nilaiOrientasiPadaHasil) * 5))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalObserver)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const getValue = (obj, key) => {
        let result = ''
        if (obj && key) {
            result = obj[key]
        }
        return result ? result : '-' 
    }

    const getDateValue = (obj, key) => {
        let result = ''
        if (obj && key) {
            result = getDate(obj[key])
        }
        return result ? result : '-' 
    }

    const getDate = (value) => {
        if (value) {
            let start = new Date(value).getDate()
            let startMonth = new Date(value).getMonth()
            let monthStart = ''
            let yearResult = new Date(value).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }

            return start + ' '+ monthStart +' '+ yearResult
        }
    }

    const getTotalNilai = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKemampuanBerkomunikasi &&
                data.nilaiKemampuanMemutuskan &&
                data.nilaiKemampuanBekerjasama &&
                data.nilaiOrientasiPadaHasil
            ) {
                result = ((Number(data.nilaiKemampuanBerkomunikasi) * 5) + (Number(data.nilaiKemampuanMemutuskan) * 5) + (Number(data.nilaiKemampuanBekerjasama) * 5) + (Number(data.nilaiOrientasiPadaHasil) * 5))
            }
        }
        return result
    }

    const getTerbobotNilai = (data, percent) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKemampuanBerkomunikasi &&
                data.nilaiKemampuanMemutuskan &&
                data.nilaiKemampuanBekerjasama &&
                data.nilaiOrientasiPadaHasil
            ) {
                let percentAsDecimal = (Number(percent) / 100);
                let nilaiTotal = ((Number(data.nilaiKemampuanBerkomunikasi) * 5) + (Number(data.nilaiKemampuanMemutuskan) * 5) + (Number(data.nilaiKemampuanBekerjasama) * 5) + (Number(data.nilaiOrientasiPadaHasil) * 5))
                result = (percentAsDecimal * nilaiTotal).toFixed(1)
            }
        }
        return result
    }

    const getRekomendasiNilai = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKemampuanBerkomunikasi &&
                data.nilaiKemampuanMemutuskan &&
                data.nilaiKemampuanBekerjasama &&
                data.nilaiOrientasiPadaHasil
            ) {
                let nilaiTotal = ((Number(data.nilaiKemampuanBerkomunikasi) * 5) + (Number(data.nilaiKemampuanMemutuskan) * 5) + (Number(data.nilaiKemampuanBekerjasama) * 5) + (Number(data.nilaiOrientasiPadaHasil) * 5))
                if (nilaiTotal >= 85) {
                    result = 'Sangat diunggulkan'
                } else if ((nilaiTotal >= 75) && (nilaiTotal <= 84)) {
                    result = 'Diunggulkan'
                } else if ((nilaiTotal >= 60) && (nilaiTotal <= 74)) {
                    result = 'Dapat diterima'
                } else if (nilaiTotal < 60) {
                    result = 'Ditolak'
                }
            }
        }
        return result
    }

    return (
        <Container className='con-beri-nilai'>
            <Back />
            <Paper className='paper-beri-nilai pt10'>
                <Container style={{ width: 660, padding: 0 }}>
                    <div className='title-beri'>
                        DETAIL NILAI INTERAKSI KELOMPOK
                    </div>
                    <div className='div-blue-beri ta-left'>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Nama Peserta</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'>{getMyName(detailNilaiPeserta && detailNilaiPeserta.dataPribadi)}</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Chapter</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'
                                style={{ textTransform: 'capitalize' }}
                            >{
                                getChapterName(chapter, detailNilaiPeserta && detailNilaiPeserta.chapterId)
                            }</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Total Nilai</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'>
                                {
                                    getTotalNilaiInteraksiKelompok(detailNilaiPeserta && detailNilaiPeserta.seleksiInteraksiKelompok)
                                }
                            </div>
                        </div>
                        <div className='flex ai-center'>
                        <div className='txt-left div-left'>Nilai Terbobot 25%</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'
                            >
                                {
                                    getTerbobotNilaiInteraksiKelompok(detailNilaiPeserta && detailNilaiPeserta.seleksiInteraksiKelompok, 25)
                                }
                            </div>
                        </div>
                    </div>
                    <div className='mt40'>
                        <div className='title-beri ta-left capitalize'>Nilai Interaksi Kelompok</div>
                        <div className='mt15'>
                            <div className='div-det-nilai'>
                                <div className='ta-left w50'>Nomor Ujian</div>
                                <div className='ta-right w50'>
                                    {
                                        getValue(detailNilaiPeserta && detailNilaiPeserta.seleksiInteraksiKelompok, 'nomorUjian')
                                    }
                                </div>
                            </div>
                            <div className='div-det-nilai'>
                                <div className='ta-left w50'>Tanggal Ujian</div>
                                <div className='ta-right w50'>
                                    {
                                        getDateValue(detailNilaiPeserta && detailNilaiPeserta.seleksiInteraksiKelompok, 'tanggalUjian')
                                    }
                                </div>
                            </div>
                            <div className='div-det-nilai'>
                                <div className='ta-left w50'>Tempat/Ruang</div>
                                <div className='ta-right w50'>
                                    {
                                        getValue(detailNilaiPeserta && detailNilaiPeserta.seleksiInteraksiKelompok, 'lokasiUjian')
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            (
                                (detailNilaiPeserta &&
                                detailNilaiPeserta.seleksiInteraksiKelompok &&
                                detailNilaiPeserta.seleksiInteraksiKelompok.observer) ? 
                                (detailNilaiPeserta &&
                                detailNilaiPeserta.seleksiInteraksiKelompok &&
                                detailNilaiPeserta.seleksiInteraksiKelompok.observer)
                                :
                                []
                            )
                            .map((observer, i) => (
                                <div>
                                    <div className='div-obs-det mt35 mb12'>
                                        Observer {i+1}
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nama Observer {i+1}</div>
                                        <div className='ta-right w50'>{ observer.namaObserver }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>A. Nilai Kemampuan Berkomunikasi</div>
                                        <div className='ta-right w50'>{ observer.nilaiKemampuanBerkomunikasi }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>B. Nilai Kemampuan Memutuskan</div>
                                        <div className='ta-right w50'>{ observer.nilaiKemampuanMemutuskan }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left '>C. Nilai Kemampuan Bekerjasama dan Memotivasi</div>
                                        <div className='ta-right '>{ observer.nilaiKemampuanBekerjasama }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>D. Nilai Orientasi Pada Hasil</div>
                                        <div className='ta-right w50'>{ observer.nilaiOrientasiPadaHasil }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Total Penilaian (setelah dikali 5)</div>
                                        <div className='ta-right w50'>{getTotalNilai(observer)}</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nilai Terbobot (25%)</div>
                                        <div className='ta-right w50'>{ getTerbobotNilai(observer, 25) }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Rekomendasi</div>
                                        <div className='ta-right w50'>{ getRekomendasiNilai(observer) }</div>
                                    </div>
                                </div>                              
                            ))
                        }
                    </div>
                    <div className='mt40' style={{ paddingBottom: '40px' }}>
                        <KembaliButton onClick={() => History.push('/seleksi-kelompok')}>
                            Kembali
                        </KembaliButton>
                    </div>
                </Container>
            </Paper>
        </Container>
    )
}

export default DetailNilai