import React, { useState, useEffect } from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import { setSaveAction, updateAdminChapter } from '../../../../../store/actions/user'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const BatalButton = withStyles((theme) => ({
    root: {
        width: '190px',
        height: '44px',
        color: '#3598DC',
        border: '1px solid #3598DC',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}))(Button);

const CreateButton = withStyles((theme) => ({
    root: {
        width: '190px',
        height: '44px',
        color: 'white',
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: 'none',
        },
    },
}))(Button);

function EditAdminChapter(props){
    const History = useHistory();
    const Dispatch = useDispatch();
    const chapter = useSelector(state=>state.chapterState.chapter)
    const propinsiDB = useSelector(state=> state.daerahState.propinsi )
    const kabupatenDB = useSelector(state=> state.daerahState.kabupaten )
    const kecamatanDB = useSelector(state=> state.daerahState.kecamatan )
    const save = useSelector(state=>state.filterState.checkerSave);
    const [click, setClick] = useState(false)
    const [id, setId] = useState(false)
    const [sendData, setSendData] = useState({
        chapterId: '',
        nama: '',
        alamat: '',
        propinsi: '',
        kabupaten: '',
        kecamatan: '',
        kelurahan: '',
        kodepos: '',
        jabatan: '',
        email: '',
        telepon: '',
    })

    useEffect(()=>{
        if (props.location) {
            if (props.location.state) {
                if (props.location.state.admin) {
                    setSendData({
                        chapterId: props.location.state.admin.chapterId,
                        nama: props.location.state.admin.nama,
                        alamat: props.location.state.admin.alamat,
                        propinsi: props.location.state.admin.propinsi,
                        kabupaten: props.location.state.admin.kabupaten,
                        kecamatan: props.location.state.admin.kecamatan,
                        kelurahan: props.location.state.admin.kelurahan,
                        kodepos: props.location.state.admin.kodepos,
                        jabatan: props.location.state.admin.jabatan,
                        email: props.location.state.admin.email,
                        telepon: props.location.state.admin.telepon,
                    })
                    setId(props.location.state.admin._id)
                }
            }
        }
    },[props])

    const handleChangeValue = (key, value) => {
        if (!save) Dispatch(setSaveAction(true))
        switch (key) {
            case 'chapterId':
                setSendData({
                    ...sendData,
                    chapterId: value,
                })
                break;
            case 'nama':
                setSendData({
                    ...sendData,
                    nama: value,
                })
                break;
            case 'alamat':
                setSendData({
                    ...sendData,
                    alamat: value,
                })
                break;
            case 'propinsi':
                setSendData({
                    ...sendData,
                    propinsi: value,
                    kabupaten: '',
                    kecamatan: '',
                    kelurahan: '',
                    kodepos: '',
                })
                break;
            case 'kabupaten':
                setSendData({
                    ...sendData,
                    kabupaten: value,
                    kecamatan: '',
                    kelurahan: '',
                    kodepos: '',
                })
                break;
            case 'kecamatan':
                setSendData({
                    ...sendData,
                    kecamatan: value,
                    kelurahan: '',
                    kodepos: '',
                })
                break;
            case 'kelurahan':
                setSendData({
                    ...sendData,
                    kelurahan: value,
                    kodepos: '',
                })
                break;
            case 'kodepos':
                setSendData({
                    ...sendData,
                    kodepos: value,
                })
                break;
            case 'jabatan':
                setSendData({
                    ...sendData,
                    jabatan: value,
                })
                break;
            case 'email':
                setSendData({
                    ...sendData,
                    email: value,
                })
                break;
            case 'telepon':
                setSendData({
                    ...sendData,
                    telepon: value,
                })
                break;
            case 'password':
                setSendData({
                    ...sendData,
                    password: value,
                })
                break;
            default:
                break;
        }
    }

    const handleFilter = (key, data) => {
        let cloud = []
        switch (key) {
            case 'kecamatan':
                if (data) {
                    data.map(props=>{
                        if (!cloud.includes(props.kecamatan)) {
                            cloud.push(props.kecamatan)
                        }
                    })
                }
                break;
            case 'kelurahan':
                if (data) {
                    data.map(props=>{
                        if (props.kecamatan === sendData.kecamatan) {
                            if (!cloud.includes(props.kelurahan)) {
                                cloud.push(props.kelurahan)
                            }
                        }
                    })
                }
                break;
            case 'kodepos':
                if (data) {
                    data.map(props=>{
                        if (props.kecamatan === sendData.kecamatan) {
                            if (props.kelurahan === sendData.kelurahan) {
                                if (!cloud.includes(props.kodepos)) {
                                    cloud.push(props.kodepos)
                                }
                            }
                        }
                    })
                }
                break;
            default:
                break;
        }
        return cloud
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const savingData = () => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        setClick(true)
        let flag = false
        for (let key in sendData) {
            if (
                key === 'chapterId' ||
                key === 'nama' ||
                key === 'alamat' ||
                key === 'email' ||
                key === 'telepon' ||
                key === 'password'
            ) {
                if (!sendData[key]) {
                    flag = true
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Silahkan Lengkapi Persyaratan`
                    })
                    goTop()
                    return
                }
                if (flag) return
            }
            if (flag) return
        }
        if (!flag) {
            if (validateEmail(sendData.email)) {
                Dispatch(updateAdminChapter(sendData, Swal, answerBack, id))
            }else {
                ToastMid.fire({
                    icon: 'warning',
                    title: `Tulis alamat email dengan benar`
                })
            }
        }
    }

    const answerBack = (route = '/pengaturan-umum/admin-chapter') => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        SwalBootstrap.fire({
            title: 'Ingin Merubah Kembali?',
            text: "",
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Isi Kembali',
            cancelButtonText: 'Tidak',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            setClick(false)
            if (result.value) {
            } else if ( result.dismiss === Swal.DismissReason.cancel) {
                History.replace(route)
            }
        })
    }

    const handleBack = (route) => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (!save) {
            History.replace(route)
        } else if(save) {
            SwalBootstrap.fire({
                title: 'Ada Perubahan Data',
                text: "Ingin Menyimpan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Simpan',
                cancelButtonText: 'Tidak',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    savingData()
                } else if ( result.dismiss === Swal.DismissReason.cancel) {
                    History.replace(route)
                    Dispatch(setSaveAction(false))
                }
            })
        }
    }

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.code : '-'
        } else {
            return ''
        }
    }

    return (
        <div className="data-container-wrapper" >
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Tambah Admin Chapter
                </span>
            </div>
            <div className="bread-cab-container">
                <span className="bread-blue">
                    Pengaturan Umum /
                </span>
                <span className="bread-blue">
                    Admin Chapter /
                </span>
                <span className="bread-grey">
                    Tambah Admin Chapter /
                </span>
            </div>
            <div className="content-parent-wrapper-2021">
                <div className="pengaturan-umum-2021-wrapper" style={{ marginTop: 0 }}>
                    <div className="penerbitan-content-left-header">
                        <span>
                            tambah admin chapter
                        </span>
                    </div>
                    <div className="daftar-table-wrapper" >
                        <span className="add-chapter-header" >
                            Silahkan Masukan Data Admin Chapter
                        </span>
                        <div className="input-tambah-wrapper" >
                            {/* Chapter */}
                            <div className="data-pribadi-wrapper" >
                                <span className="data-pribadi-font">
                                    Chapter <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <select
                                    className="data-pribadi-input"
                                    onChange={e=>handleChangeValue('chapterId',e.target.value)}
                                    value={sendData.chapterId}
                                    style={{
                                        color: sendData.chapterId ? '#666666' : '#C9C9C9',
                                        border: (click && !sendData.chapterId) && '1px solid red',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    <option
                                        value=""
                                        style={{
                                            color: sendData.chapterId ? '#d3d5d7' : '#d3d5d7',
                                        }}
                                        selected
                                    >
                                        Pilih Chapter
                                    </option>
                                    {
                                        chapter &&
                                        chapter
                                        .map((chapter,i)=>(
                                            <option
                                                key={i}
                                                style={{ color: sendData.chapterId === chapter._id ? '#3c98dc' : '#666666' }}
                                                value={chapter._id}
                                            >
                                                {chapter.chapter}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* Code */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                <span className="data-pribadi-font">
                                    Kode Chapter
                                </span>
                                <input
                                    value={getChapterCode(chapter, sendData.chapterId)}
                                    disabled
                                    type="text"
                                    placeholder="Pilih Chapter"
                                    className="data-pribadi-input"
                                    style={{
                                    }}
                                />
                            </div>
                            {/* Nama */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                <span className="data-pribadi-font">
                                    Nama Lengkap <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <input
                                    value={sendData.nama}
                                    onChange={e=>handleChangeValue('nama',e.target.value)}
                                    type="text"
                                    placeholder="Nama Lengkap"
                                    className="data-pribadi-input"
                                    style={{
                                        border: (click && !sendData.nama) && '1px solid red'
                                    }}
                                />
                            </div>
                            {/* Alamat Chapter */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: '10px' }} >
                                <span className="data-pribadi-font">
                                    Alamat <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <div className="input-wrapper-data-pribadi" >
                                    <input
                                        onChange={e=>handleChangeValue('alamat',e.target.value)}
                                        value={sendData.alamat}
                                        type="text"
                                        placeholder="Alamat Lengkap"
                                        className="data-pribadi-input3"
                                        style={{
                                            border: (click && !sendData.alamat) && '1px solid red',
                                        }}
                                    />
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('propinsi',e.target.value)}
                                        style={{ color: sendData.propinsi ? '' : '#C9C9C9' }}
                                        value={sendData.propinsi}
                                    >
                                        <option value="" style={{ color: sendData.propinsi ? '' : '#d3d5d7' }} selected>Propinsi</option>
                                        {
                                            propinsiDB &&
                                            propinsiDB.sort().map((propinsi,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.propinsi === propinsi.id ? true : false} 
                                                    style={{ color: sendData.propinsi === propinsi.id ? '#3c98dc' : 'black' }}
                                                    value={propinsi.id}
                                                >
                                                    {propinsi.propinsi}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kabupaten',e.target.value)}
                                        style={{ color: sendData.kabupaten ? '' : '#C9C9C9' }}
                                        value={sendData.kabupaten}
                                    >
                                        <option value="" style={{ color: sendData.kabupaten ? '' : '#d3d5d7' }} selected>Kota/Kabupaten</option>
                                        {
                                            kabupatenDB[sendData.propinsi] &&
                                            kabupatenDB[sendData.propinsi].sort().map((kabupaten,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kabupaten === kabupaten.id ? true : false} 
                                                    style={{ color: sendData.kabupaten === kabupaten.id ? '#3c98dc' : 'black' }}
                                                    value={kabupaten.id}
                                                >
                                                    {kabupaten.kabupaten}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="data-pribadi-wrapper" style={{ marginTop: '10px' }} >
                                <span className="data-pribadi-font"></span>
                                <div className="input-wrapper-data-pribadi" >
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kecamatan',e.target.value)}
                                        style={{ color: sendData.kecamatan ? '' : '#C9C9C9' }}
                                        value={sendData.kecamatan}
                                    >
                                        <option value="" style={{ color: sendData.kecamatan ? '' : '#d3d5d7' }} selected>Kecamatan</option>
                                        {
                                            kecamatanDB[sendData.kabupaten] &&
                                            handleFilter('kecamatan', kecamatanDB[sendData.kabupaten]).map((data,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kecamatan === data ? true : false} 
                                                    style={{ color: sendData.kecamatan === data ? '#3c98dc' : 'black' }}
                                                    value={data}
                                                >
                                                    {data}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kelurahan',e.target.value)}
                                        style={{ color: sendData.kelurahan ? '' : '#C9C9C9' }}
                                        value={sendData.kelurahan}
                                    >
                                        <option value="" style={{ color: sendData.kelurahan ? '' : '#d3d5d7' }} selected>Desa/Kelurahan</option>
                                        {
                                            kecamatanDB[sendData.kabupaten] &&
                                            handleFilter('kelurahan', kecamatanDB[sendData.kabupaten]).map((data,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kelurahan === data ? true : false} 
                                                    style={{ color: sendData.kelurahan === data ? '#3c98dc' : 'black' }}
                                                    value={data}
                                                >
                                                    {data}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kodepos',e.target.value)}
                                        style={{ color: sendData.kodepos ? '' : '#C9C9C9' }}
                                        value={sendData.kodepos}
                                    >
                                        <option value="" style={{ color: sendData.kodepos ? '' : '#d3d5d7' }} selected>Kode Pos</option>
                                        {
                                            kecamatanDB[sendData.kabupaten] &&
                                            handleFilter('kodepos', kecamatanDB[sendData.kabupaten]).map((data,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kodepos === data ? true : false} 
                                                    style={{ color: sendData.kodepos === data ? '#3c98dc' : 'black' }}
                                                    value={data}
                                                >
                                                    {data}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="data-pribadi-wrapper" style={{ marginTop: 5 }}>
                                <span className="data-pribadi-font"></span>
                                <span className="email-small-info" >
                                    Kosongkan Desa, Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat lengkap anda di kolom Alamat Lengkap
                                </span>
                            </div>
                            {/* Jabatan */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                <span className="data-pribadi-font">
                                    Jabatan
                                </span>
                                <input
                                    value={sendData.jabatan}
                                    onChange={e=>handleChangeValue('jabatan',e.target.value)}
                                    type="text"
                                    placeholder="Jabatan di Chapter"
                                    className="data-pribadi-input"
                                    style={{
                                    }}
                                />
                            </div>
                            {/* Email & Telepon */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                <span className="data-pribadi-font">
                                    Email & Telepon <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <input
                                        value={sendData.email}
                                        onChange={e=>handleChangeValue('email',e.target.value)}
                                        type="text"
                                        placeholder="Email Aktif"
                                        className="data-pribadi-input2"
                                        style={{
                                            border: (click && !sendData.email) && '1px solid red',
                                        }}
                                    />
                                    <input
                                        value={sendData.telepon}
                                        onChange={e=>handleChangeValue('telepon',e.target.value)}
                                        type="text"
                                        placeholder="08912345678"
                                        className="data-pribadi-input2"
                                        style={{
                                            border: (click && !sendData.telepon) && '1px solid red',
                                        }}
                                    />
                                </div>
                            </div>
                            {/* Email INFO */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 5 }}>
                                <span className="data-pribadi-font"></span>
                                <span className="email-small-info" >
                                    Pastikan email yang digunakan aktif
                                </span>
                            </div>
                            {/* BUTTON */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 20 }}>
                                <span className="data-pribadi-font"></span>
                                <div className='button-wrapper-save-input'>
                                    <BatalButton onClick={()=> handleBack('/pengaturan-umum/admin-chapter')} > Batal </BatalButton>
                                    <CreateButton style={{ marginLeft: 17 }} onClick={()=>savingData()}> Simpan </CreateButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAdminChapter