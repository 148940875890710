import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { BatalButton, InputButton, InputEditButton } from '../../../components/Button/Cus';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import StyledRadio from '../../../components/StyledRadio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { getInfoPesertaSeleksiWawancaraKepribadian, createWawancaraKepribadian } from '../../../store/actions/dataPeserta'
import { setSaveAction } from '../../../store/actions/user'
import { DatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ModalCustom from '../../../components/modal/ModalCustom'

function BeriNilaiWawancaraPribadi(props) {
    const {
        location
    } = props
    const save = useSelector(state=>state.filterState.checkerSave);
    const pesertaWawancaraKepribadian = useSelector(state => state.dataPesertaState.pesertaWawancaraKepribadian);
    const chapter = useSelector(state => state.chapterState.chapter)
    const History = useHistory()
    const Dispatch = useDispatch()
    const [click, setClick] = useState(false)
    const [sendData, setSendData] = useState(
        {
            nomorUjian: '',
            tanggalUjian: null,
            lokasiUjian: '',
            pewawancara: [{
                namaPewawancara: '',
                nilaiKualitasPribadi: '',
                nilaiVisi: '',
                nilaiEmpatiSosial: '',
                nilaiNasional: '',
                observasi: '',
                keterangan: '',
                validasi: '',
            }]
        }
    )
    const [modalProps, setModalProps] = useState({
        open: false,
        icon: '',
        title: '',
        text: '',
        buttonCancel: false,
        buttonCancelText: '',
        buttonCancelAction: null,
        buttonConfirm: false,
        buttonConfirmText: '',
        buttonConfirmAction: null,
    })

    const handleModalPropsClose = () => {
        setModalProps({
            ...modalProps,
            open: false,
        })
    }

    const validateScore = (value) => {
        var rgx = /^[1-5]$/;
        if (value) {
            return value.match(rgx);
        } else {
            return true
        }
    }

    const handleChangeValue = (key, value, i) => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        if ((History.location.pathname).includes('/ubah')) {
            if (!save) Dispatch(setSaveAction(true))
        }
        switch (key) {
            case 'nomorUjian':
                setSendData({
                    ...sendData,
                    nomorUjian : value
                })
                break;
            case 'tanggalUjian':
                setSendData({
                    ...sendData,
                    tanggalUjian : value
                })
                break;
            case 'lokasiUjian':
                setSendData({
                    ...sendData,
                    lokasiUjian : value
                })
                break;
            case 'namaPewawancara':
                let temp4 = sendData.pewawancara
                temp4[i][key] = value
                setSendData({
                    ...sendData,
                    pewawancara : temp4
                })
                break;
            case 'nilaiKualitasPribadi':
                if (validateScore(value)) {
                    let temp5 = sendData.pewawancara
                    temp5[i][key] = value
                    setSendData({
                        ...sendData,
                        pewawancara : temp5
                    })
                } else {
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Masukkan angka 1 - 5`,
                    })
                }
                break;
            case 'nilaiVisi':
                if (validateScore(value)) {
                    let temp6 = sendData.pewawancara
                    temp6[i][key] = value
                    setSendData({
                        ...sendData,
                        pewawancara : temp6
                    })
                } else {
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Masukkan angka 1 - 5`,
                    })
                }
                break;
            case 'nilaiEmpatiSosial':
                if (validateScore(value)) {
                    let temp7 = sendData.pewawancara
                    temp7[i][key] = value
                    setSendData({
                        ...sendData,
                        pewawancara : temp7
                    })
                } else {
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Masukkan angka 1 - 5`,
                    })
                }
                break;
            case 'nilaiNasional':
                if (validateScore(value)) {
                    let temp8 = sendData.pewawancara
                    temp8[i][key] = value
                    setSendData({
                        ...sendData,
                        pewawancara : temp8
                    })
                } else {
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Masukkan angka 1 - 5`,
                    })
                }
                break;
            case 'observasi':
                let temp9 = sendData.pewawancara
                temp9[i][key] = value
                setSendData({
                    ...sendData,
                    pewawancara : temp9
                })
                break;
            case 'keterangan':
                let temp10 = sendData.pewawancara
                temp10[i][key] = value
                setSendData({
                    ...sendData,
                    pewawancara : temp10
                })
                break;
            case 'validasi':
                let temp11 = sendData.pewawancara
                temp11[i][key] = (temp11[i][key] === value ? '' : value)
                setSendData({
                    ...sendData,
                    pewawancara : temp11
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (location) {
            if (location.state) {
                if (location.state.id) {
                    Dispatch(getInfoPesertaSeleksiWawancaraKepribadian(location.state.id))
                }
            }
        }
    }, [location])

    useEffect(() => {
        if (pesertaWawancaraKepribadian) {
            if (pesertaWawancaraKepribadian.seleksiWawancaraPribadi) {
                setSendData(pesertaWawancaraKepribadian.seleksiWawancaraPribadi)
            }
        }
    }, [pesertaWawancaraKepribadian])

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan+' ' : ''}${namaTengah ? namaTengah+' ' : ''}${namaBelakang ? namaBelakang+' ' : ''}`
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const getTotalNilai = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKualitasPribadi &&
                data.nilaiVisi &&
                data.nilaiEmpatiSosial &&
                data.nilaiNasional
            ) {
                result = ((Number(data.nilaiKualitasPribadi) * 6) + (Number(data.nilaiVisi) * 6) + (Number(data.nilaiEmpatiSosial) * 4) + (Number(data.nilaiNasional) * 4))
            }
        }
        return result
    }

    const getTerbobotNilai = (data, percent) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKualitasPribadi &&
                data.nilaiVisi &&
                data.nilaiEmpatiSosial &&
                data.nilaiNasional
                ) {
                let percentAsDecimal = (Number(percent) / 100);
                let nilaiTotal = (Number(data.nilaiKualitasPribadi) * 6) + (Number(data.nilaiVisi) * 6) + (Number(data.nilaiEmpatiSosial) * 4) + (Number(data.nilaiNasional) * 4)
                result = (percentAsDecimal * nilaiTotal).toFixed(1)
            }
        }
        return result
    }

    const getRekomendasiNilai = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKualitasPribadi &&
                data.nilaiVisi &&
                data.nilaiEmpatiSosial &&
                data.nilaiNasional
            ) {
                let nilaiTotal = (Number(data.nilaiKualitasPribadi) * 6) + (Number(data.nilaiVisi) * 6) + (Number(data.nilaiEmpatiSosial) * 4) + (Number(data.nilaiNasional) * 4)
                if (nilaiTotal >= 85) {
                    result = 'Sangat diunggulkan'
                } else if ((nilaiTotal >= 75) && (nilaiTotal <= 84)) {
                    result = 'Diunggulkan'
                } else if ((nilaiTotal >= 60) && (nilaiTotal <= 74)) {
                    result = 'Dapat diterima'
                } else if (nilaiTotal < 60) {
                    result = 'Ditolak'
                }
            }
        }
        return result
    }

    const addPewawancara = () => {
        if ((History.location.pathname).includes('/ubah')) {
            if (!save) Dispatch(setSaveAction(true))
        }
        let temp = sendData.pewawancara
        temp.push({
            namaPewawancara: '',
            nilaiKualitasPribadi: '',
            nilaiVisi: '',
            nilaiEmpatiSosial: '',
            nilaiNasional: '',
            observasi: '',
            keterangan: '',
            validasi: '',
        })
        setSendData({
            ...sendData,
            pewawancara: temp
        })
    }

    const removePewawancara = (i) => {
        if ((History.location.pathname).includes('/ubah')) {
            if (!save) Dispatch(setSaveAction(true))
        }
        let temp = sendData.pewawancara
        temp.splice(i,1)
        setSendData({
            ...sendData,
            pewawancara: temp
        })
    }

    const goTop = () => {
        setTimeout(() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }, 100);
    }

    const saving = () => {
        setClick(true)
        let flag = false
        for (let key in sendData) {
            if (
                key === 'nomorUjian' ||
                key === 'tanggalUjian' ||
                key === 'lokasiUjian'
            ) {
                if (!sendData[key]) {
                    flag = true
                    setModalProps({
                        open: true,
                        close: handleModalPropsClose,
                        icon: 'info',
                        title: `Silahkan Lengkapi Persyaratan`,
                        text: '',
                        buttonCancel: false,
                        buttonCancelText: '',
                        buttonCancelAction: null,
                        buttonConfirm: true,
                        buttonConfirmText: 'OK',
                        buttonConfirmAction: _ => [handleModalPropsClose(), goTop()],
                    })
                    return
                }
                if (flag) return
            }
            if (key === 'pewawancara') {
                if (sendData[key]) {
                    sendData[key].map(a=>{
                        for (const keyA in a) {
                            if (
                                keyA === 'namaPewawancara' ||
                                keyA === 'nilaiKualitasPribadi' ||
                                keyA === 'nilaiVisi' ||
                                keyA === 'nilaiEmpatiSosial' ||
                                keyA === 'nilaiNasional'
                            ) {
                                if (!a[keyA]) {
                                    flag = true
                                    setModalProps({
                                        open: true,
                                        close: handleModalPropsClose,
                                        icon: 'info',
                                        title: `Silahkan Lengkapi Persyaratan`,
                                        text: '',
                                        buttonCancel: false,
                                        buttonCancelText: '',
                                        buttonCancelAction: null,
                                        buttonConfirm: true,
                                        buttonConfirmText: 'OK',
                                        buttonConfirmAction: _ => [handleModalPropsClose(), goTop()],
                                    })
                                    return
                                }
                                if (flag) return
                            }
                            if (flag) return
                        }
                        if (flag) return
                    })
                }
            }
            if (flag) return
        }
        if (!flag) {
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'info',
                title: `Simpan Nilai Wawancara Kepribadian untuk ${getMyName(pesertaWawancaraKepribadian && pesertaWawancaraKepribadian.dataPribadi)}?`,
                text: 'Cek kembali sebelum menyimpan nilai',
                buttonCancel: true,
                buttonCancelText: 'Batal',
                buttonCancelAction: handleModalPropsClose,
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Yakin',
                buttonConfirmAction: _ => savingWawancara(),
            })
        }
    }

    const savingWawancara = () => {
        Dispatch(createWawancaraKepribadian(History, sendData, (pesertaWawancaraKepribadian && pesertaWawancaraKepribadian._id), Swal, getMyName(pesertaWawancaraKepribadian && pesertaWawancaraKepribadian.dataPribadi), handleModalPropsClose, setModalProps))
    }

    const getTotalNilaiPribadi = (data) => {
        let result = 0
        let totalPewawancara = 0
        if (data) {
            if (data.pewawancara) {
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiKualitasPribadi ||
                        nilai.nilaiVisi ||
                        nilai.nilaiEmpatiSosial ||
                        nilai.nilaiNasional
                    ) {
                        if (result) {
                            result = result + ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        } else {
                            result = ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        }
                    }
                })
            }
        }
        return result ? (result/totalPewawancara) : '-'
    }

    const getTerbobotNilaiPribadi = (data, percent) => {
        let totalNilai = 0
        let result = 0
        let totalPewawancara = 0
        if (data) {
            if (data.pewawancara) {
                let percentAsDecimal = (Number(percent) / 100);
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiKualitasPribadi ||
                        nilai.nilaiVisi ||
                        nilai.nilaiEmpatiSosial ||
                        nilai.nilaiNasional
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        } else {
                            totalNilai = ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalPewawancara)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const handleBack = () => {
        if (save) {
            setModalProps({
                open: true,
                close: handleModalPropsClose,
                icon: 'info',
                title: `Ada Perubahan Ingin Menyimpan?`,
                text: '',
                buttonCancel: true,
                buttonCancelText: 'Tidak',
                buttonCancelAction: _ => [History.replace('/seleksi-wawancara'), Dispatch(setSaveAction(false))],
                buttonConfirm: true,
                buttonConfirmText: 'Ya, Simpan',
                buttonConfirmAction: _ => [saving(), Dispatch(setSaveAction(false))],
            })
        } else {
            History.replace('/seleksi-wawancara')
        }
    }

    return (
        <Container className='con-beri-nilai'>
            <div className='btn-back' onClick={() => handleBack()}>
                <ArrowBackIcon className='icn-back'/>
                Kembali
            </div>
            <Paper className='paper-beri-nilai pt10'>
                <Container style={{ width: 660, padding: 0 }}>
                    <div className='title-beri'>
                        {
                            (History.location.pathname).includes('/ubah') ?
                                `UBAH NILAI WAWANCARA KEPRIBADIAN`
                                :
                                `INPUT NILAI WAWANCARA KEPRIBADIAN`
                        }
                    </div>
                    <div className='div-blue-beri ta-left'>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>
                                Nama Peserta
                            </div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'>{ getMyName(pesertaWawancaraKepribadian && pesertaWawancaraKepribadian.dataPribadi) }</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>
                                Chapter
                            </div>
                            <span className='txt-left'>:</span>
                            <div className='div-right' style={{ textTransform: 'capitalize' }}>{ getChapterName(chapter, pesertaWawancaraKepribadian && pesertaWawancaraKepribadian.chapterId) }</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>
                                Total Nilai
                            </div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'>{ getTotalNilaiPribadi(sendData) }</div>
                        </div>
                        <div className='flex ai-center'>
                            <div className='txt-left div-left'>
                                Nilai Terbobot (25%)
                            </div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'  >{ getTerbobotNilaiPribadi(sendData, 25) }</div>
                        </div>
                    </div>
                    <div className="mt25">
                        <div className="data-pribadi-font mb10 ta-left" >
                            Nomor Ujian
                        </div>
                        <input
                            value={sendData.nomorUjian}
                            onChange={e=>handleChangeValue('nomorUjian', e.target.value)}
                            type="text"
                            placeholder="Nomor Ujian"
                            className="data-pribadi-input"
                            style={{
                                border: (click && !sendData.nomorUjian) && '1px solid red'
                            }}
                        />
                    </div>
                    <div className="mt25">
                        <div className="data-pribadi-font mb10 ta-left" >
                            Tanggal Ujian
                        </div>
                        <div className="penerbitan-input"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                border: (click && !sendData.tanggalUjian) && '1px solid red',
                                width: '100%'
                            }}
                        >
                            <DatePicker
                                clearable
                                clearLabel="Hapus"
                                autoOk
                                views={['year', 'month', 'date']}
                                placeholder="Tanggal Ujian"
                                value={sendData.tanggalUjian}
                                onChange={(newValue) => {
                                    handleChangeValue( 'tanggalUjian', newValue);
                                }}
                                animateYearScrolling
                                okLabel=""
                                cancelLabel=""
                                helperText=""
                                format="dd/MM/yyyy"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: '0px',
                                    zIndex: '9',
                                    height: '44px',
                                    borderRadius: '4px',
                                    
                                }}
                                minDate={
                                    new Date("2021-1-1") 
                                }
                                maxDate={
                                    new Date("2021-12-31") 
                                }
                            />
                            <FontAwesomeIcon
                                icon={faCalendarAlt}
                                style={{
                                    color : "#3c98dc",
                                    fontSize: 18,
                                }}
                            />
                        </div>
                    </div>
                    <div className="mt25">
                        <div className="data-pribadi-font mb10 ta-left" >
                            Lokasi Ujian
                        </div>
                        <input
                            value={sendData.lokasiUjian}
                            onChange={e=>handleChangeValue('lokasiUjian', e.target.value)}
                            type="text"
                            placeholder="Lokasi Ujian"
                            className="data-pribadi-input"
                            style={{
                                border: (click && !sendData.lokasiUjian) && '1px solid red'
                            }}
                        />
                    </div>

                </Container>
                {
                    sendData &&
                    sendData.pewawancara
                    .map((wawancara, i) => (
                        <div
                            style={{
                                borderTop: i !== 0 && '1px solid #DDDDDD',
                                marginTop: i !== 0 && 40,
                                paddingTop: i !== 0 && 40,
                            }}
                        >
                            <Container style={{ width: 660, padding: 0 }}>
                                <div className="mt25" style={{ marginTop: i !== 0 && 0 }}>
                                    <div className="data-pribadi-font mb10 ta-left" >
                                        Nama Pewawancara {i + 1}
                                    </div>
                                    <input
                                        value={wawancara.namaPewawancara}
                                        onChange={e=>handleChangeValue('namaPewawancara', e.target.value, i)}
                                        type="string"
                                        placeholder={`Nama Pewawancara ${i + 1}`}
                                        className="data-pribadi-input"
                                        style={{
                                            border: (click && !wawancara.namaPewawancara) && '1px solid red'
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }}>
                                        l. Nilai Kualitas Pribadi
                                    </div>
                                    <input
                                        value={wawancara.nilaiKualitasPribadi}
                                        onChange={e=>handleChangeValue('nilaiKualitasPribadi', e.target.value, i)}
                                        type="string"
                                        placeholder="Masukkan Nilai 1 - 5"
                                        className="data-pribadi-input"
                                        style={{
                                            border: (click && !wawancara.nilaiKualitasPribadi) && '1px solid red'
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }}>
                                        II. Nilai Visi dan Prestasi
                                    </div>
                                    <input
                                        value={wawancara.nilaiVisi}
                                        onChange={e=>handleChangeValue('nilaiVisi', e.target.value, i)}
                                        type="string"
                                        placeholder="Masukkan Nilai 1 - 5"
                                        className="data-pribadi-input"
                                        style={{
                                            border: (click && !wawancara.nilaiVisi) && '1px solid red'
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }}>
                                        III. Nilai Empati Sosial
                                    </div>
                                    <input
                                        value={wawancara.nilaiEmpatiSosial}
                                        onChange={e=>handleChangeValue('nilaiEmpatiSosial', e.target.value, i)}
                                        type="string"
                                        placeholder="Masukkan Nilai 1 - 5"
                                        className="data-pribadi-input"
                                        style={{
                                            border: (click && !wawancara.nilaiEmpatiSosial) && '1px solid red'
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }} >
                                        IV. Nilai Berjiwa Nasional, Berwawasan Internasional
                                    </div>
                                    <input
                                        value={wawancara.nilaiNasional}
                                        onChange={e=>handleChangeValue('nilaiNasional', e.target.value, i)}
                                        type="string"
                                        placeholder="Masukkan Nilai 1 - 5"
                                        className="data-pribadi-input"
                                        style={{
                                            border: (click && !wawancara.nilaiNasional) && '1px solid red'
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" >
                                        Total Penilaian
                                    </div>
                                    <input disabled style={{ cursor: 'default', marginTop: 10, background: getTotalNilai(wawancara) ? '#fafafa' : "#F0F0F0", color: getTotalNilai(wawancara) ? '#666666' : "#A0A0A0" }} type="text" readOnly placeholder="Total Penilaian Akan Otomatis Terisi" className="data-pribadi-input"
                                        value={getTotalNilai(wawancara)}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" >
                                        Nilai Terbobot (25%)
                                    </div>
                                    <input disabled style={{ cursor: 'default', marginTop: 10, background: getTerbobotNilai(wawancara) ? '#fafafa' : "#F0F0F0", color: getTerbobotNilai(wawancara) ? '#666666' : "#A0A0A0" }} type="text" readOnly placeholder="Nilai Terbobot Akan Otomatis Terisi" className="data-pribadi-input"
                                        value={getTerbobotNilai(wawancara, 25)}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" >
                                        Rekomendasi
                                    </div>
                                    <input disabled style={{ cursor: 'default', marginTop: 10, background: getRekomendasiNilai(wawancara) ? '#fafafa' : "#F0F0F0", color: getRekomendasiNilai(wawancara) ? '#666666' : "#A0A0A0" }} type="text" readOnly placeholder="Nilai Rekomendasi dari Pewawancara" className="data-pribadi-input"
                                        value={getRekomendasiNilai(wawancara)}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }} >
                                        Hasil Observasi
                                    </div>
                                    <TextareaAutosize
                                        value={wawancara.observasi}
                                        onChange={e=>handleChangeValue('observasi', e.target.value, i)}
                                        type="string"
                                        placeholder="Hasil Pengamatan Tentang Siswa"
                                        className="data-pribadi-input"
                                        style={{
                                            padding: '15px 19px',
                                            resize: 'vertical',
                                            minHeight: 90,
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }} >
                                        Keterangan: Deskripsi mengenai kepemimpinan, kemandirian dan prestasi siswa
                                    </div>
                                    <TextareaAutosize
                                        value={wawancara.keterangan}
                                        onChange={e=>handleChangeValue('keterangan', e.target.value, i)}
                                        type="string"
                                        placeholder="Tulis Keterangan Disini"
                                        className="data-pribadi-input"
                                        style={{
                                            padding: '15px 19px',
                                            resize: 'vertical',
                                            minHeight: 90,
                                        }}
                                    />
                                </div>
                                <div className="mt25">
                                    <div className="data-pribadi-font mb10 ta-left" style={{ maxWidth: 'none' }} >
                                        Validasi Sejarah Kepemimpinan, Kemandirian serta Bakat Khusus
                                    </div>
                                    <div className='ml10'>
                                        <RadioGroup
                                            value={wawancara.validasi}
                                        >
                                            <FormControlLabel value="aktif" control={<StyledRadio onClick={e=>handleChangeValue('validasi', e.target.value, i)}/>} style={{ color: '#888888' }} label="Aktif/mengerti tanggung jawab pekerjaan/berprestasi" />
                                            <FormControlLabel value="cukup" control={<StyledRadio onClick={e=>handleChangeValue('validasi', e.target.value, i)}/>} style={{ color: '#888888' }} label="Cukup aktif/cukup berprestasi" />
                                            <FormControlLabel value="kurang" control={<StyledRadio onClick={e=>handleChangeValue('validasi', e.target.value, i)}/>} style={{ color: '#888888' }} label="Kurang aktif/tidak ada prestasi" />
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="mt25 ta-left flex">
                                    {
                                        i === (sendData && sendData.pewawancara && sendData.pewawancara.length - 1) && i !== 2 &&
                                        <div className='mr15'>
                                            <Button color='primary' className='btn-ad-obs' variant='outlined' onClick={addPewawancara} style={{ width: '192px', padding: '5px 8px' }}>Tambah Pewawancara</Button>
                                        </div>
                                    }
                                    {
                                        (sendData && sendData.pewawancara && sendData.pewawancara.length) > 1 &&
                                        <Button className='btn-hapus-obs' variant='contained' onClick={() => removePewawancara(i)} style={{ width: '192px', padding: '5px 8px' }}>Hapus Pewawancara</Button>
                                    }
                                </div>
                                {
                                    i === 2 && <div className="mt25 data-pribadi-font ta-left" style={{ color: '#3598DC', maxWidth: 'none' }}>Anda telah mencapai batas jumlah maksimal pewawancara (3 Pewawancara)</div>
                                }
                            </Container>
                        </div>
                    ))
                }

                <div className='mt60'>
                    <Divider />
                </div>
                <div className="div-btn-sub-can">
                    <div className="tblsi-btn-wrap" >
                        <BatalButton onClick={() => handleBack()}>
                            Batal
                        </BatalButton>
                        {
                            (History.location.pathname).includes('/ubah') ?
                                <InputEditButton
                                    onClick={saving}
                                >
                                    Simpan Perubahan Nilai
                                </InputEditButton>
                                :
                                <InputButton
                                    onClick={saving}
                                >
                                    Submit Nilai
                                </InputButton>
                        }
                    </div>
                </div>

            </Paper>
            <ModalCustom action={modalProps} />
        </Container>
    )
}

export default BeriNilaiWawancaraPribadi