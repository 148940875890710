import {
    GET_TOTAL_PESERTA,
    GET_DASHBOARD_ROW1,
    GET_DASHBOARD_ROW1PIN,
    GET_DASHBOARD_ROW2,
    GET_DASHBOARD_ROW3_LINE,
    GET_DASHBOARD_ROW3_PIE,
    GET_DASHBOARD_ROW3_BAR,
    GET_DASHBOARD_ROW_SCHOOL_BAR
} from "../constanta";

const intialState = {
    totalPeserta: 0,
    row1: {
        totalPesertaProgram : 0,
        totalPesertaFinalisasi : 0,
        totalPesertaBelumFinalisasi : 0,
        totalPesertaUploadSemuaBerkas : 0,
        totalPesertaBelumUploadSemuaBerkas : 0,
    },
    row1Pin: {
        totalPin : 0,
        totalPinActive : 0,
        totalPinDeactive : 0,
    },
    row2: {
        totalPesertaLulusVerifikasiBerkas: 0,
        totalPesertaTidakLulusVerifikasiBerkas: 0,
        totalPesertaLulusSeleksiBerkas: 0,
        totalPesertaTidakLulusSeleksiBerkas: 0,
        totalPesertaLulusSeleksiWawancara: 0,
        totalPesertaTidakLulusSeleksiWawancara: 0,
        totalPesertaLulusSeleksiInteraksiKelompok: 0,
        totalPesertaTidakLulusSeleksiInteraksiKelompok: 0,
    },
    row3Line: {
        pesertaSMK: 0,
        pesertaSMA: 0,
        pesertaMA: 0,
        pesertaPesantren: 0,
    },
    row3Pie: {
        pesertaLakiLaki: 0,
        pesertaPerempuan: 0,
    },
    row3Bar: [],
    rowSchoolBar: [],
}

function dashboardState(state = intialState, action) {
    switch (action.type) {
        case  GET_TOTAL_PESERTA:
            return {
                ...state, 
                totalPeserta : action.data
            }
        case  GET_DASHBOARD_ROW1:
            return {
                ...state, 
                row1 : action.data
            }
        case  GET_DASHBOARD_ROW1PIN:
            return {
                ...state, 
                row1Pin : action.data
            }
        case  GET_DASHBOARD_ROW2:
            return {
                ...state, 
                row2 : action.data
            }
        case  GET_DASHBOARD_ROW3_LINE:
            return {
                ...state, 
                row3Line : action.data
            }
        case  GET_DASHBOARD_ROW3_PIE:
            return {
                ...state, 
                row3Pie : action.data
            }
        case  GET_DASHBOARD_ROW3_BAR:
            return {
                ...state, 
                row3Bar : action.data
            }
        case  GET_DASHBOARD_ROW_SCHOOL_BAR:
            return {
                ...state, 
                rowSchoolBar : action.data
            }
        default: {
            return state
        }
    }
}

export default dashboardState