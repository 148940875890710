import React from 'react'
import './styles.scss'
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import DownloadIcon from '../../../../images/berkas/download.svg';
import { withStyles } from '@material-ui/core/styles';
import { downloadFile, exportExcel } from '../../../../store/actions/dataPeserta'
import { useDispatch } from 'react-redux';
import SERVER from '../../../../endpoint';

const DownloadButton = withStyles((theme) => ({
    root: {
        marginTop: 'auto',
        width: '100%',
        height: '44px',
        color: '#FFFFFF',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '19px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
        },
    },
}))(Button);

function AdminChapter(){
    const Dispatch = useDispatch()

    const download = (url, filename) => {
        // Dispatch(downloadFile(SERVER+url, filename, Swal))
        Dispatch(exportExcel(
            `/peserta/schools`,
            filename,
            Swal,
            {}
        ))
    }

    return (
        <div className="pengaturan-umum-2021-wrapper" >
            <div className="penerbitan-content-left-header" >
                <span>
                    Unduhan
                </span>
            </div>
            <div className="daftar-table-wrapper" style={{display: 'flex', flexWrap: 'wrap'}}>
                <div className="box-white-download-berkas" >
                    <div className="box-white-blue-download-berkas" >
                        <img src={DownloadIcon} alt="icon" className="icon-download" />
                        <span className="box-white-blue-download-berkas-text-dwd">
                            Daftar Sekolah 2020
                        </span>
                    </div>
                    <DownloadButton onClick={()=> download('/public/document/Daftar%20Sekolah%202020.xlsx', 'Daftar Sekolah 2020.xlsx')}>Download</DownloadButton>
                </div>
                <div className="box-white-download-berkas" >
                    <div className="box-white-blue-download-berkas" >
                        <img src={DownloadIcon} alt="icon" className="icon-download" />
                        <span className="box-white-blue-download-berkas-text-dwd">
                            Daftar Sekolah 2021
                        </span>
                    </div>
                    <DownloadButton onClick={()=> download('/public/document/Daftar%20Sekolah%202021.xlsx', 'Daftar Sekolah 2021.xlsx')}>Download</DownloadButton>
                </div>
                <div className="box-white-download-berkas" >
                    <div className="box-white-blue-download-berkas" >
                        <img src={DownloadIcon} alt="icon" className="icon-download" />
                        <span className="box-white-blue-download-berkas-text-dwd">
                            Daftar Sekolah 2022
                        </span>
                    </div>
                    <DownloadButton onClick={()=> download('/public/document/Daftar%20Sekolah%202022.xlsx', 'Daftar Sekolah 2022.xlsx')}>Download</DownloadButton>
                </div>
            </div>
        </div>
    )
}

export default AdminChapter