import React, { useEffect, useState } from 'react'
import './styles.scss'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';
import { Line, Bar, Pie } from 'react-chartjs-2';
import Breadcrumb from '../../../components/Breadcrumb';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuItem from '@material-ui/core/MenuItem';
import {
    getTotalPesertaProgramRowSchoolBar
} from '../../../store/actions/dashboard'
import { GET_DASHBOARD_ROW_SCHOOL_BAR } from '../../../store/constanta';

const CountButton = withStyles((theme) => ({
    root: {
        marginTop: -10,
        height: '40px',
        color: '#3598DC',
        backgroundColor: 'rgba(53, 152, 220, 0.1)',
        borderRadius: '1000px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'space-between',
        padding: '0 20px'
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    tableHeader: {
        background: '#FAFAFA',
        borderRadius: '2px 2px 0px 0px',
    },
    tableHeaderFont: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#888888',
    },
    tableMain: {
        boxShadow: "none",
        border: '0.5px solid #D1D1D1',
        borderRadius: '2px',
        width: '100%'
    },
    pagination: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#999999',
        paddingLeft: 0,
        marginTop: 10
    },
    paginationRight: {
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '60%'
    },
    formControl: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 15,
            width: '200px',
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            zIndex: 99
        },
    },
    formControlNormaly: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root': {
            // paddingTop: 0,
            // paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-input':{
            padding: '10px 14px 10px 0px'
        },
        '& .MuiSelect-select':{
            '&:focus': {
                backgroundColor: 'white',
            },
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 15,
            // width: '200px',
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                // background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            zIndex: 99
        },
    },
    formControlNormal: {
        '& label.Mui-focused': {
            color: '#444444',
        },
        '& label': {
            color: '#444444',
        },
        '&:hover label': {
            color: 'white',
        },
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '92px',
            height: '32px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                color: '#444444',
                background: 'white',
            },
            '&:hover fieldset': {
                border: 'none',
                background: '#3598DC',
            },
        },
        '& .MuiSelect-iconOutlined': {
            color: '#888888',
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            color: 'white',
            zIndex: 99
        },
    },
    formControlRed: {
        '& label.Mui-focused': {
            color: '#FF4040',
        },
        '& label': {
            color: '#FF4040',
        },
        '&:hover label': {
            color: 'white',
        },
        '& .MuiSelect-root': {
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '92px',
            height: '32px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                color: '#FF4040',
                background: 'white',
            },
            '&:hover fieldset': {
                border: 'none',
                background: '#3598DC',
            },
        },
        '& .MuiSelect-iconOutlined': {
            color: '#FF4040',
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            color: 'white',
            zIndex: 99
        },
    },
    formControlNormalyCustom: {
        '& label.Mui-focused': {
            color: '#999999',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiSelect-root': {
            // paddingTop: 0,
            // paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-input':{
            padding: '10px 14px 10px 0px'
        },
        '& .MuiSelect-select':{
            '&:focus': {
                backgroundColor: 'white',
            },
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            height: '44px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '0.5px solid #D1D1D1',
                // background: 'white',
            },
            '&:hover fieldset': {
                border: '0.5px solid #D1D1D1',
            },
        },
        '& .MuiSelect-iconOutlined': {
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined': {
            zIndex: 99
        },
    },
}));


function Dashboard() {
    const classes = useStyles();
    const Dispatch = useDispatch();
    const profile = useSelector(state => state.userState.profile)
    const chapter = useSelector(state => state.chapterState.chapter)
    const totalPeserta = useSelector(state => state.dashboardState.totalPeserta)
    const row1Data = useSelector(state => state.dashboardState.row1)
    const row2Data = useSelector(state => state.dashboardState.row2)
    const row1PinData = useSelector(state => state.dashboardState.row1Pin)
    const row3Line = useSelector(state => state.dashboardState.row3Line)
    const row3Pie = useSelector(state => state.dashboardState.row3Pie)
    const row3Bar = useSelector(state => state.dashboardState.row3Bar)
    const rowSchoolBar = useSelector(state => state.dashboardState.rowSchoolBar)
    const schools = useSelector(state=>state.filterState.sekolah);
    const [program, setProgram] = useState(null)
    const [dataFilter, setDataFilter] = useState({
        chapter: '',
        school: ''
    })
    const [dataLineChart, setDataLineChart] = useState({
        labels: ['SMK', 'SMA', 'MA', 'Pesantren'],
        datasets: [
            {
                label: 'Pendaftar',
                data: [0,0,0,0],
                pointHoverBorderWidth: 0.3,
                pointRadius: 5,
                fill: true,
                lineTension: 0.2,
                backgroundColor: "rgba(0, 0, 0, 0.0)",
                borderColor: "#3c98dc",
                pointBorderColor: "#3c98dc",
                pointBackgroundColor: "#3c98dc",
                pointHoverBorderColor: "white",
            },
        ],
    })
    const [dataBarChart, setDataBarChart] = useState({
        labels: ['-' ,'-' ,'-' ,'-' ,'-'],
        datasets: [
            {
                label: 'Pendaftar',
                data: [0,0,0,0,0],
                backgroundColor: [
                    '#FC8800',
                    '#00D67D',
                    '#FF1F48',
                    '#6F00FC',
                    '#B007DA',
                ],
                borderWidth: 1,
            },
        ],
    })
    const [dataPieChart, setDataPieChart] = useState({
        labels: ['Perempuan', 'Laki-laki'],
        datasets: [
            {
                label: 'Pendaftar',
                data: [1, 5],
                backgroundColor: [
                    '#C900FC',
                    '#FC8800',
                ],
                borderWidth: 1,
            },
        ],
    })
    const [dataSchoolBarChart, setDataSchoolBarChart] = useState({
        labels: ['-' ,'-' ,'-' ,'-' ,'-'],
        datasets: [
            {
                label: 'Pendaftar',
                data: [0,0,0,0,0],
                backgroundColor: [
                    '#FC8800',
                    '#00D67D',
                    '#FF1F48',
                    '#6F00FC',
                    '#B007DA',
                ],
                borderWidth: 1,
            },
        ],
    })

    useEffect(()=>{
        let options = JSON.parse(localStorage.getItem('options'))
        setProgram(options?.program)
        if(dataFilter?.chapter){
            const query = `chapter_id=${dataFilter.chapter}&school_type=${dataFilter.school}`
            Dispatch(getTotalPesertaProgramRowSchoolBar(options?.program, query))
        }
    },[localStorage.getItem('options')])

    useEffect(()=>{
        if (row3Line) {
            setDataLineChart({
                labels: ['SMK', 'SMA', 'MA', 'Pesantren'],
                datasets: [
                    {
                        label: 'Pendaftar',
                        data: [row3Line.pesertaSMK, row3Line.pesertaSMA, row3Line.pesertaMA, row3Line.pesertaPesantren],
                        pointHoverBorderWidth: 0.3,
                        pointRadius: 5,
                        fill: true,
                        lineTension: 0.2,
                        backgroundColor: "rgba(0, 0, 0, 0.0)",
                        borderColor: "#3c98dc",
                        pointBorderColor: "#3c98dc",
                        pointBackgroundColor: "#3c98dc",
                        pointHoverBorderColor: "white",
                    },
                ]
            })
        }
    },[row3Line])

    useEffect(()=>{
        if (row3Pie) {
            setDataPieChart({
                labels: ['Perempuan', 'Laki-laki'],
                datasets: [
                    {
                        label: 'Pendaftar',
                        data: [row3Pie.pesertaPerempuan, row3Pie.pesertaLakiLaki],
                        backgroundColor: [
                            '#C900FC',
                            '#FC8800',
                        ],
                        borderWidth: 1,
                    },
                ],
            })
        }
    },[row3Pie])

    useEffect(()=>{
        if (row3Bar) {
            setDataBarChart({
                labels: (row3Bar || [])?.map(chapter=> getChapterNameBar(chapter?.chapter)),
                datasets: [
                    {
                        label: 'Pendaftar',
                        data:  (row3Bar || [])?.map(chapter=> getChapterCountBar(chapter?.total)),
                        backgroundColor: [
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                        ],
                        borderWidth: 1,
                    },
                ],
            })
        }
    },[row3Bar])

    useEffect(()=>{
        if (rowSchoolBar) {
            setDataSchoolBarChart({
                labels: (rowSchoolBar || [])?.map(x=> `${x?._id?.jenis} - ${x?._id?.sekolah}`),
                datasets: [
                    {
                        label: 'Pendaftar',
                        data:  (rowSchoolBar || [])?.map(x=> x.total),
                        backgroundColor: [
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                            '#FC8800',
                            '#00D67D',
                            '#FF1F48',
                            '#6F00FC',
                            '#B007DA',
                        ],
                        borderWidth: 1,
                    },
                ],
            })
        }
    },[rowSchoolBar])

    const getChapterCountBar = (value) => {
        let count = 0
        if (value) {
            count = value
        }
        return count
    }

    const getChapterNameBar = (value) => {
        let name = '-'
        if (value) {
            name = value
        }
        return name
    }

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const handleChangeFilter = (key, value) => {
        let data = {
            ...dataFilter,
            chapter: value,
        }
        switch (key) {
            case 'chapter':
                data = {
                    ...dataFilter,
                    chapter: value,
                }
                if (value === 'remove') {
                    Dispatch({
                        type: GET_DASHBOARD_ROW_SCHOOL_BAR,
                        data: []
                    })
                }
                setDataFilter(data)
                if(data?.chapter){
                    const query = `chapter_id=${data.chapter}&school_type=${data.school}`
                    Dispatch(getTotalPesertaProgramRowSchoolBar(program, query))
                }
                break;
            case 'school':
                data = {
                    ...dataFilter,
                    school: value,
                }
                if (value === 'remove') {
                    data.school = ''
                }
                setDataFilter(data)
                if(data?.chapter){
                    const query = `chapter_id=${data.chapter}&school_type=${data.school}`
                    Dispatch(getTotalPesertaProgramRowSchoolBar(program, query))
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="data-container-wrapper" >
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Dashboard
                </span>
                    <CountButton style={{ marginLeft: 'auto', textTransform: 'capitalize' }}>
                        { 'Total ' + numberWithCommas(totalPeserta) + ' Peserta Seluruh Program'+ (profile && profile.role === 'admin chapter' ? ` Chapter ${getChapterName(chapter, profile && profile.chapterId)}` : '') }
                    </CountButton>
                {
                }
            </div>
            <Breadcrumb
                links={[
                    {
                        label: 'Dashboard',
                        link: false
                    }
                ]}
            />

            {/* ROW1 */}
            <div className="dashboard-2021-wpapper-row-one" >
                {/* COL1 */}
                <div className={`${program === 'AFS' ? "dashboard-2021-whitebox-rowone" : "dashboard-2021-whitebox-rowone-non"}`} >
                    <div className="dashboard-2021-whitebox-header" >
                        JUMLAH PENDAFTAR PROGRAM { program }
                    </div>
                    <div className="dashboard-2021-whitebox-body" >
                        <div className="dashboard-2021-whitebox-body-counting-top" >
                            <span className="dashboard-2021-whitebox-body-counting-top-number" >
                                { row1Data.totalPesertaProgram }
                            </span>
                            <span className="dashboard-2021-whitebox-body-counting-top-title" >
                                Total Pendaftar
                            </span>
                        </div>
                        <div className="dashboard-2021-whitebox-body-grey" >
                            <div className="dashboard-2021-whitebox-body-grey-content" >
                                <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                    Sudah Finalisasi
                                </span>
                                <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                    { row1Data.totalPesertaFinalisasi }
                                </span>
                            </div>
                            <div className="dashboard-2021-whitebox-body-grey-content" style={{ borderBottom: '0px' }}>
                                <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                    Belum Finalisasi
                                </span>
                                <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                    { row1Data.totalPesertaBelumFinalisasi }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* COL2 */}
                {
                    program === 'AFS' &&
                    <div className="dashboard-2021-whitebox-rowone-col2 row-2-whitebox" >
                        <div className="dashboard-2021-whitebox-header" >
                            PIN PENDAFTARAN PROGRAM { program }
                        </div>
                        <div className="dashboard-2021-whitebox-body" >
                            <div className="dashboard-2021-whitebox-body-counting-top" >
                                <span className="dashboard-2021-whitebox-body-counting-top-number" >
                                    {row1PinData.totalPin}
                                </span>
                                <span className="dashboard-2021-whitebox-body-counting-top-title" >
                                    Total PIN Diterbitkan
                                </span>
                            </div>
                            <div className="dashboard-2021-whitebox-body-grey" >
                                <div className="dashboard-2021-whitebox-body-grey-content" >
                                    <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                        PIN Diaktivasi
                                    </span>
                                    <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                        {row1PinData.totalPinActive}
                                    </span>
                                </div>
                                <div className="dashboard-2021-whitebox-body-grey-content" style={{ borderBottom: '0px' }}>
                                    <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                        PIN Kadaluarsa
                                    </span>
                                    <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                        {row1PinData.totalPinDeactive}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* COL3 */}
                <div className={`${program === 'AFS' ? 'dashboard-2021-whitebox-rowone-col3 row-3-whitebox' : 'dashboard-2021-whitebox-rowone-col3-non row-3-whitebox-non'}`} >
                    <div className="dashboard-2021-whitebox-header" >
                        PENDAFTAR { program } BERDASARKAN STATUS
                    </div>
                    <div className="dashboard-2021-whitebox-body" >
                        <div className="dashboard-2021-whitebox-body-counting-top" >
                            <span className="dashboard-2021-whitebox-body-counting-top-number" >
                            { row1Data.totalPesertaFinalisasi }
                            </span>
                            <span className="dashboard-2021-whitebox-body-counting-top-title" >
                                Total Sudah Finalisasi
                            </span>
                        </div>
                        <div className="dashboard-2021-whitebox-body-grey" >
                            <div className="dashboard-2021-whitebox-body-grey-content" >
                                <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                    Sudah Upload Seluruh Berkas
                                </span>
                                <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                { row1Data.totalPesertaUploadSemuaBerkas }
                                </span>
                            </div>
                            <div className="dashboard-2021-whitebox-body-grey-content" style={{ borderBottom: '0px' }}>
                                <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                    Belum Upload Seluruh Berkas
                                </span>
                                <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                { row1Data.totalPesertaBelumUploadSemuaBerkas }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* COL2 */}
            {
                program === 'AFS' &&
                <div className='dashboard-2021-whitebox-rowone-col2 row-2-whitebox-standin' >
                    <div className="dashboard-2021-whitebox-header" >
                        PIN PENDAFTARAN PROGRAM { program }
                    </div>
                    <div className="dashboard-2021-whitebox-body" >
                        <div className="dashboard-2021-whitebox-body-counting-top" >
                            <span className="dashboard-2021-whitebox-body-counting-top-number" >
                                {row1PinData.totalPin}
                            </span>
                            <span className="dashboard-2021-whitebox-body-counting-top-title" >
                                Total PIN Diterbitkan
                            </span>
                        </div>
                        <div className="dashboard-2021-whitebox-body-grey" >
                            <div className="dashboard-2021-whitebox-body-grey-content" >
                                <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                    PIN Diaktivasi
                                </span>
                                <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                    {row1PinData.totalPinActive}
                                </span>
                            </div>
                            <div className="dashboard-2021-whitebox-body-grey-content" style={{ borderBottom: '0px' }}>
                                <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                    PIN Kadaluarsa
                                </span>
                                <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                    {row1PinData.totalPinDeactive}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* COL3 */}
            <div className={`${program === 'AFS' ? 'dashboard-2021-whitebox-rowone-col3 row-3-whitebox-standin' : 'dashboard-2021-whitebox-rowone-col3-non row-3-whitebox-standin-non'}`}>
                <div className="dashboard-2021-whitebox-header" >
                    PENDAFTAR { program } BERDASARKAN STATUS
                </div>
                <div className="dashboard-2021-whitebox-body" >
                    <div className="dashboard-2021-whitebox-body-counting-top" >
                        <span className="dashboard-2021-whitebox-body-counting-top-number" >
                            { row1Data.totalPesertaFinalisasi }
                        </span>
                        <span className="dashboard-2021-whitebox-body-counting-top-title" >
                            Total Sudah Finalisasi
                        </span>
                    </div>
                    <div className="dashboard-2021-whitebox-body-grey" >
                        <div className="dashboard-2021-whitebox-body-grey-content" >
                            <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                Sudah Upload Seluruh Berkas
                            </span>
                            <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                { row1Data.totalPesertaUploadSemuaBerkas }
                            </span>
                        </div>
                        <div className="dashboard-2021-whitebox-body-grey-content" style={{ borderBottom: '0px' }}>
                            <span className="dashboard-2021-whitebox-body-grey-content-right" >
                                Belum Upload Seluruh Berkas
                            </span>
                            <span className="dashboard-2021-whitebox-body-grey-content-left" >
                                { row1Data.totalPesertaBelumUploadSemuaBerkas }
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* ROW2 */}
            <div className="dashboard-2021-wpapper-row-two" >
                <div className="dashboard-2021-whitebox-header" >
                    JUMLAH KELULUSAN PENDAFTAR
                </div>
                <div className="dashboard-2021-whitebox-header-row2-body" >
                    {/* #1 */}
                    <div className="dashboard-2021-colorbox-row2-1" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusVerifikasiBerkas}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Verifikasi Berkas
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusVerifikasiBerkas}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Tidak Lulus Verifikasi Berkas
                            </span>
                        </div>
                    </div>
                    {/* #2 */}
                    <div className="dashboard-2021-colorbox-row2-2 row-2-colorbox" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusSeleksiBerkas}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Seleksi Tahap Pertama
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusSeleksiBerkas}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Tidak Lulus Seleksi Tahap Pertama
                            </span>
                        </div>
                    </div>
                    {/* #3 */}
                    <div className="dashboard-2021-colorbox-row2-3 row-3-colorbox" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusSeleksiWawancara}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Seleksi Tahap Kedua
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusSeleksiWawancara}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Tidak Lulus Seleksi Tahap Kedua
                            </span>
                        </div>
                    </div>
                    {/* #4 */}
                    <div className="dashboard-2021-colorbox-row2-4 row-4-colorbox" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusSeleksiInteraksiKelompok}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Seleksi Tahap Ketiga
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusSeleksiInteraksiKelompok}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                            Tidak Lulus Seleksi Tahap Ketiga
                            </span>
                        </div>
                    </div>
                </div>
                <div className="dashboard-2021-whitebox-header-row2-body-standin" >
                    {/* #2 */}
                    <div className="dashboard-2021-colorbox-row2-2 row-2-colorbox-standin" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusSeleksiBerkas}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Seleksi Tahap Pertama
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusSeleksiBerkas}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                            Tidak Lulus Seleksi Tahap Pertama
                            </span>
                        </div>
                    </div>
                    {/* #3 */}
                    <div className="dashboard-2021-colorbox-row2-3 row-3-colorbox-standin" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusSeleksiWawancara}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Seleksi Tahap Kedua
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusSeleksiWawancara}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                            Tidak Lulus Seleksi Tahap Kedua
                            </span>
                        </div>
                    </div>
                    {/* #4 */}
                    <div className="dashboard-2021-colorbox-row2-4 row-4-colorbox-standin" >
                        <div className="dashboard-2021-colorbox-content1-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaLulusSeleksiInteraksiKelompok}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                                Lulus Seleksi Tahap Ketiga
                            </span>
                        </div>
                        <div className="dashboard-2021-colorbox-content-row2-1" >
                            <span className="dashboard-2021-colorbox-row2-1-number" >
                                {row2Data.totalPesertaTidakLulusSeleksiInteraksiKelompok}
                            </span>
                            <span className="dashboard-2021-colorbox-row2-1-title" >
                            Tidak Lulus Seleksi Tahap Ketiga
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* ROW3 */}
            <div className="dashboard-2021-wpapper-row-two" >
                <div className="dashboard-2021-whitebox-header" >
                    <span> JUMLAH PENDAFTAR { program } BERDASARKAN CHAPTER </span>
                </div>
                <div className="dashboard-2021-whitebox-header-row3-chart" >
                    <Bar data={dataBarChart} options={{
                        plugins:{   
                            legend: {
                                display: false
                            },
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                            }
                        }
                    }} />
                </div>
            </div>

            {/* ROW4 */}
            <div className="dashboard-2021-wpapper-row-three" >
                {/* COL1 */}
                <div className="dashboard-2021-whitebox-rowthree-1">
                    <div className="dashboard-2021-whitebox-header" >
                        <span>
                            jumlah pendaftar { program } berdasarkan Jenis sekolah
                        </span>
                    </div>
                    <div className="dashboard-2021-whitebox-body" >
                        <Line data={dataLineChart} options={{
                            plugins:{   
                                legend: {
                                    display: false
                                },
                            },
                            responsive: true,
                            scales: {
                                y: {
                                    beginAtZero: true,
                                }
                            }
                            
                        }} />
                    </div>
                </div>
                {/* COL2 */}
                <div className="dashboard-2021-whitebox-rowthree-3 row-3-whitebox-chart" >
                    <div className="dashboard-2021-whitebox-header" >
                        jumlah pendaftar { program } Berdasarkan Jenis Kelamin
                    </div>
                    <div className="dashboard-2021-whitebox-body" >
                        <div className="dashboard-2021-whitebox-pie-chart-wrap" >
                            <div className="dashboard-2021-whitebox-pie-chart">
                                <Pie data={dataPieChart} options={{
                                    plugins:{
                                        legend: {
                                            display: false
                                        },
                                    },
                                    responsive: true,
                                }} />
                            </div>
                            <div className="dashboard-2021-whitebox-pie-chart-info" >
                                <div className="dashboard-2021-whitebox-pie-chart-info-bluebox" >
                                { row3Pie.pesertaPerempuan + row3Pie.pesertaLakiLaki } Pendaftar
                                </div>
                                <div className="dashboard-2021-whitebox-pie-chart-info-radio">
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        style={{
                                            color: '#C900FC',
                                            marginRight: 5
                                        }}
                                    />
                                    Perempuan = { row3Pie.pesertaPerempuan }
                                </div>
                                <div className="dashboard-2021-whitebox-pie-chart-info-radio"
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        style={{
                                            color: '#FC8800',
                                            marginRight: 5
                                        }}
                                    />
                                    Laki-laki = { row3Pie.pesertaLakiLaki }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dashboard-2021-wpapper-row-three" >
                {/* COL3 */}
                <div className="dashboard-2021-whitebox-rowthree-3 row-3-whitebox-chart-standin">
                    <div className="dashboard-2021-whitebox-header" >
                        jumlah pendaftar { program } Berdasarkan Jenis Kelamin
                    </div>
                    <div className="dashboard-2021-whitebox-body" >
                        <div className="dashboard-2021-whitebox-pie-chart-wrap" >
                            <div className="dashboard-2021-whitebox-pie-chart">
                                <Pie data={dataPieChart} options={{
                                    plugins:{
                                        legend: {
                                            display: false,
                                            maintainAspectRatio: false,
                                        },
                                    },
                                    responsive: true,
                                }} />
                            </div>
                            <div className="dashboard-2021-whitebox-pie-chart-info" >
                                <div className="dashboard-2021-whitebox-pie-chart-info-bluebox" >
                                    {row3Pie.pesertaPerempuan + row3Pie.pesertaLakiLaki} Pendaftar
                                </div>
                                <div className="dashboard-2021-whitebox-pie-chart-info-radio">
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        style={{
                                            color: '#C900FC',
                                            marginRight: 5
                                        }}
                                    />
                                    Perempuan = { row3Pie.pesertaPerempuan }
                                </div>
                                <div className="dashboard-2021-whitebox-pie-chart-info-radio"
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        style={{
                                            color: '#FC8800',
                                            marginRight: 5
                                        }}
                                    />
                                    Laki-laki = { row3Pie.pesertaLakiLaki }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* COL2 */}
                <div className="dashboard-2021-whitebox-rowthree-2 row-2-whitebox-chart-standin-shadows" style={{ opacity: 0 }} />
            </div>

            {/* ROW3 */}
            <div className="dashboard-2021-wpapper-row-two">
                <div className="dashboard-2021-whitebox-header" >
                    <span> JUMLAH PENDAFTAR { program } BERDASARKAN SEKOLAH </span>
                </div>
                <div className="dashboard-2021-whitebox-header" style={{border: '0px', columnGap: 10}}>
                    <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-004 filter-004-display`} style={{ textTransform: 'capitalize' }} >
                        <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                            {
                                profile?.role === 'admin chapter' ?
                                    getChapterName(chapter, profile?.chapterId)
                                    :
                                    !dataFilter?.chapter && 'Filter Chapter'
                            }
                        </InputLabel>
                        <Select
                            native={false}
                            value={dataFilter?.chapter}
                            onChange={(e) => handleChangeFilter('chapter', e.target.value)}
                            className={classes.formControlNormaly}
                            style={{ marginLeft: 0, outline: 'none' }}
                            IconComponent={FilterListIcon}
                            inputProps={{
                                style: {
                                    backgroundColor: 'white',
                                }
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                PaperProps: {
                                    style: {
                                        maxHeight: 400,
                                    },
                                },
                                getContentAnchorEl: null
                            }}
                            disabled={profile?.role === 'admin chapter' ? true : false}
                        >
                            <MenuItem className={!dataFilter.chapter ? "value-selectable-active" : "value-selectable"} value="remove"> Pilih Chapter</MenuItem>
                            {
                                chapter?.sort((a,b) =>  a.chapter < b.chapter ? -1 : a.chapter > b.chapter ? 1 : 0)
                                .map((chapter, i) => (
                                    <MenuItem key={i} className={dataFilter?.chapter === chapter?._id ? "value-selectable-active" : "value-selectable"} value={chapter._id} style={{ textTransform: 'capitalize' }}> {chapter.chapter} </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-004 filter-004-display`} style={{ textTransform: 'capitalize' }} >
                        <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                            {
                                dataFilter?.school ? "" : 'Filter Jenis Sekolah'
                            }
                        </InputLabel>
                        <Select
                            native={false}
                            value={dataFilter?.school}
                            onChange={(e) => handleChangeFilter('school', e.target.value)}
                            className={classes.formControlNormaly}
                            style={{ marginLeft: 0, outline: 'none'}}
                            IconComponent={FilterListIcon}
                            inputProps={{
                                style: {
                                    backgroundColor: 'white'
                                }
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                PaperProps: {
                                    style: {
                                        maxHeight: 400,
                                    },
                                },
                                getContentAnchorEl: null
                            }}
                        >
                            <MenuItem className={!dataFilter?.school ? "value-selectable-active" : "value-selectable"} value="remove"> Pilih Jenis Sekolah</MenuItem>
                            {
                                schools?.map((school, i) => (
                                    <MenuItem
                                        key={i}
                                        className={dataFilter?.school === school?.value ? "value-selectable-active" : "value-selectable"} value={school.value}
                                        style={{ textTransform: 'capitalize'}}
                                    >
                                        {school.type}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className="dashboard-2021-whitebox-header-row3-chart-y"
                    style={{
                        height: rowSchoolBar?.length ?
                            `${
                                (
                                    rowSchoolBar?.length <= 10 ? 5 :
                                    rowSchoolBar?.length <= 100 ? 50 :
                                    rowSchoolBar?.length <= 200 ? 100 :
                                    rowSchoolBar?.length <= 300 ? 150 :
                                    rowSchoolBar?.length <= 400 ? 200 :
                                    rowSchoolBar?.length <= 500 ? 250 :
                                    rowSchoolBar?.length <= 600 ? 300 :
                                    rowSchoolBar?.length <= 700 ? 350 :
                                    rowSchoolBar?.length <= 800 ? 400 :
                                    rowSchoolBar?.length <= 900 ? 450 :
                                    rowSchoolBar?.length <= 1000 ? 500 :
                                    rowSchoolBar?.length <= 1100 ? 550 :
                                    rowSchoolBar?.length <= 1200 ? 600 :
                                    rowSchoolBar?.length <= 1300 ? 650 :
                                    rowSchoolBar?.length <= 1400 ? 700 :
                                    rowSchoolBar?.length <= 1500 ? 750 :
                                    rowSchoolBar?.length <= 1600 ? 800 :
                                    rowSchoolBar?.length <= 1700 ? 850 :
                                    rowSchoolBar?.length <= 1800 ? 900 :
                                    rowSchoolBar?.length <= 1900 ? 950 :
                                    rowSchoolBar?.length <= 2000 ? 1000 :
                                    100
                                ) * 50
                            }px` : '200px'
                    }}
                >
                    <Bar data={dataSchoolBarChart} options={{
                        plugins:{   
                            legend: {
                                display: false
                            },
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                            }
                        },
                        indexAxis: 'y',
                    }} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard