import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../../../images/binabudayaCirclePIN.png";
import Swal from "sweetalert2";
import { getPinPDF } from "../../../../store/actions/pin";
import { capitalize } from "@material-ui/core";

function Print() {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const History = useHistory();
  const Dispatch = useDispatch();
  const chapter = useSelector((state) => state.chapterState.chapter);
  const [pinList, setPinList] = useState([]);

  const getChapterCode = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter((a) => a._id === chapter)[0];
      return temp?.code || "-";
    }
  };

  const showDateTerbitPIN = (dateParams) => {
    let date = new Date(dateParams).getDate();
    let monthNumber = new Date(dateParams).getMonth() + 1;
    let year = new Date(dateParams).getFullYear();
    if (date < 10) {
      return `${year}${monthNumber < 10 ? "0" + monthNumber : monthNumber}${
        "0" + date
      }`;
    } else {
      return `${year}${
        monthNumber < 10 ? "0" + monthNumber : monthNumber
      }${date}`;
    }
  };

  useEffect(() => {
    if (!token) History.replace("/");
    if (id) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });
      Swal.showLoading();
      Dispatch(
        getPinPDF(id, ({ status, data, error }) => {
          if (status === 200) {
            Swal.close();
            setPinList(data);
            setTimeout(() => {
              const PIN = data?.[0];
              document.title = `${showDateTerbitPIN(PIN?.terbit)}-PIN-${
                PIN?.chapterId?.code
              }`;
              window.print();
              window.close();
            }, 1000);
          } else if (status === 400) {
            Toast.fire({
              icon: "error",
              title: error,
            });
            setTimeout(() => {
              window.close();
            }, 500);
          }
        })
      );
    } else {
      window.close()
    }
  }, [id, chapter]);

  const showDate = (dateParams) => {
    let date = new Date(dateParams).getDate();
    let monthNumber = new Date(dateParams).getMonth();
    let month = "";
    let year = new Date(dateParams).getFullYear();
    switch (monthNumber) {
      case 0:
        month = "Januari";
        break;
      case 1:
        month = "Februari";
        break;
      case 2:
        month = "Maret";
        break;
      case 3:
        month = "April";
        break;
      case 4:
        month = "Mei";
        break;
      case 5:
        month = "Juni";
        break;
      case 6:
        month = "Juli";
        break;
      case 7:
        month = "Agustus";
        break;
      case 8:
        month = "September";
        break;
      case 9:
        month = "Oktober";
        break;
      case 10:
        month = "November";
        break;
      case 11:
        month = "Desember";
        break;
      default:
        month = "hehe";
        break;
    }
    if (date < 10) {
      return "0" + date + " " + month + " " + year;
    } else {
      return date + " " + month + " " + year;
    }
  };

  const showDay = (date) => {
    let hari = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jum'at",
      "Sabtu",
    ];
    return hari[new Date(date).getDay()];
  };

  const getChapterName = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter((a) => a._id === chapter._id)[0];
      return temp ? capitalize(temp.chapter) : "-";
    }
  };

  return (
    <div className="container-print-pin-087913">
      {pinList?.map((pin, i) => (
        <div className="pin-card-0739173912" key={i}>
          <div className="pin-card-header">
            PIN Pendaftaran Seleksi Bina Antarbudaya Tahun 2025-2026
          </div>
          <div className="pin-content-381098301">
            <div className="pin-left-content-309197391">
              <span className="pin-style">{pin.pin}</span>
              <span className="exp-pin-grey" style={{ marginTop: 7 }}>
                Berlaku Sampai:
              </span>
              <span className="exp-pin-black" style={{ marginTop: 1 }}>
                {showDay(pin.expired)}, {showDate(pin.expired)}, Pukul 23:59:59
                WIB
              </span>
              <div className="footer-pin-wraper-309183019">
                https://seleksibinaantarbudaya.or.id/
              </div>
            </div>
            <div className="pin-right-content-31203123">
              <img
                src={Logo}
                alt="logo"
                className="logo-binabud-cirle-131312"
              />
              <span className="pin-chapter-name-313131">
                Chapter<br/>{getChapterName(chapter, pin.chapterId)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Print;
