import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'

function Breadcrumb(props) {
    const {
        links = []
    } = props
    const History = useHistory()

    return (
        <div className="bread-cab-container">
            {
                links?.map((bread, index)=>(
                    <div>
                        <span
                            className={bread?.link ? "bread-blue" : "bread-grey"}
                            style={{ marginTop: -7 }}
                            key={index}
                            onClick={_=>{
                                History.push(bread?.link)
                            }}
                        >
                            { bread?.label }
                        </span>
                        <span
                            className={bread?.link ? "bread-blue" : "bread-grey"}
                            style={{
                                textDecoration: "none",
                                cursor: 'default'
                            }}
                        >
                            /
                        </span>
                    </div>
                ))
            }
        </div>
    )
}

export default memo(Breadcrumb)