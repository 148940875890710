import React,{ StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3598DC",
      light: '#5dace3',
      dark: '#256a9a',
      contrastText: '#fff',
    },
    secondary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#b2b2b2",
      contrastText: "#1769aa"
    },
  },
  overrides: { 
    MuiPickersToolbar: {
      toolbar: {
        zIndex: 99999,
        backgroundColor: "#3c98dc",
      },
    },
    MuiPickersDay: {
      day: {
      },
      daySelected: {
        backgroundColor: "#3c98dc",
        '&:hover':{
          backgroundColor: "#3c98dc",
        }
      },
      dayDisabled: {
        color: "#e5e5e5",
      },
      current: {
        color: "#3c98dc",
      },
    },
  },  
});

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </Router>
      </ThemeProvider>
    </StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();