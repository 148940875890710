import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import CheckIcn from '../../images/modal/check-icn.svg'
import './styles.scss'
import { useHistory } from 'react-router-dom';

export default function ModalSuccesNilai(props) {
    const history = useHistory()

    function handleBack () {
        props.handleClose()
        history.goBack()
    }
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
            >
                <div className='con-mo-nilai'>
                    <img src={CheckIcn} alt='info' style={{ width: 80, height: 80 }} />
                    <div className='txt-modal-nilai'>
                    Berhasil Input Nilai Seleksi {props.namaSeleksi} untuk {props.nama}
                    </div>
                    <div className='cek-nilai'>Silahkan lihat dan cek nilai di Tabulasi</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='btn-modal-wawancara btn-blue' style={{width: 197, paddingLeft: 0, paddingRight: 0}} onClick={handleBack}>Ya, Kembali ke Tabulasi</div>
                    </div>
                </div>

            </Dialog>
        </>
    )
}
