import React, { useState } from 'react'
import './styles.scss'
import Swal from 'sweetalert2'
import BinabudayaLogo from '../../images/binabudaya.svg'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { loginUser } from '../../store/actions/user'
import AppBar from '@material-ui/core/AppBar';

const LoginButton = withStyles((theme) => ({
    root: {
        height: '50px',
        color: '#FFFFFF',
        backgroundColor: '#3598DC',
        fontFamily: ['Roboto', 'sans-serif'],
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04)',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#2f88c6',
        },
    },
}))(Button);

const GreenCheckbox = withStyles({
    root: {
        color: '#D1D1D1',
        '&$checked': {
            color: '#3598DC',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
    label:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#666666',
    }
});

function Login() {
    const classes = useStyles();
    const Dispatch = useDispatch()
    const History = useHistory()
    const [showPassword, setShowPassword] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)
    const [checked, setChecked] = useState(false)
    const [click, setClick] = useState(false)
    const [sendData, setSendData] = useState({
        email: '',
        password: ''
    })

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleChange = (key, value) => {
        switch (key) {
            case 'email':
                setSendData({
                    ...sendData,
                    email: value
                })
                break;
            case 'password':
                setSendData({
                    ...sendData,
                    password: value
                })
                break;
            default:
                break;
        }
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleChangeChecked = (event) => {
        setChecked(event.target.checked);
    };

    const SignIn = (e) => {
        if (e) e.preventDefault()
        setClick(true)

        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        
        if (sendData.email && sendData.password) {
            if (validateEmail(sendData.email)) {
                Dispatch(loginUser(History, sendData, Swal, checked))
            } else {
                ToastMid.fire({
                    icon: 'warning',
                    title: `Isi email dengan benar`,
                })
                setPasswordValid(true)
            }
        } else {
            ToastMid.fire({
                icon: 'warning',
                title: `Isi email dan password`,
            })
        }
    }

    return (
        <div className="login-container-wrapper">
            <AppBar
                position="fixed"
                style={{
                    zIndex: 998,
                    padding: '10px 24px',
                    background: 'linear-gradient(90deg, #005c97 0%,#363795 100% )',
                    minHeight: '0px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold'
                    }}
                >
                    Gunakan hanya browser Google Chrome atau Firefox untuk mengakses Online Registration ini
                </div>
            </AppBar>
            <div className="login-left-wrapper" >
                <div className="transparent-patern" >
                    <div className="text-login-right-wripper" >
                        <div className="text-right-top" >
                            Selamat Datang
                        </div>
                        <div className="text-login-quotes">
                            “Menciptakan Pemimpin Masa Depan!”
                        </div>
                    </div>
                </div>
            </div>
            <div className="login-right-wrapper" >
                <form>
                    <img src={BinabudayaLogo} className="image-logo-login" alt="logo"/>
                    <div className="login-text-top-wrapper" style={{ marginBottom: '15px' }} >
                        <span>
                            Seleksi Nasional Bina Antarbudaya
                        </span>
                        <span style={{ marginTop: 5 }} >
                            Tahun 2025-2026
                        </span>
                    </div>
                    <span className="login-text-middle-font">
                        Silahkan Login Untuk Melanjutkan
                    </span>
                    <div className="content-card-wripper-login" >
                        <span className="login-font-title" >Email</span>
                        <input
                            type="text"
                            placeholder="Email"
                            onChange={e=>handleChange('email',e.target.value)}
                            className="activation-input-theme"
                            style={{ border: (click && (!sendData.email || passwordValid)) && '1px solid red' }}
                        />
                        <span className="login-font-title" >Password</span>
                        <div className="password-wraper" >
                            <input
                                type={!showPassword ? 'password' : 'text'}
                                onChange={e=>handleChange('password',e.target.value)}
                                placeholder="Password"
                                className="activation-input-theme"
                                style={{ border: (click && (!sendData.password || passwordValid)) && '1px solid red' }}
                            />
                            {
                                showPassword ?
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-login"
                                    style={{ color: '#3c98dc' }}
                                    onClick={() => handleShowPassword()}
                                />
                                    :
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="eye-login"
                                    onClick={() => handleShowPassword()}
                                />
                            }
                        </div>
                        <div className="login-bottom-container-wrapper" >
                            <FormControlLabel
                                className={classes.label}
                                value="ingat"
                                onChange={handleChangeChecked}
                                control={<GreenCheckbox />}
                                label="Ingat Saya"
                                labelPlacement="end"
                            />
                            {/* <span className="lupa-password-font blue-color">
                                Lupa Password?
                            </span> */}
                        </div>
                    </div>
                    <LoginButton type="submit" className="btn-989312312" onClick={(e)=> SignIn(e)}> Masuk </LoginButton>
                </form>
            </div>
        </div>
    )
}

export default Login