import axios from "axios";
import server from "../../endpoint";
import { generateToken } from "../../helpers/bearer";
import {
  GET_TOTAL_PESERTA,
  GET_DASHBOARD_ROW1,
  GET_DASHBOARD_ROW2,
  GET_DASHBOARD_ROW1PIN,
  GET_DASHBOARD_ROW3_LINE,
  GET_DASHBOARD_ROW3_PIE,
  GET_DASHBOARD_ROW3_BAR,
  GET_DASHBOARD_ROW_SCHOOL_BAR,
} from "../constanta";

export const getTotalPeserta = () => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    if (token) {
      const { data } = await axios({
        method: "get",
        url: `${server}/dashboard/total-peserta`,
        headers: {
          token,
          "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
        },
      });

      dispatch({
        type: GET_TOTAL_PESERTA,
        data,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTotalPesertaProgramRow1 =
  (program, query) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const { data } = await axios({
          method: "get",
          url: `${server}/dashboard/row1/${program}/?${query}`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        dispatch({
          type: GET_DASHBOARD_ROW1,
          data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getTotalPesertaPinRow1 = (query) => async (dispatch) => {
  try {
    let token = localStorage.getItem("token");
    if (token) {
      const { data } = await axios({
        method: "get",
        url: `${server}/dashboard/row1-pin/?${query}`,
        headers: {
          token,
          "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
        },
      });
      dispatch({
        type: GET_DASHBOARD_ROW1PIN,
        data,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTotalPesertaProgramRow2 =
  (program, query) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const { data } = await axios({
          method: "get",
          url: `${server}/dashboard/row2/${program}/?${query}`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        dispatch({
          type: GET_DASHBOARD_ROW2,
          data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getTotalPesertaProgramRow3Line =
  (program, query) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const { data } = await axios({
          method: "get",
          url: `${server}/dashboard/row3-line/${program}/?${query}`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        dispatch({
          type: GET_DASHBOARD_ROW3_LINE,
          data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getTotalPesertaProgramRow3Pie =
  (program, query) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const { data } = await axios({
          method: "get",
          url: `${server}/dashboard/row3-pie/${program}/?${query}`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        dispatch({
          type: GET_DASHBOARD_ROW3_PIE,
          data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getTotalPesertaProgramRow3Bar =
  (program, query) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const { data } = await axios({
          method: "get",
          url: `${server}/dashboard/row3-bar/${program}/?${query}`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        dispatch({
          type: GET_DASHBOARD_ROW3_BAR,
          data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getTotalPesertaProgramRowSchoolBar =
  (program, query) => async (dispatch) => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const { data } = await axios({
          method: "get",
          url: `${server}/dashboard/row-school-bar/${program}/?${query}`,
          headers: {
            token,
            "accept-bearer-token": generateToken(process.env.REACT_APP_BEARER),
          },
        });
        dispatch({
          type: GET_DASHBOARD_ROW_SCHOOL_BAR,
          data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
