import React,{ Fragment }  from 'react';
import Backdrop from './backdrop';
import './styles.scss'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'

const UseStyles = makeStyles((theme) => ({
    button:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#FFFFFF",
        background: "#3c98dc",
        borderRadius: "8px",
        boxShadow: 'none',
        textTransform: 'capitalize',
        boxSizing: "border-box",
        width: '104px',
        height: 38,
        "&:hover":{
            boxShadow: 'none',
            background: "#3c98dc",
        }
    },
    buttonCancel:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#333333",
        textTransform: 'capitalize',
        background: "white",
        border: "1px solid #BFBFBF",
        boxSizing: "border-box",
        borderRadius: "8px",
        boxShadow: 'none',
        width: '104px',
        marginLeft: 15,
        height: 38,
        "&:hover":{
            boxShadow: 'none',
        }
    }
}))

const modal = props => {
    const classes = UseStyles();
    let {
        admin,
        passwordHandler,
        passwordHandlerRepeat,
        password,
        setPassword,
        showPassword,
        passwordRepeat,
        showPasswordRepeat,
        setPasswordRepeat,
        error,
        click,
        setError
    } = props
    return (
        <Fragment>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div
                className="ModalAlert"
                style={{
                    display : props.show ? 'block' : 'none',
                    opacity : props.show ? 1 : 0,
                    zIndex: 99999999
                }}
            >
                <div className="modal-contain-alert" >
                    <span className="modal-alert-txt" >
                        Ganti Password
                    </span>
                    <span className="modal-alert-txt" style={{ fontSize: 20, fontWeight: 'normal', marginTop: 2 }}>
                        { admin && admin.nama }
                    </span>
                    <div className="input-register-box" style={{ marginTop: '1vw', borderColor: (click && (!password)) && 'red' }}>
                        <div
                            style={{
                                display: 'flex',
                                minWidth: 40,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faUnlockAlt}
                                className="fa-lock-styles"
                            />
                        </div>
                        <input type={showPassword ? 'text' : 'password'} className="input-register" value={password} placeholder="Password" onChange={e=>[setPassword(e.target.value), setError('')]}/>
                        <FontAwesomeIcon
                            icon={faEye}
                            className="fa-eye-styles"
                            style={{ color: showPassword ? "#3c98dc": "#C6C6C6" }} onClick={passwordHandler}
                        />
                    </div>
                    <div className="input-register-box" style={{ marginTop: '1vw', borderColor: (click && (!passwordRepeat)) && 'red' }}>
                        <div
                            style={{
                                display: 'flex',
                                minWidth: 40,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faUnlockAlt}
                                className="fa-lock-styles"
                            />
                        </div>
                        <input type={showPasswordRepeat ? 'text' : 'password'} className="input-register" value={passwordRepeat} placeholder="Ulangi Password" onChange={e=>[setPasswordRepeat(e.target.value), setError('')]}/>
                        <FontAwesomeIcon
                            icon={faEye}
                            className="fa-eye-styles"
                            style={{ color: showPasswordRepeat ? "#3c98dc": "#C6C6C6" }} onClick={passwordHandlerRepeat}
                        />
                    </div>
                    <div className="input-register-box" style={{ marginTop: error && '1vw',  border: 'none', height: 0}}>
                        <span style={{ color: 'red', marginRight: 'auto', marginTop: '0.5vw' }} >
                            { error }
                        </span>
                    </div>
                    <div className="btn-modal-alert-wrapper" >
                        <Button className={classes.button} onClick={props.handleChangePass}>
                            Simpan
                        </Button>
                        <Button className={classes.buttonCancel} onClick={props.modalClosed} >
                            Batal
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default modal;