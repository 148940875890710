export const GET_PROFILE = 'GET_PROFILE'
export const IS_LOGOUT = 'IS_LOGOUT'
export const GET_CHAPTER = 'GET_CHAPTER'
export const SET_CHAPTER = 'SET_CHAPTER'
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER'
export const GET_PINLIST = 'GET_PINLIST'
export const SET_SAVE = 'SET_SAVE'
export const GET_ADMINCHAPTER = 'GET_ADMINCHAPTER'
export const SET_ADMINCHAPTER = 'SET_ADMINCHAPTER'
export const UPDATE_ADMINCHAPTER = 'UPDATE_ADMINCHAPTER'
export const DELETE_ADMINCHAPTER = 'DELETE_ADMINCHAPTER'
export const SET_VERIFIKASI_BERKAS = 'SET_VERIFIKASI_BERKAS'
export const GET_OPTION = 'GET_OPTION'
export const SET_SELEKSI_BERKAS= 'SET_SELEKSI_BERKAS'
export const GET_OPTIONS = 'GET_OPTIONS'
export const GET_VERIFIKASI = 'GET_VERIFIKASI'
export const SET_VERIFIKASI = 'SET_VERIFIKASI'
export const GET_PROFILPESERTA = 'GET_PROFILPESERTA'
export const GET_SELEKSI_BERKAS = 'GET_SELEKSI_BERKAS'
export const SET_SELEKSI_WAWANCARA = 'SET_SELEKSI_WAWANCARA'
export const GET_SELEKSI_WAWANCARA = 'GET_SELEKSI_WAWANCARA'
export const GET_SELEKSI_INTERAKSI_KELOMPOK = 'GET_SELEKSI_INTERAKSI_KELOMPOK'
export const SET_SELEKSI_INTERAKSI_KELOMPOK = 'SET_SELEKSI_INTERAKSI_KELOMPOK'
export const GET_PROFIL_PESERTA_WAWANCARA_KEPRIBADIAN = 'GET_PROFIL_PESERTA_WAWANCARA_KEPRIBADIAN'
export const GET_PROFIL_PESERTA_WAWANCARA_BAHASA_INGGRIS = 'GET_PROFIL_PESERTA_WAWANCARA_BAHASA_INGGRIS'
export const GET_DETAIL_NILAI_PESERTA = 'GET_DETAIL_NILAI_PESERTA'
export const GET_PROFIL_PESERTA_INTERAKSI_KELOMPOK = 'GET_PROFIL_PESERTA_INTERAKSI_KELOMPOK'
export const SET_REFRESH_SELEKSI_BERKAS = 'SET_REFRESH_SELEKSI_BERKAS'
export const SET_REFRESH_SELEKSI_WAWANCARA = 'SET_REFRESH_SELEKSI_WAWANCARA'
export const SET_REFRESH_SELEKSI_INTERAKSI_KELOMPOK = 'SET_REFRESH_SELEKSI_INTERAKSI_KELOMPOK'
export const GET_TOTAL_PESERTA = 'GET_TOTAL_PESERTA'
export const GET_DASHBOARD_ROW1 = 'GET_DASHBOARD_ROW1'
export const GET_DASHBOARD_ROW1PIN = 'GET_DASHBOARD_ROW1PIN'
export const GET_DASHBOARD_ROW2 = 'GET_DASHBOARD_ROW2'
export const GET_DASHBOARD_ROW3_LINE = 'GET_DASHBOARD_ROW3_LINE'
export const GET_DASHBOARD_ROW3_PIE = 'GET_DASHBOARD_ROW3_PIE'
export const GET_DASHBOARD_ROW3_BAR = 'GET_DASHBOARD_ROW3_BAR'
export const GET_DASHBOARD_ROW_SCHOOL_BAR = 'GET_DASHBOARD_ROW_SCHOOL_BAR'
export const SET_TABULASI = 'SET_TABULASI'
