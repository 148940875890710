import React, { useState, useEffect, Fragment } from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import { setSaveAction } from '../../../../../store/actions/user'
import { updateChapter } from '../../../../../store/actions/chapter'
import { useDispatch, useSelector } from 'react-redux'
// COMPONENT

// MATERIAL
const DeleteButton = withStyles((theme) => ({
    root: {
        marginLeft: 13,
        width: '82px',
        height: '44px',
        color: '#3598DC',
        border: '1px solid #3598DC',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}))(Button);

const AddButton = withStyles((theme) => ({
    root: {
        marginLeft: 13,
        width: '82px',
        height: '44px',
        color: 'white',
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: 'none',
        },
    },
}))(Button);

const BatalButton = withStyles((theme) => ({
    root: {
        width: '190px',
        height: '44px',
        color: '#3598DC',
        border: '1px solid #3598DC',
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        '&:hover': {
            boxShadow: 'none',
        },
    },
}))(Button);

const CreateButton = withStyles((theme) => ({
    root: {
        width: '190px',
        height: '44px',
        color: 'white',
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: 'none',
        },
    },
}))(Button);

function EditChapter(props){
    const History = useHistory();
    const Dispatch = useDispatch();
    const profile = useSelector(state=>state.userState.profile)
    const chapterList = useSelector(state=>state.chapterState.chapterList)
    const propinsiDB = useSelector(state=> state.daerahState.propinsi )
    const kabupatenDB = useSelector(state=> state.daerahState.kabupaten )
    const kecamatanDB = useSelector(state=> state.daerahState.kecamatan )
    const save = useSelector(state=>state.filterState.checkerSave);
    const [click, setClick] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [id, setId] = useState(false)
    const [sendData, setSendData] = useState({
        chapter: '',
        code: '',
        email: '',
        alamat: '',
        propinsi: '',
        kabupaten: '',
        kecamatan: '',
        kelurahan: '',
        kodepos: '',
        telepon: [''],
        sosialMedia: [''],
        status: true
    })

    const handleChangeValue = (key, value, i) => {
        if (!save) Dispatch(setSaveAction(true))
        switch (key) {
            case 'chapter':
                const chapterFilter = chapterList.filter(a=> a.name === value)[0]
                setSendData({
                    ...sendData,
                    chapter: chapterFilter ? chapterFilter.name : '',
                    code: chapterFilter ? chapterFilter.code : '',
                })
                break;
            case 'email':
                setSendData({
                    ...sendData,
                    email: value,
                })
                break;
            case 'alamat':
                setSendData({
                    ...sendData,
                    alamat: value,
                })
                break;
            case 'propinsi':
                setSendData({
                    ...sendData,
                    propinsi: value,
                    kabupaten: '',
                    kecamatan: '',
                    kelurahan: '',
                    kodepos: '',
                })
                break;
            case 'kabupaten':
                setSendData({
                    ...sendData,
                    kabupaten: value,
                    kecamatan: '',
                    kelurahan: '',
                    kodepos: '',
                })
                break;
            case 'kecamatan':
                setSendData({
                    ...sendData,
                    kecamatan: value,
                    kelurahan: '',
                    kodepos: '',
                })
                break;
            case 'kelurahan':
                setSendData({
                    ...sendData,
                    kelurahan: value,
                    kodepos: '',
                })
                break;
            case 'kodepos':
                setSendData({
                    ...sendData,
                    kodepos: value,
                })
                break;
            case 'telepon':
                let temp = sendData.telepon
                temp[i] = value
                setSendData({
                    ...sendData,
                    telepon: temp
                })
                break;
            case 'sosialMedia':
                let temp1 = sendData.sosialMedia
                temp1[i] = value
                setSendData({
                    ...sendData,
                    sosialMedia: temp1
                })
                break;
            default:
                break;
        }
    }

    const handleFilter = (key, data) => {
        let cloud = []
        switch (key) {
            case 'kecamatan':
                if (data) {
                    data.map(props=>{
                        if (!cloud.includes(props.kecamatan)) {
                            cloud.push(props.kecamatan)
                        }
                    })
                }
                break;
            case 'kelurahan':
                if (data) {
                    data.map(props=>{
                        if (props.kecamatan === sendData.kecamatan) {
                            if (!cloud.includes(props.kelurahan)) {
                                cloud.push(props.kelurahan)
                            }
                        }
                    })
                }
                break;
            case 'kodepos':
                if (data) {
                    data.map(props=>{
                        if (props.kecamatan === sendData.kecamatan) {
                            if (props.kelurahan === sendData.kelurahan) {
                                if (!cloud.includes(props.kodepos)) {
                                    cloud.push(props.kodepos)
                                }
                            }
                        }
                    })
                }
                break;
            default:
                break;
        }
        return cloud
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const addTelepon = () => {
        let temp = sendData.telepon
        temp.push('')
        setSendData({
            ...sendData,
            telepon: temp
        })
        setRefresh(!refresh)
    }

    const deleteTelepon = (i) => {
        let temp = sendData.telepon
        temp.splice(i,1)
        setSendData({
            ...sendData,
            telepon: temp
        })
        setRefresh(!refresh)
    }

    const addSosialMedia = () => {
        let temp = sendData.sosialMedia
        temp.push('')
        setSendData({
            ...sendData,
            sosialMedia: temp
        })
        setRefresh(!refresh)
    }

    const deleteSosialMedia = (i) => {
        let temp = sendData.sosialMedia
        temp.splice(i,1)
        setSendData({
            ...sendData,
            sosialMedia: temp
        })
        setRefresh(!refresh)
    }

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const savingData = () => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        setClick(true)
        let flag = false
        for (let key in sendData) {
            if (
                key === 'chapter' ||
                key === 'code' ||
                key === 'email' ||
                key === 'alamat'
            ) {
                if (!sendData[key]) {
                    flag = true
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Silahkan Lengkapi Persyaratan`
                    })
                    goTop()
                    return
                }
                if (flag) return
            }
            if (flag) return
        }
        if (!flag) {
            if (validateEmail(sendData.email)) {
                Dispatch(updateChapter(sendData, Swal, answerBack, id))
            }else {
                ToastMid.fire({
                    icon: 'warning',
                    title: `Tulis alamat email dengan benar`
                })
            }
        }
    }

    const answerBack = (route = '/pengaturan-umum/info-chapter') => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        SwalBootstrap.fire({
            title: 'Ingin Merubah Kembali?',
            text: "",
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: 'Isi Kembali',
            cancelButtonText: 'Tidak',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            setClick(false)
            if (result.value) {
            } else if ( result.dismiss === Swal.DismissReason.cancel) {
                History.replace(route)
            }
        })
    }

    const handleBack = (route) => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if (!save) {
            History.replace(route)
        } else if(save) {
            SwalBootstrap.fire({
                title: 'Ada Perubahan Data',
                text: "Ingin Menyimpan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Simpan',
                cancelButtonText: 'Tidak',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    savingData()
                } else if ( result.dismiss === Swal.DismissReason.cancel) {
                    History.replace(route)
                    Dispatch(setSaveAction(false))
                }
            })
        }
    }

    useEffect(()=>{
        if (props.location) {
            if (props.location.state) {
                if (props.location.state.chapter) {
                    setSendData({
                        chapter: props.location.state.chapter.chapter,
                        code: props.location.state.chapter.code,
                        email: props.location.state.chapter.email,
                        alamat: props.location.state.chapter.alamat,
                        propinsi: props.location.state.chapter.propinsi,
                        kabupaten: props.location.state.chapter.kabupaten,
                        kecamatan: props.location.state.chapter.kecamatan,
                        kelurahan: props.location.state.chapter.kelurahan,
                        kodepos: props.location.state.chapter.kodepos,
                        telepon: props.location.state.chapter.telepon,
                        sosialMedia: props.location.state.chapter.sosialMedia,
                        status: props.location.state.chapter.status
                    })
                    setId(props.location.state.chapter._id)
                }
            }
        }
    },[props])

    useEffect(()=>{
        if (profile) {
            if (profile.role !== 'admin nasional') {
                History.replace('/pengaturan-umum/info-chapter')
            }
        }
    },[profile])
    
    return (
        <div className="data-container-wrapper" >
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Edit Chapter
                </span>
            </div>
            <div className="bread-cab-container">
                <span className="bread-blue">
                    Pengaturan Umum /
                </span>
                <span className="bread-blue">
                    Info Chapter /
                </span>
                <span className="bread-grey">
                    Edit Chapter /
                </span>
            </div>
            <div className="content-parent-wrapper-2021">
                <div className="pengaturan-umum-2021-wrapper" style={{ marginTop: 0 }}>
                    <div className="penerbitan-content-left-header">
                        <span>
                            Edit chapter
                        </span>
                    </div>
                    <div className="daftar-table-wrapper" >
                        <span className="add-chapter-header" >
                            Silahkan Masukan Data Chapter
                        </span>
                        <div className="input-tambah-wrapper" >
                            {/* Chapter */}
                            <div className="data-pribadi-wrapper" >
                                <span className="data-pribadi-font">
                                    Chapter <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <select
                                    className="data-pribadi-input"
                                    onChange={e=>handleChangeValue('chapter',e.target.value)}
                                    value={sendData.chapter}
                                    style={{
                                        color: sendData.chapter ? '#666666' : '#C9C9C9',
                                        border: (click && !sendData.chapter) && '1px solid red',
                                        textTransform: 'capitalize'
                                    }}
                                    disabled
                                >
                                    <option
                                        value=""
                                        style={{
                                            color: sendData.chapter ? '#d3d5d7' : '#d3d5d7',
                                        }}
                                        selected
                                    >
                                        Pilih Chapter
                                    </option>
                                    <option
                                        style={{ color: sendData.chapter === sendData.chapter ? '#3c98dc' : '#666666' }}
                                        value={sendData.chapter}
                                    >
                                        {sendData.chapter}
                                    </option>
                                </select>
                            </div>
                            {/* Code */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                <span className="data-pribadi-font">
                                    Kode Chapter <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <input
                                    value={sendData.code}
                                    disabled
                                    type="text"
                                    placeholder="Pilih Chapter"
                                    className="data-pribadi-input"
                                    style={{
                                        border: (click && !sendData.code) && '1px solid red'
                                    }}
                                />
                            </div>
                            {/* Email */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                <span className="data-pribadi-font">
                                    e-mail <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <input
                                    value={sendData.email}
                                    onChange={e=>handleChangeValue('email',e.target.value)}
                                    type="text"
                                    placeholder="e-mail"
                                    className="data-pribadi-input"
                                    style={{
                                        border: (click && !sendData.email) && '1px solid red'
                                    }}
                                />
                            </div>
                            {/* Email INFO */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 5 }}>
                                <span className="data-pribadi-font"></span>
                                <span className="email-small-info" >
                                    Pastikan email yang digunakan aktif
                                </span>
                            </div>
                            {/* Alamat Chapter */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: '10px' }} >
                                <span className="data-pribadi-font">
                                    Alamat Chapter <b style={{ color: 'red', marginLeft: 5 }} > * </b>
                                </span>
                                <div className="input-wrapper-data-pribadi" >
                                    <input
                                        onChange={e=>handleChangeValue('alamat',e.target.value)}
                                        value={sendData.alamat}
                                        type="text"
                                        placeholder="Alamat Lengkap"
                                        className="data-pribadi-input3"
                                        style={{
                                            border: (click && !sendData.alamat) && '1px solid red',
                                        }}
                                    />
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('propinsi',e.target.value)}
                                        style={{ color: sendData.propinsi ? '' : '#C9C9C9' }}
                                        value={sendData.propinsi}
                                    >
                                        <option value="" style={{ color: sendData.propinsi ? '' : '#d3d5d7' }} selected>Propinsi</option>
                                        {
                                            propinsiDB &&
                                            propinsiDB.sort().map((propinsi,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.propinsi === propinsi.id ? true : false} 
                                                    style={{ color: sendData.propinsi === propinsi.id ? '#3c98dc' : 'black' }}
                                                    value={propinsi.id}
                                                >
                                                    {propinsi.propinsi}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kabupaten',e.target.value)}
                                        style={{ color: sendData.kabupaten ? '' : '#C9C9C9' }}
                                        value={sendData.kabupaten}
                                    >
                                        <option value="" style={{ color: sendData.kabupaten ? '' : '#d3d5d7' }} selected>Kota/Kabupaten</option>
                                        {
                                            kabupatenDB[sendData.propinsi] &&
                                            kabupatenDB[sendData.propinsi].sort().map((kabupaten,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kabupaten === kabupaten.id ? true : false} 
                                                    style={{ color: sendData.kabupaten === kabupaten.id ? '#3c98dc' : 'black' }}
                                                    value={kabupaten.id}
                                                >
                                                    {kabupaten.kabupaten}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="data-pribadi-wrapper" style={{ marginTop: '10px' }} >
                                <span className="data-pribadi-font"></span>
                                <div className="input-wrapper-data-pribadi" >
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kecamatan',e.target.value)}
                                        style={{ color: sendData.kecamatan ? '' : '#C9C9C9' }}
                                        value={sendData.kecamatan}
                                    >
                                        <option value="" style={{ color: sendData.kecamatan ? '' : '#d3d5d7' }} selected>Kecamatan</option>
                                        {
                                            kecamatanDB[sendData.kabupaten] &&
                                            handleFilter('kecamatan', kecamatanDB[sendData.kabupaten]).map((data,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kecamatan === data ? true : false} 
                                                    style={{ color: sendData.kecamatan === data ? '#3c98dc' : 'black' }}
                                                    value={data}
                                                >
                                                    {data}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kelurahan',e.target.value)}
                                        style={{ color: sendData.kelurahan ? '' : '#C9C9C9' }}
                                        value={sendData.kelurahan}
                                    >
                                        <option value="" style={{ color: sendData.kelurahan ? '' : '#d3d5d7' }} selected>Desa/Kelurahan</option>
                                        {
                                            kecamatanDB[sendData.kabupaten] &&
                                            handleFilter('kelurahan', kecamatanDB[sendData.kabupaten]).map((data,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kelurahan === data ? true : false} 
                                                    style={{ color: sendData.kelurahan === data ? '#3c98dc' : 'black' }}
                                                    value={data}
                                                >
                                                    {data}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    <select
                                        className="data-pribadi-input3"
                                        onChange={e=>handleChangeValue('kodepos',e.target.value)}
                                        style={{ color: sendData.kodepos ? '' : '#C9C9C9' }}
                                        value={sendData.kodepos}
                                    >
                                        <option value="" style={{ color: sendData.kodepos ? '' : '#d3d5d7' }} selected>Kode Pos</option>
                                        {
                                            kecamatanDB[sendData.kabupaten] &&
                                            handleFilter('kodepos', kecamatanDB[sendData.kabupaten]).map((data,i)=>(
                                                <option
                                                    key={i}
                                                    selected={sendData.kodepos === data ? true : false} 
                                                    style={{ color: sendData.kodepos === data ? '#3c98dc' : 'black' }}
                                                    value={data}
                                                >
                                                    {data}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="data-pribadi-wrapper" style={{ marginTop: 5 }}>
                                <span className="data-pribadi-font"></span>
                                <span className="email-small-info" >
                                    Kosongkan Desa, Kelurahan atau Kodepos jika tidak ditemukan, tulis alamat lengkap anda di kolom Alamat Lengkap
                                </span>
                            </div>
                            {
                                sendData.telepon &&
                                sendData.telepon.map((telepon, i)=>(
                                    <div key={i} className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                        <span className="data-pribadi-font">No. Telepon</span>
                                        <div className="wrapper-tambah-chapter-btn-input" >
                                            <div className="nohp-input-wrapper"
                                                style={{
                                                    // border: (click && !telepon) && '1px solid red',
                                                }}
                                            >
                                                <input
                                                    value={telepon}
                                                    type="text"
                                                    placeholder="08123456789"
                                                    className="activation-input-hp"
                                                    onChange={(e)=> handleChangeValue('telepon',e.target.value,i)}
                                                />
                                            </div>
                                            <div className="wrapper-tambah-chapter-btn-input-right" >
                                                {
                                                    sendData.telepon.length === 1 ?
                                                        <AddButton onClick={()=>addTelepon()} > Tambah </AddButton>
                                                    :
                                                    <Fragment>
                                                        {
                                                            i === sendData.telepon.length -1 &&
                                                            <AddButton onClick={()=>addTelepon()} > Tambah </AddButton>
                                                        }
                                                        <DeleteButton onClick={()=> deleteTelepon(i)} > Hapus </DeleteButton>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                sendData.sosialMedia &&
                                sendData.sosialMedia.map((sosmed, i)=>(
                                    <div key={i} className="data-pribadi-wrapper" style={{ marginTop: 10 }}>
                                        <span className="data-pribadi-font">Media Sosial</span>
                                        <div className="wrapper-tambah-chapter-btn-input" >
                                            <div className="nohp-input-wrapper"
                                                style={{
                                                    // border: (click && !sosmed) && '1px solid red',
                                                }}
                                            >
                                                <input
                                                    value={sosmed}
                                                    type="text"
                                                    placeholder="Nama Akun Media Sosial"
                                                    className="activation-input-hp"
                                                    onChange={(e)=> handleChangeValue('sosialMedia',e.target.value,i)}
                                                />
                                            </div>
                                            <div className="wrapper-tambah-chapter-btn-input-right" >
                                                {
                                                    sendData.sosialMedia.length === 1 ?
                                                        <AddButton onClick={()=>addSosialMedia()} > Tambah </AddButton>
                                                    :
                                                    <Fragment>
                                                        {
                                                            i === sendData.sosialMedia.length -1 &&
                                                            <AddButton onClick={()=>addSosialMedia()} > Tambah </AddButton>
                                                        }
                                                        <DeleteButton onClick={()=>deleteSosialMedia(i)} > Hapus </DeleteButton>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {/* BUTTON */}
                            <div className="data-pribadi-wrapper" style={{ marginTop: 20 }}>
                                <span className="data-pribadi-font"></span>
                                <div className='button-wrapper-save-input'>
                                    <BatalButton onClick={()=> handleBack('/pengaturan-umum/info-chapter')} > Batal </BatalButton>
                                    <CreateButton style={{ marginLeft: 17 }} onClick={()=>savingData()}> Simpan </CreateButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditChapter