import React, { useEffect, useState }  from 'react'
import Dialog from '@material-ui/core/Dialog';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import './styles.scss'

function ModalSeleksiWawancara(props) {
    const {
        open,
        handleClose,
        setKeteranganSave,
        datacr
    } = props
    const [keterangan, setKeterangan] = useState('')
    const [statusChange, setStatusChange] = useState(false)

    useEffect(()=>{
        setKeterangan('')
        setStatusChange(false)
        if (datacr) {
            if (datacr.komentarSeleksiChapter) {
                setKeterangan(datacr.komentarSeleksiChapter)
                setStatusChange(true)
            }
        }
    },[open === true])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style:{
                    width: 600,
                    padding: '20px 20px'
                }
            }}
        >
            <div style={{ width: '100%',  }}>
                <div className="title-modal-keterangan-modal" >
                    { statusChange ? 'Ubah Keterangan' : 'Tulis Keterangan' }
                </div>
                <TextareaAutosize
                    maxRows={8}
                    minRows={8}
                    aria-label="text-box"
                    placeholder="Tulis keterangan disini"
                    value={keterangan}
                    onChange={e=> setKeterangan(e.target.value) }
                    className="textbox-modal-keterangan-modal"
                    style={{
                        width: '100%'
                    }}
                />
                <div className="wrapper-btn-modal-keterangan-modal" >
                    <div className="blue-btn-modal-keterangan-modal"
                        onClick={e=>setKeteranganSave(keterangan, statusChange)}
                    >
                        {
                            statusChange ?
                            'Simpan Perubahan'
                            :
                            'Submit Keterangan'
                        }
                    </div>
                    <div className="grey-btn-modal-keterangan-modal" onClick={handleClose}>
                        Batal
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalSeleksiWawancara