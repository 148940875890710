import React, { useState, useEffect } from 'react'
import Back from '../../../components/Button/Back'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { KembaliButton } from '../../../components/Button/Cus'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDetailPesertaSeleksi2 } from '../../../store/actions/dataPeserta'


function DetailNilai() {
    const { id } = useParams()
    const detailNilaiPeserta = useSelector(state => state.dataPesertaState.detailNilaiPeserta);
    const chapter = useSelector(state => state.chapterState.chapter)
    const History = useHistory()
    const Dispatch = useDispatch()
    const [kategori, setKategori] = useState('Kepribadian')

    useEffect(() => {
        if (id) {
            Dispatch(getDetailPesertaSeleksi2(id))
        }
    }, [id])

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan + ' ' : ''}${namaTengah ? namaTengah + ' ' : ''}${namaBelakang ? namaBelakang + ' ' : ''}`
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const getTotalNilaiBahasaInggris = (data) => {
        let totalPewawancara = 0
        let result = 0
        if (data) {
            if (data.pewawancara) {
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiOralProficiency ||
                        nilai.nilaiAttitude
                    ) {
                        if (result) {
                            result = result + ((Number(nilai.nilaiOralProficiency) * 3) + (Number(nilai.nilaiAttitude) * 2.5))
                        } else {
                            result = ((Number(nilai.nilaiOralProficiency) * 3) + (Number(nilai.nilaiAttitude) * 2.5))
                        }
                    }
                })
            }
        }
        return result ? (result/totalPewawancara) : '-'
    }

    const getTerbobotNilaiBahasaInggris = (data, percent) => {
        let totalNilai = 0
        let result = 0
        let totalPewawancara = 0
        if (data) {
            if (data.pewawancara) {
                let percentAsDecimal = (Number(percent) / 100);
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiOralProficiency ||
                        nilai.nilaiAttitude
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiOralProficiency) * 3) + (Number(nilai.nilaiAttitude) * 2.5))
                        } else {
                            totalNilai = ((Number(nilai.nilaiOralProficiency) * 3) + (Number(nilai.nilaiAttitude) * 2.5))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalPewawancara)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const getTotalNilaiPribadi = (data) => {
        let totalPewawancara = 0
        let result = 0
        if (data) {
            if (data.pewawancara) {
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiKualitasPribadi ||
                        nilai.nilaiVisi ||
                        nilai.nilaiEmpatiSosial ||
                        nilai.nilaiNasional
                    ) {
                        if (result) {
                            result = result + ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        } else {
                            result = ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        }
                    }
                })
            }
        }
        return result ? (result/totalPewawancara) : '-'
    }

    const getTerbobotNilaiPribadi = (data, percent) => {
        let totalNilai = 0
        let totalPewawancara = 0
        let result = 0
        if (data) {
            if (data.pewawancara) {
                let percentAsDecimal = (Number(percent) / 100);
                totalPewawancara = data.pewawancara.length
                data.pewawancara.map(nilai=>{
                    if (
                        nilai.nilaiKualitasPribadi ||
                        nilai.nilaiVisi ||
                        nilai.nilaiEmpatiSosial ||
                        nilai.nilaiNasional
                    ) {
                        if (totalNilai) {
                            totalNilai = totalNilai + ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        } else {
                            totalNilai = ((Number(nilai.nilaiKualitasPribadi) * 6) + (Number(nilai.nilaiVisi) * 6) + (Number(nilai.nilaiEmpatiSosial) * 4) + (Number(nilai.nilaiNasional) * 4))
                        }
                    }
                })
                if (totalNilai) {
                    result = (percentAsDecimal * (totalNilai/totalPewawancara)).toFixed(1)
                }
            }
        }
        return result ? result : '-'
    }

    const getValue = (obj, key) => {
        let result = ''
        if (obj && key) {
            result = obj[key]
        }
        return result ? result : '-' 
    }

    const getDateValue = (obj, key) => {
        let result = ''
        if (obj && key) {
            result = getDate(obj[key])
        }
        return result ? result : '-' 
    }

    const getDate = (value) => {
        if (value) {
            let start = new Date(value).getDate()
            let startMonth = new Date(value).getMonth()
            let monthStart = ''
            let yearResult = new Date(value).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }

            return start + ' '+ monthStart +' '+ yearResult
        }
    }

    const getTotalNilaiBahasaInggrisPewawancara = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiOralProficiency &&
                data.nilaiAttitude
            ) {
                result = ((Number(data.nilaiOralProficiency) * 3) + (Number(data.nilaiAttitude) * 2.5))
            }
        }
        return result
    }

    const getTerbobotNilaiBahasaInggrisPewawancara = (data, percent) => {
        let result = ''
        if (data) {
            if (
                data.nilaiOralProficiency &&
                data.nilaiAttitude
                ) {
                let percentAsDecimal = (Number(percent) / 100);
                let nilaiTotal = ((Number(data.nilaiOralProficiency) * 3) + (Number(data.nilaiAttitude) * 2.5))
                result = (percentAsDecimal * nilaiTotal).toFixed(1)
            }
        }
        return result
    }

    const getTotalNilaiPribadiPewawancara = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKualitasPribadi &&
                data.nilaiVisi &&
                data.nilaiEmpatiSosial &&
                data.nilaiNasional
            ) {
                result = ((Number(data.nilaiKualitasPribadi) * 6) + (Number(data.nilaiVisi) * 6) + (Number(data.nilaiEmpatiSosial) * 4) + (Number(data.nilaiNasional) * 4))
            }
        }
        return result
    }

    const getTerbobotNilaiPribadiPewawancara = (data, percent) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKualitasPribadi &&
                data.nilaiVisi &&
                data.nilaiEmpatiSosial &&
                data.nilaiNasional
                ) {
                let percentAsDecimal = (Number(percent) / 100);
                let nilaiTotal = (Number(data.nilaiKualitasPribadi) * 6) + (Number(data.nilaiVisi) * 6) + (Number(data.nilaiEmpatiSosial) * 4) + (Number(data.nilaiNasional) * 4)
                result = (percentAsDecimal * nilaiTotal).toFixed(1)
            }
        }
        return result
    }

    const getRekomendasiNilai = (data) => {
        let result = ''
        if (data) {
            if (
                data.nilaiKualitasPribadi &&
                data.nilaiVisi &&
                data.nilaiEmpatiSosial &&
                data.nilaiNasional
            ) {
                let nilaiTotal = (Number(data.nilaiKualitasPribadi) * 6) + (Number(data.nilaiVisi) * 6) + (Number(data.nilaiEmpatiSosial) * 4) + (Number(data.nilaiNasional) * 4)
                if (nilaiTotal >= 85) {
                    result = 'Sangat diunggulkan'
                } else if ((nilaiTotal >= 75) && (nilaiTotal <= 84)) {
                    result = 'Diunggulkan'
                } else if ((nilaiTotal >= 60) && (nilaiTotal <= 74)) {
                    result = 'Dapat diterima'
                } else if (nilaiTotal < 60) {
                    result = 'Ditolak'
                }
            }
        }
        return result
    }

    return (
        <Container className='con-beri-nilai'>
            <Back />
            <Paper className='paper-beri-nilai pt10'>
                <Container style={{ width: 660, padding: 0 }}>
                    <div className='title-beri'>
                        DETAIL NILAI WAWANCARA
                    </div>
                    <div className='div-blue-beri ta-left'>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Nama Peserta</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'>{getMyName(detailNilaiPeserta && detailNilaiPeserta.dataPribadi)}</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Chapter</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'
                                style={{ textTransform: 'capitalize' }}
                            >{
                                getChapterName(chapter, detailNilaiPeserta && detailNilaiPeserta.chapterId)
                            }</div>
                        </div>
                        <div className='div-txt-beri'>
                            <div className='txt-left div-left'>Total Nilai</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'>{
                                kategori === 'Kepribadian' ?
                                getTotalNilaiPribadi(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraPribadi)
                                :
                                getTotalNilaiBahasaInggris(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraBahasaInggris)
                            }</div>
                        </div>
                        <div className='flex ai-center'>
                        <div className='txt-left div-left'>Nilai Terbobot { kategori === 'Kepribadian' ? '(25%)' : '(15%)'}</div>
                            <span className='txt-left'>:</span>
                            <div className='div-right'
                            >
                            {
                                kategori === 'Kepribadian' ?
                                getTerbobotNilaiPribadi(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraPribadi, 25)
                                :
                                getTerbobotNilaiBahasaInggris(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraBahasaInggris, 15)
                            }
                            </div>
                        </div>
                    </div>
                    <div className='mt40'>
                        <div className='flex'>
                            <div className={kategori === 'Kepribadian' ? 'btn-wawancara-blue' : 'btn-wawancara-grey'} onClick={() => [setKategori('Kepribadian')]}>Wawancara Kepribadian</div>
                            <div className={kategori === 'Bahasa Inggris' ? 'btn-wawancara-blue ml20' : 'btn-wawancara-grey ml20'} onClick={() => [setKategori('Bahasa Inggris')]}>Wawancara Bahasa Inggris</div>
                        </div>
                        <div className='title-beri ta-left capitalize'>Nilai Wawancara {kategori}</div>
                        <div className='mt15'>
                            <div className='div-det-nilai'>
                                <div className='ta-left w50'>Nomor Ujian</div>
                                <div className='ta-right w50'>
                                    {
                                        kategori === 'Kepribadian' ?
                                        getValue(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraPribadi, 'nomorUjian')
                                        :
                                        getValue(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraBahasaInggris, 'nomorUjian')
                                    }
                                </div>
                            </div>
                            <div className='div-det-nilai'>
                                <div className='ta-left w50'>Tanggal Ujian</div>
                                <div className='ta-right w50'>
                                    {
                                        kategori === 'Kepribadian' ?
                                        getDateValue(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraPribadi, 'tanggalUjian')
                                        :
                                        getDateValue(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraBahasaInggris, 'tanggalUjian')
                                    }
                                </div>
                            </div>
                            <div className='div-det-nilai'>
                                <div className='ta-left w50'>Lokasi Ujian</div>
                                <div className='ta-right w50'>
                                    {
                                        kategori === 'Kepribadian' ?
                                        getValue(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraPribadi, 'lokasiUjian')
                                        :
                                        getValue(detailNilaiPeserta && detailNilaiPeserta.seleksiWawancaraBahasaInggris, 'lokasiUjian')
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            kategori === 'Bahasa Inggris' &&
                            (
                                (detailNilaiPeserta &&
                                detailNilaiPeserta.seleksiWawancaraBahasaInggris &&
                                detailNilaiPeserta.seleksiWawancaraBahasaInggris.pewawancara) ? 
                                (detailNilaiPeserta &&
                                detailNilaiPeserta.seleksiWawancaraBahasaInggris &&
                                detailNilaiPeserta.seleksiWawancaraBahasaInggris.pewawancara)
                                :
                                []
                            )
                            .map((wawancara, i) => (
                                <div>
                                    <div className='div-obs-det mt35 mb12'>
                                        Pewawancara {i + 1}
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nama Pewawancara</div>
                                        <div className='ta-right w50'>{ wawancara.namaPewawancara }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>l. Nilai Oral Profiency</div>
                                        <div className='ta-right w50'>{ wawancara.nilaiOralProficiency }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>II. Nilai Attitude</div>
                                        <div className='ta-right w50'>{ wawancara.nilaiAttitude }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left '>Total Score</div>
                                        <div className='ta-right '>{ getTotalNilaiBahasaInggrisPewawancara(wawancara) }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nilai Terbobot 15%</div>
                                        <div className='ta-right w50'>{ getTerbobotNilaiBahasaInggrisPewawancara(wawancara, 15) }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Komentar dan Observasi</div>
                                        <div className='ta-right w50' style={{ whiteSpace: 'pre-wrap' }} >{ wawancara.observasi ? wawancara.observasi : '-' }</div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            kategori === 'Kepribadian' &&
                            (
                                (detailNilaiPeserta &&
                                detailNilaiPeserta.seleksiWawancaraPribadi &&
                                detailNilaiPeserta.seleksiWawancaraPribadi.pewawancara) ? 
                                (detailNilaiPeserta &&
                                detailNilaiPeserta.seleksiWawancaraPribadi &&
                                detailNilaiPeserta.seleksiWawancaraPribadi.pewawancara)
                                :
                                []
                            )
                            .map((wawancara, i) => (
                                <div>
                                    <div className='div-obs-det mt35 mb12'>
                                        Pewawancara {i + 1}
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nama Pewawancara</div>
                                        <div className='ta-right w50'>{ wawancara.namaPewawancara }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>l. Nilai Kualitas Pribadi</div>
                                        <div className='ta-right w50'>{ wawancara.nilaiKualitasPribadi }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>II. Nilai Visi dan Prestasi</div>
                                        <div className='ta-right w50'>{ wawancara.nilaiVisi }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left '>III. Nilai Empati Sosial</div>
                                        <div className='ta-right '>{ wawancara.nilaiEmpatiSosial }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>IV. Nilai Berjiwa Nasional, Berwawasan Internasional</div>
                                        <div className='ta-right w50'>{ wawancara.nilaiNasional }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Total Penilaian</div>
                                        <div className='ta-right w50'>{ getTotalNilaiPribadiPewawancara(wawancara) }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Nilai Terbobot (25%)</div>
                                        <div className='ta-right w50'>{ getTerbobotNilaiPribadiPewawancara(wawancara, 25) }</div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Rekomendasi</div>
                                        <div className='ta-right w50'
                                            style={{  }}
                                        >
                                            {
                                                getRekomendasiNilai(wawancara)
                                            }
                                        </div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Hasil Observasi</div>
                                        <div className='ta-right w50' style={{ whiteSpace: 'pre-wrap' }} >
                                            { wawancara.observasi ? wawancara.observasi : '-' }
                                        </div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Keterangan: Deskripsi mengenai kepemimpinan, kemandirian dan prestasi siwa</div>
                                        <div className='ta-right w50' style={{ whiteSpace: 'pre-wrap' }}>
                                            { wawancara.keterangan ? wawancara.keterangan : '-' }
                                        </div>
                                    </div>
                                    <div className='div-det-nilai'>
                                        <div className='ta-left w50'>Validasi Sejarah Kepemimpinan, Kemandirian serta Bakat Khusus</div>
                                        <div className='ta-right w50' style={{ whiteSpace: 'pre-wrap', textTransform: 'capitalize' }}>
                                            {
                                                wawancara.validasi === 'aktif' ?
                                                'Aktif/mengerti tanggung jawab pekerjaan/berprestasi' :
                                                wawancara.validasi === 'cukup' ?
                                                'Cukup aktif/cukup berprestasi' :
                                                wawancara.validasi === 'kurang' ?
                                                'Kurang aktif/tidak ada prestasi' :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='mt40' style={{ paddingBottom: '40px' }}>
                        <KembaliButton onClick={() => [History.goBack()]}>
                            Kembali
                        </KembaliButton>
                    </div>
                </Container>
            </Paper>
        </Container>
    )
}

export default DetailNilai