import React, { useEffect, useState, Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import fileblue from '../../images/modal/file-c-blue.svg'
import filered from '../../images/modal/file-c-red.svg'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import './styles.scss'
import { showDate, showHour } from '../../helpers/convertDate';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import { downloadFile } from '../../store/actions/dataPeserta'
import { useDispatch } from 'react-redux';

function ModalBerkasChapter(props) {
    const {
        handleUpload
    } = props
    const Dispatch = useDispatch()
    const [berkas, setBerkas] = useState([])
    const [flag, setFlag] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [dataSelect, setDataSelect] = useState(null)
    const [dataIndex, setDataIndex] = useState(0)

    useEffect(() => {
        if (props) {
            if (props.peserta) {
                setBerkas(
                    [
                        { nama: 'Berkas Pendaftaran', file: { file: 'ada', date: props.peserta.tanggalFinalisasi } },
                        { nama: 'Surat Pernyataan Sekolah', key: 'pernyataanSekolah', file: props.peserta.suratPernyataanSekolah },
                        { nama: 'Surat Pernyataan Orang Tua', key: 'izinOrangTua', file: props.peserta.suratIzinOrangTua },
                        { nama: 'Surat Rekomendasi Lingkungan Rumah', key: 'rekomendasiKeluarga', file: props.peserta.suratRekomendasiKeluarga },
                        { nama: 'Rekomendasi Lingkungan Sekolah', key: 'rekomendasiSekolah', file: props.peserta.suratRekomendasiSekolah },
                        { nama: 'Rekomendasi Teman Dekat', key: 'rekomendasiTemanDekat', file: props.peserta.suratRekomendasiTemanDekat },
                        { nama: 'Raport', key: 'raport', file: props.peserta.raport },
                        { nama: 'Akta Kelahiran', key: 'aktaKelahiran', file: props.peserta.aktaKelahiran },
                        { nama: 'SKHUN & Ijazah SMP', key: 'skhunIjazah', file: props.peserta.skhunIjazah },
                        { nama: 'Sertifikat dan lain-lain', key: 'sertifikatDll', file: props.peserta.sertifikatDll }
                    ]
                )
                if (props.peserta._id) setFlag(true)
            }
        }
    }, [props])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event, dat, i) => {
        setAnchorEl(event.currentTarget)
        setDataSelect(dat)
        setDataIndex(i)
    };

    const getMyName = (dataPribadi) => {
        let namaDepan
        let namaTengah
        let namaBelakang
        if (dataPribadi) {
            namaDepan = dataPribadi.namaDepan
            namaTengah = dataPribadi.namaTengah
            namaBelakang = dataPribadi.namaBelakang
        }
        return `${namaDepan ? namaDepan+' ' : ''}${namaTengah ? namaTengah+' ' : ''}${namaBelakang ? namaBelakang+' ' : ''}`
    }

    const handleDownload = (e, url, fileName) => {
        if (e) e.preventDefault()
        if (props) {
            if (props.peserta) {
                if (props.peserta.dataPribadi) {
                    if (fileName && url) {
                        Dispatch(downloadFile(url, `${getMyName(props.peserta.dataPribadi)}-${fileName}.pdf` ,Swal))
                    }
                }
            }
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            maxWidth='lg'
        >
            <div className='title-m-berkas'>
                <div className='title-berkas-modal'>Berkas Peserta - { props && props.peserta && props.peserta.dataPribadi && getMyName(props.peserta.dataPribadi)}</div>
                <IconButton onClick={props.handleClose} size='small'>
                    <CloseIcon style={{ color: '#A2A2A2' }} />
                </IconButton>
            </div>
            {
                props &&
                props.peserta &&
                props.peserta._id &&
                <div className='p20' style={{ width: 700, paddingTop: 10 }}>
                    <div className='txt-ttl-berkas' style={{ marginTop: 5 }} >Nama-nama berkas</div>
                    <div className='mt12'>
                        <Grid container spacing={2}>
                            {
                                berkas.map((item, i) => (
                                    flag &&
                                    <Grid item xs={6}>
                                        {
                                            props &&
                                            props.peserta &&
                                            props.peserta.otoritasiChapter !== true ?
                                                !item.file || item.file.file === null ?
                                                    <div className='div-berkas-red'>
                                                        <img src={filered} alt='icn' style={{ width: 38 }} />
                                                        <div className='ml10'>
                                                            <div className='txt-name'>{item.nama}</div>
                                                            <div className='no-berkas'>Belum ada berkas</div>
                                                        </div>
                                                    </div> :
                                                    <div className='div-berkas-blue'>
                                                        <img src={fileblue} alt='icn' style={{ width: 38 }} />
                                                        <div className='ml10'>
                                                            <div className='txt-name'>{item.nama}</div>
                                                            <div className='txt-upload'>Upload: {showDate(item.file.date)} | {showHour(item.file.date)}</div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                marginLeft: "auto"
                                                            }}
                                                        >
                                                            <Link
                                                                to={{ pathname:
                                                                    i !== 0 ?
                                                                        item && item.file && item.file.file
                                                                        :
                                                                        `profil/${props && props.peserta && props.peserta._id}`
                                                                }}
                                                                target="_BLANK"
                                                                style={{
                                                                    textDecoration: 'none',
                                                                    marginLeft: 'auto',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faEye}
                                                                    style={{
                                                                        color: '#3c98dc',
                                                                        fontSize: 20,
                                                                    }}
                                                                />
                                                            </Link>
                                                            {
                                                                i === 0 ?
                                                                <Link
                                                                    to={{ pathname: `/download-profile/${props && props.peserta && props.peserta._id}` }}
                                                                    target="_BLANK"
                                                                    style={{
                                                                        textDecoration: 'none',
                                                                        cursor: 'pointer',
                                                                        marginTop: 2
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faArrowCircleDown}
                                                                        style={{
                                                                            color: '#3c98dc',
                                                                            fontSize: 20,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                </Link>
                                                                :
                                                                <FontAwesomeIcon
                                                                    icon={faArrowCircleDown}
                                                                    style={{
                                                                        color: '#3c98dc',
                                                                        fontSize: 20,
                                                                        cursor: 'pointer',
                                                                        marginTop: 2
                                                                    }}
                                                                    onClick={e=> handleDownload(e, item && item.file && item.file.file, item && item.nama) }
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                !item.file || item.file.file === null ?
                                                    <div className='div-berkas-red' >
                                                        <img src={filered} alt='icn' style={{ width: 38 }} />
                                                        <div className='ml10'>
                                                            <div className='txt-name'>{item.nama}</div>
                                                            <input
                                                                type="file"
                                                                className="upload-image__file"
                                                                style={{
                                                                    width: 94,
                                                                    height: 22,
                                                                    marginTop: 6
                                                                }}
                                                                accept=".pdf"
                                                                onChange={e=>handleUpload(item.key, e, 'PATCH')}
                                                            />
                                                            <div className='btn-upload-modal-berkas mt6' style={{ marginTop: 4 }}>UPLOAD BERKAS</div>
                                                        </div>
                                                    </div>
                                                :
                                                <div className='div-berkas-blue div-chapter-modal'>
                                                    <div className='flex ai-center'>
                                                        <img src={fileblue} alt='icn' style={{ width: 38 }} />
                                                        <div className='ml10'>
                                                            <div className='txt-name'>{item.nama}</div>
                                                            <div className='txt-upload' >Upload: {showDate(item.file.date)} | {showHour(item.file.date)}</div>
                                                        </div>
                                                    </div>
                                                    <MoreHorizIcon onClick={(e) => handleClick(e, item, i)} className='more-modal' />
                                                </div>
                                        }
                                    </Grid>
                                ))

                            }
                        </Grid>
                        <Menu
                            className='menu-table'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <Link
                                to={{ pathname:
                                    dataIndex !== 0 ?
                                        dataSelect && dataSelect.file && dataSelect.file.file
                                        :
                                        `profil/${props && props.peserta && props.peserta._id}`
                                }}
                                target="_BLANK"
                                style={{ textDecoration: 'none' }}
                            >
                                <MenuItem className='menu-item-table'>
                                    Lihat
                                </MenuItem>
                            </Link>
                            
                            {
                                dataIndex !== 0 &&
                                <Fragment>
                                    <Divider />
                                    <input
                                        type="file"
                                        className="upload-image__file"
                                        style={{
                                            width: 115,
                                            height: 28,
                                            marginTop: 0,
                                        }}
                                        accept=".pdf"
                                        onChange={e=>[handleUpload(dataSelect && dataSelect.key, e, 'PATCH'), handleClose()]}
                                    />
                                    <MenuItem className='menu-item-table' >
                                        Ganti Berkas
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem className='menu-item-table'
                                        onClick={e=>[handleUpload(dataSelect && dataSelect.key, null, 'DELETE'), handleClose()]}
                                    >
                                        Hapus
                                    </MenuItem>
                                </Fragment>
                            }
                        </Menu>
                    </div>
                </div>
            }
            {
                props &&
                props.peserta &&
                props.peserta.otoritasiChapter === true &&
                <div className='title-m-berkas' style={{ padding: '13px 20px' }}>
                    <div className='txt-act-berkas' style={{ paddingRight: 53 }}>Otoritas sudah diberikan, Anda bisa meng-ganti, upload atau menghapus berkas</div>
                </div>
            }
        </Dialog>
    )
}

export default ModalBerkasChapter