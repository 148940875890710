import {
    SET_SAVE,
    SET_REFRESH_SELEKSI_BERKAS,
    SET_REFRESH_SELEKSI_WAWANCARA,
    SET_REFRESH_SELEKSI_INTERAKSI_KELOMPOK,
} from '../constanta'

const intialState = {
    score: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100],
    negara: [
        "Argentina",
        'belgia flanders',
        "belgia prancis",
        "brazil",
        "republik tiongkok (china)",
        "republic ceko",
        "denmark",
        "spanyol",
        "finlandia",
        "jerman",
        "hongaria",
        "india",
        "italia",
        "jepang",
        "meksiko",
        "belanda",
        "norwegia",
        "filipina",
        "polandia",
        "rusia",
        "swiss",
        "thailand",
        "turki",
        "amerika serikat"
    ],
    essay: {
        afs: [
            'Jika saat ini Anda punya satu kesempatan untuk berbincang dengan satu orang tokoh yang terkenal, baik masih hidup atau sudah meninggal dunia, dengan siapa Anda ingin berbincang? Apa yang ingin dibicarakan dan didiskusikan?',
            'Describe the present-day Earth from an alien’s point of view.',
        ],
        yes: [
            'Jika saat ini Anda punya satu kesempatan untuk membuat aturan dan peraturan untuk koloni manusia pertama di Planet Mars, aturan dan peraturan apa saja yang akan Anda buat tetapkan? Kenapa aturan dan peraturan tersebut Anda tetapkan?',
            'Choose a fictional character and explain why they represents you the best.',
        ],
        kakehashi: [
            '',
        ]
    },
    berkas: {
        YES: {
            SuratPenyataanSekolah : {
                link: '/public/document/Surat%20Pernyataan%20Sekolah%20YES.pdf',
                fileName: 'Surat Pernyataan Sekolah (YES).pdf'
            },
            SuratIzinOrangTua : {
                link: '/public/document/Surat%20Pernyataan%20Orang%20Tua%20(YES).pdf',
                fileName: 'Surat Izin Orang Tua (YES).pdf'
            },
            SuratRekomendasiLingkunganRumah : {
                link: '/public/document/Surat%20Rekomendasi%20Lingkungan%20Sekolah%20(YES).pdf',
                fileName: 'Surat Rekomendasi Lingkungan Rumah (YES).pdf'
            },
            SuratRekomendasiLingkunganSekolah : {
                link: '/public/document/Surat%20Rekomendasi%20Sekolah%20(YES).pdf',
                fileName: 'Surat Rekomendasi Lingkungan Sekolah (YES).pdf'
            },
            SuratRekomendasiTemanDekat : {
                link: '/public/document/Surat%20Rekomendasi%20Teman%20Dekat%20(YES).pdf',
                fileName: 'Surat Rekomendasi Teman Dekat (YES).pdf'
            },
        },
        KAKEHASHI: {
            SuratPenyataanSekolah : {
                link: '/public/document/Surat%20Pernyataan%20Sekolah%20(Kakehashi).pdf',
                fileName: 'Surat Pernyataan Sekolah (KAKEHASHI).pdf'
            },
            SuratIzinOrangTua : {
                link: '/public/document/Surat%20Izin%20Orang%20Tua%20(Kakehashi).pdf',
                fileName: 'Surat Izin Orang Tua (KAKEHASHI).pdf'
            },
            SuratRekomendasiLingkunganRumah : {
                link: '/public/document/Surat%20Rekomendasi%20Lingkungan%20Rumah%20(Kakehashi).pdf',
                fileName: 'Surat Rekomendasi Lingkungan Rumah (KAKEHASHI).pdf'
            },
            SuratRekomendasiLingkunganSekolah : {
                link: '/public/document/Surat%20Rekomendasi%20Lingkungan%20Sekolah%20(Kakehashi).pdf',
                fileName: 'Surat Rekomendasi Lingkungan Sekolah (KAKEHASHI).pdf'
            },
            SuratRekomendasiTemanDekat : {
                link: '/public/document/Surat%20Rekomendasi%20Teman%20Dekat%20(Kakehashi).pdf',
                fileName: 'Surat Rekomendasi Teman Dekat (KAKEHASHI).pdf'
            },
        },
        AFS: {
            SuratPenyataanSekolah : {
                link: '/public/document/Surat%20Rekomendasi%20Lingkungan%20Sekolah%20(AFS).pdf',
                fileName: 'Surat Pernyataan Sekolah (AFS).pdf'
            },
            SuratIzinOrangTua : {
                link: '/public/document/Surat%20Izin%20Orang%20Tua%20(AFS).pdf',
                fileName: 'Surat Izin Orang Tua (AFS).pdf'
            },
            SuratRekomendasiLingkunganRumah : {
                link: '/public/document/Surat%20Rekomendasi%20Lingkungan%20Rumah%20(AFS).pdf',
                fileName: 'Surat Rekomendasi Lingkungan Rumah (AFS).pdf'
            },
            SuratRekomendasiLingkunganSekolah : {
                link: '/public/document/Surat%20Pernyataan%20Sekolah%20(AFS).pdf',
                fileName: 'Surat Rekomendasi Lingkungan Sekolah (AFS).pdf'
            },
            SuratRekomendasiTemanDekat : {
                link: '/public/document/Surat%20Rekomendasi%20Teman%20Dekat%20(AFS).pdf',
                fileName: 'Surat Rekomendasi Teman Dekat (AFS).pdf'
            },
        }
    },
    checkerSave: false,
    refreshSeleksiBerkas: false,
    refreshSeleksiWawancara: false,
    refreshSeleksiInteraksiKelompok: false,
    sekolah: [
        {
            type: "SMA",
            value: "SMA"
        },
        {
            type: "SMK",
            value: "SMK"
        },
        {
            type: "MA",
            value: "MA (Non Pesantren)"
        },
        {
            type: "Pesantren",
            value: "Pesantren"
        },
    ]
}

function FilterState(state = intialState, action) {
    switch (action.type) {
        case SET_SAVE:
            return {
                ...state, 
                checkerSave : action.action,
            }
        case SET_REFRESH_SELEKSI_BERKAS:
            return {
                ...state, 
                refreshSeleksiBerkas : action.action,
            }
        case SET_REFRESH_SELEKSI_WAWANCARA:
            return {
                ...state, 
                refreshSeleksiWawancara : action.action,
            }
        case SET_REFRESH_SELEKSI_INTERAKSI_KELOMPOK:
            return {
                ...state, 
                refreshSeleksiInteraksiKelompok : action.action,
            }
        default: {
            return state
        }
    }
}

export default FilterState

