import axios from 'axios'
import server from '../../endpoint'
import { generateToken } from '../../helpers/bearer'
import { setSaveAction } from './user'
import {
    GET_CHAPTER,
    SET_CHAPTER,
    UPDATE_CHAPTER
} from "../constanta";

export const getChapter = () => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if (token) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/chapter`,
                headers: {
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                }
            })
            dispatch({
                type: GET_CHAPTER,
                data
            })
        }
    } catch (error) {
        console.error(error);
    }
}

export const createChapter = ( sendData, Swal, answer) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'post',
                url: `${server}/chapter`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })

            dispatch({
                type: SET_CHAPTER,
                data : data
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Dibuat`,
            })
            answer()
        }
    } catch (err) {
        console.error(err)
    }
}

export const updateChapter = ( sendData, Swal, answer, id) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            const { data } = await axios({
                method: 'put',
                url: `${server}/chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            dispatch({
                type: UPDATE_CHAPTER,
                data : data
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Diubah`,
            })
            answer()
        }
    } catch (err) {
        console.error(err)
    }
}

export const changeStatusChapter = ( status, Swal, id) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            const { data } = await axios({
                method: 'patch',
                url: `${server}/chapter/status/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    status
                }
            })
            dispatch({
                type: UPDATE_CHAPTER,
                data : data
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Diganti`,
            })
        }
    } catch (err) {
        console.error(err)
    }
}