import React, { useState, Fragment, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import Divider from '@material-ui/core/Divider';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import './styles.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PaginationCustom from '../../../components/PaginationCustom'
import Menu from '@material-ui/core/Menu';
import ModalBerkas from '../../../components/modal/ModalBerkas';
import ModalBerkasChapter from '../../../components/modal/ModalBerkasChapter';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { sendEmail, changeStatusPesertaVerifikasiBerkas, changeStatusPesertaOtorisasiBerkas, berkasAction, exportExcel, importExcel, getDataVerifikasiBerkas } from '../../../store/actions/dataPeserta';
import Swal from 'sweetalert2';
import ModalCustom from '../../../components/modal/ModalCustom'
import XLSX from 'xlsx'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from '../../../components/JZZIPUtils';
import Breadcrumb from '../../../components/Breadcrumb';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    background: '#FAFAFA',
    borderRadius: '2px 2px 0px 0px',
  },
  tableHeaderFont: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#888888',
  },
  tableMain: {
    boxShadow: "none",
    border: '0.5px solid #D1D1D1',
    borderRadius: '2px',
    width: '100%'
  },
  pagination: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#999999',
    paddingLeft: 0,
    marginTop: 10
  },
  paginationRight: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%'
  },
  formControl: {
    '& label.Mui-focused': {
      color: '#999999',
    },
    '& label': {
      color: '#999999',
    },
    '& .MuiSelect-root': {
      paddingTop: 0,
      paddingBottom: 15,
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      width: '200px',
      height: '44px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition: '0.8s',
      '&.Mui-focused fieldset': {
        border: '0.5px solid #D1D1D1',
        background: 'white',
      },
      '&:hover fieldset': {
        border: '0.5px solid #D1D1D1',
      },
    },
    '& .MuiSelect-iconOutlined': {
      zIndex: 99
    },
    '&:hover .MuiSelect-iconOutlined': {
      zIndex: 99
    },
  },
  formControlNormaly: {
    '& label.Mui-focused': {
      color: '#999999',
    },
    '& label': {
      color: '#999999',
    },
    '& .MuiSelect-root': {
      // paddingTop: 0,
      // paddingBottom: 15,
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px 10px 0px'
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'white',
      },
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      // width: '200px',
      height: '44px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition: '0.8s',
      '&.Mui-focused fieldset': {
        border: '0.5px solid #D1D1D1',
        // background: 'white',
      },
      '&:hover fieldset': {
        border: '0.5px solid #D1D1D1',
      },
    },
    '& .MuiSelect-iconOutlined': {
      zIndex: 99
    },
    '&:hover .MuiSelect-iconOutlined': {
      zIndex: 99
    },
  },
  formControlNormal: {
    '& label.Mui-focused': {
      color: '#444444',
    },
    '& label': {
      color: '#444444',
    },
    '&:hover label': {
      color: 'white',
    },
    '& .MuiSelect-root': {
      paddingTop: 0,
      paddingBottom: 15,
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      width: '92px',
      height: '32px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition: '0.8s',
      '&.Mui-focused fieldset': {
        border: '0.5px solid #D1D1D1',
        color: '#444444',
        background: 'white',
      },
      '&:hover fieldset': {
        border: 'none',
        background: '#3598DC',
      },
    },
    '& .MuiSelect-iconOutlined': {
      color: '#888888',
      zIndex: 99
    },
    '&:hover .MuiSelect-iconOutlined': {
      color: 'white',
      zIndex: 99
    },
  },
  formControlRed: {
    '& label.Mui-focused': {
      color: '#FF4040',
    },
    '& label': {
      color: '#FF4040',
    },
    '&:hover label': {
      color: 'white',
    },
    '& .MuiSelect-root': {
      paddingTop: 0,
      paddingBottom: 15,
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      width: '92px',
      height: '32px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition: '0.8s',
      '&.Mui-focused fieldset': {
        border: '0.5px solid #D1D1D1',
        color: '#FF4040',
        background: 'white',
      },
      '&:hover fieldset': {
        border: 'none',
        background: '#3598DC',
      },
    },
    '& .MuiSelect-iconOutlined': {
      color: '#FF4040',
      zIndex: 99
    },
    '&:hover .MuiSelect-iconOutlined': {
      color: 'white',
      zIndex: 99
    },
  },
  formControlNormalyCustom: {
    '& label.Mui-focused': {
      color: '#999999',
    },
    '& label': {
      color: '#999999',
    },
    '& .MuiSelect-root': {
      // paddingTop: 0,
      // paddingBottom: 15,
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px 10px 0px'
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'white',
      },
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: 0,
      paddingBottom: 0,
      height: '44px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      transition: '0.8s',
      '&.Mui-focused fieldset': {
        border: '0.5px solid #D1D1D1',
        // background: 'white',
      },
      '&:hover fieldset': {
        border: '0.5px solid #D1D1D1',
      },
    },
    '& .MuiSelect-iconOutlined': {
      zIndex: 99
    },
    '&:hover .MuiSelect-iconOutlined': {
      zIndex: 99
    },
  },
}));

function VerifikasiBerkas() {
  // const verifikasiBerkas = useSelector(state => state.dataPesertaState.verifikasiBerkas);
  const options = JSON.parse(localStorage.getItem('options'))
  const optionState = useSelector(state => state.optionState.option)
  const chapter = useSelector(state => state.chapterState.chapter)
  const propinsiDB = useSelector(state => state.daerahState.propinsi)
  const kabupatenDB = useSelector(state => state.daerahState.kabupaten)
  const classes = useStyles();
  const Dispatch = useDispatch()
  const hiddenFileInput = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const profile = useSelector(state => state.userState.profile)
  const [openModal, setOpenModal] = useState(false)
  const [modalProps, setModalProps] = useState({
    open: false,
    icon: '',
    title: '',
    text: '',
    buttonCancel: false,
    buttonCancelText: '',
    buttonCancelAction: null,
    buttonConfirm: false,
    buttonConfirmText: '',
    buttonConfirmAction: null,
  })
  const [datacr, setDataCr] = useState(null)
  const [datacrIndex, setDataCrIndex] = useState(null)
  const defaultFilterOptions = createFilterOptions();
  const [dataFilter, setDataFilter] = useState({
    search: '',
    chapter: '',
    status: null,
    berkas: null
  })
  const [isExport, setIsExport] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [verifikasiBerkas, setVerifikasiBerkas] = useState([])

  useEffect(() => {
    if (options?.program) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
      })
      Swal.showLoading()
      Dispatch(getDataVerifikasiBerkas(options?.program,
        { limit, offset: page, chapter_id: dataFilter?.chapter, status_verifikasi_berkas: dataFilter?.status, berkas: dataFilter?.berkas, search_id: dataFilter?.search },
        ({ status, data, error }) => {
          Swal.close()
          if (status === 200) {
            setVerifikasiBerkas(data)
          } else if (status === 400) {
            Toast.fire({
              icon: 'error',
              title: error
            })
          }
        }
      ))
    }
  }, [
    options?.program,
    page,
    limit,
    dataFilter.chapter,
    dataFilter.status,
    dataFilter.berkas,
    dataFilter.search,
    refetch
  ])

  function exportFile() {
    // setIsExport(true)
    Dispatch(exportExcel(
      `/peserta/verifikasi-berkas/export`,
      `Verifikasi Berkas ${options?.program}${(dataFilter?.chapter || profile?.chapterId) ? `_${getChapterName(chapter, (dataFilter?.chapter || profile?.chapterId))}` : ''}`,
      Swal,
      {
        chapter_id: dataFilter?.chapter,
        status_verifikasi_berkas: dataFilter?.status,
        berkas: dataFilter?.berkas,
        search_id: dataFilter?.search,
        program: options?.program
      }
    ))
  }

  useEffect(() => {
    if (isExport) {
      Swal.showLoading()
      exportTo()
    }
  }, [isExport])

  function exportTo() {
    var wb = XLSX.utils.table_to_book(document.getElementById('exporttable-verifikasi'));
    XLSX.writeFile(wb, 'export-verifikasi-berkas.xlsx');
    setIsExport(false)
    Swal.close()
  }

  const handleModalPropsClose = () => {
    setModalProps({
      ...modalProps,
      open: false,
    })
  }

  const handleModalPropsOpen = (value, newStatus) => {
    let obj = {
      title: '',
      text: '',
      icon: 'info',
      buttonConfirmText: 'Ya, Yakin',
      buttonCancelText: 'Tidak'
    }
    if (value !== null && newStatus) {
      obj.title = `${value === true ? 'Meluluskan' : value === false ? 'Tidak Meluluskan' : 'Menghapus'} ${getMyName(datacr && datacr.dataPribadi)} Ditahap Verifikasi Berkas?`
      obj.text = `Cek kembali keputusan Anda sebelum memberi keputusan kelulusan`
    } else if (value !== null) {
      obj.title = `Mengubah Status Kelulusan ${getMyName(datacr && datacr.dataPribadi)}?`
      obj.text = `Anda akan ${value === true ? 'meluluskan' : value === false ? 'tidak meluluskan' : 'Menghapus'} ${getMyName(datacr && datacr.dataPribadi)} ditahap verifikasi berkas`
    } else if (value === null) {
      obj.icon = 'delete'
      obj.buttonCancelText = `Batal`
      obj.buttonConfirmText = `Ya, Hapus`
      obj.title = `Yakin Akan Menghapus Status Kelulusan?`
      obj.text = `Cek kembali keputusan Anda sebelum menghapus kelulusan`
    }
    setModalProps({
      open: true,
      close: handleModalPropsClose,
      icon: obj.icon,
      title: obj.title,
      text: obj.text,
      buttonCancel: true,
      buttonCancelText: obj.buttonCancelText,
      buttonCancelAction: handleModalPropsClose,
      buttonConfirm: true,
      buttonConfirmText: obj.buttonConfirmText,
      buttonConfirmAction: _ => changeStatusVerifikasi(value, newStatus),
    })
  }

  const handleModalPropsOpenOtoritas = (value, handleCloseMenu) => {
    setModalProps({
      open: true,
      close: handleModalPropsClose,
      icon: 'info',
      title: `${value === true ? 'Memberikan' : value === false && 'Membatalkan'} Otoritas untuk ${getMyName(datacr && datacr.dataPribadi)}?`,
      text: 'Cek kembali keputusan Anda sebelum memberi keputusan otoritas',
      buttonCancel: true,
      buttonCancelText: 'Batal',
      buttonCancelAction: handleModalPropsClose,
      buttonConfirm: true,
      buttonConfirmText: `Ya, ${value === true ? 'Berikan' : value === false && 'Batalkan'}`,
      buttonConfirmAction: _ => changeOtoritasiChapter(value, handleCloseMenu),
    })
  }

  const closeModal = () => {
    setOpenModal(false)
    handleReset()
  }

  const handleReset = () => {
    setDataCr(null)
    setDataCrIndex(null)
  }

  const handleChangePage = (_, newPage) => {
    if (page !== newPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, dat) => {
    setAnchorEl(event.currentTarget)
    if (dat) {
      let index = verifikasiBerkas?.data?.indexOf(dat)
      setDataCr(dat)
      setDataCrIndex(index)
    }
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event, dat) => {
    setAnchorEl2(event.currentTarget)
    if (dat) {
      let index = verifikasiBerkas?.data?.indexOf(dat)
      setDataCr(dat)
      setDataCrIndex(index)
    }
  };

  const [anchorElUbahStatus, setAnchorElUbahStatus] = useState(null);
  const handleCloseUbahStatus = () => {
    setAnchorElUbahStatus(null);
  };

  const handleClickUbahStatus = (event) => {
    setAnchorElUbahStatus(event.currentTarget)
  };

  const [anchorElInputStatus, setAnchorElInputStatus] = useState(null);
  const handleCloseInputStatus = () => {
    setAnchorElInputStatus(null);
  };

  const handleClickInputStatus = (event, dat) => {
    setAnchorElInputStatus(event.currentTarget)
    if (dat) {
      let index = verifikasiBerkas?.data?.indexOf(dat)
      setDataCr(dat)
      setDataCrIndex(index)
    }
  };

  const changeStatusVerifikasi = (value, newStatus) => {
    let options = JSON.parse(localStorage.getItem('options'))
    handleCloseInputStatus()
    handleCloseUbahStatus()
    handleClose()
    if (datacr) {
      Dispatch(changeStatusPesertaVerifikasiBerkas(value, newStatus, datacr.nomorUrut, options.program, datacr.chapterId, datacr._id, verifikasiBerkas, datacrIndex, Swal, handleReset, setModalProps, handleModalPropsClose, getMyName(datacr && datacr.dataPribadi)))
    }
  }

  const changeOtoritasiChapter = (value, handleCloseMenu) => {
    handleClose2()
    if (datacr) {
      Dispatch(changeStatusPesertaOtorisasiBerkas(value, datacr._id, verifikasiBerkas?.data, datacrIndex, Swal, setDataCr, closeModal, handleCloseMenu, getMyName(datacr && datacr.dataPribadi), setModalProps, handleModalPropsClose, handleReset))
    }
  }

  const handleUpload = (key, file, method) => {
    let value
    if (file) value = file.target.files[0]
    let data = new FormData()
    data.append('file', value)
    data.set('tanggal', new Date())
    Dispatch(berkasAction(data, Swal, key, method, datacr._id, verifikasiBerkas, datacrIndex, setDataCr, setModalProps, handleModalPropsClose))
    if (document.getElementById(key)) {
      document.getElementById(key).value = null;
    }
  }

  const classStatus = (peserta) => {
    if (peserta && optionState) {
      if (
        !(peserta.suratPernyataanSekolah && peserta.suratPernyataanSekolah.file) ||
        !(peserta.suratIzinOrangTua && peserta.suratIzinOrangTua.file) ||
        !(peserta.suratIzinOrangTua && peserta.suratIzinOrangTua.file) ||
        !(peserta.suratRekomendasiKeluarga && peserta.suratRekomendasiKeluarga.file) ||
        !(peserta.suratRekomendasiSekolah && peserta.suratRekomendasiSekolah.file) ||
        !(peserta.suratRekomendasiTemanDekat && peserta.suratRekomendasiTemanDekat.file) ||
        !(peserta.raport && peserta.raport.file) ||
        !(peserta.aktaKelahiran && peserta.aktaKelahiran.file) ||
        !(peserta.skhunIjazah && peserta.skhunIjazah.file) ||
        !(peserta.sertifikatDll && peserta.sertifikatDll.file)
      ) {
        return 'belum-lengkap'
      }
      if (peserta.otoritasiChapter === true || peserta.otoritasiChapter === false) {
        return 'belum-lengkap-adm'
      }
    }
    return ''
  }

  const noPesertaGenerate = (peserta) => {
    let options = JSON.parse(localStorage.getItem('options'))
    let program = '-'
    if (options) {
      if (options.program === 'KAKEHASHI') {
        program = 'MXT'
      } else {
        program = options.program
      }
    }
    if (peserta) {
      return `YBA/YP25-26/${getChapterCode(chapter, peserta && peserta.chapterId)}/${program}/${peserta.nomorUrut === '0000' ? '-' : peserta.nomorUrut}`
    }
    return ''
  }

  const getChapterCode = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter(a => a._id === chapter)[0]
      return temp ? temp.code : '-'
    }
  }

  const getMyName = (dataPribadi) => {
    let namaDepan
    let namaTengah
    let namaBelakang
    if (dataPribadi) {
      namaDepan = dataPribadi.namaDepan
      namaTengah = dataPribadi.namaTengah
      namaBelakang = dataPribadi.namaBelakang
    }
    return `${namaDepan ? namaDepan + ' ' : ''}${namaTengah ? namaTengah + ' ' : ''}${namaBelakang ? namaBelakang + ' ' : ''}`
  }

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 5);
  };

  const getAddressPlace = (dataPribadi) => {
    let alamat
    let kecamatan
    let kota
    let propinsi
    let kodepos
    if (dataPribadi) {
      if (dataPribadi.propinsi) {
        if (propinsiDB.filter(a => a.id === dataPribadi.propinsi)[0]) {
          propinsi = propinsiDB.filter(a => a.id === dataPribadi.propinsi)[0].propinsi
        }
      }
      if (kabupatenDB[dataPribadi.propinsi]) {
        if (kabupatenDB[dataPribadi.propinsi].filter(a => a.id === dataPribadi.kabupaten)[0]) {
          kota = kabupatenDB[dataPribadi.propinsi].filter(a => a.id === dataPribadi.kabupaten)[0].kabupaten
        }
      }
      alamat = dataPribadi.alamat
      kecamatan = dataPribadi.kecamatan
      kodepos = dataPribadi.kodepos
    }
    return `${alamat ? alamat : '-'}, Kecamatan ${kecamatan ? kecamatan : '-'}, Kota/Kabupaten ${kota ? kota : '-'}, Propinsi ${propinsi ? propinsi : '-'} ${kodepos ? kodepos : ''}`
  }

  const getValue = (obj, key) => {
    let result
    if (obj) {
      result = obj[key]
    }
    return `${result ? result : '-'}`
  }

  const handleChangeFilter = (key, value) => {
    switch (key) {
      case 'search':
        setDataFilter({
          ...dataFilter,
          search: value
        })
        break;
      case 'chapter':
        setDataFilter({
          ...dataFilter,
          chapter: value
        })
        break;
      case 'status':
        setDataFilter({
          ...dataFilter,
          status: value
        })
        break;
      case 'berkas':
        setDataFilter({
          ...dataFilter,
          berkas: value
        })
        break;
      default:
        break;
    }
  }

  const getChapterName = (chapterList, chapter) => {
    if (chapterList && chapter) {
      let temp = chapterList.filter(a => a._id === chapter)[0]
      return temp ? temp.chapter : '-'
    }
  }

  const getTotal = (list, key, value) => {
    if (list) {
      if (key === 'kelamin') {
        let count = list.filter(a => dataFilter.chapter ? a.chapterId === dataFilter.chapter : a)
          .filter(a => dataFilter.status !== null ? a.statusVerifikasiBerkas === dataFilter.status : a)
          .filter(a => dataFilter.berkas !== null ? dataFilter.berkas === checkBerkas(a) : a)
        if (value !== 'all') {
          count = count.filter(a => (a.dataPribadi && a.dataPribadi.kelamin) === value)
        }
        if (count) {
          return count.length
        } else {
          return 0
        }
      }
    }
  }

  const checkBerkas = (peserta) => {
    if (peserta) {
      if (
        !(peserta.suratPernyataanSekolah && peserta.suratPernyataanSekolah.file) ||
        !(peserta.suratIzinOrangTua && peserta.suratIzinOrangTua.file) ||
        !(peserta.suratIzinOrangTua && peserta.suratIzinOrangTua.file) ||
        !(peserta.suratRekomendasiKeluarga && peserta.suratRekomendasiKeluarga.file) ||
        !(peserta.suratRekomendasiSekolah && peserta.suratRekomendasiSekolah.file) ||
        !(peserta.suratRekomendasiTemanDekat && peserta.suratRekomendasiTemanDekat.file) ||
        !(peserta.raport && peserta.raport.file) ||
        !(peserta.aktaKelahiran && peserta.aktaKelahiran.file) ||
        !(peserta.skhunIjazah && peserta.skhunIjazah.file) ||
        !(peserta.sertifikatDll && peserta.sertifikatDll.file)
      ) {
        return false
      } else {
        return true
      }
    }
  }

  const downloadAndZip = urls => {
    Swal.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      text: "Proses download berkas sedang berlangsung, silahkan tunggu.",
      onOpen: () => {
        Swal.showLoading()
      }
    })
    var zip = new JSZip();
    var urls = [
      datacr && datacr.suratPernyataanSekolah && datacr.suratPernyataanSekolah.file,
      datacr && datacr.suratIzinOrangTua && datacr.suratIzinOrangTua.file,
      datacr && datacr.suratRekomendasiKeluarga && datacr.suratRekomendasiKeluarga.file,
      datacr && datacr.suratRekomendasiSekolah && datacr.suratRekomendasiSekolah.file,
      datacr && datacr.suratRekomendasiTemanDekat && datacr.suratRekomendasiTemanDekat.file,
      datacr && datacr.raport && datacr.raport.file,
      datacr && datacr.aktaKelahiran && datacr.aktaKelahiran.file,
      datacr && datacr.skhunIjazah && datacr.skhunIjazah.file,
      datacr && datacr.sertifikatDll && datacr.sertifikatDll.file,
    ];
    var urlsName = [
      'Surat Pernyataan Sekolah.pdf',
      'Surat Pernyataan Orang Tua.pdf',
      'Surat Rekomendasi Lingkungan Rumah.pdf',
      'Rekomendasi Lingkungan Sekolah.pdf',
      'Rekomendasi Teman Dekat.pdf',
      'Raport.pdf',
      'Akta Kelahiran.pdf',
      'SKHUN & Ijazah SMP.pdf',
      'Sertifikat dan lain-lain.pdf',
    ];
    var count = 0;
    var zipFilename = `${getMyName(datacr && datacr.dataPribadi)}.zip`;
    urls.forEach(function (url, i) {
      if (url) {
        var filename = urlsName[i];
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) throw err;
          zip.file(filename, data, {
            binary: true,
            createFolders: true
          });
          count++;
          if (count == urls.length) {
            zip.generateAsync({
              type: "blob"
            })
              .then(function (blob) {
                Swal.close()
                saveAs(blob, zipFilename);
              });
          }
        });
      } else {
        count++;
        if (count == urls.length) {
          zip.generateAsync({
            type: "blob"
          })
            .then(function (blob) {
              Swal.close()
              saveAs(blob, zipFilename);
            });
        }
      }
    });
  }

  const setAlamatDisplayText = (count, text) => {
    if (text && count) {
      let txt = text.slice(0, count)
      return `${txt}...`
    } else {
      return text
    }
  }

  const handleImport = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const file = event.target.files[0];
    const sendData = new FormData()
    sendData.append('file', file)
    hiddenFileInput.current.value = '';
    Dispatch(importExcel(
      `/peserta/verifikasi-berkas`,
      sendData,
      Swal,
      setRefetch,
      refetch
    ))
  };

  return (
    <div className="data-container-wrapper" >
      <div className="bread-cab-container">
        <span className="input-data-header">
          Verifikasi Berkas
        </span>
      </div>
      <Breadcrumb
        links={[
          {
            label: 'Verifikasi Berkas',
            link: false
          }
        ]}
      />
      <div className="daftar-component-wrapper" >
        <div className="daftar-component-header" >
          <span className="daftar-header-font" >
            Verifikasi Berkas
          </span>
        </div>
        <div className="daftar-table-wrapper" >
          <div className="daftar-filter-table" style={{ justifyContent: 'space-between' }} >
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <div className='flex wrapping-filter-001'>
                <div className="search-filter wrapping-filter-002"
                  style={{ height: "fit-content" }}
                >
                  <Autocomplete
                    filterOptions={filterOptions}
                    style={{
                      marginBottom: 'auto'
                    }}
                    options={
                      (verifikasiBerkas?.search || [])
                    }
                    noOptionsText={'Tidak Ditemukan'}
                    getOptionLabel={(option) => `${getMyName(option?.dataPribadi)}`}
                    freeSolo
                    fullWidth
                    id="free-solo-2-demo"
                    onChange={(_, val) => [handleChangeFilter('search', val?._id), setPage(1)]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className='search-nilai-cus'
                        placeholder='Cari'
                        variant="outlined"
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          endAdornment: (
                            <InputAdornment position="end">
                              <i class="fas fa-search search-custom-filter" style={{ minWidth: 47, paddingRight: 0 }}></i>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                    renderOption={(option) => (
                      <div>
                        <div>{getMyName(option && option.dataPribadi)}</div>
                        <div className='sch-auto'>{getValue(option && option.riwayatPendidikan, 'namaSMA')}</div>
                      </div>
                    )}
                  />
                </div>
                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-004 filter-004-display`} style={{ textTransform: 'capitalize' }} >
                  <InputLabel className="filter-table-peserta-placeholder" shrink={false}
                  >
                    {
                      profile && profile.role === 'admin chapter' ?
                        getChapterName(chapter, profile?.chapterId)
                        :
                        !dataFilter.chapter && 'Filter Chapter'
                    }
                  </InputLabel>
                  <Select
                    native={false}
                    value={dataFilter.chapter}
                    onChange={(e) => [handleChangeFilter('chapter', e.target.value), setPage(1)]}
                    className={classes.formControlNormaly}
                    style={{ marginLeft: 0, outline: 'none' }}
                    IconComponent={FilterListIcon}
                    inputProps={{
                      style: {
                        backgroundColor: 'white',
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                      getContentAnchorEl: null
                    }}
                    disabled={profile && profile.role === 'admin chapter' ? true : false}
                  >
                    <MenuItem className={!dataFilter.chapter ? "value-selectable-active" : "value-selectable"} value=""> Seluruh Chapter</MenuItem>
                    {
                      chapter?.sort((a, b) => a.chapter < b.chapter ? -1 : a.chapter > b.chapter ? 1 : 0)
                        .map((chapter, i) => (
                          <MenuItem key={i} className={dataFilter.chapter === chapter._id ? "value-selectable-active" : "value-selectable"} value={chapter._id} style={{ textTransform: 'capitalize' }}> {chapter.chapter} </MenuItem>
                        ))
                    }
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-005 filter-005-display`} >
                  <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                    {
                      dataFilter.status === null && 'Filter Status'
                    }
                  </InputLabel>
                  <Select
                    native={false}
                    value={dataFilter.status}
                    onChange={(e) => [handleChangeFilter('status', e.target.value), setPage(1)]}
                    className={classes.formControlNormaly}
                    style={{ marginLeft: 0, outline: 'none' }}
                    IconComponent={FilterListIcon}
                    inputProps={{
                      style: {
                        backgroundColor: 'white',
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem className={!dataFilter.status ? "value-selectable-active" : "value-selectable"} value={null}> Seluruh Status</MenuItem>
                    <MenuItem className={dataFilter.status === true ? "value-selectable-active" : "value-selectable"} value={true}>Lulus</MenuItem>
                    <MenuItem className={dataFilter.status === false ? "value-selectable-active" : "value-selectable"} value={false}>Tidak Lulus</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={`${classes.formControlNormalyCustom} wrapping-filter-006 filter-006-display`}>
                  <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                    {
                      dataFilter.berkas === null && 'Filter Berkas'
                    }
                  </InputLabel>
                  <Select
                    native={false}
                    value={dataFilter.berkas}
                    onChange={(e) => [handleChangeFilter('berkas', e.target.value), setPage(1)]}
                    className={classes.formControlNormalyCustom}
                    style={{ marginLeft: 0, outline: 'none' }}
                    IconComponent={FilterListIcon}
                    inputProps={{
                      style: {
                        backgroundColor: 'white',
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem className={!dataFilter.berkas ? "value-selectable-active" : "value-selectable"} value={null}> Tampilkan Seluruh Data</MenuItem>
                    <MenuItem className={dataFilter.berkas === true ? "value-selectable-active" : "value-selectable"} value={true}>Berkas Sudah Lengkap</MenuItem>
                    <MenuItem className={dataFilter.berkas === false ? "value-selectable-active" : "value-selectable"} value={false}>Berkas Belum Lengkap</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="wrapper-filter-column" >
                <FormControl variant="outlined" className={`${classes.formControlNormaly} wrapping-filter-005 filter-005-display-column`} >
                  <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                    {
                      dataFilter.status === null && 'Filter Status'
                    }
                  </InputLabel>
                  <Select
                    native={false}
                    value={dataFilter.status}
                    onChange={(e) => [handleChangeFilter('status', e.target.value), setPage(1)]}
                    className={classes.formControlNormaly}
                    style={{ marginLeft: 0, outline: 'none' }}
                    IconComponent={FilterListIcon}
                    inputProps={{
                      style: {
                        backgroundColor: 'white',
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem className={!dataFilter.status ? "value-selectable-active" : "value-selectable"} value={null}> Seluruh Status</MenuItem>
                    <MenuItem className={dataFilter.status === true ? "value-selectable-active" : "value-selectable"} value={true}>Lulus</MenuItem>
                    <MenuItem className={dataFilter.status === false ? "value-selectable-active" : "value-selectable"} value={false}>Tidak Lulus</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={`${classes.formControlNormalyCustom} wrapping-filter-006 filter-006-display-column`} >
                  <InputLabel className="filter-table-peserta-placeholder" shrink={false}>
                    {
                      dataFilter.berkas === null && 'Filter Berkas'
                    }
                  </InputLabel>
                  <Select
                    native={false}
                    value={dataFilter.berkas}
                    onChange={(e) => [handleChangeFilter('berkas', e.target.value), setPage(1)]}
                    className={classes.formControlNormalyCustom}
                    style={{ marginLeft: 0, outline: 'none' }}
                    IconComponent={FilterListIcon}
                    inputProps={{
                      style: {
                        backgroundColor: 'white',
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem className={!dataFilter.berkas ? "value-selectable-active" : "value-selectable"} value={null}> Tampilkan Seluruh Data</MenuItem>
                    <MenuItem className={dataFilter.berkas === true ? "value-selectable-active" : "value-selectable"} value={true}>Berkas Sudah Lengkap</MenuItem>
                    <MenuItem className={dataFilter.berkas === false ? "value-selectable-active" : "value-selectable"} value={false}>Berkas Belum Lengkap</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={{ rowGap: 5, columnGap: 5, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }} >
              <Fragment>
                <Button variant='contained' className='btn-ekspor' onClick={handleImport}>Import</Button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  accept=".xlsx, .xls"
                />
              </Fragment>
              <Button variant='contained' className='btn-ekspor' onClick={exportFile}>Export</Button>
            </div>
          </div>
          <div className="daftar-filter-table" style={{ marginTop: '1vw', flexWrap: 'wrap', rowGap: 10, columnGap: 10 }} >
            <div style={{ display: 'flex' }} >
              <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', backgroundColor: '#3c98dc', padding: '5px 15px 5px 15px', borderRadius: '5px 0px 0px 5px', color: "white" }} >
                <span>Perempuan : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {verifikasiBerkas?.count?.female || 0}
                </span>
              </div>
              <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', borderRadius: '0px 0px 0px 0px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', backgroundColor: '#3c98dc', color: "white" }} >
                <span>Laki-laki : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {verifikasiBerkas?.count?.male || 0}
                </span>
              </div>
              <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 5px 5px 0px', backgroundColor: '#3c98dc', color: 'white' }} >
                <span>Total Peserta : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {(verifikasiBerkas?.count?.male || 0) + (verifikasiBerkas?.count?.female || 0)}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', padding: '5px 15px 5px 15px', borderRadius: '5px 0px 0px 5px', color: "white", backgroundColor: '#3c98dc' }} >
                <span>SMK : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {verifikasiBerkas?.count?.smk || 0}
                </span>
              </div>
              <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 0px 0px 0px', color: "white", backgroundColor: '#3c98dc' }} >
                <span>SMA : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {verifikasiBerkas?.count?.sma || 0}
                </span>
              </div>
              <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 0px 0px 0px', color: "white", backgroundColor: '#3c98dc' }} >
                <span>MA : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {verifikasiBerkas?.count?.ma || 0}
                </span>
              </div>
              <div style={{ display: 'flex', borderLeft: '2px solid #DDDDDD', padding: '5px 15px 5px 15px', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', borderRadius: '0px 5px 5px 0px', color: "white", backgroundColor: '#3c98dc' }} >
                <span>Pesantren : </span>
                <span style={{ marginLeft: 3, fontSize: 15 }} >
                  {verifikasiBerkas?.count?.pesantren || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="daftar-filter-table" style={{ marginTop: '1vw', flexWrap: 'wrap', rowGap: 10, columnGap: 10 }} >
            <div style={{ display: 'flex' }}>
              {
                verifikasiBerkas?.daftarSekolah?.map((x, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', fontWeight: 'bolder', fontFamily: 'arial', padding: '5px 15px 5px 15px', borderRadius: index === 0 ? '5px 0px 0px 5px' : verifikasiBerkas?.daftarSekolah?.length - 1 === index ? '0px 5px 5px 0px' : '0px 0px 0px 0px', color: "white", backgroundColor: '#3c98dc', borderLeft: index !== 0 && '2px solid #DDDDDD' }} >
                    <span>{`${x?._id?.jenis} - ${x?._id?.sekolah}`} : </span>
                    <span style={{ marginLeft: 3, fontSize: 15 }} >
                      {x?.total || 0}
                    </span>
                  </div>
                ))
              }
            </div>
          </div>
          {profile && profile.role === 'admin chapter' &&
            <div className='flex mt15'>
              <div className='div-otoritas-label'>
                <div className='ellip-oto bg-blue' />
                <span className='c-blue'>Akses dibuka</span>
              </div>
              <div className='div-otoritas-label ml20'>
                <div className='ellip-oto bg-red' />
                <span className='c-red'>Akses ditutup</span>
              </div>
            </div>
          }
          <div className="daftar-table cus-table" style={{ maxWidth: '100vw', position: 'relative' }}>
            <TableContainer className='table-con' component={Paper} style={{ maxHeight: 80 * 5 + 95, border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 2 }}>
              <Table aria-label="simple table" className='table-fix' id='exporttable-verifikasi' stickyHeader>
                <TableHead className='head-table-seleksi'>
                  <TableRow
                  >
                    <TableCell align="center"
                      style={{
                        zIndex: 999,
                        left: 0,
                        minWidth: 67,
                        height: 95
                      }}
                    >
                      NO
                    </TableCell>
                    <TableCell align="center" style={{ zIndex: 999, minWidth: 103, left: 67 }}>NO. PESERTA</TableCell>
                    <TableCell align="center" style={{ zIndex: 999, minWidth: 190, left: 170 }}>NAMA</TableCell>
                    <TableCell align="center" style={{ zIndex: 99, minWidth: 200 }}>SEKOLAH</TableCell>
                    <TableCell align="center" style={{ zIndex: 99, minWidth: 222 }} >ALAMAT</TableCell>
                    <TableCell align="center" style={{ zIndex: 99, minWidth: 200 }}>LULUS/TIDAK LULUS<br />VERIFIKASI BERKAS</TableCell>
                    {!isExport && <TableCell align="center" className='table-border-left col-fix head-table-seleksi' style={{ minWidth: 173, right: 0, zIndex: 999 }} >AKSI</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  {
                    verifikasiBerkas?.data?.map((peserta, i) => (
                      <TableRow className='table-row' key={i} >
                        <TableCell className={`${classStatus(peserta)} col-fix`} style={{ left: 0, minWidth: 67, height: 80 }}>
                          <div className='div-no-veri'>
                            <div>  {((page - 1) * limit) + i + 1}</div>
                            {
                              profile &&
                              profile.role === 'admin chapter' &&
                              <Fragment>
                                {
                                  profile &&
                                  profile.role === 'admin chapter' &&
                                  <Fragment>
                                    {
                                      peserta &&
                                      peserta.otoritasiChapter === true &&
                                      <div className='ellip-oto bg-blue ml10' style={{ marginRight: 0 }} />
                                    }
                                    {
                                      peserta &&
                                      peserta.otoritasiChapter === false &&
                                      <div className='ellip-oto bg-red ml10' style={{ marginRight: 0 }} />
                                    }
                                  </Fragment>
                                }
                              </Fragment>
                            }
                          </div>
                        </TableCell>
                        <TableCell align="left" className={`${classStatus(peserta)} col-fix`} style={{ minWidth: 103, left: 67 }}>{noPesertaGenerate(peserta)}</TableCell>
                        <TableCell align="left" className={`${classStatus(peserta)} col-fix`} style={{ minWidth: 190, left: 170 }}>{getMyName(peserta && peserta.dataPribadi)}</TableCell>
                        <TableCell align="center" className='' >{getValue(peserta && peserta.riwayatPendidikan, 'namaSMA')}</TableCell>
                        <TableCell align="center" style={{ maxWidth: 350, minWidth: 350 }} className=''>{setAlamatDisplayText(90, getAddressPlace(peserta && peserta.dataPribadi))}</TableCell>
                        <TableCell align="center" style={{
                          maxWidth: 200,
                          minWidth: 200,
                          backgroundColor: peserta.statusVerifikasiBerkas === true ? "#c6efce" : peserta.statusVerifikasiBerkas === false && "#f2c5ce",
                          color: peserta.statusVerifikasiBerkas === true ? "#53824f" : peserta.statusVerifikasiBerkas === false && "#843840"
                        }} className=''>{peserta.statusVerifikasiBerkas === true ? 'Lulus' : peserta.statusVerifikasiBerkas === false && 'Tidak Lulus'}</TableCell>
                        {/* <TableCell align="center" className=''>
                                                {
                                                    peserta &&
                                                        (peserta.statusVerifikasiBerkas !== null) ?
                                                        <div className='div-rank'>
                                                            <span>{}</span>
                                                            <Button className='btn-pilihan' onClick={(event) => handleClick(event, peserta)}>
                                                                <i class="fas fa-caret-down arr-cus"></i>
                                                            </Button>
                                                        </div> :
                                                        <Button variant='outlined' color='primary' onClick={(event) => handleClickInputStatus(event, peserta)} className='btn-outlined btn-out-blue'>Input Status</Button>
                                                }
                                            </TableCell> */}
                        {!isExport &&
                          <TableCell align="center" className='table-border-left col-fix' style={{ minWidth: 173, right: 0 }}>
                            <div className='div-rank'>
                              <Button color='primary' variant='outlined' className='btn-outlined w-aksi p4' style={{ width: 100 }} onClick={(event) => handleClick2(event, peserta)}>Pilihan <i class="fas fa-caret-down ml-5"></i></Button>
                            </div>
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                  <Menu
                    className='menu-table'
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose2}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <MenuItem className='menu-item-table' onClick={() => [setOpenModal(true), handleClose2()]}>Lihat Berkas</MenuItem>
                    <Divider />
                    <Link
                      to={{ pathname: `/profil/${datacr && datacr._id}` }}
                      target="_BLANK"
                      style={{
                        textDecoration: 'none',
                        marginLeft: 'auto',
                        cursor: 'pointer'
                      }}
                    >
                      <MenuItem className='menu-item-table'>Lihat Profil</MenuItem>
                    </Link>
                    <Divider />
                    <MenuItem className='menu-item-table' onClick={() => downloadAndZip()}>Download Seluruh Berkas</MenuItem>
                    <Divider />
                    <Link
                      to={{ pathname: `/download-profile/${datacr && datacr._id}` }}
                      target="_BLANK"
                      style={{
                        textDecoration: 'none',
                        marginLeft: 'auto',
                        cursor: 'pointer'
                      }}
                    >
                      <MenuItem className='menu-item-table'>Download Profil</MenuItem>
                    </Link>
                  </Menu>
                  <Menu
                    className='menu-table'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <MenuItem className='menu-item-table' onClick={(event) => handleClickUbahStatus(event)}>Ubah Status</MenuItem>
                    <Divider />
                    <MenuItem className='menu-item-table' onClick={() => handleModalPropsOpen(null)}>Hapus</MenuItem>
                  </Menu>
                  <Menu
                    className='menu-table'
                    anchorEl={anchorElUbahStatus}
                    keepMounted
                    open={Boolean(anchorElUbahStatus)}
                    onClose={handleCloseUbahStatus}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                  >
                    {
                      datacr &&
                        datacr.statusVerifikasiBerkas === false ?
                        <MenuItem className='menu-item-table' onClick={() => handleModalPropsOpen(true)}>Lulus</MenuItem>
                        :
                        <MenuItem className='menu-item-table' disabled>Lulus</MenuItem>
                    }
                    <Divider />
                    {
                      datacr &&
                        datacr.statusVerifikasiBerkas === true ?
                        <MenuItem className='menu-item-table' onClick={() => handleModalPropsOpen(false)}>Tidak Lulus</MenuItem>
                        :
                        <MenuItem className='menu-item-table' disabled>Tidak Lulus</MenuItem>
                    }
                  </Menu>
                  <Menu
                    className='menu-table'
                    anchorEl={anchorElInputStatus}
                    keepMounted
                    open={Boolean(anchorElInputStatus)}
                    onClose={handleCloseInputStatus}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <MenuItem className='menu-item-table' onClick={() => handleModalPropsOpen(true, true)}>Lulus</MenuItem>
                    <Divider />
                    <MenuItem className='menu-item-table' onClick={() => handleModalPropsOpen(false, true)}>Tidak Lulus</MenuItem>
                  </Menu>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <PaginationCustom
            handleChangePage={handleChangePage}
            page={page}
            setPage={setPage}
            dataFilter={verifikasiBerkas?.length || 0}
            limit={limit}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {
        profile &&
          profile.role === 'admin chapter' ?
          <ModalBerkasChapter open={openModal} handleClose={closeModal} peserta={datacr} handleUpload={handleUpload} /> :
          <ModalBerkas open={openModal} handleClose={closeModal} peserta={datacr} changeOtoritasiChapter={handleModalPropsOpenOtoritas} />
      }
      <ModalCustom action={modalProps} />
    </div>
  )
}

export default VerifikasiBerkas