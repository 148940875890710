import React, { Fragment, useState } from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Swal from 'sweetalert2'
import ClipLoader from "react-spinners/BarLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { changeStatusAdminChapter, deleteAdminChapter, changePasswordAdminChapter } from '../../../../store/actions/user'
import ModalChangePassword from '../../../../components/changePassword'

// MATERIAL
const AddButton = withStyles((theme) => ({
    root: {
        width: '210px',
        height: '44px',
        color: 'white',
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: 'none',
        },
    },
}))(Button);

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    tableHeader:{
        background: '#FAFAFA',
        borderRadius: '2px 2px 0px 0px',
    },
    tableHeaderFont : {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#888888',
    },
    tableMain: {
        boxShadow: "none",
        border: '0.5px solid #D1D1D1',
        borderRadius: '2px',
        width: '100%'
    },
    pagination:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#999999',
        paddingLeft: 0,
        marginTop: 10
    },
    paginationRight:{
        marginTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width : '60%'
    },
    formControl: {
        '& label.Mui-focused': {
            color: '#3598DC',
        },
        '& label': {
            color: '#3598DC',
        },
        '&:hover label': {
            color: 'white',
        },
        '& .MuiSelect-root':{
            paddingTop: 0,
            paddingBottom: 15,
            borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            width: '92px',
            height: '32px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            transition: '0.8s',
            '&.Mui-focused fieldset': {
                border: '1px solid #3598DC',
                color: '#3598DC',
                background: 'white',
            },
            '&:hover fieldset': {
                border: 'none',
                background: '#3598DC',
            },
        },
        '& .MuiSelect-iconOutlined':{
            color: '#3598DC',
            zIndex: 99
        },
        '&:hover .MuiSelect-iconOutlined':{
            color: 'white',
            zIndex: 99
        },
    },
}));

function AdminChapter(){
    const chapter = useSelector(state=>state.chapterState.chapter)
    const profile = useSelector(state=>state.userState.profile)
    const adminChapter = useSelector(state=>state.userState.adminChapter)
    const classes = useStyles();
    const History = useHistory();
    const Dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [limit, setRowsPerPage] = useState(10);
    const [adminData, setAdminData] = useState(null)
    const [modalToggle , setModalToggle] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [click, setClick] = useState(false)
    const [error, setError] = useState('')

    const [searchValue, setSearchValue] = useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    
    const HandleFromIndex = (from, to, count) => {
        return `dari ${count} Data`
    } 

    const handleChange = (event, value) => {
        setPage(value-1)
    };

    const handleEditStatus = (admin) => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if(admin) {
            SwalBootstrap.fire({
                title: `${admin.status ? 'Non-Aktifkan' : 'Aktifkan'}`,
                text: `${admin.nama}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    Dispatch(changeStatusAdminChapter(!admin.status, Swal, admin._id))
                } else if ( result.dismiss === Swal.DismissReason.cancel) {
                }
            })
        }
    }

    const handleModalDelete = (admin) => {
        const SwalBootstrap = Swal.mixin({
            customClass: {
                confirmButton: 'btn-finalisasi-yakin',
                cancelButton: 'btn-finalisasi-tidak'
            },
            buttonsStyling: false
        })
        if(admin) {
            SwalBootstrap.fire({
                title: `Hapus`,
                text: `${admin.nama}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    Dispatch(deleteAdminChapter(Swal, admin._id))
                } else if ( result.dismiss === Swal.DismissReason.cancel) {
                }
            })
        }
    }

    const getChapterName = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.chapter : '-'
        }
    }

    const modalHandler = (e, data) => {
        if (e) {e.preventDefault();}
        resetPassword()
        setModalToggle(!modalToggle)
        setAdminData(data)
    }

    const passwordHandler = () => {
        setShowPassword(!showPassword)
    }

    const passwordHandlerRepeat = () => {
        setShowPasswordRepeat(!showPasswordRepeat)
    }

    const handleChangePass = () => {
        setClick(true)
        if(password && passwordRepeat) {
            if (password !== passwordRepeat) {
                setError('Password tidak sama')
            } else {
                Dispatch(changePasswordAdminChapter(password, Swal, adminData._id, setError, resetPassword))
            }
        }
    }

    const resetPassword = () => {
        setAdminData(null)
        setModalToggle(false)
        setShowPassword(false)
        setPassword('')
        setShowPasswordRepeat(false)
        setPasswordRepeat('')
        setClick(false)
        setError('')
    }

    return (
        <div className="pengaturan-umum-2021-wrapper" >
            <div className="penerbitan-content-left-header" >
                <span>
                    ADMIN CHAPTER
                </span>
            </div>
            <div className="daftar-table-wrapper" >
                <div className="daftar-filter-table">
                    <div className="search-filter filter-table" style={{ width: '385px' }}> 
                        <input onChange={e=>setSearchValue(e.target.value)} type="text" className="filter-table" style={{ width: '100%', borderLeft: 'none', borderRadius: '4px 0px 0px 4px', backgroundColor: 'white', color: searchValue ? '#6b6b6b' : '' }}  placeholder="Cari" />
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="search-custom-filter"
                            style={{ minWidth: 47 }}
                        />
                    </div>
                    {
                        (profile && profile.role === 'admin nasional') &&
                        <div className="tambah-chapter-wrap-btn" >
                            <AddButton onClick={()=> History.push('/pengaturan-umum/admin-chapter/tambah')} >
                                <AddCircleIcon style={{ marginRight: 14 }} />
                                Tambah Admin
                            </AddButton>
                        </div>
                    }
                </div>
                <div className="daftar-table">
                    <TableContainer className={classes.tableMain} component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow className={classes.tableHeader} >
                                    <TableCell className={classes.tableHeaderFont} >NO</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">CHAPTER</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">NAMA</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">EMAIL</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">NO. HP</TableCell>
                                    <TableCell className={classes.tableHeaderFont} align="left">ALAMAT</TableCell>
                                    {
                                        (profile && profile.role === 'admin nasional') &&
                                        <Fragment>
                                            <TableCell className={classes.tableHeaderFont} align="left">STATUS</TableCell>
                                            <TableCell className={classes.tableHeaderFont} align="left">AKSI</TableCell>
                                        </Fragment>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                adminChapter?.filter(admin=> ((admin.nama).toLowerCase() || getChapterName(chapter, admin.chapterId)).includes((searchValue).toLowerCase()))
                                .slice(page * limit, page * limit + limit)
                                .sort((a,b) =>  getChapterName(chapter, a.chapterId) < getChapterName(chapter, b.chapterId) ? -1 : getChapterName(chapter, a.chapterId) > getChapterName(chapter, b.chapterId) ? 1 : 0)
                                .map((admin,i) => (
                                    <TableRow key={i}  className="table-hover-style-custom"
                                        style={{
                                            backgroundColor: !admin.status && '#FFD9D9'
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {((page) * limit ) + i + 1}
                                        </TableCell>
                                        <TableCell align="left" style={{ textTransform: 'capitalize' }} >{getChapterName(chapter, admin.chapterId)}</TableCell>
                                        <TableCell align="left">{admin.nama ? admin.nama : '-'}</TableCell>
                                        <TableCell align="left">{admin.email ? admin.email : '-'}</TableCell>
                                        <TableCell align="left">{admin.telepon ? admin.telepon : '-'}</TableCell>
                                        <TableCell align="left">{admin.alamat ? admin.alamat : '-'}</TableCell>
                                        {
                                            (profile && profile.role === 'admin nasional') &&
                                            <Fragment>
                                                <TableCell align="left">{admin.status ? 'Aktif' : 'Tidak Aktif'}</TableCell>
                                                <TableCell align="left">
                                                    <FormControl variant="outlined" className={classes.formControl}>
                                                        <InputLabel className="aksi-placeholder" shrink={false}>
                                                            Aksi
                                                        </InputLabel>
                                                        <Select
                                                            native={false}
                                                            value={''}
                                                            className={classes.formControl}
                                                            style={{ marginLeft: 0, outline: 'none' }}
                                                            IconComponent={ArrowDropDownIcon}
                                                            inputProps={{
                                                                style:{
                                                                    backgroundColor: 'white',
                                                                }
                                                            }}
                                                        >
                                                            <div className="info-chapter-aksi-position-modal" >
                                                                <MenuItem className="value-selectable-aksi"  value="" onClick={()=>History.push('/pengaturan-umum/admin-chapter/edit',{ admin })} > Edit </MenuItem>
                                                                <MenuItem className={ 'value-selectable-aksi' } onClick={e=>modalHandler(e,admin)} value=""> Ganti Password </MenuItem>
                                                                <MenuItem className={ !admin.status ? "value-selectable-aksi" : "value-selectable-aksi-red-mid"} onClick={e=>handleEditStatus(admin)} value=""> { admin.status ? "Non-Aktifkan" : 'Aktifkan' } </MenuItem>
                                                                <MenuItem className="value-selectable-aksi-red" value="" onClick={()=> handleModalDelete(admin)}> Hapus </MenuItem>
                                                            </div>
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            </Fragment>
                                        }
                                    </TableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        adminChapter &&
                        adminChapter.length !== 0 ?
                        <div className="pagination-wrapper">
                            <div className="pagination-left" >
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: adminChapter.length }]}
                                    count={adminChapter.length}
                                    page={page}
                                    rowsPerPage={limit}
                                    className={classes.pagination}
                                    labelRowsPerPage="Menampilkan"
                                    labelDisplayedRows={({ from, to, count }) => HandleFromIndex(from, to, count)}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                />
                            </div>
                            <Pagination
                                className={classes.paginationRight}
                                count={Math.ceil(
                                    adminChapter?.filter(admin=> ((admin.nama).toLowerCase() || getChapterName(chapter, admin.chapterId)).includes((searchValue).toLowerCase()))?.length/limit)}
                                onChange={handleChange}
                                variant="outlined"
                                shape="rounded"
                                page={page+1}
                                boundaryCount={3}
                                siblingCount={3}
                            />
                        </div>
                        :
                        <div className="pagination-wrapper" style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                            {
                                adminChapter &&
                                adminChapter.length === 0 ?
                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                    <span>Tidak Ada Admin Chapter</span>
                                </div>
                                :
                                <ClipLoader
                                    height={5}
                                    width={'80%'}
                                    color={"#3598dc"}
                                    loading={true}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
            <ModalChangePassword
                admin={adminData}
                show={modalToggle}
                modalClosed={modalHandler}
                children={"modal"}
                passwordHandler={passwordHandler}
                passwordHandlerRepeat={passwordHandlerRepeat}
                password={password}
                setPassword={setPassword}
                click={click}
                showPassword={showPassword}
                passwordRepeat={passwordRepeat}
                showPasswordRepeat={showPasswordRepeat}
                setPasswordRepeat={setPasswordRepeat}
                error={error}
                setError={setError}
                handleChangePass={handleChangePass}
            />
        </div>
    )
}

export default AdminChapter