import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import KeyLogo from '../../../../images/pin/key.png'
import PersonLogo from '../../../../images/pin/person.png'
import HomeLogo from '../../../../images/pin/home.png'
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from "@material-ui/pickers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import { createPin } from '../../../../store/actions/pin'
import Breadcrumb from '../../../../components/Breadcrumb'

const TerbitkanButton = withStyles((theme) => ({
    root: {
        marginTop: 35,
        width: '180px',
        height: '44px',
        color: '#FFFFFF',
        backgroundColor: '#3598DC',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        justifyContent: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        '&:hover': {
            backgroundColor: '#2f88c6',
            boxShadow: 'none',
        },
    },
}))(Button);

export default function InputData(props) {
    const Dispatch = useDispatch()
    const History = useHistory()
    const chapter = useSelector(state=>state.chapterState.chapter)
    const profile = useSelector(state => state.userState.profile)
    const jumlahPIN = useSelector(state => state.filterState.score)
    const [click, setClick] = useState(false)
    const [sendData, setSendData] = useState({
        chapterId: '',
        jumlah: '',
        expired: null,
    });
    
    const handleChangeValue = (key, value) => {
        switch (key) {
            case 'chapterId':
                setSendData({ ...sendData, chapterId: value})
                break;
            case 'jumlah':
                setSendData({ ...sendData, jumlah: value})
                break;
            case 'expired':
                setSendData({
                    ...sendData,
                    expired: value  && new Date( new Date(value).getFullYear(), new Date(value).getMonth(), new Date(value).getDate(), 23, 59, 59, 999)
                })
                break;
            default:
                break;
        }
    };

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const getChapterCode = (chapterList, chapter) => {
        if (chapterList && chapter) {
            let temp = chapterList.filter(a=> a._id === chapter )[0]
            return temp ? temp.code : '-'
        }
    }

    const savingData = () => {
        const ToastMid = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        setClick(true)
        let flag = false
        
        for (let key in sendData) {
            if (
                key === 'chapterId' ||
                key === 'jumlah' ||
                key === 'expired'
            ) {
                if (!sendData[key]) {
                    flag = true
                    ToastMid.fire({
                        icon: 'warning',
                        title: `Silahkan Lengkapi Persyaratan`
                    })
                    goTop()
                    return
                }
                if (flag) return
            }
            if (flag) return
        }

        if (!flag) {
            Dispatch(createPin(History, {...sendData, terbit: new Date(), chapterCode: getChapterCode(chapter, sendData.chapterId) }, Swal, goTop, reset))
        }

    }

    const reset = () => {
        setClick(false)
        setSendData({
            chapterId: '',
            jumlah: '',
            expired: null,
        })
    }

    return (
        <div className="data-container-wrapper" >
            <div className="bread-cab-container">
                <span className="input-data-header">
                    Management PIN
                </span>
            </div>
            <Breadcrumb
                links={[
                    {
                        label: 'Management PIN',
                        link: true
                    },
                    {
                        label: 'Penerbitan PIN',
                        link: false
                    }
                ]}
            />
            <div className="penerbitan-pin-content-wrapper" >
                <div className="penerbitan-pin-content-left" >
                    <div className="penerbitan-content-left-header" >
                        <span>
                            Terbitkan PIN Baru
                        </span>
                    </div>
                    <div className="penerbitan-content-input-wrapper" >
                        <div className="penerbitan-content-input" >
                            <span className="penerbitan-input-font">
                                Penerbit PIN
                            </span>
                            <select
                                className="penerbitan-input"
                                value={sendData.chapterId}
                                onChange={e=>handleChangeValue('chapterId', e.target.value)}
                                style={{
                                    color: sendData.chapterId ? '#666666' : '#C9C9C9',
                                    border: (click && !sendData.chapterId) && '1px solid red',
                                    textTransform: "capitalize"
                                }}
                            >
                                <option
                                    value=""
                                    style={{
                                        color: sendData?.chapterId ? '#d3d5d7' : '#d3d5d7',
                                    }}
                                    selected
                                >
                                    Pilih Penerbit/Chapter
                                </option>
                                {
                                    chapter?.filter(chap=> profile?.role === 'admin chapter' ? chap?._id === profile?.chapterId : chap?.status === true)
                                    .sort((a,b) =>  a?.chapter < b?.chapter ? -1 : a?.chapter > b?.chapter ? 1 : 0)
                                    .map((chapter,i)=>(
                                        <option key={i} style={{ color: sendData?.chapterId === chapter?._id ? '#3c98dc' : '#666666', textTransform: 'capitalize' }} value={chapter._id}>{chapter.chapter}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="penerbitan-content-input" style={{ marginTop: 20 }}>
                            <span className="penerbitan-input-font">
                                Jumlah PIN
                            </span>
                            <select
                                className="penerbitan-input"
                                value={sendData.jumlah}
                                onChange={e=>handleChangeValue('jumlah', e.target.value)}
                                style={{
                                    color: sendData.jumlah ? '#666666' : '#C9C9C9',
                                    border: (click && !sendData.jumlah) && '1px solid red'
                                }}
                            >
                                <option
                                    value=""
                                    style={{
                                        color: sendData.jumlah ? '#d3d5d7' : '#d3d5d7'
                                    }}
                                    selected
                                >
                                        Pilih Jumlah PIN
                                </option>
                                {
                                    jumlahPIN
                                    .sort((a,b) => b-a)
                                    .map((jum,i)=>(
                                        <option key={i} style={{ color: Number(sendData.jumlah) === jum ? '#3c98dc' : '#666666' }} value={jum}>{jum}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="penerbitan-content-input" style={{ marginTop: 20 }}>
                            <span className="penerbitan-input-font">
                                Berlaku Sampai
                            </span>
                            <div className="penerbitan-input"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: (click && !sendData.expired) && '1px solid red'
                                }}
                            >
                                <DatePicker
                                    clearable
                                    clearLabel="Hapus"
                                    autoOk
                                    views={['year', 'month', 'date']}
                                    placeholder="Tanggal Kadaluarsa"
                                    value={sendData.expired}
                                    onChange={(newValue) => {
                                        handleChangeValue( 'expired', newValue);
                                    }}
                                    animateYearScrolling
                                    okLabel=""
                                    cancelLabel=""
                                    helperText=""
                                    format="dd/MM/yyyy"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        border: '0px',
                                        zIndex: '9',
                                        height: '44px',
                                        borderRadius: '4px',
                                        
                                    }}
                                    minDate={
                                        new Date()
                                    }
                                    maxDate={
                                        new Date("2025-12-31") 
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    style={{
                                        color : "#3c98dc",
                                        fontSize: 18,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="penerbitan-content-input" style={{ marginTop: 0 }}>
                            <span className="penerbitan-input-font">
                            </span>
                            <div className="penerbitan-date-info">
                                Pukul 23:59:59 WIB
                            </div>
                        </div>
                        <div className="penerbitan-content-input" style={{ marginTop: 0 }}>
                            <span className="penerbitan-input-font"></span>
                            <TerbitkanButton onClick={e=>savingData()} > Terbitkan PIN </TerbitkanButton>
                        </div>
                    </div>
                </div>
                <div className="penerbitan-pin-content-right" >
                    <div className="penerbitan-right-content" >
                        <div className="penerbitan-logo-right" >
                            <img src={KeyLogo} alt="icon" style={{ width: 20 }} />
                        </div>
                        <span className="penerbitan-right-font-header" >
                            Apa itu PIN Pendaftaran
                        </span>
                    </div>
                    <span className="penerbitan-right-font">
                        PIN Pendaftaran adalah kode berupa 16 huruf yang digunakan untuk pendaftaran seleksi Bina Antarbudaya.
                    </span>
                    <div className="divider" />
                    <div className="penerbitan-right-content" >
                        <div className="penerbitan-logo-right" >
                            <img src={PersonLogo} alt="icon" style={{ width: 20 }} />
                        </div>
                        <span className="penerbitan-right-font-header" >
                            Bagi Pendaftar
                        </span>
                    </div>
                    <span className="penerbitan-right-font">
                        Mengaktifkan PIN Pendaftaran adalah langkah awal pendaftaran seleksi. Tanpa PIN Pendaftaran, pendaftar tidak dapat membuat akun dan mendaftar untuk proses seleksi Bina Antarbudaya.
                    </span>
                    <div className="divider" />
                    <div className="penerbitan-right-content" >
                        <div className="penerbitan-logo-right" >
                            <img src={HomeLogo} alt="icon" style={{ width: 20 }} />
                        </div>
                        <span className="penerbitan-right-font-header" >
                            Bagi Chapter
                        </span>
                    </div>
                    <span className="penerbitan-right-font">
                        PIN Pendaftaran menjadi mekanisme pendapatan, dengan cara chapter menjual PIN Pendaftaran sebagai biaya administrasi seleksi. Namun, sistem pendaftaran online ini tidak dapat mencatat berapa PIN yang terjual, hanya berapa PIN yang diaktifkan, karena PIN Pendaftaran dapat dibeli namun tidak digunakan.
                    </span>
                </div>
            </div>
        </div>
    )
}