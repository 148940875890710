import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import CheckIcon from '../../images/check.svg'
import InfoIcon from '../../images/info.svg'
import TrashIcon from '../../images/trash.svg'
import './styles.scss'

function ModalCustom(props) {
    const {
        action
    } = props
    return (
            <Dialog
                open={action.open}
                onClose={action.handleModalPropsClose}
                maxWidth='lg'
            >
                <div style={{ width: 500, padding: '30px 40px', textAlign: 'center' }}>
                    {
                        action.icon &&
                        <img src={action.icon === 'info' ? InfoIcon : action.icon === 'success' ? CheckIcon : action.icon === 'delete' && TrashIcon} alt='info' style={{ width: 80, height: 80 }} />
                    }
                    <div className='title-modal-custom'>
                        {action.title}
                    </div>
                    <div className='text-modal-custom' style={{ display: action.text ? 'flex' : 'none' }} >
                        {action.text}
                    </div>
                    <div className="button-wrapper-modal-custom">
                        <div style={{ display: action.buttonConfirm ? 'flex' : 'none', marginRight: action.buttonCancel && 10 }} className='button-confirm-modal-custom' onClick={action.buttonConfirmAction}>
                            { action.buttonConfirmText }
                        </div>
                        <div style={{ display: action.buttonCancel ? 'flex' : 'none', marginLeft: action.buttonConfirm && 10 }} className='button-cancel-modal-custom' onClick={action.buttonCancelAction}>
                            { action.buttonCancelText }
                        </div>
                    </div>
                </div>
            </Dialog>
    )
}

export default ModalCustom