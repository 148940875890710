import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import InfoIcn from '../../images/modal/info-icn.svg'
import './styles.scss'
import Button from '@material-ui/core/Button';

function ModalUbahStatusBerkas(props) {
    function hapus() {
        if (props.data.for === 'input rank') {
            props.changeRank(props.data.rank)
        } else if (props.data.for === 'ubah status' || props.data.for === 'input status') {
            props.changeStatus(props.data.status)
        }
    }
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            maxWidth='lg'
        >
            <div className='con-mo-nilai'>
                <img src={InfoIcn} alt='info' style={{ width: 80, height: 80 }} />
                <div className='txt-modal-nilai'>
                    {
                        props.data.for === 'input rank' ?
                            `Beri Rangking ${props.data.rank} Untuk ${props.data.nama} Ditahap Seleksi Berkas?` : null
                    }
                    {
                        props.data.for === 'input status' ?
                            props.data.status === true ?
                                `Meluluskan ${props.data.nama} Ditahap Seleksi Berkas?` : `Tidak Meluluskan ${props.data.nama} Ditahap Seleksi Berkas?`
                            : null
                    }
                    {
                        props.data.for === 'ubah status' ?
                            `Mengubah Status Kelulusan ${props.data.nama}?`
                            : null
                    }
                </div>
                <div className='cek-nilai'>
                    {
                        props.data.for === 'input rank' ?
                            'Cek kembali keputusan Anda sebelum memberi rangking' : null
                    }
                    {
                        props.data.for === 'input status' ?
                            'Cek kembali keputusan Anda sebelum memberi keputusan kelulusan' : null
                    }
                    {
                        props.data.for === 'ubah status' ?
                            props.data.status === true ?
                                `Anda akan meluluskan ${props.data.nama} ditahap seleksi berkas` : `Anda akan tidak meluluskan ${props.data.nama} ditahap seleksi berkas`
                            : null
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='btn-modal-wawancara btn-blue' style={{ width: 125, height: 44, marginRight: 20 }} onClick={hapus}>Ya, Yakin</div>
                    <Button className='btn-hapus-obs' variant='contained' style={{ width: 125, height: 44, background: '#E7E7E7' }} onClick={props.handleClose}>Batal</Button>
                </div>
            </div>

        </Dialog>
    )
}

export default ModalUbahStatusBerkas