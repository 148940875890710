import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

export default function Back() {
    const history = useHistory()
    return (
        <div className='btn-back' onClick={() => [history.goBack()]}>
            <ArrowBackIcon className='icn-back'/>
            Kembali
        </div>
    )
}
