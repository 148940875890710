import { Route, Switch, useHistory } from 'react-router-dom';
import ReactGa from 'react-ga'
import './App.css';
import Login from './screens/Login'
import Menu from './screens/Menu'
import PINDownload from './screens/Menu/PIN/pdf'
import profilePeserta from './screens/profilePeserta'
import VerificationEmail from './screens/VerificationEmail'
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getProfile } from './store/actions/user'
import Swal from 'sweetalert2'
import BeriNilaiBerkas from './screens/Menu/SeleksiBerkas/BeriNilaiBerkas';
import BeriNilaiInteraksi from './screens/Menu/SeleksiInteraksi/BeriNilaiInteraksi';
import BeriNilaiWawancaraPribadi from './screens/Menu/SeleksiWawancara/BeriNilaiWawancaraPribadi';
import BeriNilaiWawancaraInggris from './screens/Menu/SeleksiWawancara/BeriNilaiWawancaraInggris';
import DetailNilaiBerkas from './screens/Menu/SeleksiBerkas/DetailNilai';
import DetailNilaiKelompok from './screens/Menu/SeleksiInteraksi/DetailNilai';
import DetailNilaiWawancara from './screens/Menu/SeleksiWawancara/DetailNilai';
import BerkasLengkap from './components/BerkasLengkap';

function App() {
  const Dispatch = useDispatch()
  const History = useHistory()

  useEffect(() => {
    Dispatch(getProfile(History, Swal))
    ReactGa.initialize('G-SSKT28Y72Q')
    ReactGa.pageview(window.location.pathname + window.location.search)
  },[])

  return (
    <div className="App">
      <Switch>
        <Route path="/download-profile/:id" component={BerkasLengkap} />
        <Route path="/seleksi-wawancara/detail-nilai/:id" component={DetailNilaiWawancara} />
        <Route path="/seleksi-kelompok/detail-nilai/:id" component={DetailNilaiKelompok} />
        <Route path="/seleksi-berkas/detail-nilai/:id" component={DetailNilaiBerkas} />
        <Route path="/seleksi-berkas/beri-nilai/edit/:id" component={BeriNilaiBerkas} />
        <Route path="/seleksi-wawancara/beri-nilai/bahasa-inggris" component={BeriNilaiWawancaraInggris} />
        <Route path="/seleksi-wawancara/ubah-nilai/bahasa-inggris" component={BeriNilaiWawancaraInggris} />
        <Route path="/seleksi-wawancara/beri-nilai/pribadi" component={BeriNilaiWawancaraPribadi} />
        <Route path="/seleksi-wawancara/ubah-nilai/pribadi" component={BeriNilaiWawancaraPribadi} />
        <Route path="/seleksi-berkas/beri-nilai/:id" component={BeriNilaiBerkas} />
        <Route path="/management-pin/download/:id" component={PINDownload} />
        <Route path="/seleksi-kelompok/beri-nilai" component={BeriNilaiInteraksi} />
        <Route path="/seleksi-kelompok/ubah-nilai" component={BeriNilaiInteraksi} />
        <Route path="/verification/:id" component={VerificationEmail} />
        <Route path="/dashboard" component={Menu} />
        <Route path="/tabulasi" component={Menu} />
        <Route path="/management-pin" component={Menu} />
        <Route path="/seleksi-berkas" component={Menu} />
        <Route path="/seleksi-wawancara" component={Menu} />
        <Route path="/seleksi-kelompok" component={Menu} />
        <Route path="/pengaturan-umum" component={Menu} />
        <Route path="/verifikasi-berkas" component={Menu} />
        <Route path="/profil/:id" component={profilePeserta} />
        <Route path="/" component={Login} />
      </Switch>
    </div>
  );
}

export default App;
