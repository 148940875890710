import axios from 'axios'
import server from '../../endpoint'
import { getChapter } from './chapter'
// import { getPinGroup } from './pin'
import { getOption } from './option'
import { generateToken } from '../../helpers/bearer'
import {
    GET_PROFILE,
    SET_SAVE,
    IS_LOGOUT,
    GET_ADMINCHAPTER,
    SET_ADMINCHAPTER,
    UPDATE_ADMINCHAPTER,
    DELETE_ADMINCHAPTER,
    SET_REFRESH_SELEKSI_BERKAS,
    SET_REFRESH_SELEKSI_WAWANCARA,
    SET_REFRESH_SELEKSI_INTERAKSI_KELOMPOK,
} from "../constanta";

export const logoutUser = ( History, Swal ) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
            type: IS_LOGOUT,
        })
        History.replace('/')
        ToastMid.fire({
            icon: 'success',
            title: `Berhasil Keluar`,
        })
    } catch (err) {
        console.error(err)
    }
}

export const loginUser = ( History, sendData, Swal, checked) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    let options = JSON.parse(localStorage.getItem('options'))
    try {
        Swal.showLoading()
        const { data } = await axios({
            method: 'post',
            url: `${server}/user/login`,
            data: sendData,
            headers: {
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            },
        })
        Swal.close()
        localStorage.setItem('token', data.token)
        localStorage.setItem('options', JSON.stringify({...options, remember: checked, program: 'AFS'}))
        History.replace('/dashboard')
        dispatch({
            type: GET_PROFILE,
            profile : data.profile
        })
        dispatch(getChapter())
        dispatch(getAdminChapter())
        // dispatch(getPinGroup())
        dispatch(getOption())
        ToastMid.fire({
            icon: 'success',
            title: `Selamat Datang ${data.profile.nama}`,
        })
    } catch (err) {
        Swal.close()
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        ToastMid.fire({
                            icon: 'error',
                            title: err.response.data.message
                        })
                    }
                }
            }
        }
    }
}

export const getProfile = ( History, Swal ) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        let options = JSON.parse(localStorage.getItem('options'))
        if( token &&
            (
                options.remember ||
                (History.location.pathname !== '/login')
            )
        ) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/user/profile`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            if (
                History.location.pathname === '/'
            ) {
                if (options.remember) {
                    History.replace('/dashboard')
                    dispatch(getChapter())
                    dispatch(getAdminChapter())
                    // dispatch(getPinGroup())
                    dispatch(getOption())
                }
            } else {
                dispatch(getChapter())
                dispatch(getAdminChapter())
                // dispatch(getPinGroup())
                dispatch(getOption())
            }
            dispatch({
                type: GET_PROFILE,
                profile : data
            })
        } else {
            if (
                ((History.location.pathname).includes('/dashboard'))
            ) {
                History.replace('/')
                ToastMid.fire({
                    icon: 'error',
                    title: `Akses diblokir`,
                })
            }
            dispatch({
                type: IS_LOGOUT
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const getAdminChapter = () => async dispatch => {
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            const { data } = await axios({
                method: 'get',
                url: `${server}/user/admin-chapter`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: GET_ADMINCHAPTER,
                data : data
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const createAdminChapter = ( sendData, Swal, answer) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'post',
                url: `${server}/user/admin-chapter`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            dispatch({
                type: SET_ADMINCHAPTER,
                data : data
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Dibuat`,
            })
            answer()
        }
    } catch (err) {
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        if (err.response.data.message === 'Email Sudah Digunakan!') {
                            ToastMid.fire({
                                icon: 'warning',
                                title: `Email Telah Digunakan`,
                            })
                        }
                    }
                    if (err.response.data.errors) {
                        [...err.response.data.errors].forEach(a=>{
                            if (a === 'Isi alamat email dengan benar') {
                                ToastMid.fire({
                                    icon: 'warning',
                                    title: `Isi alamat email dengan benar`,
                                })
                            } else if (a === 'Password kurang dari 8 karakter') {
                                ToastMid.fire({
                                    icon: 'warning',
                                    title: `Password kurang dari 8 karakter`,
                                })
                            }
                        })
                    }
                }
            }
        }
    }
}

export const updateAdminChapter = ( sendData, Swal, answer, id) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'put',
                url: `${server}/user/admin-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            dispatch({
                type: UPDATE_ADMINCHAPTER,
                data : data
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Diubah`,
            })
            answer()
        }
    } catch (err) {
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        if (err.response.data.message === 'Email Sudah Digunakan!') {
                            ToastMid.fire({
                                icon: 'warning',
                                title: `Email Telah Digunakan`,
                            })
                        }
                    }
                    if (err.response.data.errors) {
                        [...err.response.data.errors].forEach(a=>{
                            if (a === 'Isi alamat email dengan benar') {
                                ToastMid.fire({
                                    icon: 'warning',
                                    title: `Isi alamat email dengan benar`,
                                })
                            } else if (a === 'Password kurang dari 8 karakter') {
                                ToastMid.fire({
                                    icon: 'warning',
                                    title: `Password kurang dari 8 karakter`,
                                })
                            } else if (a === 'email') {
                                ToastMid.fire({
                                    icon: 'warning',
                                    title: `Email telah digunakan`,
                                })
                            }
                        })
                    }
                }
            }
        }
    }
}

export const changeStatusAdminChapter = ( status, Swal, id) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'patch',
                url: `${server}/user/status/admin-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    status
                }
            })
            dispatch({
                type: UPDATE_ADMINCHAPTER,
                data : data
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Diganti`,
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const deleteAdminChapter = ( Swal, id) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            await axios({
                method: 'delete',
                url: `${server}/user/admin-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
            })
            dispatch({
                type: DELETE_ADMINCHAPTER,
                data : id
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Dihapus`,
            })
        }
    } catch (err) {
        console.error(err)
    }
}

export const changePasswordAdminChapter = ( password, Swal, id, setError, resetPassword) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            await axios({
                method: 'patch',
                url: `${server}/user/password/admin-chapter/${id}`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: {
                    password
                }
            })
            dispatch(setSaveAction(false))
            ToastMid.fire({
                icon: 'success',
                title: `Berhasil Diganti`,
            })
            resetPassword()
        }
    } catch (err) {
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.errors) {
                        [...err.response.data.errors].forEach(a=>{
                            if (a === 'Password kurang dari 8 karakter') {
                                setError(`Password kurang dari 8 karakter`)
                            }
                        })
                    }
                }
            }
        }
    }
}

export const setSaveAction = ( action ) => async dispatch => {
    try {
        dispatch({
            type: SET_SAVE,
            action
        })
    } catch (err) {
        if (err) {
            console.error(err)
        }
    }
}

export const verifikasiEmail = ( History, { id, setSuccess }, Swal ) => async dispatch => {
    try {
        await axios({
            method: 'patch',
            url: `${server}/user/verification/${id}`,
            headers: {
                'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
            },
        })
        setSuccess(true)
    } catch (err) {
        Swal.close()
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        setSuccess({ message: err.response.data.message })
                    }
                }
            }
        }
    }
}

export const setRefreshSeleksiBerkas = ( action ) => async dispatch => {
    try {
        dispatch({
            type: SET_REFRESH_SELEKSI_BERKAS,
            action
        })
    } catch (err) {
        if (err) {
            console.error(err)
        }
    }
}

export const setRefreshSeleksiWawancara = ( action ) => async dispatch => {
    try {
        dispatch({
            type: SET_REFRESH_SELEKSI_WAWANCARA,
            action
        })
    } catch (err) {
        if (err) {
            console.error(err)
        }
    }
}

export const setRefreshSeleksiInteraksiKelompok = ( action ) => async dispatch => {
    try {
        dispatch({
            type: SET_REFRESH_SELEKSI_INTERAKSI_KELOMPOK,
            action
        })
    } catch (err) {
        if (err) {
            console.error(err)
        }
    }
}

export const changePassword = ( History, sendData, Swal, setError, resetPassword) => async dispatch => {
    const ToastMid = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    try {
        let token = localStorage.getItem('token')
        if( token ) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            await axios({
                method: 'patch',
                url: `${server}/user/change-password`,
                headers: {
                    token,
                    'accept-bearer-token': generateToken(process.env.REACT_APP_BEARER)
                },
                data: sendData
            })
            Swal.close()
            ToastMid.fire({
                icon: 'success',
                title: `Password Berhasil Diganti, Silahkan Masuk Kembali`,
            })
            History.replace('/')
            resetPassword()
        }
    } catch (err) {
        if (err) {
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        setError(err.response.data.message)
                    }
                    if (err.response.data.errors) {
                        [...err.response.data.errors].forEach(a=>{
                            if (a === 'Password kurang dari 8 karakter') {
                                setError(`Password kurang dari 8 karakter`)
                            }
                        })
                    }
                }
            }
        }
    }
}